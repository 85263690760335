import * as type from "../types";
import {
  getZonesService,
  saveZoneService,
  deleteZoneService,
  getAllZonesService,
} from "../../network/service/ZoneService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchZones = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ZONES_FETCH_REQUEST,
      });
      const response = await dispatch(getZonesService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ZONES_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ZONES_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ZONES_FETCH_FAILURE,
      });
    }
  };
};

export const fetchAllZones = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_ZONES_FETCH_REQUEST,
      });
      const response = await dispatch(getAllZonesService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_ZONES_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_ZONES_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_ZONES_FETCH_FAILURE,
      });
    }
  };
};

export const saveZone = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ZONE_SAVE_REQUEST,
      });
      const response = await dispatch(saveZoneService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ZONE_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.ZONE_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ZONE_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteZone = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ZONE_DELETE_REQUEST,
      });
      const response = await dispatch(deleteZoneService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ZONE_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.ZONE_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ZONE_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
