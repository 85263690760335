import * as type from "../types";
import { format } from "react-string-format";

import {
  authService,
  logoutService,
  getFleetActiveSessionService,
} from "../../network/service/AuthService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";
import axios from "axios";

export const authenticate =
  (params = null) =>
  (dispatch) => {
    dispatch({
      type: type.AUTH_REQUEST,
    });

    const config = {
      method: "POST",
      url: "/api/web/login",
      withCredentials: true,
      params: {
        "remember-me": params["remember-me"],
      },
      headers: {
        Authorization: format(
          "Basic {0}",
          new Buffer(
            format("{0}:{1}", params.username, params.password)
          ).toString("base64")
        ),
      },
    };
    return axios(config)
      .then((response) => {
        dispatch({
          type: type.AUTH_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: type.GET_JWT_SUCCESS,
          payload: response.headers.authorization,
        });
        dispatch({
          type: type.GET_JWT_REFRESH_SUCCESS,
          payload: response.headers["x-auth-refresh-token"],
        });
      })
      .catch((error) => {
        dispatch({
          type: type.AUTH_FAILURE,
          payload: error.response.data.message,
        });
      });
  };

export const loggedInUserChange = (params) => {
  return (dispatch) => {
    dispatch({
      type: type.LOGGGED_IN_USER_SAVE_SUCCESS,
      payload: params,
    });
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.LOGOUT_REQUEST,
      });
      const response = await dispatch(logoutService());
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.LOGOUT_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.LOGOUT_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.LOGOUT_FAILURE,
      });
    }
  };
};

export const logoutJWT = () => {
  return async (dispatch) => {
    dispatch({
      type: type.LOGOUT_JWT,
    });
  };
};

export const selectTenant = (tenant) => {
  return async (dispatch) => {
    dispatch({
      type: type.SELECTED_TENANT,
      payload: tenant,
    });
  };
};

export const getFleetActiveSession = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.FLEET_SESSION_REQUEST,
      });
      const response = await dispatch(getFleetActiveSessionService());
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.FLEET_SESSION_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.FLEET_SESSION_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.FLEET_SESSION_FAILURE,
      });
    }
  };
};

export const forgotPassword =
  (params = null) =>
  (dispatch) => {
    dispatch({
      type: type.FORGOT_PASSWORD_REQUEST,
    });

    const config = {
      method: "POST",
      url: "/api/web/users/reset-password-email",
      withCredentials: true,
      params: {
        email: params.username,
      },
    };
    return axios(config)
      .then((response) => {
        dispatch({
          type: type.FORGOT_PASSWORD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: type.FORGOT_PASSWORD_FAILURE,
          payload: error.response.data.message,
        });
      });
  };

export const resetPassword =
  (params = null) =>
  (dispatch) => {
    dispatch({
      type: type.RESET_PASSWORD_REQUEST,
    });
    const config = {
      method: "POST",
      url: "/api/web/users/password-forgot-reset",
      withCredentials: true,
      params: {
        token: params.token,
      },
      data: {
        newPassword: params.password,
      },
    };
    return axios(config)
      .then((response) => {
        dispatch({
          type: type.RESET_PASSWORD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: type.RESET_PASSWORD_FAILURE,
          payload: error.response.data.message,
        });
      });
  };
