import * as type from "../types";
import {
  getRelaySwitchesService,
  saveRelaySwitchService,
  deleteRelaySwitchService,
  getAllRelaySwitchesService,
} from "../../network/service/RelaySwitchService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchRelaySwitches = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.RELAYSWITCHES_FETCH_REQUEST,
      });
      const response = await dispatch(getRelaySwitchesService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.RELAYSWITCHES_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.RELAYSWITCHES_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.RELAYSWITCHES_FETCH_FAILURE,
      });
    }
  };
};

export const fetchAllRelaySwitches = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_RELAYSWITCHES_FETCH_REQUEST,
      });
      const response = await dispatch(getAllRelaySwitchesService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_RELAYSWITCHES_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_RELAYSWITCHES_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_RELAYSWITCHES_FETCH_FAILURE,
      });
    }
  };
};

export const saveRelaySwitch = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.RELAYSWITCH_SAVE_REQUEST,
      });
      const response = await dispatch(saveRelaySwitchService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.RELAYSWITCH_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.RELAYSWITCH_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.RELAYSWITCH_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteRelaySwitch = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.RELAYSWITCH_DELETE_REQUEST,
      });
      const response = await dispatch(deleteRelaySwitchService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.RELAYSWITCH_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.RELAYSWITCH_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.RELAYSWITCH_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
