import * as type from "../types";
import { RequestState } from "./RequestState";

const INITIAL_STATE = {
  allWithKilledAssets: {},
  assets: {},
  asset: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  assetKilledActionComplete: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.ASSETS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        assets: {},
        loading: true,
      };
    case type.ASSETS_FETCH_SUCCESS:
      return {
        ...state,
        assets: action.payload,
        loading: false,
      };
    case type.ASSETS_FETCH_FAILURE:
      return {
        ...state,
        assets: {},
        loading: false,
      };
    case type.ASSET_SAVE_REQUEST:
      return {
        ...state,
        error: {},
        asset: {},
        requestStatus: RequestState.PENDING,
      };
    case type.ASSET_SAVE_SUCCESS:
      return {
        ...state,
        asset: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.ASSET_SAVE_FAILURE:
      return {
        ...state,
        asset: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
    case type.ASSET_DELETE_REQUEST:
      return {
        ...state,
        requestStatus: RequestState.PENDING,
      };
    case type.ASSET_DELETE_SUCCESS:
      return {
        ...state,
        asset: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.ASSET_DELETE_FAILURE:
      return {
        ...state,
        asset: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };

    case type.ASSET_KILL_REQUEST:
      return {
        ...state,
        RequestState: RequestState.PENDING,
        assetKilledActionComplete: false,
      };
    case type.ASSET_KILL_SUCCESS:
      return {
        ...state,
        asset: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
        assetKilledActionComplete: true,
      };
    case type.ASSET_KILL_FAILURE:
      return {
        ...state,
        asset: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
    case type.CLEAN_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: RequestState.EMPTY,
      };
    case type.ALL_WITH_KILLED_ASSETS_REQUEST:
      return {
        ...state,
        error: {},
        allWithKilledAssets: {},
        loading: true,
      };
    case type.ALL_WITH_KILLED_ASSETS_SUCCESS:
      return {
        ...state,
        allWithKilledAssets: action.payload,
        loading: false,
        error: {},
      };
    case type.ALL_WITH_KILLED_ASSETS_FAILURE:
      return {
        ...state,
        error: action.payload,
        allWithKilledAssets: {},
        requestStatus: RequestState.PENDING,
      };
    default:
      return state;
  }
};

export default reducer;
