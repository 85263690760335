import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select, Tooltip, Row, Col, Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "../../layout/Style.css";
import { getModalSizeByWindowSize } from "../../layout/Constants";
import AddDepartmentForm from "../Departments/AddDepartmentForm";
import {
  fetchDepartments,
  saveDepartment,
} from "../../../api/redux/action/DepartmentActions";

import { ElementIDS } from "../../../components/common/ElementsIDs";

const { Option } = Select;

const AddAssetGroupForm = (props) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState({ department: false });
  const [departmentForm] = Form.useForm();
  const [newDepartmentData, setNewDepartmentData] = useState(undefined);
  const [modalSize, setModalSize] = useState(getModalSizeByWindowSize());

  const [sortedDepartments, setSortedDepartments] = useState([]);

  const { departments, language, englishLang } = useSelector((store) => ({
    departments: store.departmentReducer.departments,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  useEffect(() => {
    dispatch(fetchDepartments());
  }, [newDepartmentData]);

  useEffect(() => {
    if (
      departments &&
      departments.content &&
      newDepartmentData &&
      newDepartmentData.name
    ) {
      departments.content.map((item) => {
        if (item.name == newDepartmentData.name) {
          props.form.setFieldsValue({
            ...(newDepartmentData && {
              department: item.departmentId,
            }),
          });
        }
      });
    }
  }, [departments]);

  useEffect(() => {
    if (departments && departments.content) {
      let sorted = departments.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedDepartments(sorted);
    }
  }, [departments]);

  const handleAddNewDepartment = () => {
    showModal(true);
  };

  const handleDepartmentFormSave = (values) => {
    const formData = {
      name: departmentForm.getFieldValue().name,
    };
    if (Object.keys(formData).length > 0) {
      dispatch(saveDepartment(formData))
        .then(() => {
          setNewDepartmentData(values);

          departmentForm.resetFields();
        })
        .catch((err) => console.log(err));
    }
    setVisible(false);
  };

  const showModal = (department) => {
    setVisible({ department: department });
  };

  const handleModalOk = (e) => {
    departmentForm.submit();
  };

  const handleModalCancel = (e) => {
    setVisible(false);
  };

  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="assetGroup-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        label={language?.NAME?.value ?? englishLang?.NAME?.value}
        name="name"
        rules={requiredFieldRule}
      >
        <Input
          id={ElementIDS().name}
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <Form.Item
        label={language?.DEPARTMENT?.value ?? englishLang?.DEPARTMENT?.value}
      >
        <Row gutter={3}>
          <Col flex={9} span={22}>
            <Form.Item name="department" noStyle>
              <Select
                onChange={(e) => {
                  props.setDirty();
                }}
                allowClear
                style={{ width: "100%" }}
                placeholder={
                  language?.SELECT_DEPARTMENT?.value ??
                  englishLang?.SELECT_DEPARTMENT?.value
                }
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                id={ElementIDS().name}
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              >
                {sortedDepartments
                  ? sortedDepartments.map((item) => (
                      <Option
                        id={ElementIDS().option}
                        key={item.departmentId}
                        value={item.departmentId}
                      >
                        {item.name}
                      </Option>
                    ))
                  : undefined}
              </Select>
            </Form.Item>
          </Col>
          <Col flex={1} span={2}>
            <Tooltip
              title={
                language?.CREATE_NEW_DEPARTMENT?.value ??
                englishLang?.CREATE_NEW_DEPARTMENT?.value
              }
            >
              <Button
                id={ElementIDS().newDepartment}
                icon={<PlusOutlined />}
                ghost
                type="primary"
                onClick={handleAddNewDepartment}
                style={{ width: "100%" }}
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              />
            </Tooltip>
          </Col>
        </Row>
      </Form.Item>

      <Modal
        title={
          language?.ADD_DEPARTMENT?.value ?? englishLang?.ADD_DEPARTMENT?.value
        }
        visible={visible.department}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={modalSize}
      >
        <Row justify="center">
          <Col span={20}>
            <AddDepartmentForm
              handleSave={handleDepartmentFormSave}
              form={departmentForm}
              language={language}
              setDirty={props.setDirty}
            />
          </Col>
        </Row>
      </Modal>
      {props.footer}
    </Form>
  );
};

export default AddAssetGroupForm;
