import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Divider,
  Space,
  Tooltip,
  Row,
  DatePicker,
  Select,
  Button,
  Tag,
  Popover,
} from "antd";
import { RedoOutlined, EnvironmentOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchReport,
  resetReport,
} from "../../../api/redux/action/ReportActions";
import useNotification from "../../../components/common/ResultNotifier";
import { fetchAssets } from "../../../api/redux/action/AssetActions";
import { fetchAssetGroups } from "../../../api/redux/action/AssetGroupActions";
import Header from "../../../components/common/ReportHeader";
import { toTimestamp } from "../../layout/Constants";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import { exportRequest } from "../../../api/network/axios/exportRequest";
import AssetJourneyFirstDetectedMap from "./AssetJourneyFirstDetectedMap";
import AssetJourneyLastDetectedMap from "./AssetJourneyLastDetectedMap";
const dateFormat = "DD-MM-YYYY";
const { Option } = Select;

const { RangePicker } = DatePicker;

function AssetJourney() {
  const today = new Date();
  const todayStr =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [flattenedData, setFlattenedData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [sortedAssetGroups, setSortedAssetGroups] = useState([]);
  const [missingFieldsText, setMissingFieldsText] = useState();
  const [dataTable, setDataTable] = useState();

  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);
  const [assetOptions, setAssetOptions] = React.useState([]);

  const [filter, setFilter] = useState({
    fromDate: todayStr,
    toDate: todayStr,
    assetId: null,
  });

  const {
    user,
    data,
    allData,
    error,
    requestStatus,
    assetGroups,
    assets,
    loading,
    userTimeZone,
    language,
    englishLang,
  } = useSelector((store) => ({
    user: store.authReducer.user,
    data: store.reportReducer.data,
    allData: store.reportReducer.allData,
    error: store.reportReducer.error,
    requestStatus: store.reportReducer.requestStatus,
    assetGroups: store.assetGroupReducer.assetGroups,
    assets: store.assetReducer.assets,
    loading: store.reportReducer.loading,
    userTimeZone: store.authReducer.userTimeZone,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));
  const columns = [
    {
      title: language?.FIXED_MOBILE?.value ?? englishLang?.FIXED_MOBILE?.value,
      dataIndex: ["zoneMovementDetails"],
      render: (text) =>
        text && text.zoneId ? (
          <Tag>{language?.FIXED?.value ?? englishLang?.FIXED?.value}</Tag>
        ) : (
          <Tag>{language?.MOBILE?.value ?? englishLang?.MOBILE?.value}</Tag>
        ),
    },

    {
      key: "firstSeen",
      sorter: true,
      sortField: "firstSeen",
      sortOrder: sortedInfo.columnKey === "firstSeen" && sortedInfo.order,
      title:
        language?.FIRST_DETECTED?.value ?? englishLang?.FIRST_DETECTED?.value,
      dataIndex: ["firstSeen"],
      render: (firstSeen) => (
        <span>
          {firstSeen
            ? moment.tz(firstSeen, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
    },
    {
      key: "lastSeen",
      sorter: true,
      sortField: "lastSeen",
      sortOrder: sortedInfo.columnKey === "lastSeen" && sortedInfo.order,
      sorter: true,
      title:
        language?.LAST_DETECTED?.value ?? englishLang?.LAST_DETECTED?.value,
      dataIndex: ["lastSeen"],
      render: (lastSeen) => (
        <span>
          {lastSeen
            ? moment.tz(lastSeen, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
    },
    {
      key: "zone",
      sorter: true,
      sortField: "zone",
      sortOrder: sortedInfo.columnKey === "zone" && sortedInfo.order,
      sorter: true,
      title: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      dataIndex: ["zoneMovementDetails"],
      render: (text) =>
        text ? (
          text.firstSeenLocation ? (
            <span>
              {text.firstSeenLocation && text.firstSeenLocation.address}
              {text.firstSeenLocation &&
              text.firstSeenLocation.latitude &&
              text.firstSeenLocation.latitude ? (
                <Button
                  style={{ marginLeft: "10px" }}
                  type="primary"
                  shape="circle"
                  icon={<EnvironmentOutlined />}
                  onClick={() => {
                    window.open(
                      "http://www.google.com/maps/place/" +
                        text.firstSeenLocation.latitude +
                        "," +
                        text.firstSeenLocation.longitude,
                      "_blank"
                    );
                  }}
                />
              ) : (
                ""
              )}
            </span>
          ) : text ? (
            <span>
              {text.zoneName} <i> ({text.siteName})</i>
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        ),
    },
    {
      title:
        language?.VEHICLE_REGISTRATION_NUMBER?.value ??
        englishLang?.VEHICLE_REGISTRATION_NUMBER?.value,
      dataIndex: ["vehicleMovementEvent", "vehicleRegNo"],
    },
    {
      title:
        language?.FIRST_DETECTED_LOCATION?.value ??
        englishLang?.FIRST_DETECTED_LOCATION?.value,
      dataIndex: ["vehicleMovementEvent", "firstSeenLocation"],
      render: (firstSeenLocation, eventId) => (
        <span>
          {firstSeenLocation &&
          firstSeenLocation.latitude &&
          firstSeenLocation.latitude ? (
            <Popover
              trigger="click"
              title={
                eventId.zoneMovementDetails
                  ? eventId.zoneMovementDetails.siteName
                  : eventId.vehicleMovementEvent.vehicleRegNo
              }
              content={
                <AssetJourneyFirstDetectedMap
                  lat={firstSeenLocation.latitude}
                  long={firstSeenLocation.longitude}
                />
              }
            >
              <EnvironmentOutlined
                style={{ marginRight: "10px", color: "#1890ff" }}
              />
            </Popover>
          ) : (
            ""
          )}
          {firstSeenLocation && firstSeenLocation.address}
        </span>
      ),
    },
    {
      title:
        language?.LAST_DETECTED_LOCATION?.value ??
        englishLang?.LAST_DETECTED_LOCATION?.value,
      dataIndex: ["vehicleMovementEvent", "lastSeenLocation"],
      render: (lastSeenLocation, eventId) => (
        <span>
          {lastSeenLocation &&
          lastSeenLocation.latitude &&
          lastSeenLocation.latitude ? (
            <Popover
              trigger="click"
              title={
                eventId.zoneMovementDetails
                  ? eventId.zoneMovementDetails.siteName
                  : eventId.vehicleMovementEvent.vehicleRegNo
              }
              content={
                <AssetJourneyLastDetectedMap
                  lat={lastSeenLocation.latitude}
                  long={lastSeenLocation.longitude}
                />
              }
            >
              <EnvironmentOutlined
                style={{ marginRight: "10px", color: "#1890ff" }}
              />
            </Popover>
          ) : (
            ""
          )}
          {lastSeenLocation && lastSeenLocation.address}
        </span>
      ),
    },
  ];

  useEffect(() => {
    dispatch(resetReport());
    dispatch(fetchAssetGroups());
    dispatch(fetchAssets());
  }, []);

  useEffect(() => {
    if (assetGroups && assetGroups.content) {
      let sorted = assetGroups.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedAssetGroups(sorted);
    }
  }, [assetGroups]);

  useEffect(() => {
    if (assets.content) {
      const cats = assets.content.reduce(
        (catsSoFar, { assetGroup, assetId, code, name }) => {
          if (!catsSoFar[assetGroup.assetGroupId])
            catsSoFar[assetGroup.assetGroupId] = [];
          catsSoFar[assetGroup.assetGroupId].push({
            name: name,
            code: code,
            assetId: assetId,
          });
          return catsSoFar;
        },
        {}
      );

      setOptions(cats);
    }
  }, [assets.content]);

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    dataSource: data,
    updateEntityPath: "",
    filter,
    loading: loading,
    missingFieldsText: missingFieldsText,
    setSortedInfo: setSortedInfo,
  });

  const checkNoMissingParams = () => {
    if (!(filter.fromDate && filter.toDate && filter.assetId)) {
      setMissingFieldsText(
        language?.SELECT_DATE_RANGE_ASSETGROUP_ASSET?.value ??
          englishLang?.SELECT_DATE_RANGE_ASSETGROUP_ASSET?.value
      );
      return false;
    }
    return true;
  };

  const ascDesc = () => {
    if (sortedInfo.order === "ascend") {
      return "ASC";
    } else {
      return "DESC";
    }
  };

  useEffect(() => {
    let endpoint = sortedInfo.columnKey
      ? `all-movements?sort=${sortedInfo.columnKey},${ascDesc()}`
      : "all-movements";

    dispatch(
      fetchReport({
        page: searchFormHelperHandler(),
        size: pageSize,
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
        assetId: filter.assetId ? filter.assetId : undefined,
        endpoint: endpoint,
      })
    );
  }, [currentPage, filter, isLoading, refresh, pageSize, sortedInfo]);

  useEffect(() => {
    setDataTable(DataTable);
  }, [loading, language]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-asset-journey";
    let options = {
      url: "/api/web/reports/all-movements/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
        assetId: filter.assetId ? filter.assetId : undefined,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  const handleDateRangeChange = (date, dateString) => {
    setFilter({
      ...filter,
      fromDate: dateString[0],
      toDate: dateString[1],
    });
  };
  const handleAssetChange = (value) => {
    setFilter({
      ...filter,
      assetId: value,
    });
  };

  const handleAssetGroupChange = (value) => {
    let sorted = [];
    if (options[value]) {
      sorted = options[value].sort((a, b) =>
        a.code.localeCompare(b.code, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
    }
    setAssetOptions(sorted);
  };

  const handleRefreshClick = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      let flatData;
      function flatDataHandler() {
        if (data.content === undefined) {
          return (flatData = Object.values(data));
        } else {
          return (flatData = Object.values(data.content));
        }
      }
      flatDataHandler();

      flatData.map((item) => {
        item.firstSeenFormatted = item.firstSeen
          ? moment
              .tz(item.firstSeen, userTimeZone, false)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
        item.lastSeenFormatted = item.lastSeen
          ? moment.tz(item.lastSeen, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedData(Object.values(flatData));
    }
  }, [data]);

  useEffect(() => {
    if (allData && Object.keys(allData).length !== 0) {
      let flatData = Object.values(allData.content);

      flatData.map((item) => {
        item.firstSeenFormatted = item.firstSeen
          ? moment
              .tz(item.firstSeen, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
        item.lastSeenFormatted = item.lastSeen
          ? moment.tz(item.lastSeen, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedAllData(Object.values(flatData));
    }
  }, [allData]);

  const headers = [
    {
      label:
        language?.FIRST_DETECTED?.value ?? englishLang?.FIRST_DETECTED?.value,
      key: "firstSeenFormatted",
    },
    {
      label:
        language?.LAST_DETECTED?.value ?? englishLang?.LAST_DETECTED?.value,
      key: "lastSeenFormatted",
    },
    {
      label: language?.SITE?.value ?? englishLang?.SITE?.value,
      key: "zoneMovementDetails.siteName",
    },
    {
      label: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      key: "zoneMovementDetails.zoneName",
    },
    {
      label:
        language?.VEHICLE_REGISTRATION_NUMBER?.value ??
        englishLang?.VEHICLE_REGISTRATION_NUMBER?.value,
      key: "vehicleMovementEvent.vehicleRegNo",
    },
    {
      label:
        language?.FIRST_DETECTED_LOCATION?.value ??
        englishLang?.FIRST_DETECTED_LOCATION?.value,
      key: "vehicleMovementEvent.firstSeenLocation.address",
    },
    {
      label:
        language?.LAST_DETECTED_LOCATION?.value ??
        englishLang?.LAST_DETECTED_LOCATION?.value,
      key: "vehicleMovementEvent.lastSeenLocation.address",
    },
  ];

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          hasSelected={hasSelected}
          exportTableTitle={
            language?.ASSETJORNEY?.value ?? englishLang?.ASSETJORNEY?.value
          }
          addNewText=""
          title={
            language?.ASSET_JOURNEY?.value ?? englishLang?.ASSET_JOURNEY?.value
          }
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportAllButtonClick={handleExportAllButtonClick}
        />
        <Row>
          <Col>
            <Space>
              <Tooltip
                title={
                  language?.SELECT_DATE_RANGE?.value ??
                  englishLang?.SELECT_DATE_RANGE?.value
                }
              >
                <RangePicker
                  id={ElementIDS().calendar}
                  defaultValue={[
                    moment(todayStr, dateFormat),
                    moment(todayStr, dateFormat),
                  ]}
                  format={dateFormat}
                  onChange={handleDateRangeChange}
                />
              </Tooltip>
              <Tooltip
                title={
                  language?.SELECT_AN_ASSET_GRUOP?.value ??
                  englishLang?.SELECT_AN_ASSET_GRUOP?.value
                }
              >
                <Select
                  id={ElementIDS().selectAssetGroup}
                  //   defaultValue="All"
                  style={{ width: 200 }}
                  placeholder={
                    language?.SELECT_AN_ASSET_GRUOP?.value ??
                    englishLang?.SELECT_AN_ASSET_GRUOP?.value
                  }
                  optionFilterProp="children"
                  onChange={handleAssetGroupChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {sortedAssetGroups
                    ? sortedAssetGroups.map((item) => (
                        <Option
                          key={item.assetGroupId}
                          value={item.assetGroupId}
                        >
                          {item.name}
                        </Option>
                      ))
                    : undefined}
                </Select>
              </Tooltip>

              <Tooltip
                title={
                  language?.SELECT_AN_ASSET?.value ??
                  englishLang?.SELECT_AN_ASSET?.value
                }
              >
                <Select
                  id={ElementIDS().selectAsset}
                  //   defaultValue="All"
                  style={{ width: 200 }}
                  placeholder={
                    language?.SELECT_AN_ASSET?.value ??
                    englishLang?.SELECT_AN_ASSET?.value
                  }
                  optionFilterProp="children"
                  onChange={handleAssetChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {assetOptions
                    ? assetOptions.map((item) => (
                        <Option key={item.assetId} value={item.assetId}>
                          {item.code}
                        </Option>
                      ))
                    : undefined}
                </Select>
              </Tooltip>
              <Button
                id={ElementIDS().refresh}
                type="primary"
                onClick={() => handleRefreshClick()}
              >
                {" "}
                <RedoOutlined />
                {language?.REFRESH?.value ?? englishLang?.REFRESH?.value}
              </Button>
            </Space>
          </Col>
        </Row>
        <Divider />
        {dataTable}
        <ResultNotifier />
      </div>
    </>
  );
}

export default AssetJourney;
