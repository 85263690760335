import React from "react";
import { Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

//DASHBOARD
import Dashboard from "../page/Dashboard/Dashboard";

//MANAGE
import AssetGroups from "../page/manage/AssetGroups/AssetGroups";
import AddAssetGroup from "../page/manage/AssetGroups/AddAssetGroup";
import Assets from "../page/manage/Assets/Assets";
import AddAsset from "../page/manage/Assets/AddAsset";
import AssetLocation from "../page/manage/AssetLocation/AssetLocation";
import AddAssetLocation from "../page/manage/AssetLocation/AddAssetLocation";
import Departments from "../page/manage/Departments/Departments";
import AddDepartment from "../page/manage/Departments/AddDepartment";
import CustomFields from "../page/manage/CustomFields/CustomFields";
import AddCustomField from "../page/manage/CustomFields/AddCustomField";

//FIXED INSTALLATION
import Antennas from "../page/fixedInstallation/Antennas/Antennas";
import AddAntenna from "../page/fixedInstallation/Antennas/AddAntenna";
import FixedReaders from "../page/fixedInstallation/FixedReaders/FixedReaders";
import AddFixedReaders from "../page/fixedInstallation/FixedReaders/AddFixedReader";
import Gateways from "../page/fixedInstallation/Gateways/Gateways";
import AddGateway from "../page/fixedInstallation/Gateways/AddGateway";
import IpRelayCards from "../page/fixedInstallation/IpRelayCards/IpRelayCards";
import AddIpRelayCard from "../page/fixedInstallation/IpRelayCards/AddIpRelayCard";
import RelaySwitches from "../page/fixedInstallation/RelaySwitches/RelaySwitches";
import AddRelaySwitch from "../page/fixedInstallation/RelaySwitches/AddRelaySwitch";
import Sites from "../page/fixedInstallation/Sites/Sites";
import AddSite from "../page/fixedInstallation/Sites/AddSite";
import Zones from "../page/fixedInstallation/Zones/Zones";
import AddZone from "../page/fixedInstallation/Zones/AddZone";
import FloorPlan from "../page/fixedInstallation/FloorPlan/FloorPlan";
import ViewFloorPlan from "../page/fixedInstallation/FloorPlan/ViewFloorPlan";

//MOBILE INSTALLATION
import MobileReaders from "../page/mobileInstallation/MobileReaders/MobileReaders";

//REPORTS
import LastDetectedFullReport from "../page/report/LastDetected/LastDetectedFullReport";
import LastDetected from "../page/report/LastDetected/LastDetected";
import CurrentlyUndetected from "../page/report/CurrentlyUndetected/CurrentlyUndetected";
import AssetJourney from "../page/report/AssetJourney/AssetJourney";
import HandheldScannedAssets from "../page/report/HandheldScannedAssets/HandheldScannedAssets";
import ZoneMovement from "../page/report/ZoneMovement/ZoneMovement";
import VehicleMovement from "../page/report/VehicleMovement/VehicleMovement";
import AssetAuditJourney from "../page/report/AssetAuditJourney/AssetAuditJourney";
import KilledAssets from "../page/report/KilledAssets/KilledAssets";
import LastDetected2D from "../page/report/LastDetected/FloorPlan/LastDetected2D";
import SmsConsumption from "../page/report/SmsConsumption/SmsConsumption";

//ALERTS
import AlertRules from "../page/alertRules/AlertRules/AlertRules";
import AddAlertRule from "../page/alertRules/AlertRules/AddAlertRule";
import AlertLog from "../page/alertRules/AlertLog/AlertLog";

//USER MANAGEMENT
import Users from "../page/userManagement/Users/Users";
import AddUser from "../page/userManagement/Users/AddUser";
import UserGroups from "../page/userManagement/UserGroups/UserGroups";
import AddUserGroup from "../page/userManagement/UserGroups/AddUserGroup";
import Roles from "../page/userManagement/Roles/Roles";
import AddRole from "../page/userManagement/Roles/AddRole";
import Contacts from "../page/userManagement/Contacts/Contacts";
import AddContact from "../page/userManagement/Contacts/AddContact";
import AuditLog from "../page/userManagement/AuditLog/AuditLog";

//USER PROFILE
import UserProfile from "../page/UserPages/userProfile/UserProfile";
import UserSettings from "../page/UserPages/Settings/UserSettings";
import ResetPassword from "../page/userManagement/Users/ResetPassword";

//SURPLUS
import FixedAssetMovement from "../page/report/FixedAssetMovement/FixedAssetMovement";
import UndetectedAssets from "../page/report/UndetectedAssets/UndetectedAssets";

//ERROR PAGES
import NotFound from "../page/common/404";
import NotAuthorized from "../page/common/403";

import { hasPermission } from "../page/layout/Constants";

const routes = [
  {
    path: "/",
    component: Dashboard,
    key: "/",
    data: {
      requiresLogin: true,
      permissionRequired: false,
    },
  },
  {
    path: "/assetGroups",
    component: AssetGroups,
    key: "assetGroups",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_ASSET_GROUPS",
    },
  },
  {
    path: "/add-assetGroup",
    component: AddAssetGroup,
    key: "addAssetGroups",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_ASSET_GROUPS",
    },
  },
  {
    path: "/update-assetGroup",
    component: AddAssetGroup,
    key: "updateAssetGroups",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_ASSET_GROUPS",
    },
  },
  {
    path: "/assets",
    component: Assets,
    key: "assets",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_ASSETS",
    },
  },
  {
    path: "/add-asset",
    component: AddAsset,
    key: "addAasset",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_ASSETS",
    },
  },
  {
    path: "/update-asset",
    component: AddAsset,
    key: "updateAasset",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "UPDATE_ASSETS",
    },
  },
  {
    path: "/departments",
    component: Departments,
    key: "departments",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_DEPARTMENTS",
    },
  },

  {
    path: "/add-department",
    component: AddDepartment,
    key: "addDepartment",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_DEPARTMENTS",
    },
  },
  {
    path: "/update-department",
    component: AddDepartment,
    key: "updateDepartment",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "UPDATE_DEPARTMENTS",
    },
  },

  {
    path: "/customFields",
    component: CustomFields,
    key: "customFields",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_CUSTOM_FIELDS",
    },
  },

  {
    path: "/add-customField",
    component: AddCustomField,
    key: "addCustomField",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_CUSTOM_FIELDS",
    },
  },
  {
    path: "/update-customField",
    component: AddCustomField,
    key: "updateCustomField",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "UPDATE_CUSTOM_FIELDS",
    },
  },
  {
    path: "/sites",
    component: Sites,
    key: "sites",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_SITES",
    },
  },
  {
    path: "/add-site",
    component: AddSite,
    key: "addSite",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_SITES",
    },
  },
  {
    path: "/update-site",
    component: AddSite,
    key: "updateSite",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_SITES",
    },
  },
  {
    path: "/zones",
    component: Zones,
    key: "zones",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_ZONES",
    },
  },
  {
    path: "/add-zone",
    component: AddZone,
    key: "addZone",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_ZONES",
    },
  },
  {
    path: "/update-zone",
    component: AddZone,
    key: "updateZone",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_ZONES",
    },
  },
  {
    path: "/gateways",
    component: Gateways,
    key: "gateways",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_GATEWAYS",
    },
  },
  {
    path: "/add-gateway",
    component: AddGateway,
    key: "addGateway",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_GATEWAYS",
    },
  },
  {
    path: "/update-gateway",
    component: AddGateway,
    key: "updateGateway",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "UPDATE_GATEWAYS",
    },
  },
  {
    path: "/fixedReaders",
    component: FixedReaders,
    key: "fixedReaders",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_FIXED_READERS",
    },
  },
  {
    path: "/add-fixedReader",
    component: AddFixedReaders,
    key: "addFixedReaders",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_FIXED_READERS",
    },
  },
  {
    path: "/update-fixedReader",
    component: AddFixedReaders,
    key: "updateFixedReaders",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "UPDATE_FIXED_READERS",
    },
  },
  {
    path: "/antennas",
    component: Antennas,
    key: "antennas",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_ANTENNAS",
    },
  },
  {
    path: "/add-antenna",
    component: AddAntenna,
    key: "addAntenna",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_ANTENNAS",
    },
  },
  {
    path: "/update-antenna",
    component: AddAntenna,
    key: "updateAntenna",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_ANTENNAS",
    },
  },
  {
    path: "/ipRelayCards",
    component: IpRelayCards,
    key: "ipRelayCards",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_IP_RELAY_CARDS",
    },
  },
  {
    path: "/add-ipRelayCard",
    component: AddIpRelayCard,
    key: "addIpRelayCard",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_IP_RELAY_CARDS",
    },
  },
  {
    path: "/update-ipRelayCard",
    component: AddIpRelayCard,
    key: "updateIpRelayCard",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "UPDATE_IP_RELAY_CARDS",
    },
  },
  {
    path: "/relaySwitches",
    component: RelaySwitches,
    key: "relaySwitches",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_RELAY_SWITCHES",
    },
  },
  {
    path: "/add-relaySwitch",
    component: AddRelaySwitch,
    key: "addRelaySwitch",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_RELAY_SWITCHES",
    },
  },
  {
    path: "/update-relaySwitch",
    component: AddRelaySwitch,
    key: "updateRelaySwitch",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "UPDATE_RELAY_SWITCHES",
    },
  },
  {
    path: "/alertRules",
    component: AlertRules,
    key: "alertRules",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_ALERT_RULES",
    },
  },
  {
    path: "/add-alertRule",
    component: AddAlertRule,
    key: "addAlertRule",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_ALERT_RULES",
    },
  },
  {
    path: "/update-alertRule",
    component: AddAlertRule,
    key: "updateAlertRule",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_ALERT_RULES",
    },
  },
  {
    path: "/mobileReaders",
    component: MobileReaders,
    key: "mobileReaders",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_MOBILE_READERS",
    },
  },
  {
    path: "/lastDetected",
    component: LastDetectedFullReport,
    key: "lastDetected",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "GENERATE_LAST_DETECTED_REPORT",
    },
  },
  {
    path: "/undetectedAssets",
    component: UndetectedAssets,
    key: "undetectedAssets",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "GENERATE_UNDETECTED_ASSETS_REPORT",
    },
  },
  {
    path: "/currentlyUndetected",
    component: CurrentlyUndetected,
    key: "currentlyUndetected",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "GENERATE_CURRENTLY_UNDETECTED_REPORT",
    },
  },
  {
    path: "/auditLog",
    component: AuditLog,
    key: "auditLog",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "GENERATE_AUDIT_LOGS_REPORT",
    },
  },
  {
    path: "/handheldScannedAssets",
    component: HandheldScannedAssets,
    key: "handheldScannedAssets",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "GENERATE_HANDHELD_SCANNED_ASSETS_REPORT",
    },
  },
  {
    path: "/alertLog",
    component: AlertLog,
    key: "alertLog",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "GENERATE_ALERT_LOGS_REPORT",
    },
  },
  {
    path: "/fixedAssetMovement",
    component: FixedAssetMovement,
    key: "fixedAssetMovement",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "GENERATE_FIXED_ASSET_MOVEMENT_REPORT",
    },
  },
  {
    path: "/zoneMovement",
    component: ZoneMovement,
    key: "zoneMovement",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "GENERATE_ZONE_MOVEMENT_REPORT",
    },
  },
  {
    path: "/vehicleMovement",
    component: VehicleMovement,
    key: "vehicleMovement",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "GENERATE_VEHICLE_MOVEMENT_REPORT",
    },
  },
  {
    path: "/assetJourney",
    component: AssetJourney,
    key: "assetJourney",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "GENERATE_ASSET_JOURNEY_REPORT",
    },
  },
  {
    path: "/users",
    component: Users,
    key: "users",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_USERS",
    },
  },
  {
    path: "/add-user",
    component: AddUser,
    key: "addUser",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_USERS",
    },
  },
  {
    path: "/update-user",
    component: AddUser,
    key: "updateUser",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_USERS",
    },
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    key: "resetPassword",
    data: {
      requiresLogin: true,
      permissionRequired: false,
    },
  },
  {
    path: "/userGroups",
    component: UserGroups,
    key: "userGroups",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_USER_GROUPS",
    },
  },
  {
    path: "/add-userGroup",
    component: AddUserGroup,
    key: "addUserGroup",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_USER_GROUPS",
    },
  },
  {
    path: "/update-userGroup",
    component: AddUserGroup,
    key: "updateUserGroup",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_USER_GROUPS",
    },
  },
  {
    path: "/roles",
    component: Roles,
    key: "roles",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_ROLES",
    },
  },
  {
    path: "/add-role",
    component: AddRole,
    key: "addRole",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_ROLES",
    },
  },
  {
    path: "/update-role",
    component: AddRole,
    key: "updateRole",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_ROLES",
    },
  },
  {
    path: "/contacts",
    component: Contacts,
    key: "contacts",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_CONTACTS",
    },
  },
  {
    path: "/add-contact",
    component: AddContact,
    key: "addContact",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_CONTACTS",
    },
  },
  {
    path: "/update-contact",
    component: AddContact,
    key: "updateContact",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_CONTACTS",
    },
  },
  {
    path: "/profile",
    component: UserProfile,
    key: "profile",
    data: {
      requiresLogin: true,
      permissionRequired: false,
      permissions: "UPDATE_USERS",
    },
  },
  {
    path: "/assetAudit-journey",
    component: AssetAuditJourney,
    key: "assetAuditJourney",
    data: {
      requiresLogin: true,
      permissionRequired: false,
      permissions: "GENERATE_ASSET_AUDIT_JOURNEY_REPORT",
    },
  },
  {
    path: "/assetLocation",
    component: AssetLocation,
    key: "assetLocation",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_ASSET_LOCATIONS",
    },
  },
  {
    path: "/add-assetLocation",
    component: AddAssetLocation,
    key: "addAssetLocation",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "CREATE_ASSET_LOCATIONS",
    },
  },
  {
    path: "/update-assetLocation",
    component: AddAssetLocation,
    key: "updateAssetLocation",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_ASSET_LOCATIONS",
    },
  },
  {
    path: "/killed-assets",
    component: KilledAssets,
    key: "killedAssets",
    data: {
      requiresLogin: true,
      subscriptionRequired: true,
      subscriptions: "KILLING_ZONES_SUBSCRIPTION",
      permissionRequired: true,
      permissions: "GENERATE_KILLED_ASSETS_REPORT",
    },
  },
  {
    path: "/sms-consumption",
    component: SmsConsumption,
    key: "smsConsumption",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "GENERATE_SMS_ALERT_LOG_REPORT",
    },
  },
  {
    path: "/user-settings",
    component: UserSettings,
    key: "userSettings",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "UPDATE_USERS",
    },
  },
  {
    path: "/floor-plan",
    component: FloorPlan,
    key: "floorPlan",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_FLOOR_PLANS",
    },
  },
  {
    path: "/view-floor-plan",
    component: ViewFloorPlan,
    key: "viewFloorPlan",
    data: {
      requiresLogin: true,
      permissionRequired: true,
      permissions: "LIST_FLOOR_PLANS",
    },
  },
];
// Check for user permission
const RoutingList = (user, selectedTenant) => {
  return routes.map((item) => {
    if (
      !item.data.permissionRequired ||
      (item.data.permissionRequired &&
        hasPermission(selectedTenant, item.data.permissions))
    ) {
      return (
        //if user has permission let him visit the next page
        <Route
          exact
          path={item.path}
          component={item.component}
          key={item.key}
        />
      );
    } else {
      // if user dosent have permission move him to not Authorized page
      return (
        <Route
          exact
          path={item.path}
          component={NotAuthorized}
          key={item.key}
        />
      );
    }
  });
};

// Check if the page that user typed inside URL exist's
const RoutingUserList = ({ user, selectedTenant }) => {
  // map the Routes
  const existingURLs = routes.map((item) => {
    return item.path;
  });
  // put data inside array
  let newArray = Array.from(existingURLs);
  // get data that user typed inside URL
  const location = useLocation();
  let userLocationURL = location.pathname;
  // compare data and if page dosent exist move the user to 404 page
  if (newArray.indexOf(userLocationURL) !== -1) {
    return RoutingList(user, selectedTenant);
  } else {
    return <Route component={NotFound} />;
  }
};

export default RoutingUserList;
