import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Checkbox, notification, Form, Input, Button } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";

import { authenticate } from "../../api/redux/action/AuthActions";
import { fetchTranslations } from "../../api/redux/action/LangActions";

import "./Style.css";
import rfidLogo from "../../assets/images/rfid_logo_lhtBlue.png";

import { getVersionNumber } from "../layout/Constants";
import "antd/dist/antd.min.css";
import { getLoginPreferredLogo } from "../../api/redux/action/UserActions";

const Login = (props) => {
  const [defaultLogo, setDefaultLogo] = useState();

  const history = useHistory();
  const dispatch = useDispatch();

  const { user, loading, error, preferredLogo } = useSelector((store) => ({
    user: store.authReducer.user,
    loading: store.authReducer.loading,
    error: store.authReducer.error,
    preferredLogo: store.userReducer.preferredLogo,
  }));

  useEffect(() => {
    if (preferredLogo !== undefined) {
      if (preferredLogo !== undefined) {
        setDefaultLogo(preferredLogo.logoUrl);
      } else {
        setDefaultLogo(rfidLogo);
      }
    }
  }, [preferredLogo]);

  useEffect(() => {
    dispatch(getLoginPreferredLogo());
  }, []);

  useEffect(() => {
    if (user) {
      history.push("/");
    }
  }, [user]);

  useEffect(() => {
    dispatch(fetchTranslations());
  }, []);

  useEffect(() => {
    if (error) {
      notification.open({
        type: "error",
        message: "Error!",
        description: error,
        duration: 10,
      });
    }
  }, [error]);

  const onFinish = (values) => {
    dispatch(authenticate(values));
  };

  const version = (
    <span style={{ fontSize: "11px", color: "#d9d9d9" }}>
      Version {getVersionNumber()}
    </span>
  );

  return (
    <div className="login">
      <img
        className="rfid_logo"
        src="/Images/rfid_logo_lhtBlue.png"
        alt="RFID Logo"
      />
      <img
        className="rfid_logo_mobile"
        src="/Images/rfid_logo_white.png"
        alt="RFID Logo White"
      />
      <div className="left_side_screen">
        <img
          className="regular_image"
          src="/Images/left-graphic.png"
          alt="RFID Image"
        />
      </div>
      <div className="right_side_screen">
        <div className="login_content">
          <h1>Welcome back!</h1>
          <h3>RFID helps you take control of your assets on the field.</h3>
          <p>
            Login <span></span>
          </p>
          <Form
            name="normal_login"
            layout="horizontal"
            initialValues={{
              "remember-me": true,
            }}
            className="login_form"
            onFinish={onFinish}
          >
            <Form.Item className="username_form_container" name="username">
              <Input
                className="login_input"
                prefix={<UserOutlined style={{ fontSize: 13 }} />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item className="password_form_container" name="password">
              <Input.Password
                className="password_input"
                prefix={<LockOutlined style={{ fontSize: 13 }} />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              className="remember_me"
              name="remember-me"
              valuePropName="checked"
              noStyle
            >
              <Checkbox style={{ color: "white" }}>Remember me</Checkbox>
            </Form.Item>
            <div className="login_button_container">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  block
                  loading={loading}
                >
                  Log in
                </Button>
                <Button
                  type="link"
                  style={{ color: "white" }}
                  href="/forgot-password"
                >
                  Forgot Password
                </Button>
              </Form.Item>
            </div>
          </Form>
          <div>
            <div className="handsOn_Logo_bottom">
              <a
                href="https://www.handsonsystems.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./Images/handson-logo.png" alt="Handson" />
              </a>
            </div>
            <div className="copyright">
              <p className="copyright_description">
                © {new Date().getFullYear()} All rights reserved. RFID is a
                registered trademark of HandsOn Systems Ltd.
              </p>
              <strong>V {getVersionNumber()}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
