import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Form, Row, Modal } from "antd";

import { saveZone } from "../../../api/redux/action/ZoneActions";
import { useHistory } from "react-router-dom";
import { hasSubscription } from "../../layout/Constants";
import { hasPermission } from "../../layout/Constants";
import { QuestionCircleOutlined } from "@ant-design/icons";
import useNotification from "../../../components/common/ResultNotifier";
import AddZoneForm from "./AddZoneForm";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";
import AddFooterSection from "../../../components/common/AddFooterSection";
import { validateInput } from "../../../components/RequiredFieldRule";

const AddZone = (props, title) => {
  const [isChecked, setChecked] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [prompt, setDirty, setPristine] = UnsavedChanges();

  let history = useHistory();
  const { confirm } = Modal;

  const {
    error,
    requestStatus,
    user,
    language,
    zones,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    error: store.zoneReducer.error,
    requestStatus: store.zoneReducer.requestStatus,
    user: store.authReducer.user,
    language: store.langReducer.language,
    zones: store.zoneReducer.zones,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const showResultMessage = hasSubscription(
    selectedTenant,
    "KILLING_ZONES_SUBSCRIPTION"
  )
    ? language?.ZONE_ABATTOIR_NOTIFICATION_SUCCESS?.value ??
      englishLang?.ZONE_ABATTOIR_NOTIFICATION_SUCCESS?.value
    : language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;

  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    showResultMessage
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      setChecked(
        props.location.state && props.location.state.selectedRow.killAssets
      );
      form.setFieldsValue({
        zoneId: props.location.state.selectedRow.zoneId,
        name: props.location.state.selectedRow.name,
        site: props.location.state.selectedRow.site.siteId,
        // killAssets: props.location.state.selectedRow.killAssets,
        location: props.location.state.selectedRow.location,
      });
    }
  }, [props.location.state]);

  function checkingCheckBox() {
    setChecked(!isChecked);
    form.setFieldsValue({
      killAssets: !isChecked,
    });
  }

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      return history.push("/zones");
    }
  }, [requestStatus]);

  const handleSave = (values) => {
    const formData = {
      name: validateInput(form.getFieldValue().name),
      siteId: form.getFieldValue().site,
      killAssets: form.getFieldValue().killAssets,
      location: form.getFieldValue().location,
    };

    setPristine();
    dispatch(saveZone(formData, form.getFieldValue().zoneId));
  };

  const handleCancel = () => {
    history.push("/zones");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_ZONES");
  }

  const showKillAssetConfirm = () => {
    if (zones && zones.killAssets) {
      confirm({
        title:
          language?.KILL_ASSET_MODEL_TITLE?.value ??
          englishLang?.KILL_ASSET_MODEL_TITLE?.value,
        icon: <QuestionCircleOutlined />,
        onOk: () => {
          form.submit();
        },
        okText: language?.SAVE?.value ?? englishLang?.SAVE?.value,
        cancelText: language?.CANCEL?.value ?? englishLang?.CANCEL?.value,
        content:
          language?.KILL_ASSET_MODEL_DESCRIPTION?.value ??
          englishLang?.KILL_ASSET_MODEL_DESCRIPTION?.value,
      });
    } else {
      form.submit();
    }
  };

  const footer = (
    <AddFooterSection
      language={language}
      viewOption={props.location.state}
      handleCancel={handleCancel}
      form={form}
      updatePath={"/update-zone"}
      handleEditingPermission={handleEditingPermission}
      showKillAssetConfirm={showKillAssetConfirm}
    />
  );

  return (
    <Card
      title={language?.ZONE?.value ?? englishLang?.ZONE?.value}
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddZoneForm
            readOnly={props.location.state}
            handleSave={handleSave}
            form={form}
            footer={footer}
            setDirty={setDirty}
            language={language}
            isChecked={isChecked}
            checkingCheckBox={checkingCheckBox}
          />
        </Col>
      </Row>
      <ResultNotifier />
      {prompt}
    </Card>
  );
};

export default AddZone;
