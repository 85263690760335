import * as type from "../types";
import { getFloorPlans } from "../../network/service/FloorPlanService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";
export const fetchFloorPlan = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.GET_FLOOR_PLAN_REQUEST,
      });
      const response = await dispatch(getFloorPlans(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.GET_FLOOR_PLAN_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.GET_FLOOR_PLAN_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.GET_FLOOR_PLAN_FAILURE,
      });
    }
  };
};
