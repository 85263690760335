import * as type from "../types";
import { RequestState } from "./RequestState";

const INITIAL_STATE = {
  assetGroups: {},
  assetGroup: {},
  allAssetGroups: {},
  assetGroupWithField: [],
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  exportAllLoading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.ASSETGROUPS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        assetGroups: {},
        loading: true,
      };
    case type.ASSETGROUPS_FETCH_SUCCESS:
      return {
        ...state,
        assetGroups: action.payload,
        loading: false,
      };
    case type.ASSETGROUPS_FETCH_FAILURE:
      return {
        ...state,
        assetGroups: {},
        loading: false,
      };
    case type.ALL_ASSETGROUPS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        allAssetGroups: {},
        exportAllLoading: true,
      };
    case type.ALL_ASSETGROUPS_FETCH_SUCCESS:
      return {
        ...state,
        allAssetGroups: action.payload,
        exportAllLoading: false,
      };
    case type.ALL_ASSETGROUPS_FETCH_FAILURE:
      return {
        ...state,
        allAssetGroups: {},
        exportAllLoading: false,
      };
    case type.ASSETGROUP_SAVE_REQUEST:
      return {
        ...state,
        error: {},
        assetGroup: {},
        requestStatus: RequestState.PENDING,
      };
    case type.ASSETGROUP_SAVE_SUCCESS:
      return {
        ...state,
        assetGroup: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.ASSETGROUP_SAVE_FAILURE:
      return {
        ...state,
        assetGroup: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };

    case type.ASSETGROUP_DELETE_REQUEST:
      return {
        ...state,
        requestStatus: RequestState.PENDING,
      };
    case type.ASSETGROUP_DELETE_SUCCESS:
      return {
        ...state,
        assetGroup: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.ASSETGROUP_DELETE_FAILURE:
      return {
        ...state,
        assetGroup: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };

    case type.ASSETGROUPS_WITH_FIELDS_FETCH_REQUEST:
      return {
        ...state,
        assetGroupWithField: [],
        requestStatus: RequestState.PENDING,
        loading: true,
        error: {},
      };
    case type.ASSETGROUPS_WITH_FIELDS_FETCH_SUCCESS:
      return {
        ...state,
        assetGroupWithField: action.payload,
        requestStatus: RequestState.SUCCESS,
        loading: false,
        error: {},
      };
    case type.ASSETGROUPS_WITH_FIELDS_FETCH_FAILURE:
      return {
        ...state,
        assetGroupWithField: [],
        requestStatus: RequestState.FAILURE,
        loading: false,
        error: action.payload,
      };
    case type.CLEAN_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: RequestState.EMPTY,
      };
    default:
      return state;
  }
};

export default reducer;
