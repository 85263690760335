import { request } from "../axios/request";

export const getReportService = (params = null) => {
  const endpoint = params.endpoint;
  delete params.endpoint;

  return async (dispatch, getState) => {
    return dispatch(
      request("/reports/" + endpoint, getState(), {
        method: "GET",
        params,
      })
    );
  };
};

export const markAsReadService = (data) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/alert-logs/read", getState(), {
        method: "POST",
        data,
      })
    );
  };
};

export const getExportReportService = (params = null) => {
  const endpoint = params.endpoint;
  delete params.endpoint;

  return async (dispatch, getState) => {
    return dispatch(
      request("/reports/" + endpoint, getState(), {
        method: "GET",
        params,
      })
    );
  };
};
