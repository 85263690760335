import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import rfidLogo from "../../assets/images/rfid_logo_lhtBlue.png";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getPreferredLogo } from "../../api/redux/action/UserActions";

const SiderLogo = React.memo(({ collapsed }) => {
  const dispatch = useDispatch();

  const { preferredLogo } = useSelector(
    (store) => ({
      preferredLogo: store.userReducer.preferredLogo,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getPreferredLogo());
  }, []);

  const defaultLogo = useMemo(() => {
    try {
      const getPreferredLogoLocalStorage = JSON.parse(
        localStorage.getItem("preferredLogo")
      );
      return getPreferredLogoLocalStorage.logoUrl;
    } catch (e) {
      return rfidLogo;
    }
  }, [preferredLogo]);

  return (
    <>
      {defaultLogo === undefined ? (
        <Spin indicator={<LoadingOutlined />} />
      ) : (
        <img
          src={defaultLogo + "?" + Date.now()}
          alt="Logo"
          className={
            collapsed === false
              ? "menu-logo menu-logo-app"
              : "menu-logo-collapsed"
          }
        />
      )}
    </>
  );
});

export default SiderLogo;
