import * as type from "../types";
import {
  getGatewaysService,
  saveGatewayService,
  deleteGatewayService,
  getAllGatewaysService,
  getSingleGatewaysService,
} from "../../network/service/GatewayService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchGateways = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.GATEWAYS_FETCH_REQUEST,
      });
      const response = await dispatch(getGatewaysService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.GATEWAYS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.GATEWAYS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.GATEWAYS_FETCH_FAILURE,
      });
    }
  };
};

export const fetchSingleGateway = (id = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.FETCH_SINGLE_GATEWAY_REQUEST,
      });
      const response = await dispatch(getSingleGatewaysService(id));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.FETCH_SINGLE_GATEWAY_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.FETCH_SINGLE_GATEWAY_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.FETCH_SINGLE_GATEWAY_FAILURE,
      });
    }
  };
};

export const fetchAllGateways = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_GATEWAYS_FETCH_REQUEST,
      });
      const response = await dispatch(getAllGatewaysService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_GATEWAYS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_GATEWAYS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_GATEWAYS_FETCH_FAILURE,
      });
    }
  };
};

export const saveGateway = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.GATEWAY_SAVE_REQUEST,
      });
      const response = await dispatch(saveGatewayService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.GATEWAY_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.GATEWAY_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.GATEWAY_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteGateway = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.GATEWAY_DELETE_REQUEST,
      });
      const response = await dispatch(deleteGatewayService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.GATEWAY_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.GATEWAY_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.GATEWAY_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
