import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";

import { saveIpRelayCard } from "../../../api/redux/action/IpRelayCardActions";
import useNotification from "../../../components/common/ResultNotifier";
import AddIpRelayCardForm from "./AddIpRelayCardForm";
import { useHistory } from "react-router-dom";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import AddFooterSection from "../../../components/common/AddFooterSection";
import { hasPermission } from "../../layout/Constants";
import { validateInput } from "../../../components/RequiredFieldRule";

const AddIpRelayCard = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [prompt, setDirty, setPristine] = UnsavedChanges();

  let history = useHistory();

  const { error, requestStatus, language, user, englishLang, selectedTenant } =
    useSelector((store) => ({
      error: store.ipRelayCardReducer.error,
      requestStatus: store.ipRelayCardReducer.requestStatus,
      language: store.langReducer.language,
      user: store.authReducer.user,
      englishLang: store.langReducer.englishLang,
      selectedTenant: store.authReducer.selectedTenant,
    }));
  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;
  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      form.setFieldsValue({
        ipRelayCardId: props.location.state.selectedRow.ipRelayCardId,
        gateway: props.location.state.selectedRow.gateway.gatewayId,
        ipAddress: props.location.state.selectedRow.ipAddress,
        portNumber: props.location.state.selectedRow.port,
      });
    }
  }, []);

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      return history.push("/ipRelayCards");
    }
  }, [requestStatus]);

  const handleSave = (values) => {
    const formData = {
      gatewayId: form.getFieldValue().gateway,
      ipAddress: validateInput(form.getFieldValue().ipAddress),
      port: validateInput(form.getFieldValue().portNumber),
    };

    setPristine();
    dispatch(saveIpRelayCard(formData, form.getFieldValue().ipRelayCardId));
  };

  const handleCancel = () => {
    history.push("/ipRelayCards");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_IP_RELAY_CARDS");
  }

  const footer = (
    <AddFooterSection
      language={language}
      viewOption={props.location.state}
      handleCancel={handleCancel}
      form={form}
      updatePath={"/update-ipRelayCard"}
      handleEditingPermission={handleEditingPermission}
    />
  );

  return (
    <Card
      title={
        language?.IP_RELAY_CARD?.value ?? englishLang?.IP_RELAY_CARD?.value
      }
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddIpRelayCardForm
            language={language}
            handleSave={handleSave}
            form={form}
            footer={footer}
            setDirty={setDirty}
            readOnly={props.location.state}
          />
        </Col>
      </Row>
      <ResultNotifier />
      {prompt}
    </Card>
  );
};

export default AddIpRelayCard;
