import { request } from "../axios/request";
import { requestPublic, requestPublicWithTenant } from "../axios/requestPublic";

export const getUsersService = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/users", getState(), {
        method: "GET",
        params,
      })
    );
  };
};

export const saveUserService = (data, id = 0) => {
  if (id > 0) {
    return async (dispatch, getState) => {
      return dispatch(
        request("/users/" + id, getState(), {
          method: "PUT",
          data,
        })
      );
    };
  }
  return async (dispatch, getState) => {
    return dispatch(
      request("/users", getState(), {
        method: "POST",
        data,
      })
    );
  };
};

export const deleteUserService = (id = 0) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/users/" + id, getState(), {
        method: "DELETE",
      })
    );
  };
};

export const resetPasswordService = (data, id = 0) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/users/" + id + "/password-reset", getState(), {
        method: "POST",
        data,
      })
    );
  };
};

export const activateService = (data, id = 0) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/users/" + id, getState(), {
        method: "PATCH",
        data,
      })
    );
  };
};

export const languageSaveChangerService = (data, id = 0) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/users/" + id + "/language-change", getState(), {
        method: "POST",
        data,
      })
    );
  };
};

export const fetchTranslationsService = () => {
  return async (dispatch, getState) => {
    return dispatch(
      requestPublic("/translations", getState(), {
        method: "GET",
      })
    );
  };
};
export const getPreferredColumnsService = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/preferred-page-columns?name=handheld-scan-report", getState(), {
        method: "GET",
        params,
      })
    );
  };
};

export const savePreferredLogoService = (data) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/custom-settings/logo", getState(), {
        method: "PUT",
        data,
      })
    );
  };
};

export const savePreferredColumnsService = (data, id = 0) => {
  if (id > 0) {
    return async (dispatch, getState) => {
      return dispatch(
        request("/preferred-page-columns/" + id, getState(), {
          method: "PUT",
          data,
        })
      );
    };
  }
  return async (dispatch, getState) => {
    return dispatch(
      request("/preferred-page-columns", getState(), {
        method: "POST",
        data,
      })
    );
  };
};

export const getPreferredLogoService = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      requestPublicWithTenant("/custom-settings/logo", getState(), {
        method: "GET",
        params,
      })
    );
  };
};

export const deletePreferredLogoService = () => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/custom-settings/logo", getState(), {
        method: "DELETE",
      })
    );
  };
};

export const changePasswordService = (data, id = 0) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/users/" + id + "/password-change", getState(), {
        method: "POST",
        data,
      })
    );
  };
};
