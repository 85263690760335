import { Radio, Row } from "antd";
import { useState } from "react";
import LastDetected from "./LastDetected";
import LastDetected2D from "./FloorPlan/LastDetected2D";
import classname from "./LastDetectedFullReport.module.css";
import { useSelector } from "react-redux";
import { hasPermission } from "../../layout/Constants";
const LastDetectedFullReport = () => {
  const { language, englishLang, selectedTenant } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));
  const [mode, setMode] = useState("data-table");
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const modeSelector = (
    <Row className={classname.modeSelector}>
      <h1>
        {language?.LAST_DETECTED?.value ?? englishLang?.LAST_DETECTED?.value}
      </h1>
      <Radio.Group
        onChange={handleModeChange}
        value={mode}
        style={{
          marginBottom: 8,
        }}
      >
        <Radio.Button value="data-table">
          {language?.DATA_TABLE_TAB?.value ??
            englishLang?.DATA_TABLE_TAB?.value}
        </Radio.Button>
        <Radio.Button
          value="floor-plan"
          disabled={!hasPermission(selectedTenant, "LIST_FLOOR_PLANS")}
        >
          {language?.FLOOR_PLAN_TAB?.value ??
            englishLang?.FLOOR_PLAN_TAB?.value}
        </Radio.Button>
      </Radio.Group>
    </Row>
  );
  const renderedData =
    mode === "data-table" ? (
      <LastDetected modeSelector={modeSelector} />
    ) : (
      <LastDetected2D modeSelector={modeSelector} />
    );
  return <div>{renderedData}</div>;
};
export default LastDetectedFullReport;
