import * as type from "../types";
import { RequestState } from "./RequestState";

const INITIAL_STATE = {
  contacts: {},
  contact: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.CONTACTS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        contacts: {},
        loading: true,
      };
    case type.CONTACTS_FETCH_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
        loading: false,
      };
    case type.CONTACTS_FETCH_FAILURE:
      return {
        ...state,
        contacts: {},
        loading: false,
      };
    case type.CONTACT_SAVE_REQUEST:
      return {
        ...state,
        error: {},
        contact: {},
        requestStatus: RequestState.PENDING,
      };
    case type.CONTACT_SAVE_SUCCESS:
      return {
        ...state,
        contact: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.CONTACT_SAVE_FAILURE:
      return {
        ...state,
        contact: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
    case type.CONTACT_DELETE_REQUEST:
      return {
        ...state,
        requestStatus: RequestState.PENDING,
      };
    case type.CONTACT_DELETE_SUCCESS:
      return {
        ...state,
        contact: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.CONTACT_DELETE_FAILURE:
      return {
        ...state,
        contact: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
    case type.CLEAN_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: RequestState.EMPTY,
      };
    default:
      return state;
  }
};

export default reducer;
