import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";

import { saveDepartment } from "../../../api/redux/action/DepartmentActions";
import useNotification from "../../../components/common/ResultNotifier";
import AddDepartmentForm from "./AddDepartmentForm";
import { useHistory } from "react-router-dom";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";
import { validateInput } from "../../../components/RequiredFieldRule";

import AddFooterSection from "../../../components/common/AddFooterSection";
import { hasPermission } from "../../layout/Constants";

const AddDepartment = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [prompt, setDirty, setPristine] = UnsavedChanges();

  let history = useHistory();

  const { error, requestStatus, language, user, englishLang, selectedTenant } =
    useSelector((store) => ({
      error: store.departmentReducer.error,
      requestStatus: store.departmentReducer.requestStatus,
      language: store.langReducer.language,
      user: store.authReducer.user,
      englishLang: store.langReducer.englishLang,
      selectedTenant: store.authReducer.selectedTenant,
    }));
  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;
  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      form.setFieldsValue(props.location.state.selectedRow);
    }
  }, []);

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      return history.push("/departments");
    }
  }, [requestStatus]);

  const handleSave = () => {
    setPristine();

    const formData = { name: validateInput(form.getFieldValue().name) };

    dispatch(saveDepartment(formData, form.getFieldValue().departmentId));
  };

  const handleCancel = () => {
    history.push("/departments");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_DEPARTMENTS");
  }

  const footer = (
    <AddFooterSection
      language={language}
      viewOption={props.location.state}
      handleCancel={handleCancel}
      form={form}
      updatePath={"/update-department"}
      handleEditingPermission={handleEditingPermission}
    />
  );

  return (
    <Card
      title={language?.DEPARTMENT?.value ?? englishLang?.DEPARTMENT?.value}
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddDepartmentForm
            handleSave={handleSave}
            form={form}
            footer={footer}
            setDirty={setDirty}
            readOnly={props.location.state}
            language={language}
          />
        </Col>
      </Row>
      <ResultNotifier />
      {prompt}
    </Card>
  );
};

export default AddDepartment;
