import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";

import { saveContact } from "../../../api/redux/action/ContactActions";
import useNotification from "../../../components/common/ResultNotifier";
import AddContactForm from "./AddContactForm";
import { useHistory } from "react-router-dom";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";
import { hasPermission } from "../../layout/Constants";
import AddFooterSection from "../../../components/common/AddFooterSection";
import { validateInput } from "../../../components/RequiredFieldRule";

const AddContact = (props) => {
  const [isSmsNotification, setSmsNotification] = useState(false);
  const [isEmailNotification, setEmailNotification] = useState(false);
  const [mobileNumber, setMobileNumber] = useState({
    phoneNum: "",
    countryCode: "",
  });

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [prompt, setDirty, setPristine] = UnsavedChanges();

  let history = useHistory();

  form.setFieldsValue({
    mobile: {
      code:
        props.location.state &&
        props.location.state.selectedRow &&
        props.location.state.selectedRow.mobile
          ? parseInt(
              props.location.state.selectedRow.mobile.match(/\((.*?)\)/)[1]
            )
          : 356,
    },
  });

  const { error, requestStatus, user, language, englishLang, selectedTenant } =
    useSelector((store) => ({
      error: store.contactReducer.error,
      requestStatus: store.contactReducer.requestStatus,
      user: store.authReducer.user,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
      selectedTenant: store.authReducer.selectedTenant,
    }));
  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;
  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );
  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      var email = props.location.state.selectedRow.contactDetails
        ? props.location.state.selectedRow.contactDetails.find(
            (o) => o.contactType === "EMAIL"
          )
        : "";
      var mobile = props.location.state.selectedRow.contactDetails
        ? props.location.state.selectedRow.contactDetails.find(
            (o) => o.contactType === "MOBILE"
          )
        : "";
      setMobileNumber({
        countryCode: mobile?.contactValue?.replace(/[\(\)]/g, "").slice(0, 3),
        phoneNum: mobile?.contactValue?.replace(/[\(\)]/g, ""),
      });

      setSmsNotification(mobile ? mobile.receiveSmsNotifications : false);
      setEmailNotification(email ? email.receiveNotifications : false);
      form.setFieldsValue({
        contactId: props.location.state.selectedRow.contactId,
        name: props.location.state.selectedRow.name,
        surname: props.location.state.selectedRow.surname,
        email: props.location.state.selectedRow.email,
      });
    }
  }, [props.location.state]);

  function smsNotificationsHandler() {
    setSmsNotification(!isSmsNotification);
    form.setFieldsValue({
      receiveSmsNotifications: !isSmsNotification,
    });
  }

  function emailNotificationsHandler() {
    setEmailNotification(!isEmailNotification);
    form.setFieldsValue({
      receiveEmailNotifications: !isEmailNotification,
    });
  }

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      return history.push("/contacts");
    }
  }, [requestStatus]);

  const handleSave = (values) => {
    var contactDetails = [];

    if (form.getFieldValue().email) {
      contactDetails.push({
        contactType: "EMAIL",
        contactValue: form.getFieldValue().email,
        receiveNotifications: isEmailNotification ? isEmailNotification : false,
      });
    }

    if (mobileNumber.phoneNum) {
      let withOutDialCode = mobileNumber.phoneNum.slice(
        mobileNumber.countryCode.length
      );

      contactDetails.push({
        contactType: "MOBILE",
        contactValue: "(" + mobileNumber.countryCode + ")" + withOutDialCode,
        receiveNotifications: false,
        receiveSmsNotifications: isSmsNotification ? isSmsNotification : false,
      });
    }

    const formData = {
      name: validateInput(form.getFieldValue().name),
      surname: validateInput(form.getFieldValue().surname),
      contactDetails: contactDetails,
    };
    setPristine();
    dispatch(saveContact(formData, form.getFieldValue().contactId));
  };

  const handleCancel = () => {
    history.push("/contacts");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_CONTACTS");
  }

  const footer = (
    <AddFooterSection
      language={language}
      viewOption={props.location.state}
      handleCancel={handleCancel}
      form={form}
      updatePath={"/update-contact"}
      handleEditingPermission={handleEditingPermission}
    />
  );

  return (
    <Card title="Contact" loading={isLoading}>
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddContactForm
            handleSave={handleSave}
            form={form}
            footer={footer}
            setDirty={setDirty}
            readOnly={props.location.state}
            isSmsNotification={isSmsNotification}
            smsNotificationsHandler={smsNotificationsHandler}
            isEmailNotification={isEmailNotification}
            emailNotificationsHandler={emailNotificationsHandler}
            language={language}
            setMobileNumber={setMobileNumber}
            editMobileNumber={props.location.state?.selectedRow.mobile}
          />
        </Col>
      </Row>
      <ResultNotifier />
      {prompt}
    </Card>
  );
};

export default AddContact;
