import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  Select,
  Tooltip,
  Row,
  Col,
  Button,
  Modal,
  Switch,
} from "antd";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import { hasSubscription } from "../../layout/Constants";
import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "../../layout/Style.css";
import { getModalSizeByWindowSize, defaultIcon } from "../../layout/Constants";
import AddSiteForm from "../Sites/AddSiteForm";
import { fetchSites, saveSite } from "../../../api/redux/action/SiteActions";
import { ElementIDS } from "../../../components/common/ElementsIDs";

const { Option } = Select;
const currentTimestamp = Date.now();
let mapZone;
let markerZone;

const AddZoneForm = (props) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState({ site: false });
  const [siteForm] = Form.useForm();
  const [newSiteData, setNewSiteData] = useState(undefined);
  const [modalSize, setModalSize] = useState(getModalSizeByWindowSize());
  const [sortedSites, setSortedSites] = useState([]);
  const [resetSiteMap, setResetSiteMap] = useState(false);

  const { sites, user, zones, englishLang, selectedTenant } = useSelector(
    (store) => ({
      sites: store.siteReducer.sites,
      user: store.authReducer.user,
      zones: store.zoneReducer.zones,
      englishLang: store.langReducer.englishLang,
      selectedTenant: store.authReducer.selectedTenant,
    })
  );

  useEffect(() => {
    if (mapZone) {
      if (
        props.form.getFieldsValue().location &&
        props.form.getFieldsValue().location.latitude &&
        props.form.getFieldsValue().location.longitude
      ) {
        const markerLatLng = new L.LatLng(
          props.form.getFieldsValue().location.latitude,
          props.form.getFieldsValue().location.longitude
        );
        const latLng = {
          latlng: markerLatLng,
        };
        addMarker(latLng);
        mapZone.panTo(markerLatLng);
      }
    }
  }, [mapZone, props.form.getFieldsValue()]);

  useEffect(() => {
    if (sites && sites.content && newSiteData && newSiteData.name) {
      sites.content.map((item) => {
        if (item.name == newSiteData.name) {
          props.form.setFieldsValue({
            ...(newSiteData && {
              site: item.siteId,
            }),
          });
        }
      });
    }
  }, [sites]);

  useEffect(() => {
    dispatch(fetchSites());
  }, [newSiteData]);

  useEffect(() => {
    if (sites && sites.content) {
      let sorted = sites.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedSites(sorted);
    }
  }, [sites]);

  useEffect(() => {
    var container = L.DomUtil.get("mapZone" + currentTimestamp);
    if (container != null) {
      container._leaflet_id = null;
    }

    mapZone = L.map("mapZone" + currentTimestamp, {
      center: [35.9375, 14.3754],
      zoom: 10,
    });

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      // maxZoom: 16,
    }).addTo(mapZone);

    if (props.form.getFieldsValue().location) {
      markerZone = new L.marker(
        new L.LatLng(
          props.form.getFieldsValue().location.latitude,
          props.form.getFieldsValue().location.longitude
        ),
        { icon: defaultIcon }
      ).addTo(mapZone);
    }

    return () => {
      if (mapZone) {
        mapZone.off();
        mapZone.remove();
        mapZone = null;
      }
    };
  }, [props.zoneMapReset]);

  useEffect(() => {
    if (props.readOnly && props.readOnly.viewOption === "disabled") {
    } else {
      mapZone.on("click", addMarker);
      mapZone.on("click", (e) => {
        if (props.readOnly && props.readOnly.viewOption === "disabled") {
        } else {
          props.setDirty();
        }
      });
    }
  }, [props.readOnly, props.zoneMapReset]);

  const handleAddNewSite = () => {
    showModal(true);
    setResetSiteMap(true);
  };

  const handleSiteFormSave = (values) => {
    const formData = {
      name: siteForm.getFieldValue().name,
      location: siteForm.getFieldValue().location,
    };
    if (Object.keys(formData).length > 0) {
      dispatch(saveSite(formData))
        .then(() => {
          setNewSiteData(values);
          formData = {
            name: siteForm.resetFields().name,
            location: siteForm.resetFields().location,
          };
        })
        .catch((err) => console.log(err));
    }
    setVisible(false);
  };

  const showModal = (site) => {
    setVisible({ site: site });
  };

  const handleModalOk = (e) => {
    siteForm.submit();
    setResetSiteMap(false);
  };

  const handleModalCancel = (e) => {
    setResetSiteMap(false);
    setVisible(false);
  };

  const addMarker = (e) => {
    if (markerZone) {
      // check
      mapZone.removeLayer(markerZone); // remove
    }
    markerZone = new L.marker(e.latlng, { icon: defaultIcon }).addTo(mapZone); // set

    const coordinates = markerZone.getLatLng();
    props.form.setFieldsValue({
      location: {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      },
    });
  };

  const infoLocation = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        width: "5rem",
      }}
    >
      <p
        style={{
          textalign: "center",
          margin: 0,
        }}
      >
        {props.language?.LOCATION?.value ?? englishLang?.LOCATION?.value}
      </p>
      <InfoCircleOutlined
        style={{
          zIndex: 1,
          fontSize: "14px",
          cursor: "pointer",
          color: "#585858",
        }}
        type="message"
      />
    </div>
  );

  const infoKillingAssets = (
    <Tooltip
      title={
        props.language?.KILL_ASSET_NOTE?.value ??
        englishLang?.KILL_ASSET_NOTE?.value
      }
      placement="left"
      overlayClassName={
        props.readOnly && props.readOnly.viewOption
          ? "toolTip_hidden"
          : "toolTip_Visible"
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "6rem",
        }}
      >
        <p
          style={{
            textalign: "center",
            margin: 0,
            marginRight: "2px",
          }}
        >
          {props.language?.KILL_ASSET_SWITCH?.value ??
            englishLang?.KILL_ASSET_SWITCH?.value}
        </p>
        <InfoCircleOutlined
          style={{
            zIndex: 1,
            fontSize: "14px",
            cursor: "pointer",
            color: "#585858",
          }}
          type="message"
        />
      </div>
    </Tooltip>
  );

  const killingAssetForm = hasSubscription(
    selectedTenant,
    "KILLING_ZONES_SUBSCRIPTION"
  ) ? (
    <Form.Item
      name="killAssets"
      label={infoKillingAssets}
      className="kill_asset_check"
    >
      <Switch
        disabled={
          props.readOnly && props.readOnly.viewOption ? "disabled" : null
        }
        className="checkbox"
        onChange={props.checkingCheckBox}
        checked={props.isChecked}
      ></Switch>
    </Form.Item>
  ) : null;

  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="zone-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        id={ElementIDS().name}
        label={props.language?.NAME?.value ?? englishLang?.NAME?.value}
        name="name"
        rules={requiredFieldRule}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <Form.Item
        label={props.language?.SITE?.value ?? englishLang?.SITE?.value}
        required
      >
        <Row gutter={3}>
          <Col flex={9} span={22}>
            <Form.Item
              name="site"
              rules={requiredFieldRule}
              id={ElementIDS().site}
            >
              <Select
                onChange={(e) => {
                  props.setDirty();
                }}
                allowClear
                style={{ width: "100%" }}
                placeholder={
                  props.language?.PLEASE_SELECT_A_SITE?.value ??
                  englishLang?.PLEASE_SELECT_A_SITE?.value
                }
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              >
                {sortedSites
                  ? sortedSites.map((item) => (
                      <Option
                        id={ElementIDS().option}
                        key={item.siteId}
                        value={item.siteId}
                      >
                        {item.name}
                      </Option>
                    ))
                  : undefined}
              </Select>
            </Form.Item>
          </Col>
          <Col flex={1} span={2}>
            <Tooltip
              title={
                props.language?.CREATE_NEW_SITE?.value ??
                englishLang?.CREATE_NEW_SITE?.value
              }
            >
              <Button
                id={ElementIDS().create}
                icon={<PlusOutlined />}
                ghost
                type="primary"
                onClick={handleAddNewSite}
                style={{ width: "100%" }}
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              />
            </Tooltip>
          </Col>
        </Row>
      </Form.Item>
      {killingAssetForm}
      <Tooltip
        title={
          props.language?.CLICK_ON_THE_MAP_TO_SET_LOCATION?.value ??
          englishLang?.CLICK_ON_THE_MAP_TO_SET_LOCATION?.value
        }
        overlayClassName={
          props.readOnly && props.readOnly.viewOption
            ? "toolTip_hidden"
            : "toolTip_Visible"
        }
      >
        <Form.Item
          label={infoLocation}
          name="location"
          rules={requiredFieldRule}
        >
          <div
            id={"mapZone" + currentTimestamp}
            style={{ height: "40vh", zIndex: 0 }}
          />
        </Form.Item>
      </Tooltip>

      <Modal
        title={props.language?.ADD_SITE?.value ?? englishLang?.ADD_SITE?.value}
        visible={visible.site}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={modalSize}
      >
        <Row justify="center">
          <Col span={20}>
            <AddSiteForm
              language={props.language}
              handleSave={handleSiteFormSave}
              form={siteForm}
              setDirty={props.setDirty}
              resetSiteMap={resetSiteMap}
            />
          </Col>
        </Row>
      </Modal>
      {props.footer}
    </Form>
  );
};

export default AddZoneForm;
