import React, { useRef, useEffect, useState } from "react";
import { Image, Text, Group, Circle } from "react-konva";

const FloorPlanComponentsView = ({
  component,
  onSelect,
  isSelected,
  assetCount,
  report,
}) => {
  const [image, setImage] = useState(null);
  const [count, setCount] = useState(null);

  const imageRef = useRef();
  // Component Images
  const anntenaIcon = "./FloorPlanComponents/anntenaIcon.png";
  const readerIcon = "./FloorPlanComponents/readerIcon.png";
  const readerIconAIO = "./FloorPlanComponents/readerIconAIO.png";
  const gatewayIcon = "./FloorPlanComponents/gatewayIcon.png";
  const iPRelayCardIcon = "./FloorPlanComponents/iPRelayCardIcon.png";
  const sirenIcon = "./FloorPlanComponents/sirenIcon.png";
  const barrierIcon = "./FloorPlanComponents/barrierIcon.png";

  const checkWhatComponent = () => {
    switch (component.type) {
      case "Antenna":
        return anntenaIcon;
      case "Reader AIO":
        return readerIconAIO;
      case "Mini PC":
        return gatewayIcon;
      case "Siren":
        return sirenIcon;
      case "Reader":
        return readerIcon;
      case "Denkovi":
        return iPRelayCardIcon;
      case "Barrier":
        return barrierIcon;
    }
  };

  const onMouseEnter = (event) => {
    if (isSelected) {
      event.target.getStage().container().style.cursor = "pointer";
    }
  };

  const onMouseLeave = (event) => {
    event.target.getStage().container().style.cursor = "default";
  };

  useEffect(() => {
    const img = new window.Image();
    img.src = checkWhatComponent();
    img.onload = () => {
      setImage(img);
    };
  }, [component]);
  component.icon = image;

  // Converting strings into actual code
  component.height = parseInt(component.height);
  component.width = parseInt(component.width);
  component.x = parseInt(component.x);
  component.y = parseInt(component.y);
  component.rotation = parseInt(component.rotation);
  component.visible = Boolean(component.visible);
  component.isDragging = Boolean(component.isDragging);

  useEffect(() => {
    // Find the assetCount for the matching zone
    const matchingZone = assetCount?.find(
      (zone) => zone.zoneId === component?.zoneId
    );
    // Set the count to 0 if no matching zone is found
    const count = matchingZone ? matchingZone.assetCount : 0;
    // Set the count in state
    setCount(count);
  }, [assetCount, component?.zoneId, report]);

  return (
    <>
      <Group onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Text
          align="center"
          text={component.zone ? component.zone : component.title}
          x={component.x - 30}
          y={component.y - 40}
          fontSize={12}
          fontFamily="monospace"
          fontStyle="bold"
          fill="#2c87c0"
        />
        {report ? (
          <>
            <Circle
              radius={count < 100 ? 16 : 20}
              fill="#faad14"
              x={component.x + 35}
              y={component.y + 30}
              offsetX={6} // This centers the Circle horizontally
              offsetY={12} // This centers the Circle vertically
            />
            <Text
              align="center"
              text={count}
              x={component.x + 20}
              y={component.y + 20}
              fontSize={20}
              fontFamily="monospace"
              fontStyle="bold"
              fill="#ffffff"
              offsetX={
                count < 10
                  ? -2 // If count is less than 10, center horizontally with offsetX=3
                  : count < 100
                  ? Math.round(count?.toString().length / 2) * 3 // Otherwise center based on count length
                  : 8 // If count is greater than or equal to 100, center with offsetX=14
              }
              offsetY={
                count < 10
                  ? 9 // If count is less than 10, center vertically with offsetY=9
                  : count < 100
                  ? 9 // Otherwise, center vertically with offsetY=9
                  : 10 // If count is greater than or equal to 100, center with offsetY=10
              }
            />
          </>
        ) : null}

        <Image
          ref={imageRef}
          image={component.icon}
          offsetX={component ? component.width / 2 : 0}
          offsetY={component ? component.height / 2 : 0}
          {...component}
          onClick={onSelect}
        />
      </Group>
    </>
  );
};

export default FloorPlanComponentsView;
