import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchRelaySwitches,
  deleteRelaySwitch,
} from "../../../api/redux/action/RelaySwitchActions";
import RelaySwitchActionView from "../../../components/common/ActionMenu";
import useNotification from "../../../components/common/ResultNotifier";
import { hasPermission } from "../../layout/Constants";
import { useHistory } from "react-router-dom";
import { exportRequest } from "../../../api/network/axios/exportRequest";
function RelaySwitches() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState();
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });
  let history = useHistory();

  const {
    relaySwitches,
    allRelaySwitches,
    error,
    requestStatus,
    loading,
    user,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    relaySwitches: store.relaySwitchReducer.relaySwitches,
    allRelaySwitches: store.relaySwitchReducer.allRelaySwitches,
    error: store.relaySwitchReducer.error,
    requestStatus: store.relaySwitchReducer.requestStatus,
    loading: store.relaySwitchReducer.loading,
    user: store.authReducer.user,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const columns = [
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
      sorter: true,
      sortField: "name",
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      render: (name) => (
        <a
          onClick={() => {
            const viewOption = "disabled";

            history.push({
              pathname: "/update-relaySwitch",
              state: { selectedRow, viewOption },
            });
          }}
        >
          {name}
        </a>
      ),
    },
    {
      title:
        language?.SWITCH_NUMBER?.value ?? englishLang?.SWITCH_NUMBER?.value,
      dataIndex: "switchNo",
      sorter: true,
      sortField: "switchNo",
      sortOrder: sortedInfo.columnKey === "switchNo" && sortedInfo.order,
    },
    {
      title:
        language?.IP_RELAT_CARD?.value ?? englishLang?.IP_RELAT_CARD?.value,
      dataIndex: ["ipRelayCard", "ipAddress"],
      sorter: true,
      sortField: "ipRelayCardEntity.ipAddress",
      sortOrder:
        sortedInfo.columnKey === "ipRelayCardEntity.ipAddress" &&
        sortedInfo.order,
    },
    {
      title: language?.PULSE_IN_MS?.value ?? englishLang?.PULSE_IN_MS?.value,
      dataIndex: "pulseMs",
      sorter: true,
      sortField: "pulseMs",
      sortOrder: sortedInfo.columnKey === "pulseMs" && sortedInfo.order,
    },
  ];

  const [allowPermission, setAllowPermission] = useState({
    edit: hasPermission(selectedTenant, "UPDATE_RELAY_SWITCHES"),
    delete: hasPermission(selectedTenant, "DELETE_RELAY_SWITCHES"),
  });

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const handleSingleDelete = (selected) => {
    dispatch(deleteRelaySwitch(selected.relaySwitchId));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    sortField,
    sortOrder,
    selectedRow,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "relaySwitch",
    dataSource: relaySwitches,
    updateEntityPath: "update-relaySwitch",
    customActionView:
      allowPermission.edit || allowPermission.delete
        ? RelaySwitchActionView
        : "",
    searchText,
    loading: loading,
    handleSingleDelete,
    allowPermission,
    setSortedInfo,
    title:
      language?.RELAY_SWITCHES?.value ?? englishLang?.RELAY_SWITCHES?.value,
  });

  useEffect(() => {
    dispatch(
      fetchRelaySwitches({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-relay-switches";
    let options = {
      url: "/api/web/relay-switches/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        searchText: searchText,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  let flattenedData = [];

  if (Object.keys(relaySwitches).length !== 0) {
    flattenedData = Object.values(relaySwitches.content);
  }

  useEffect(() => {
    if (allRelaySwitches && Object.keys(allRelaySwitches).length !== 0) {
      setFlattenedAllData(Object.values(allRelaySwitches.content));
    }
  }, [allRelaySwitches]);

  const headers = [
    { label: language?.NAME?.value ?? englishLang?.NAME?.value, key: "name" },
    {
      label:
        language?.SWITCH_NUMBER?.value ?? englishLang?.SWITCH_NUMBER?.value,
      key: "switchNo",
    },
    {
      label:
        language?.IP_RELAT_CARD?.value ?? englishLang?.IP_RELAT_CARD?.value,
      key: "ipRelayCard.ipAddress",
    },
    {
      label: language?.PULSE_IN_MS?.value ?? englishLang?.PULSE_IN_MS?.value,
      key: "pulseMs",
    },
  ];

  const addNewPath = hasPermission(selectedTenant, "CREATE_RELAY_SWITCHES")
    ? "add-relaySwitch"
    : "";

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath={addNewPath}
          hasSelected={hasSelected}
          exportTableTitle={
            language?.RELAYSWITCHES?.value ?? englishLang?.RELAYSWITCHES?.value
          }
          addNewText={
            language?.ADD_RELAY_SWITCH?.value ??
            englishLang?.ADD_RELAY_SWITCH?.value
          }
          title={
            language?.RELAY_SWITCHES?.value ??
            englishLang?.RELAY_SWITCHES?.value
          }
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllButtonClick={handleExportAllButtonClick}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportButton={true}
          exportAllButton={true}
          handleSearch={setSearchText}
          tooltipText={
            language?.TOOLTIP_SEARCH_ALL?.value ??
            englishLang?.TOOLTIP_SEARCH_ALL?.value
          }
        />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default RelaySwitches;
