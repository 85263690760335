import Header from "../../../components/common/ReportHeader";
import useDataTable from "../../../components/common/DataTable";
import useNotification from "../../../components/common/ResultNotifier";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReport,
  fetchAllReport,
  resetReport,
} from "../../../api/redux/action/ReportActions";
import { toTimestamp } from "../../layout/Constants";
import { exportRequest } from "../../../api/network/axios/exportRequest";
import moment from "moment-timezone";

import { ElementIDS } from "../../../components/common/ElementsIDs";
import { RedoOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Divider,
  Row,
  Col,
  Tooltip,
  Form,
  Space,
  Button,
} from "antd";

const SmsConsumption = () => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();

  const dateFormat = "DD-MM-YYYY";
  const today = new Date();
  const todayStr =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  const [dataTable, setDataTable] = useState();

  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState({
    fromDate: todayStr,
    toDate: todayStr,
  });
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  const {
    data,
    allData,
    loading,
    error,
    requestStatus,
    userTimeZone,
    language,
    englishLang,
  } = useSelector((store) => ({
    data: store.reportReducer.data,
    allData: store.reportReducer.allData,
    loading: store.reportReducer.loading,
    error: store.reportReducer.error,
    requestStatus: store.reportReducer.requestStatus,
    userTimeZone: store.authReducer.userTimeZone,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const columns = [
    {
      key: "sentTimestamp",
      sorter: true,
      sortField: "sentTimestamp",
      sortOrder: sortedInfo.columnKey === "sentTimestamp" && sortedInfo.order,
      title:
        language?.DATE_AND_TIME?.value ?? englishLang?.DATE_AND_TIME?.value,
      dataIndex: "sentTimestamp",
      render: (sentTimestamp) => (
        <span>
          {sentTimestamp
            ? moment
                .tz(sentTimestamp, "DD-MM-YYYY HH:mm:ss", userTimeZone)
                .format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
    },
    {
      key: "name",
      sorter: true,
      sortField: "name",
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
    },
    {
      key: "surname",
      sorter: true,
      sortField: "surname",
      sortOrder: sortedInfo.columnKey === "surname" && sortedInfo.order,
      title: language?.SURNAME?.value ?? englishLang?.SURNAME?.value,
      dataIndex: "surname",
    },
    {
      key: "phoneNumber",
      sorter: false,
      sortField: "phoneNumber",
      sortOrder: sortedInfo.columnKey === "phoneNumber" && sortedInfo.order,
      title:
        language?.MOBILE_NUMBER?.value ?? englishLang?.MOBILE_NUMBER?.value,
      dataIndex: "phoneNumber",
    },
  ];

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const {
    DataTable,
    pageSize,
    currentPage,
    hasSelected,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    updateEntityPath: "",
    dataSource: data,
    filter,
    loading: loading,
    setSortedInfo: setSortedInfo,
  });

  const handleDateRangeChange = (date, dateString) => {
    setFilter({
      ...filter,
      fromDate: dateString[0],
      toDate: dateString[1],
    });
  };

  const handleRefreshClick = () => {
    setRefresh(!refresh);
  };
  const ascDesc = () => {
    if (sortedInfo.order === "ascend") {
      return "ASC";
    } else {
      return "DESC";
    }
  };

  useEffect(() => {
    dispatch(resetReport());
  }, []);

  useEffect(() => {
    let endpoint = sortedInfo.columnKey
      ? `sms-alert-log-report?sort=${sortedInfo.columnKey},${ascDesc()}`
      : "sms-alert-log-report";

    dispatch(
      fetchReport({
        page: searchFormHelperHandler(),
        size: pageSize,
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
        endpoint: endpoint,
      })
    );
  }, [currentPage, filter, isLoading, refresh, pageSize, sortedInfo]);

  useEffect(() => {
    setDataTable(DataTable);
  }, [loading, language]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "sms-alert-log-report";

    let options = {
      url: "/api/web/reports/sms-alert-log-report/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
    };

    options = {
      ...options,
      params: {
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      let flatData;
      function flatDataHandler() {
        if (data.content === undefined) {
          return (flatData = Object.values(data));
        } else {
          return (flatData = Object.values(data.content));
        }
      }
      flatDataHandler();
      flatData.map((item) => {
        item.sentTimestamp = item.sentTimestamp
          ? moment
              .tz(item.sentTimestamp, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedAllData(Object.values(flatData));
    }
  }, [data]);

  const headers = [
    {
      label:
        language?.DATE_AND_TIME?.value ?? englishLang?.DATE_AND_TIME?.value,
      key: "sentTimestamp",
    },
    { label: language?.NAME?.value ?? englishLang?.NAME?.value, key: "name" },
    {
      label: language?.SURNAME?.value ?? englishLang?.SURNAME?.value,
      key: "surname",
    },
    {
      label:
        language?.MOBILE_NUMBER?.value ?? englishLang?.MOBILE_NUMBER?.value,
      key: "phoneNumber",
    },
  ];

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          hasSelected={hasSelected}
          exportTableTitle={
            language?.SMS_CONSUMPTION?.value ??
            englishLang?.SMS_CONSUMPTION?.value
          }
          addNewText=""
          title={
            language?.SMS_CONSUMPTION?.value ??
            englishLang?.SMS_CONSUMPTION?.value
          }
          csvHeaders={headers}
          csvAllData={flattenedAllData}
          loading={loading}
          exportAllLoading={exportAllLoading}
          exportAllButtonClick={handleExportAllButtonClick}
        />
        <Row>
          <Col>
            <Form>
              <Space>
                <Tooltip
                  title={
                    language?.SELECT_A_DATE_RANGE?.value ??
                    englishLang?.SELECT_A_DATE_RANGE?.value
                  }
                >
                  <RangePicker
                    id={ElementIDS().calendar}
                    defaultValue={[
                      moment(todayStr, dateFormat),
                      moment(todayStr, dateFormat),
                    ]}
                    format={dateFormat}
                    onChange={handleDateRangeChange}
                  />
                </Tooltip>
                <Button
                  id={ElementIDS().refresh}
                  type="primary"
                  onClick={() => handleRefreshClick()}
                >
                  <RedoOutlined />
                  {language?.REFRESH?.value ?? englishLang?.REFRESH?.value}
                </Button>
              </Space>
            </Form>
          </Col>
        </Row>
        <Divider />
        {dataTable}
        <ResultNotifier />
      </div>
    </>
  );
};

export default SmsConsumption;
