import { Layout, Card, Row, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { logout } from "../../api/redux/action/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { selectTenant, logoutJWT } from "../../api/redux/action/AuthActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import MainLayout from "./MainLayout";

const TenantLogin = ({ user }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setIsLoading] = useState(false);
  const [screen, setScreen] = useState();

  const handleLogout = () => {
    setIsLoading(true);
    dispatch(logoutJWT());
  };

  const { tenant } = useSelector((store) => ({
    tenant: store.authReducer.selectedTenant,
  }));
  useEffect(() => {
    if (tenant) {
      setScreen(<MainLayout user={user} selectedTenant={tenant} />);
    } else {
      setScreen(
        <Layout style={{ minHeight: "100vh" }}>
          {" "}
          <div className="login">
            <img
              className="rfid_logo"
              src="/Images/rfid_logo_lhtBlue.png"
              alt="RFID Logo"
            />
            <img
              className="rfid_logo_mobile"
              src="/Images/rfid_logo_white.png"
              alt="RFID Logo White"
            />
            <div className="left_side_screen">
              <img
                className="regular_image"
                src="/Images/left-graphic.png"
                alt="RFID Image"
              />
            </div>
            <div className="right_side_screen">
              <div className="login_content">
                <p>
                  Tenant List <span></span>
                </p>
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    padding: "0px 20px",
                  }}
                >
                  {user.tenantConfigurations.map((tenant, index) => (
                    <div key={index}>
                      <Card
                        style={{
                          width: "auto",
                          margin: "20px 0px",
                          borderRadius: "20px",
                          fontSize: "12px",
                          display: "flex",
                          alignContent: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          flexDirection: "column",
                          borderRadius: "5px",
                        }}
                        onClick={() => {
                          dispatch(selectTenant(tenant));
                          location.reload(); //This line helps App.js fetch current tenant for Apollo client
                        }}
                      >
                        {" "}
                        <Row
                          style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                          }}
                        >
                          <h2 style={{ margin: "0px" }}>{tenant.tenantName}</h2>
                          {/* <RightOutlined
                            style={{
                              fontSize: "20px",
                              color: "red",
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              textAlign: "center",
                              margin: "5px 10px",
                            }}
                          /> */}
                        </Row>
                      </Card>
                    </div>
                  ))}
                </div>

                <Button
                  onClick={handleLogout}
                  className="login-form-button"
                  loading={loading}
                >
                  Log out
                </Button>
                <div>
                  <div className="handsOn_Logo_bottom">
                    <a
                      href="https://www.handsonsystems.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="./Images/handson-logo.png" alt="Handson" />
                    </a>
                  </div>
                  <div className="copyright">
                    <p className="copyright_description">
                      © {new Date().getFullYear()} All rights reserved. RFID is
                      a registered trademark of HandsOn Systems Ltd.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      );
    }
  }, [tenant]);

  return screen;
};

export default TenantLogin;
