import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import moment from "moment-timezone";

function Map1({ data }) {
  const { user, userTimeZone, language, englishLang } = useSelector(
    (store) => ({
      user: store.authReducer.user,
      userTimeZone: store.authReducer.userTimeZone,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
    })
  );

  useEffect(() => {
    const lastSeenSinceMap = L.map("lastSeenSinceMap").setView(
      [35.9375, 14.3754],
      10
    );

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      //   attribution:
      //     '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      // maxZoom: 16,
    }).addTo(lastSeenSinceMap);

    var markers = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
      maxClusterRadius: function (zoom) {
        return 20;
      },
    });

    const points =
      data && data.assetLastDetectedAge.length > 0
        ? data.assetLastDetectedAge.map((p) => {
            if (p.location) {
              var latlng = L.latLng(p.location.latitude, p.location.longitude);

              var label =
                `<strong>${
                  language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value
                }: </strong>` +
                p.asset.code +
                `</br> <strong>${
                  language?.LAST_DETECTED?.value ??
                  englishLang?.LAST_DETECTED?.value
                }: </strong>` +
                moment
                  .tz(p.lastDetected, userTimeZone)
                  .format("DD-MM-YYYY HH:mm:ss");
              var pMarker = new L.CircleMarker(latlng, {
                radius: 5,
                color: "#FFFFFF",
                weight: 2,
                fillOpacity: 1,
                fillColor: "red",
              });

              pMarker.bindPopup(label);
              markers.addLayer(pMarker);
              return [p.location.latitude, p.location.longitude];
            }
          })
        : [];

    let marks = [];
    points.map((n) => {
      marks.push(L.marker(n));
    });

    let group = L.featureGroup(marks);

    setTimeout(function () {
      lastSeenSinceMap.fitBounds(group.getBounds());
    }, 1000);

    //   setTimeout(function(){
    lastSeenSinceMap.addLayer(markers);
    // },500)
  }, []);

  return <div id="lastSeenSinceMap" style={{ height: "40vh" }} />;
}

export default Map1;
