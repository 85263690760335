import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Card, Tooltip, Skeleton, Switch, Space, Slider, Result } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import HeatMap from "./HeatMap";
import { ASSET_LAST_SEEN_DISTRIBUTION_OVER_LAST_NUMBER_OF_DAYS } from "../../../../api/graphQL/queries";
import { Redirect } from "react-router";
import { Empty } from "antd";
import { useSelector } from "react-redux";
const LastSeenAnalysis = (props) => {
  const [inputValue, setInputValue] = useState(0);
  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));
  const { loading, error, data, startPolling, stopPolling } = useQuery(
    ASSET_LAST_SEEN_DISTRIBUTION_OVER_LAST_NUMBER_OF_DAYS,
    {
      variables: { geohashLength: 10, maxAgeInDays: inputValue },
      pollInterval: 60000,
      fetchPolicy: "network-only",
    }
  );
  // useEffect(() => {
  //   startPolling(60000);

  //   return () => {
  //     stopPolling();
  //   };
  // }, [stopPolling,startPolling])

  if (loading)
    return (
      <div style={{ background: "#fff", padding: "20px" }}>
        <Skeleton active />
      </div>
    );
  // if (error) return `Error!`;
  if (error) {
    // window.location.reload();
    // localStorage.removeItem("user");
    // document.cookie = "JSESSIONID= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    // window.location.reload();
    // <Redirect push to="/login" />;
    return (
      <Result
        status="warning"
        title={
          language?.ERROR_FETCHING_DATA?.value ??
          englishLang?.ERROR_FETCHING_DATA?.value
        }
      />
    );
  }

  const tooltip = (
    <Tooltip
      title={
        <>
          <p>
            {props.language?.MAP_DISTRIBUTION_DETECTED?.value ??
              englishLang?.MAP_DISTRIBUTION_DETECTED?.value}
            <sup>2</sup>{" "}
            {props.language?.COUNT_OCCURRENCES_HEATMAP?.value ??
              englishLang?.COUNT_OCCURRENCES_HEATMAP?.value}
          </p>
        </>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );

  const marks = {
    0: "",
    10: "",
    20: "",
    30: "",
  };

  function formatter(value) {
    if (value == 0) {
      return "Today";
    } else if (value == 1) {
      return "Yesterday";
    } else {
      return `${value} days`;
    }
  }

  const onChange = (newValue) => {
    setInputValue(newValue);
  };

  // check if we got data from api response
  function CheckForData() {
    if (
      data &&
      data.assetLastDetectedDistributionOverLastNumberOfDays.length > 0
    ) {
      return <HeatMap data={data} />;
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Card
      title={
        props.language?.LAST_DETECTED_DISTR_HEATMAP?.value ??
        englishLang?.LAST_DETECTED_DISTR_HEATMAP?.value
      }
      extra={
        <Space>
          <Slider
            style={{ width: "300px" }}
            min={0}
            max={30}
            marks={marks}
            defaultValue={inputValue}
            onAfterChange={onChange}
            formatter={formatter}
          />
          <br />
          <br />
          {tooltip}
        </Space>
      }
    >
      {CheckForData()}
    </Card>
  );
};

export default LastSeenAnalysis;
