// ASSET GROUPS
export const ASSETGROUPS_FETCH_REQUEST = "ASSETGROUPS_FETCH_REQUEST";
export const ASSETGROUPS_FETCH_SUCCESS = "ASSETGROUPS_FETCH_SUCCESS";
export const ASSETGROUPS_FETCH_FAILURE = "ASSETGROUPS_FETCH_FAILURE";
export const ALL_ASSETGROUPS_FETCH_REQUEST = "ALL_ASSETGROUPS_FETCH_REQUEST";
export const ALL_ASSETGROUPS_FETCH_SUCCESS = "ALL_ASSETGROUPS_FETCH_SUCCESS";
export const ALL_ASSETGROUPS_FETCH_FAILURE = "ALL_ASSETGROUPS_FETCH_FAILURE";
export const ASSETGROUP_SAVE_REQUEST = "ASSETGROUP_SAVE_REQUEST";
export const ASSETGROUP_SAVE_SUCCESS = "ASSETGROUP_SAVE_SUCCESS";
export const ASSETGROUP_SAVE_FAILURE = "ASSETGROUP_SAVE_FAILURE";
export const ASSETGROUP_DELETE_REQUEST = "ASSETGROUP_DELETE_REQUEST";
export const ASSETGROUP_DELETE_SUCCESS = "ASSETGROUP_DELETE_SUCCESS";
export const ASSETGROUP_DELETE_FAILURE = "ASSETGROUP_DELETE_FAILURE";
export const ASSETGROUPS_WITH_FIELDS_FETCH_REQUEST =
  "ASSETGROUPS_WITH_FIELDS_FETCH_REQUEST";
export const ASSETGROUPS_WITH_FIELDS_FETCH_SUCCESS =
  "ASSETGROUPS_WITH_FIELDS_FETCH_SUCCESS";
export const ASSETGROUPS_WITH_FIELDS_FETCH_FAILURE =
  "ASSETGROUPS_WITH_FIELDS_FETCH_FAILURE";

// ASSETS
export const ASSETS_FETCH_REQUEST = "ASSETS_FETCH_REQUEST";
export const ASSETS_FETCH_SUCCESS = "ASSETS_FETCH_SUCCESS";
export const ASSETS_FETCH_FAILURE = "ASSETS_FETCH_FAILURE";
export const ALL_ASSETS_FETCH_REQUEST = "ALL_ASSETS_FETCH_REQUEST";
export const ALL_ASSETS_FETCH_SUCCESS = "ALL_ASSETS_FETCH_SUCCESS";
export const ALL_ASSETS_FETCH_FAILURE = "ALL_ASSETS_FETCH_FAILURE";
export const ASSET_SAVE_REQUEST = "ASSET_SAVE_REQUEST";
export const ASSET_SAVE_SUCCESS = "ASSET_SAVE_SUCCESS";
export const ASSET_SAVE_FAILURE = "ASSET_SAVE_FAILURE";
export const ASSET_DELETE_REQUEST = "ASSET_DELETE_REQUEST";
export const ASSET_DELETE_SUCCESS = "ASSET_DELETE_SUCCESS";
export const ASSET_DELETE_FAILURE = "ASSET_DELETE_FAILURE";
export const ASSET_KILL_REQUEST = "ASSET_KILL_REQUEST";
export const ASSET_KILL_SUCCESS = "ASSET_KILL_SUCCESS";
export const ASSET_KILL_FAILURE = "ASSET_KILL_FAILURE";

// ALL ASSETS WITH KILLED ASSETS
export const ALL_WITH_KILLED_ASSETS_REQUEST = "ALL_WITH_KILLED_ASSETS_REQUEST";
export const ALL_WITH_KILLED_ASSETS_SUCCESS = "ALL_WITH_KILLED_ASSETS_SUCCESS";
export const ALL_WITH_KILLED_ASSETS_FAILURE = "ALL_WITH_KILLED_ASSETS_FAILURE";

// ASSET LOCATION
export const ASSETLOCATION_FETCH_REQUEST = "ASSETLOCATION_FETCH_REQUEST";
export const ASSETLOCATION_FETCH_SUCCESS = "ASSETLOCATION_FETCH_SUCCESS";
export const ASSETLOCATION_FETCH_FAILURE = "ASSETLOCATION_FETCH_FAILURE";
export const ALL_ASSETLOCATION_FETCH_REQUEST =
  "ALL_ASSETLOCATION_FETCH_REQUEST";
export const ALL_ASSETLOCATION_FETCH_SUCCESS =
  "ALL_ASSETLOCATION_FETCH_SUCCESS";
export const ALL_ASSETLOCATION_FETCH_FAILURE =
  "ALL_ASSETLOCATION_FETCH_FAILURE";
export const ASSETLOCATION_SAVE_REQUEST = "ASSETLOCATION_SAVE_REQUEST";
export const ASSETLOCATION_SAVE_SUCCESS = "ASSETLOCATION_SAVE_SUCCESS";
export const ASSETLOCATION_SAVE_FAILURE = "ASSETLOCATION_SAVE_FAILURE";
export const ASSETLOCATION_DELETE_REQUEST = "ASSETLOCATION_DELETE_REQUEST";
export const ASSETLOCATION_DELETE_SUCCESS = "ASSETLOCATION_DELETE_SUCCESS";
export const ASSETLOCATION_DELETE_FAILURE = "ASSETLOCATION_DELETE_FAILURE";

// DEPARTMENTS
export const DEPARTMENTS_FETCH_REQUEST = "DEPARTMENTS_FETCH_REQUEST";
export const DEPARTMENTS_FETCH_SUCCESS = "DEPARTMENTS_FETCH_SUCCESS";
export const DEPARTMENTS_FETCH_FAILURE = "DEPARTMENTS_FETCH_FAILURE";
export const ALL_DEPARTMENTS_FETCH_REQUEST = "ALL_DEPARTMENTS_FETCH_REQUEST";
export const ALL_DEPARTMENTS_FETCH_SUCCESS = "ALL_DEPARTMENTS_FETCH_SUCCESS";
export const ALL_DEPARTMENTS_FETCH_FAILURE = "ALL_DEPARTMENTS_FETCH_FAILURE";
export const DEPARTMENT_SAVE_REQUEST = "DEPARTMENT_SAVE_REQUEST";
export const DEPARTMENT_SAVE_SUCCESS = "DEPARTMENT_SAVE_SUCCESS";
export const DEPARTMENT_SAVE_FAILURE = "DEPARTMENT_SAVE_FAILURE";
export const DEPARTMENT_DELETE_REQUEST = "DEPARTMENT_DELETE_REQUEST";
export const DEPARTMENT_DELETE_SUCCESS = "DEPARTMENT_DELETE_SUCCESS";
export const DEPARTMENT_DELETE_FAILURE = "DEPARTMENT_DELETE_FAILURE";

// CUSTOM FIELDS
export const CUSTOMFIELDS_FETCH_REQUEST = "CUSTOMFIELDS_FETCH_REQUEST";
export const CUSTOMFIELDS_FETCH_SUCCESS = "CUSTOMFIELDS_FETCH_SUCCESS";
export const CUSTOMFIELDS_FETCH_FAILURE = "CUSTOMFIELDS_FETCH_FAILURE";
export const ALL_CUSTOMFIELDS_FETCH_REQUEST = "ALL_CUSTOMFIELDS_FETCH_REQUEST";
export const ALL_CUSTOMFIELDS_FETCH_SUCCESS = "ALL_CUSTOMFIELDS_FETCH_SUCCESS";
export const ALL_CUSTOMFIELDS_FETCH_FAILURE = "ALL_CUSTOMFIELDS_FETCH_FAILURE";
export const CUSTOMFIELD_SAVE_REQUEST = "CUSTOMFIELD_SAVE_REQUEST";
export const CUSTOMFIELD_SAVE_SUCCESS = "CUSTOMFIELD_SAVE_SUCCESS";
export const CUSTOMFIELD_SAVE_FAILURE = "CUSTOMFIELD_SAVE_FAILURE";
export const CUSTOMFIELD_DELETE_REQUEST = "CUSTOMFIELD_DELETE_REQUEST";
export const CUSTOMFIELD_DELETE_SUCCESS = "CUSTOMFIELD_DELETE_SUCCESS";
export const CUSTOMFIELD_DELETE_FAILURE = "CUSTOMFIELD_DELETE_FAILURE";

// ALERTRULES
export const ALERTRULES_FETCH_REQUEST = "ALERTRULES_FETCH_REQUEST";
export const ALERTRULES_FETCH_SUCCESS = "ALERTRULES_FETCH_SUCCESS";
export const ALERTRULES_FETCH_FAILURE = "ALERTRULES_FETCH_FAILURE";
export const ALERTRULE_SAVE_REQUEST = "ALERTRULE_SAVE_REQUEST";
export const ALERTRULE_SAVE_SUCCESS = "ALERTRULE_SAVE_SUCCESS";
export const ALERTRULE_SAVE_FAILURE = "ALERTRULE_SAVE_FAILURE";
export const ALERTRULE_DELETE_REQUEST = "ALERTRULE_DELETE_REQUEST";
export const ALERTRULE_DELETE_SUCCESS = "ALERTRULE_DELETE_SUCCESS";
export const ALERTRULE_DELETE_FAILURE = "ALERTRULE_DELETE_FAILURE";
export const ALERTRULE_ENABLE_REQUEST = "ALERTRULE_ENABLE_REQUEST";
export const ALERTRULE_ENABLE_SUCCESS = "ALERTRULE_ENABLE_SUCCESS";
export const ALERTRULE_ENABLE_FAILURE = "ALERTRULE_ENABLE_FAILURE";

// ANTENNAS
export const ANTENNAS_FETCH_REQUEST = "ANTENNAS_FETCH_REQUEST";
export const ANTENNAS_FETCH_SUCCESS = "ANTENNAS_FETCH_SUCCESS";
export const ANTENNAS_FETCH_FAILURE = "ANTENNAS_FETCH_FAILURE";
export const ALL_ANTENNAS_FETCH_REQUEST = "ALL_ANTENNAS_FETCH_REQUEST";
export const ALL_ANTENNAS_FETCH_SUCCESS = "ALL_ANTENNAS_FETCH_SUCCESS";
export const ALL_ANTENNAS_FETCH_FAILURE = "ALL_ANTENNAS_FETCH_FAILURE";
export const ANTENNA_SAVE_REQUEST = "ANTENNA_SAVE_REQUEST";
export const ANTENNA_SAVE_SUCCESS = "ANTENNA_SAVE_SUCCESS";
export const ANTENNA_SAVE_FAILURE = "ANTENNA_SAVE_FAILURE";
export const ANTENNA_DELETE_REQUEST = "ANTENNA_DELETE_REQUEST";
export const ANTENNA_DELETE_SUCCESS = "ANTENNA_DELETE_SUCCESS";
export const ANTENNA_DELETE_FAILURE = "ANTENNA_DELETE_FAILURE";
export const FETCH_SINGLE_ANTENNA_REQUEST = "FETCH_SINGLE_ANTENNA_REQUEST";
export const FETCH_SINGLE_ANTENNA_SUCCESS = "FETCH_SINGLE_ANTENNA_SUCCESS";
export const FETCH_SINGLE_ANTENNA_FAILURE = "FETCH_SINGLE_ANTENNA_FAILURE";

// FIXEDREADERS
export const FIXEDREADERS_FETCH_REQUEST = "FIXEDREADERS_FETCH_REQUEST";
export const FIXEDREADERS_FETCH_SUCCESS = "FIXEDREADERS_FETCH_SUCCESS";
export const FIXEDREADERS_FETCH_FAILURE = "FIXEDREADERS_FETCH_FAILURE";
export const ALL_FIXEDREADERS_FETCH_REQUEST = "ALL_FIXEDREADERS_FETCH_REQUEST";
export const ALL_FIXEDREADERS_FETCH_SUCCESS = "ALL_FIXEDREADERS_FETCH_SUCCESS";
export const ALL_FIXEDREADERS_FETCH_FAILURE = "ALL_FIXEDREADERS_FETCH_FAILURE";
export const FIXEDREADER_SAVE_REQUEST = "FIXEDREADER_SAVE_REQUEST";
export const FIXEDREADER_SAVE_SUCCESS = "FIXEDREADER_SAVE_SUCCESS";
export const FIXEDREADER_SAVE_FAILURE = "FIXEDREADER_SAVE_FAILURE";
export const FIXEDREADER_DELETE_REQUEST = "FIXEDREADER_DELETE_REQUEST";
export const FIXEDREADER_DELETE_SUCCESS = "FIXEDREADER_DELETE_SUCCESS";
export const FIXEDREADER_DELETE_FAILURE = "FIXEDREADER_DELETE_FAILURE";
export const FETCH_SINGLE_FIXEDREADERS_REQUEST =
  "FETCH_SINGLE_FIXEDREADERS_REQUEST";
export const FETCH_SINGLE_FIXEDREADERS_SUCCESS =
  "FETCH_SINGLE_FIXEDREADERS_SUCCESS";
export const FETCH_SINGLE_FIXEDREADERS_FAILURE =
  "FETCH_SINGLE_FIXEDREADERS_FAILURE";

// GATEWAYS
export const GATEWAYS_FETCH_REQUEST = "GATEWAYS_FETCH_REQUEST";
export const GATEWAYS_FETCH_SUCCESS = "GATEWAYS_FETCH_SUCCESS";
export const ALL_GATEWAYS_FETCH_FAILURE = "ALL_GATEWAYS_FETCH_FAILURE";
export const ALL_GATEWAYS_FETCH_REQUEST = "ALL_GATEWAYS_FETCH_REQUEST";
export const ALL_GATEWAYS_FETCH_SUCCESS = "ALL_GATEWAYS_FETCH_SUCCESS";
export const GATEWAYS_FETCH_FAILURE = "GATEWAYS_FETCH_FAILURE";
export const GATEWAY_SAVE_REQUEST = "GATEWAY_SAVE_REQUEST";
export const GATEWAY_SAVE_SUCCESS = "GATEWAY_SAVE_SUCCESS";
export const GATEWAY_SAVE_FAILURE = "GATEWAY_SAVE_FAILURE";
export const GATEWAY_DELETE_REQUEST = "GATEWAY_DELETE_REQUEST";
export const GATEWAY_DELETE_SUCCESS = "GATEWAY_DELETE_SUCCESS";
export const GATEWAY_DELETE_FAILURE = "GATEWAY_DELETE_FAILURE";
export const FETCH_SINGLE_GATEWAY_REQUEST = "FETCH_SINGLE_GATEWAY_REQUEST";
export const FETCH_SINGLE_GATEWAY_SUCCESS = "FETCH_SINGLE_GATEWAY_SUCCESS";
export const FETCH_SINGLE_GATEWAY_FAILURE = "FETCH_SINGLE_GATEWAY_FAILURE";

// IP RELAY CARD
export const IPRELAYCARDS_FETCH_REQUEST = "IPRELAYCARDS_FETCH_REQUEST";
export const IPRELAYCARDS_FETCH_SUCCESS = "IPRELAYCARDS_FETCH_SUCCESS";
export const IPRELAYCARDS_FETCH_FAILURE = "IPRELAYCARDS_FETCH_FAILURE";
export const ALL_IPRELAYCARDS_FETCH_REQUEST = "ALL_IPRELAYCARDS_FETCH_REQUEST";
export const ALL_IPRELAYCARDS_FETCH_SUCCESS = "ALL_IPRELAYCARDS_FETCH_SUCCESS";
export const ALL_IPRELAYCARDS_FETCH_FAILURE = "ALL_IPRELAYCARDS_FETCH_FAILURE";
export const IPRELAYCARD_SAVE_REQUEST = "IPRELAYCARD_SAVE_REQUEST";
export const IPRELAYCARD_SAVE_SUCCESS = "IPRELAYCARD_SAVE_SUCCESS";
export const IPRELAYCARD_SAVE_FAILURE = "IPRELAYCARD_SAVE_FAILURE";
export const IPRELAYCARD_DELETE_REQUEST = "IPRELAYCARD_DELETE_REQUEST";
export const IPRELAYCARD_DELETE_SUCCESS = "IPRELAYCARD_DELETE_SUCCESS";
export const IPRELAYCARD_DELETE_FAILURE = "IPRELAYCARD_DELETE_FAILURE";
export const FETCH_IPRELAYCARD_REQUEST = "FETCH_IPRELAYCARD_REQUEST";
export const FETCH_IPRELAYCARD_SUCCESS = "FETCH_IPRELAYCARD_SUCCESS";
export const FETCH_IPRELAYCARD_FAILURE = "FETCH_IPRELAYCARD_FAILURE";

// RELAYSWITCHES
export const RELAYSWITCHES_FETCH_REQUEST = "RELAYSWITCHES_FETCH_REQUEST";
export const RELAYSWITCHES_FETCH_SUCCESS = "RELAYSWITCHES_FETCH_SUCCESS";
export const RELAYSWITCHES_FETCH_FAILURE = "RELAYSWITCHES_FETCH_FAILURE";
export const ALL_RELAYSWITCHES_FETCH_REQUEST =
  "ALL_RELAYSWITCHES_FETCH_REQUEST";
export const ALL_RELAYSWITCHES_FETCH_SUCCESS =
  "ALL_RELAYSWITCHES_FETCH_SUCCESS";
export const ALL_RELAYSWITCHES_FETCH_FAILURE =
  "ALL_RELAYSWITCHES_FETCH_FAILURE";
export const RELAYSWITCH_SAVE_REQUEST = "RELAYSWITCH_SAVE_REQUEST";
export const RELAYSWITCH_SAVE_SUCCESS = "RELAYSWITCH_SAVE_SUCCESS";
export const RELAYSWITCH_SAVE_FAILURE = "RELAYSWITCH_SAVE_FAILURE";
export const RELAYSWITCH_DELETE_REQUEST = "RELAYSWITCH_DELETE_REQUEST";
export const RELAYSWITCH_DELETE_SUCCESS = "RELAYSWITCH_DELETE_SUCCESS";
export const RELAYSWITCH_DELETE_FAILURE = "RELAYSWITCH_DELETE_FAILURE";

// SITES
export const SITES_FETCH_REQUEST = "SITES_FETCH_REQUEST";
export const SITES_FETCH_SUCCESS = "SITES_FETCH_SUCCESS";
export const SITES_FETCH_FAILURE = "SITES_FETCH_FAILURE";
export const ALL_SITES_FETCH_REQUEST = "ALL_SITES_FETCH_REQUEST";
export const ALL_SITES_FETCH_SUCCESS = "ALL_SITES_FETCH_SUCCESS";
export const ALL_SITES_FETCH_FAILURE = "ALL_SITES_FETCH_FAILURE";
export const SITE_SAVE_REQUEST = "SITE_SAVE_REQUEST";
export const SITE_SAVE_SUCCESS = "SITE_SAVE_SUCCESS";
export const SITE_SAVE_FAILURE = "SITE_SAVE_FAILURE";
export const SITE_DELETE_REQUEST = "SITE_DELETE_REQUEST";
export const SITE_DELETE_SUCCESS = "SITE_DELETE_SUCCESS";
export const SITE_DELETE_FAILURE = "SITE_DELETE_FAILURE";

// ZONES
export const ZONES_FETCH_REQUEST = "ZONES_FETCH_REQUEST";
export const ZONES_FETCH_SUCCESS = "ZONES_FETCH_SUCCESS";
export const ZONES_FETCH_FAILURE = "ZONES_FETCH_FAILURE";
export const ALL_ZONES_FETCH_REQUEST = "ALL_ZONES_FETCH_REQUEST";
export const ALL_ZONES_FETCH_SUCCESS = "ALL_ZONES_FETCH_SUCCESS";
export const ALL_ZONES_FETCH_FAILURE = "ALL_ZONES_FETCH_FAILURE";
export const ZONE_SAVE_REQUEST = "ZONE_SAVE_REQUEST";
export const ZONE_SAVE_SUCCESS = "ZONE_SAVE_SUCCESS";
export const ZONE_SAVE_FAILURE = "ZONE_SAVE_FAILURE";
export const ZONE_DELETE_REQUEST = "ZONE_DELETE_REQUEST";
export const ZONE_DELETE_SUCCESS = "ZONE_DELETE_SUCCESS";
export const ZONE_DELETE_FAILURE = "ZONE_DELETE_FAILURE";

// MOBILEREADERS
export const MOBILEREADERS_FETCH_REQUEST = "MOBILEREADERS_FETCH_REQUEST";
export const MOBILEREADERS_FETCH_SUCCESS = "MOBILEREADERS_FETCH_SUCCESS";
export const MOBILEREADERS_FETCH_FAILURE = "MOBILEREADERS_FETCH_FAILURE";
export const ALL_MOBILEREADERS_FETCH_REQUEST =
  "ALL_MOBILEREADERS_FETCH_REQUEST";
export const ALL_MOBILEREADERS_FETCH_SUCCESS =
  "ALL_MOBILEREADERS_FETCH_SUCCESS";
export const ALL_MOBILEREADERS_FETCH_FAILURE =
  "ALL_MOBILEREADERS_FETCH_FAILURE";
export const MOBILEREADER_SAVE_REQUEST = "MOBILEREADER_SAVE_REQUEST";
export const MOBILEREADER_SAVE_SUCCESS = "MOBILEREADER_SAVE_SUCCESS";
export const MOBILEREADER_SAVE_FAILURE = "MOBILEREADER_SAVE_FAILURE";
export const MOBILEREADER_DELETE_REQUEST = "MOBILEREADER_DELETE_REQUEST";
export const MOBILEREADER_DELETE_SUCCESS = "MOBILEREADER_DELETE_SUCCESS";
export const MOBILEREADER_DELETE_FAILURE = "MOBILEREADER_DELETE_FAILURE";

// USERS
export const USERS_FETCH_REQUEST = "USERS_FETCH_REQUEST";
export const USERS_FETCH_SUCCESS = "USERS_FETCH_SUCCESS";
export const USERS_FETCH_FAILURE = "USERS_FETCH_FAILURE";
export const USER_SAVE_REQUEST = "USER_SAVE_REQUEST";
export const USER_SAVE_SUCCESS = "USER_SAVE_SUCCESS";
export const USER_SAVE_FAILURE = "USER_SAVE_FAILURE";
export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const USER_ACTIVATE_REQUEST = "USER_ACTIVATE_REQUEST";
export const USER_ACTIVATE_SUCCESS = "USER_ACTIVATE_SUCCESS";
export const USER_ACTIVATE_FAILURE = "USER_ACTIVATE_FAILURE";
export const TOKEN_VALID_REQUEST = "TOKEN_VALID_REQUEST";
export const TOKEN_VALID_SUCCESS = "TOKEN_VALID_SUCCESS";
export const TOKEN_VALID_FAILURE = "TOKEN_VALID_FAILURE";

// CONTACTS
export const CONTACTS_FETCH_REQUEST = "CONTACTS_FETCH_REQUEST";
export const CONTACTS_FETCH_SUCCESS = "CONTACTS_FETCH_SUCCESS";
export const CONTACTS_FETCH_FAILURE = "CONTACTS_FETCH_FAILURE";
export const CONTACT_SAVE_REQUEST = "CONTACT_SAVE_REQUEST";
export const CONTACT_SAVE_SUCCESS = "CONTACT_SAVE_SUCCESS";
export const CONTACT_SAVE_FAILURE = "CONTACT_SAVE_FAILURE";
export const CONTACT_DELETE_REQUEST = "CONTACT_DELETE_REQUEST";
export const CONTACT_DELETE_SUCCESS = "CONTACT_DELETE_SUCCESS";
export const CONTACT_DELETE_FAILURE = "CONTACT_DELETE_FAILURE";

// USER GROUPS
export const USERGROUPS_FETCH_REQUEST = "USERGROUPS_FETCH_REQUEST";
export const USERGROUPS_FETCH_SUCCESS = "USERGROUPS_FETCH_SUCCESS";
export const USERGROUPS_FETCH_FAILURE = "USERGROUPS_FETCH_FAILURE";
export const USERGROUP_SAVE_REQUEST = "USERGROUP_SAVE_REQUEST";
export const USERGROUP_SAVE_SUCCESS = "USERGROUP_SAVE_SUCCESS";
export const USERGROUP_SAVE_FAILURE = "USERGROUP_SAVE_FAILURE";
export const USERGROUP_DELETE_REQUEST = "USERGROUP_DELETE_REQUEST";
export const USERGROUP_DELETE_SUCCESS = "USERGROUP_DELETE_SUCCESS";
export const USERGROUP_DELETE_FAILURE = "USERGROUP_DELETE_FAILURE";

//ROLES
export const ROLES_FETCH_REQUEST = "ROLES_FETCH_REQUEST";
export const ROLES_FETCH_SUCCESS = "ROLES_FETCH_SUCCESS";
export const ROLES_FETCH_FAILURE = "ROLES_FETCH_FAILURE";
export const ROLE_SAVE_REQUEST = "ROLE_SAVE_REQUEST";
export const ROLE_SAVE_SUCCESS = "ROLE_SAVE_SUCCESS";
export const ROLE_SAVE_FAILURE = "ROLE_SAVE_FAILURE";
export const ROLE_DELETE_REQUEST = "ROLE_DELETE_REQUEST";
export const ROLE_DELETE_SUCCESS = "ROLE_DELETE_SUCCESS";
export const ROLE_DELETE_FAILURE = "ROLE_DELETE_FAILURE";

//PERMISSIONS
export const PERMISSIONS_FETCH_REQUEST = "PERMISSIONS_FETCH_REQUEST";
export const PERMISSIONS_FETCH_SUCCESS = "PERMISSIONS_FETCH_SUCCESS";
export const PERMISSIONS_FETCH_FAILURE = "PERMISSIONS_FETCH_FAILURE";
export const PERMISSION_SAVE_REQUEST = "PERMISSION_SAVE_REQUEST";
export const PERMISSION_SAVE_SUCCESS = "PERMISSION_SAVE_SUCCESS";
export const PERMISSION_SAVE_FAILURE = "PERMISSION_SAVE_FAILURE";
export const PERMISSION_DELETE_REQUEST = "PERMISSION_DELETE_REQUEST";
export const PERMISSION_DELETE_SUCCESS = "PERMISSION_DELETE_SUCCESS";
export const PERMISSION_DELETE_FAILURE = "PERMISSION_DELETE_FAILURE";

// REPORT
export const REPORT_RESET = "REPORT_RESET";
export const REPORT_FETCH_REQUEST = "REPORT_FETCH_REQUEST";
export const REPORT_FETCH_SUCCESS = "REPORT_FETCH_SUCCESS";
export const REPORT_FETCH_FAILURE = "REPORT_FETCH_FAILURE";
export const REPORT_FETCH_ALL_REQUEST = "REPORT_FETCH_ALL_REQUEST";
export const REPORT_FETCH_ALL_SUCCESS = "REPORT_FETCH_ALL_SUCCESS";
export const REPORT_FETCH_ALL_FAILURE = "REPORT_FETCH_ALL_FAILURE";
export const REPORT_MARK_AS_READ_REQUEST = "REPORT_MARK_AS_READ_REQUEST";
export const REPORT_MARK_AS_READ_SUCCESS = "REPORT_MARK_AS_READ_SUCCESS";
export const REPORT_MARK_AS_READ_FAILURE = "REPORT_MARK_AS_READ_FAILURE";
export const REPORT_ALL_FLOORPLAN_FETCH_REQUEST =
  "REPORT_ALL_FLOORPLAN_FETCH_REQUEST";
export const REPORT_ALL_FLOORPLAN_FETCH_SUCCESS =
  "REPORT_ALL_FLOORPLAN_FETCH_SUCCESS";
export const REPORT_ALL_FLOORPLAN_FETCH_FAILURE =
  "REPORT_ALL_FLOORPLAN_FETCH_FAILURE";
export const REPORT_FLOORPLAN_FETCH_REQUEST = "REPORT_FLOORPLAN_FETCH_REQUEST";
export const REPORT_FLOORPLAN_FETCH_SUCCESS = "REPORT_FLOORPLAN_FETCH_SUCCESS";
export const REPORT_FLOORPLAN_FETCH_FAILURE = "REPORT_FLOORPLAN_FETCH_FAILURE";

export const CLEAN_REQUEST_STATUS = "CLEAN_REQUEST_STATUS";

export const UNAUTHORIZED = "UNAUTHORIZED";
export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const SELECTED_TENANT = "SELECTED_TENANT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const LOGOUT_JWT = "LOGOUT_JWT";
export const GET_JWT_SUCCESS = "GET_JWT_SUCCESS";
export const GET_JWT_REFRESH_SUCCESS = "GET_JWT_REFRESH_SUCCESS";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const FLEET_SESSION_REQUEST = "FLEET_SESSION_REQUEST";
export const FLEET_SESSION_SUCCESS = "FLEET_SESSION_SUCCESS";
export const FLEET_SESSION_FAILURE = "FLEET_SESSION_FAILURE";
export const LOGGGED_IN_USER_SAVE_REQUEST = "LOGGGED_IN_USER_SAVE_REQUEST";
export const LOGGGED_IN_USER_SAVE_SUCCESS = "LOGGGED_IN_USER_SAVE_SUCCESS";
export const LOGGGED_IN_USER_SAVE_FAILURE = "LOGGGED_IN_USER_SAVE_FAILURE";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

//DASHBOARD
export const TOTALS_FETCH_REQUEST = "TOTALS_FETCH_REQUEST";
export const TOTALS_FETCH_SUCCESS = "TOTALS_FETCH_SUCCESS";
export const TOTALS_FETCH_FAILURE = "TOTALS_FETCH_FAILURE";

//LANGUAGE
export const LANGUAGES_FETCH_REQUEST = "LANG_FETCH_REQUEST";
export const LANGUAGES_FETCH_SUCCESS = "LANG_FETCH_SUCCESS";
export const LANGUAGES_FETCH_FAILURE = "LANG_FETCH_FAILURE";
export const LANG_CHANGE_SUCCESS = "LANG_CHANGE_SUCCESS";
export const LANG_CHANGE_FAILURE = "LANG_CHANGE_FAILURE";
export const LANG_SAVE_REQUEST = "LANG_SAVE_REQUEST";
export const LANG_SAVE_FAILURE = "LANG_SAVE_FAILURE";
export const LANG_SAVE_SUCCESS = "LANG_SAVE_SUCCESS";

//BANNER
export const SHOW_BANNER = "SHOW_BANNER";
export const REMOVE_BANNER = "REMOVE_BANNER";

//PREFERRED COLUMNS HANDHELD
export const PREFERRED_COLUMNS_HANDHELD_FETCH_REQUEST =
  "PREFERRED_COLUMNS_HANDHELD_FETCH_REQUEST";
export const PREFERRED_COLUMNS_HANDHELD_FETCH_SUCCESS =
  "PREFERRED_COLUMNS_HANDHELD_FETCH_SUCCESS";
export const PREFERRED_COLUMNS_HANDHELD_FETCH_FAILURE =
  "PREFERRED_COLUMNS_HANDHELD_FETCH_FAILURE";
export const PREFERRED_COLUMNS_HANDHELD_SAVE_REQUEST =
  "PREFERRED_COLUMNS_HANDHELD_SAVE_REQUEST";
export const PREFERRED_COLUMNS_HANDHELD_SAVE_SUCCESS =
  "PREFERRED_COLUMNS_HANDHELD_SAVE_SUCCESS";
export const PREFERRED_COLUMNS_HANDHELD_SAVE_FAILURE =
  "PREFERRED_COLUMNS_HANDHELD_SAVE_FAILURE";

//PROFILE
export const USER_LOGO_REQUEST = "USER_LOGO_REQUEST";
export const USER_LOGO_SUCCESS = "USER_LOGO_SUCCESS";
export const USER_LOGO_FAILURE = "USER_LOGO_FAILURE";
export const GET_USER_LOGO_REQUEST = "GET_USER_LOGO_REQUEST";
export const GET_USER_LOGO_SUCCESS = "GET_USER_LOGO_SUCCESS";
export const GET_USER_LOGO_FAILURE = "GET_USER_LOGO_FAILURE";
export const GET_USER_LOGO_LOGIN_REQUEST = "GET_USER_LOGO_LOGIN_REQUEST";
export const GET_USER_LOGO_LOGIN_SUCCESS = "GET_USER_LOGO_LOGIN_SUCCESS";
export const GET_USER_LOGO_LOGIN_FAILURE = "GET_USER_LOGO_LOGIN_FAILURE";
export const DELETE_USER_LOGO_REQUEST = "DELETE_USER_LOGO_REQUEST";
export const DELETE_USER_LOGO_SUCCESS = "DELETE_USER_LOGO_SUCCESS";
export const DELETE_USER_LOGO_FAILURE = "DELETE_USER_LOGO_FAILURE";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

//FLOORPLAN
export const GET_FLOOR_PLAN_REQUEST = "GET_FLOOR_PLAN_REQUEST";
export const GET_FLOOR_PLAN_SUCCESS = "GET_FLOOR_PLAN_SUCCESS";
export const GET_FLOOR_PLAN_FAILURE = "GET_FLOOR_PLAN_FAILURE";

//UPLOAD ASSETS
export const POST_UPLOAD_ASSETS_REQUEST = "POST_UPLOAD_ASSETS_REQUEST";
export const POST_UPLOAD_ASSETS_SUCCESS = "POST_UPLOAD_ASSETS_SUCCESS";
export const POST_UPLOAD_ASSETS_FAILURE = "POST_UPLOAD_ASSETS_FAILURE";
export const RESET_UPLOAD_STORE = "RESET_UPLOAD_STORE";
