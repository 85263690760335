import React from "react";
import { Form, Input } from "antd";

import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "../../layout/Style.css";
import { useSelector } from "react-redux";

const AddGatewayForm = (props) => {
  const { englishLang } = useSelector((store) => ({
    englishLang: store.langReducer.englishLang,
  }));

  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="gateway-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        label={props.language?.NAME?.value ?? englishLang?.NAME?.value}
        name="name"
        rules={requiredFieldRule}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label={
          props.language?.DESCRPTION?.value ?? englishLang?.DESCRPTION?.value
        }
      >
        <Input.TextArea />
      </Form.Item>
      {props.footer}
    </Form>
  );
};

export default AddGatewayForm;
