import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select, Tooltip, Row, Col, Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "../../layout/Style.css";
import { getModalSizeByWindowSize } from "../../layout/Constants";
import AddGatewayForm from "../Gateways/AddGatewayForm";
import { fetchGateways } from "../../../api/redux/action/GatewayActions";

const { Option } = Select;

const AddFixedReaderForm = (props) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState({ gateway: false });
  const [gatewayForm] = Form.useForm();
  const [modalSize, setModalSize] = useState(getModalSizeByWindowSize());

  const { gateways, language, englishLang } = useSelector((store) => ({
    gateways: store.gatewayReducer.gateways,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  useEffect(() => {
    dispatch(fetchGateways());
  }, []);

  const handleGatewaySelect = (selected) => {};

  const handleGatewaySearch = (val) => {};

  const handleAddNewGateway = () => {
    showModal(true);
  };

  const handleGatewayFormSave = (values) => {};

  const showModal = (gateway) => {
    setVisible({ gateway: gateway });
  };

  const handleModalOk = (e) => {
    // gatewayForm.submit();
    setVisible(false);
  };

  const handleModalCancel = (e) => {
    setVisible(false);
  };

  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="fixedReader-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        label={language?.NAME?.value ?? englishLang?.NAME?.value}
        name="name"
        rules={requiredFieldRule}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Gateway" required>
        <Row gutter={3}>
          <Col flex={9} span={22}>
            <Form.Item name="gateway" rules={requiredFieldRule}>
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder={
                  language?.SELECT_GATEWAY?.value ??
                  englishLang?.SELECT_GATEWAY?.value
                }
                onSelect={handleGatewaySelect}
                showSearch
                onSearch={handleGatewaySearch}
              >
                {gateways && gateways.content
                  ? gateways.content.map((item) => (
                      <Option key={item.gatewayId} value={item.gatewayId}>
                        {item.name}
                      </Option>
                    ))
                  : undefined}
              </Select>
            </Form.Item>
          </Col>
          <Col flex={1} span={2}>
            <Tooltip
              title={
                language?.CREATE_GATEWAY?.value ??
                englishLang?.CREATE_GATEWAY?.value
              }
            >
              <Button
                icon={<PlusOutlined />}
                ghost
                type="primary"
                onClick={handleAddNewGateway}
                style={{ width: "100%" }}
              />
            </Tooltip>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        label={language?.IP_ADDRESS?.value ?? englishLang?.IP_ADDRESS?.value}
        name="ipAddress"
        rules={requiredFieldRule}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={language?.PORT_NUMBER?.value ?? englishLang?.PORT_NUMBER?.value}
        name="portNumber"
      >
        <Input />
      </Form.Item>

      <Modal
        title={language?.ADD_GATEWAY?.value ?? englishLang?.ADD_GATEWAY?.value}
        visible={visible.gateway}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={modalSize}
      >
        <Row justify="center">
          <Col span={20}>
            <AddGatewayForm
              handleSave={handleGatewayFormSave}
              form={gatewayForm}
            />
          </Col>
        </Row>
      </Modal>

      {props.footer}
    </Form>
  );
};

export default AddFixedReaderForm;
