import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Divider,
  Space,
  Tooltip,
  Row,
  DatePicker,
  Select,
  Button,
} from "antd";
import { RedoOutlined } from "@ant-design/icons";
import moment from "moment-timezone";

import useDataTable from "../../../components/common/DataTable";
import {
  fetchReport,
  resetReport,
} from "../../../api/redux/action/ReportActions";
import useNotification from "../../../components/common/ResultNotifier";
import { fetchZones } from "../../../api/redux/action/ZoneActions";
import { fetchSites } from "../../../api/redux/action/SiteActions";
import Header from "../../../components/common/ReportHeader";
import { toTimestamp } from "../../layout/Constants";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import { exportRequest } from "../../../api/network/axios/exportRequest";

const dateFormat = "DD-MM-YYYY";
const { Option } = Select;

const { RangePicker } = DatePicker;

function ZoneMovement() {
  const today = new Date();
  const todayStr =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState();

  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [flattenedData, setFlattenedData] = useState([]);
  const [options, setOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = React.useState([]);
  const [sortedSites, setSortedSites] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });
  const [filter, setFilter] = useState({
    fromDate: todayStr,
    toDate: todayStr,
    zoneIds: null,
  });
  const [refresh, setRefresh] = useState(false);
  const [dateValue, setDateValue] = useState([]);
  const [zoneValue, setZoneValue] = useState();
  const [siteValue, setSiteValue] = useState();
  const [missingFieldsText, setMissingFieldsText] = useState();

  const {
    user,
    data,
    allData,
    error,
    requestStatus,
    sites,
    zones,
    loading,
    userTimeZone,
    language,
    englishLang,
  } = useSelector((store) => ({
    user: store.authReducer.user,
    data: store.reportReducer.data,
    allData: store.reportReducer.allData,
    error: store.reportReducer.error,
    requestStatus: store.reportReducer.requestStatus,
    sites: store.siteReducer.sites,
    zones: store.zoneReducer.zones,
    loading: store.reportReducer.loading,
    userTimeZone: store.authReducer.userTimeZone,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const columns = [
    {
      title: language?.SITE?.value ?? englishLang?.SITE?.value,
      dataIndex: "site",
    },
    {
      title: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      dataIndex: ["zone", "name"],
    },
    {
      key: "assetEntity.code",
      sorter: true,
      sortField: "assetEntity.code",
      sortOrder:
        sortedInfo.columnKey === "assetEntity.code" && sortedInfo.order,
      title: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      dataIndex: ["asset", "code"],
    },
    {
      key: "assetEntity.name",
      sorter: true,
      sortField: "assetEntity.name",
      sortOrder:
        sortedInfo.columnKey === "assetEntity.name" && sortedInfo.order,
      title: language?.ASSET?.value ?? englishLang?.ASSET?.value,
      dataIndex: ["asset", "name"],
    },
    {
      key: "assetEntity.assetGroupEntity.name",
      sorter: true,
      sortField: "assetEntity.assetGroupEntity.name",
      sortOrder:
        sortedInfo.columnKey === "assetEntity.assetGroupEntity.name" &&
        sortedInfo.order,
      title: language?.ASSET_GROUP?.value ?? englishLang?.ASSET_GROUP?.value,
      dataIndex: ["asset", "assetGroup", "name"],
    },
    {
      key: "firstSeen",
      sorter: true,
      sortField: "firstSeen",
      sortOrder: sortedInfo.columnKey === "firstSeen" && sortedInfo.order,
      title:
        language?.FIRST_DETECTED?.value ?? englishLang?.FIRST_DETECTED?.value,
      dataIndex: ["firstSeen"],
      render: (firstSeen) => (
        <span>
          {firstSeen
            ? moment.tz(firstSeen, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
    },
    {
      key: "lastSeen",
      sorter: true,
      sortField: "lastSeen",
      sortOrder: sortedInfo.columnKey === "lastSeen" && sortedInfo.order,
      title:
        language?.LAST_DETECTED?.value ?? englishLang?.LAST_DETECTED?.value,
      dataIndex: ["lastSeen"],
      render: (lastSeen) => (
        <span>
          {lastSeen
            ? moment.tz(lastSeen, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
    },
  ];

  useEffect(() => {
    dispatch(resetReport());
    dispatch(fetchSites());
    dispatch(fetchZones());
  }, []);

  useEffect(() => {
    if (sites && sites.content) {
      let sorted = sites.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedSites(sorted);
    }
  }, [sites]);

  useEffect(() => {
    if (zones.content) {
      const cats = zones.content.reduce((catsSoFar, { site, zoneId, name }) => {
        if (!catsSoFar[site.siteId]) catsSoFar[site.siteId] = [];
        catsSoFar[site.siteId].push({
          name: name,
          zoneId: zoneId,
        });
        return catsSoFar;
      }, {});

      setOptions(cats);
    }
  }, [zones.content]);

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const { DataTable, hasSelected, currentPage, pageSize } = useDataTable({
    columns,
    dataSource: data,
    updateEntityPath: "",
    filter,
    loading: loading,
    missingFieldsText: missingFieldsText,
    setSortedInfo: setSortedInfo,
  });

  const checkNoMissingParams = () => {
    if (!(filter.fromDate && filter.toDate && filter.zoneIds)) {
      setMissingFieldsText(
        language?.SELECT_DATE_RANGE_SITE_ZONE?.value ??
          englishLang?.SELECT_DATE_RANGE_SITE_ZONE?.value
      );
      return false;
    }
    return true;
  };

  const ascDesc = () => {
    if (sortedInfo.order === "ascend") {
      return "ASC";
    } else {
      return "DESC";
    }
  };

  useEffect(() => {
    let endpoint = sortedInfo.columnKey
      ? `zone-movement?sort=${sortedInfo.columnKey},${ascDesc()}`
      : "zone-movement";

    // dispatch(resetReport());
    if (checkNoMissingParams()) {
      setMissingFieldsText();
      dispatch(
        fetchReport({
          page: currentPage,
          size: pageSize,
          fromDate: toTimestamp(filter.fromDate, "start"),
          toDate: toTimestamp(filter.toDate, "end"),
          zoneIds: filter.zoneIds ? filter.zoneIds : undefined,
          endpoint: endpoint,
        })
      );
    }
  }, [currentPage, filter, isLoading, refresh, pageSize, sortedInfo]);

  useEffect(() => {
    setDataTable(DataTable);
  }, [loading, language]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-zone-movement";
    let options = {
      url: "/api/web/reports/zone-movement/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
        zoneIds: filter.zoneIds ? filter.zoneIds : undefined,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  const handleDateRangeChange = (date, dateString) => {
    setDateValue(date);
    setFilter({
      ...filter,
      fromDate: dateString[0],
      toDate: dateString[1],
    });
  };
  const handleZoneChange = (value) => {
    setZoneValue(value);
    setFilter({
      ...filter,
      zoneIds: value,
    });
  };

  const handleSiteChange = (value) => {
    setSiteValue(value);
    let sorted = [];
    if (options[value]) {
      sorted = options[value].sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
    }
    setZoneOptions(sorted);
  };

  const handleRefreshClick = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      let flatData;
      function flatDataHandler() {
        if (data.content === undefined) {
          return (flatData = Object.values(data));
        } else {
          return (flatData = Object.values(data.content));
        }
      }
      flatDataHandler();

      flatData.map((item) => {
        item.firstSeenFormatted = item.firstSeen
          ? moment
              .tz(item.firstSeen, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
        item.lastSeenFormatted = item.lastSeen
          ? moment.tz(item.lastSeen, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedData(Object.values(flatData));
    }
  }, [data]);

  useEffect(() => {
    if (allData && Object.keys(allData).length !== 0) {
      let flatData = Object.values(allData.content);

      flatData.map((item) => {
        item.firstSeenFormatted = item.firstSeen
          ? moment
              .tz(item.firstSeen, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
        item.lastSeenFormatted = item.lastSeen
          ? moment.tz(item.lastSeen, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedAllData(Object.values(flatData));
    }
  }, [allData]);

  const headers = [
    { label: language?.SITE?.value ?? englishLang?.SITE?.value, key: "site" },
    {
      label: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      key: "zone.name",
    },
    {
      label: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      key: "asset.code",
    },
    {
      label: language?.ASSET?.value ?? englishLang?.ASSET?.value,
      key: "asset.name",
    },
    {
      label: language?.ASSET_GROUP?.value ?? englishLang?.ASSET_GROUP?.value,
      key: "asset.assetGroup.name",
    },
    {
      label:
        language?.FIRST_DETECTED?.value ?? englishLang?.FIRST_DETECTED?.value,
      key: "firstSeenFormatted",
    },
    {
      label:
        language?.LAST_DETECTED?.value ?? englishLang?.LAST_DETECTED?.value,
      key: "lastSeenFormatted",
    },
  ];

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          hasSelected={hasSelected}
          exportTableTitle={
            language?.ZONEMOVEMENT?.value ?? englishLang?.ZONEMOVEMENT?.value
          }
          addNewText=""
          title={
            language?.ZONE_MOVEMENT?.value ?? englishLang?.ZONE_MOVEMENT?.value
          }
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportAllButtonClick={handleExportAllButtonClick}
        />
        <Row>
          <Col>
            <Space>
              <Tooltip
                title={
                  language?.SELECT_A_DATE_RANGE?.value ??
                  englishLang?.SELECT_A_DATE_RANGE?.value
                }
              >
                <RangePicker
                  defaultValue={[
                    moment(todayStr, dateFormat),
                    moment(todayStr, dateFormat),
                  ]}
                  format={dateFormat}
                  onChange={handleDateRangeChange}
                  style={{ width: 250 }}
                  id={ElementIDS().calendar}
                />
              </Tooltip>

              {/* <Tooltip 
                         title="Select Zone">
                            <Cascader options={options} onChange={handleZoneChange} placeholder="Please select a Zone" />
                        </Tooltip> */}

              <Tooltip
                title={
                  language?.SELECT_A_SITE?.value ??
                  englishLang?.SELECT_A_SITE?.value
                }
              >
                <Select
                  id={ElementIDS().optionSite}
                  //   defaultValue="All"
                  style={{ width: 200 }}
                  placeholder={
                    language?.SELECT_A_SITE?.value ??
                    englishLang?.SELECT_A_SITE?.value
                  }
                  optionFilterProp="children"
                  onChange={handleSiteChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {sortedSites
                    ? sortedSites.map((item) => (
                        <Option
                          id={ElementIDS().optionSite}
                          key={item.siteId}
                          value={item.siteId}
                        >
                          {item.name}
                        </Option>
                      ))
                    : undefined}
                </Select>
              </Tooltip>

              <Tooltip
                title={
                  language?.SELECT_A_ZONE?.value ??
                  englishLang?.SELECT_A_ZONE?.value
                }
              >
                <Select
                  id={ElementIDS().selectZone}
                  style={{ minWidth: 200 }}
                  // mode= 'multiple'
                  // maxTagCount= 'responsive'
                  //   defaultValue="All"
                  // style={{ width: 200 }}
                  placeholder={
                    language?.SELECT_A_ZONE?.value ??
                    englishLang?.SELECT_A_ZONE?.value
                  }
                  optionFilterProp="children"
                  onChange={handleZoneChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {/* {assets && assets.content
                  ? assets.content.map((item) => (
                      <Option key={item.assetId} value={item.assetId}>
                   <strong>  {item.code}</strong>    {item.name}
                      </Option>
                    ))
                  : undefined} */}

                  {zoneOptions
                    ? zoneOptions.map((item) => (
                        <Option
                          id={ElementIDS().optionZone}
                          key={item.zoneId}
                          value={item.zoneId}
                        >
                          {item.name}
                        </Option>
                      ))
                    : undefined}
                </Select>
              </Tooltip>
              <Button
                id={ElementIDS().refresh}
                type="primary"
                onClick={() => handleRefreshClick()}
              >
                {" "}
                <RedoOutlined />
                {language?.REFRESH?.value ?? englishLang?.REFRESH?.value}
              </Button>
            </Space>
          </Col>
        </Row>
        <Divider />
        {dataTable}
        <ResultNotifier />
      </div>
    </>
  );
}

export default ZoneMovement;
