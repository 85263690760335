import React from 'react';
import {Skeleton, Typography } from 'antd';
import { useHistory } from 'react-router-dom';


import ChartCard from '../../../../components/ChartCard/ChartCard';

const { Text } = Typography;

function MergedTotalWidget({title,total,down,up,secondaryTitle, secondaryTotal, clickPath,icon, secondaryClickPath, secondId,id}) {
	const history = useHistory();

	const handleClick=(path)=>{
		history.push({
			pathname: path
		});
	}

    const footer = (secondaryTitle) 
    	? 	
		
		(
			<div onClick={()=>handleClick(secondaryClickPath)} >
			<Text id={secondId} type="secondary">{secondaryTitle} </Text>{' '}
				<span style={{  float:'right'}}> {secondaryTotal}</span>	
			</div>
		)
		:''


		if(total==null) return <div style={{ background: '#fff', padding: '20px'}}><Skeleton active/></div>	

  	return (

		<div>
			<ChartCard
			loading={total}
			clickPath={()=>{handleClick(clickPath)}}
      		bordered
      		title={title}
      		contentHeight={8}
     		total={total}
            footer={footer}		
			id={id}
			>
				
    		</ChartCard>
		</div>
			  
		  
  	);
}

export default MergedTotalWidget;

