import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";

import { resetPassword } from "../../../api/redux/action/UserActions";
import useNotification from "../../../components/common/ResultNotifier";
import ResetPasswordForm from "./ResetPasswordForm";
import history from "../../../router/history";

import { ElementIDS } from "../../../components/common/ElementsIDs";

const ResetPassword = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { error, requestStatus, language, englishLang } = useSelector(
    (store) => ({
      error: store.userReducer.error,
      requestStatus: store.userReducer.requestStatus,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
    })
  );

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      form.setFieldsValue({
        userAccountId: props.location.state.selectedRow.userAccountId,
        username: props.location.state.selectedRow.username,
        password: props.location.state.selectedRow.password,
      });
    }
  }, []);

  const handleSave = (values) => {
    const formData = {
      newPassword: form.getFieldValue().password,
    };

    dispatch(resetPassword(formData, form.getFieldValue().userAccountId)).then(
      () => {
        history.goBack();
      }
    );
  };

  const handleCancel = () => {
    history.goBack();
  };

  const footer = (
    <>
      <Divider />

      <Row justify="center">
        <Space>
          <Button
            id={ElementIDS().cancel}
            type="dashed"
            htmlType="button"
            onClick={handleCancel}
          >
            {language?.CANCEL?.value ?? englishLang?.CANCEL?.value}
          </Button>
          <Button
            id={ElementIDS().save}
            type="primary"
            onClick={() => form.submit()}
          >
            {language?.SAVE?.value ?? englishLang?.SAVE?.value}
          </Button>
        </Space>
      </Row>
    </>
  );

  return (
    <Card
      title={language?.USER?.value ?? englishLang?.USER?.value}
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <ResetPasswordForm
            handleSave={handleSave}
            form={form}
            footer={footer}
            language={language}
          />
        </Col>
      </Row>
      <ResultNotifier />
    </Card>
  );
};

export default ResetPassword;
