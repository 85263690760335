import { useSelector } from "react-redux";
import { Form, Input } from "antd";
import "./style.css";

import { ElementIDS } from "../../../components/common/ElementsIDs";
import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "./style.css";

const PersonalDetailsForm = (props) => {
  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  return (
    <div>
      <Form
        labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
        wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
        form={props.form}
        name="user-form"
        onFinish={props.handleSave}
      >
        <Form.Item
          id={ElementIDS().name}
          label={language?.NAME?.value ?? englishLang?.NAME?.value}
          name="name"
          rules={requiredFieldRule}
        >
          <Input
            onChange={(e) => {
              props.setDirty();
            }}
          />
        </Form.Item>

        <Form.Item
          id={ElementIDS().surName}
          label={language?.SURNAME?.value ?? englishLang?.SURNAME?.value}
          name="surname"
          rules={requiredFieldRule}
        >
          <Input
            onChange={(e) => {
              props.setDirty();
            }}
          />
        </Form.Item>

        <Form.Item
          id={ElementIDS().userNamePersonalDetails}
          label={language?.USERNAME?.value ?? englishLang?.USERNAME?.value}
          name="username"
          rules={[
            {
              required: true,
              message:
                language?.REQUIRED_FIELD?.value ??
                englishLang?.REQUIRED_FIELD?.value,
            },
            { type: "email" },
          ]}
        >
          <Input
            disabled
            onChange={(e) => {
              props.setDirty();
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default PersonalDetailsForm;
