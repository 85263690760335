import * as type from '../types';
import { RequestState } from './RequestState';

const INITIAL_STATE = {
  customFields: {},
  allCustomFields:{},
  customField: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  exportAllLoading:false,
};

const reducer =  (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.CUSTOMFIELDS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        customFields: {},
        loading: true
      };
    case type.CUSTOMFIELDS_FETCH_SUCCESS:
      return {
        ...state,
        customFields: action.payload,
        loading: false
      };
    case type.CUSTOMFIELDS_FETCH_FAILURE:
      return {
        ...state,
        customFields: {},
        loading: false
      };
      case type.ALL_CUSTOMFIELDS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        allCustomFields: {},
        exportAllLoading: true
      };
    case type.ALL_CUSTOMFIELDS_FETCH_SUCCESS:
      return {
        ...state,
        allCustomFields: action.payload,
        exportAllLoading: false
      };
    case type.ALL_CUSTOMFIELDS_FETCH_FAILURE:
      return {
        ...state,
        allCustomFields: {},
        exportAllLoading: false
      };
      case type.CUSTOMFIELD_SAVE_REQUEST:
        return {
          ...state,
          error: {},
          customField: {},
          requestStatus: RequestState.PENDING,
        };
   
    case type.CUSTOMFIELD_SAVE_SUCCESS:
      return {
        ...state,
        customField: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.CUSTOMFIELD_SAVE_FAILURE:
      return {
        ...state,
        customField: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
     
      case type.CUSTOMFIELD_DELETE_REQUEST:
        return{
          ...state,
          requestStatus: RequestState.PENDING,
        }
        case type.CUSTOMFIELD_DELETE_SUCCESS:
          return {
            ...state,
            customField: action.payload,
            error: {},
            requestStatus: RequestState.SUCCESS,
          };
        case type.CUSTOMFIELD_DELETE_FAILURE:
          return {
            ...state,
            customField: {},
            error: action.payload,
            requestStatus: RequestState.FAILURE,
          };
           case type.CLEAN_REQUEST_STATUS:
        return {
          ...state,
          requestStatus: RequestState.EMPTY
        };
    default:
      return state;
  }
};


export default reducer;