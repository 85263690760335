import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";
import ReactDOMServer from "react-dom/server";

import { saveAlertRule } from "../../../api/redux/action/AlertRuleActions";
import useNotification from "../../../components/common/ResultNotifier";
import AddAlertRuleForm from "./AddAlertRuleForm";
import { useHistory } from "react-router-dom";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import { getAlertRuleDescription } from "./AlertRuleDescription";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";
import { hasPermission } from "../../layout/Constants";
import AddFooterSection from "../../../components/common/AddFooterSection";
import { validateInput } from "../../../components/RequiredFieldRule";

const AddAlertRule = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [prompt, setDirty, setPristine] = UnsavedChanges();
  const [active, setActive] = useState(false);
  let history = useHistory();
  const { error, requestStatus, user, language, englishLang, selectedTenant } =
    useSelector((store) => ({
      error: store.alertRuleReducer.error,
      requestStatus: store.alertRuleReducer.requestStatus,
      user: store.authReducer.user,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
      selectedTenant: store.authReducer.selectedTenant,
    }));

  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    language?.ALERT_RULE_NOTIFICATION_SUCCESS?.value ??
      englishLang?.ALERT_RULE_NOTIFICATION_SUCCESS?.value
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      var assetGroups = [];
      if (props.location.state.selectedRow.assetGroups) {
        props.location.state.selectedRow.assetGroups.map((item) => {
          assetGroups.push(item.assetGroupId);
        });
      }

      var assets = [];
      if (props.location.state.selectedRow.assets) {
        props.location.state.selectedRow.assets.map((item) => {
          assets.push(item.assetId);
        });
      }

      var sites = [];
      if (props.location.state.selectedRow.sites) {
        props.location.state.selectedRow.sites.map((item) => {
          sites.push(item.siteId);
        });
      }

      var zones = [];
      if (props.location.state.selectedRow.zones) {
        props.location.state.selectedRow.zones.map((item) => {
          zones.push(item.zoneId);
        });
      }

      var relaySwitches = [];
      if (props.location.state.selectedRow.alertActions.relaySwitches) {
        props.location.state.selectedRow.alertActions.relaySwitches.map(
          (item) => {
            relaySwitches.push(item.relaySwitchId);
          }
        );
      }

      var contacts = [];
      if (props.location.state.selectedRow.alertActions.contacts) {
        props.location.state.selectedRow.alertActions.contacts.map((item) => {
          contacts.push(item.contactId);
        });
      }

      setActive(
        props.location.state && props.location.state.selectedRow.active
      );

      form.setFieldsValue({
        alertRuleId: props.location.state.selectedRow.alertRuleId,
        name: props.location.state.selectedRow.name,
        assetGroups: assetGroups,
        assets: assets,
        sites: sites,
        zones: zones,
        relaySwitches: relaySwitches,
        contacts: contacts,
        // active: !active,
      });
    }
  }, [props.location.state]);

  const onActive = () => {
    setActive(!active);
    form.setFieldsValue({
      active: !active,
    });
  };

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      return history.push("/alertRules");
    }
  }, [requestStatus]);

  const handleSave = (values) => {
    const assetGroups = [];
    if (form.getFieldValue().assetGroups) {
      form.getFieldValue().assetGroups.map((item) =>
        assetGroups.push({
          assetGroupId: item,
        })
      );
    }

    const assets = [];
    if (form.getFieldValue().assets) {
      form.getFieldValue().assets.map((item) => {
        assets.push({
          assetId: item,
        });
      });
    }

    const sites = [];
    if (form.getFieldValue().sites) {
      form.getFieldValue().sites.map((item) => {
        sites.push({
          siteId: item,
        });
      });
    }

    const zones = [];
    if (form.getFieldValue().zones) {
      form.getFieldValue().zones.map((item) => {
        zones.push({
          zoneId: item,
        });
      });
    }

    const relaySwitches = [];
    if (form.getFieldValue().relaySwitches) {
      form.getFieldValue().relaySwitches.map((item) => {
        relaySwitches.push({
          relaySwitchId: item,
        });
      });
    }

    const contacts = [];
    if (form.getFieldValue().contacts) {
      form.getFieldValue().contacts.map((item) => {
        contacts.push({
          contactId: item,
        });
      });
    }

    const description = getAlertRuleDescription(form, language, englishLang);
    const formData = {
      alertRuleId: form.getFieldValue().alertRuleId,
      name: validateInput(form.getFieldValue().name),
      assetGroups: assetGroups,
      assets: assets,
      sites: sites,
      zones: zones,
      active: active,
      detectedInOtherZonesOrSites: false,
      alertActions: {
        relaySwitches: relaySwitches,
        contacts: contacts,
      },
      description: ReactDOMServer.renderToString(description),
    };

    setPristine();
    dispatch(saveAlertRule(formData, form.getFieldValue().alertRuleId));
  };

  const handleCancel = () => {
    history.push("/alertRules");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_ALERT_RULES");
  }

  const footer = (
    <AddFooterSection
      viewOption={props.location.state}
      handleCancel={handleCancel}
      form={form}
      updatePath={"/update-alertRule"}
      handleEditingPermission={handleEditingPermission}
    />
  );

  return (
    <Card
      title={language?.ALERT_RULE?.value ?? englishLang?.ALERT_RULE?.value}
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddAlertRuleForm
            handleSave={handleSave}
            form={form}
            footer={footer}
            setDirty={setDirty}
            readOnly={props.location.state}
            // checkIfTrue={checkIfTrue}
            language={language}
            onActive={onActive}
            active={active}
          />
        </Col>
      </Row>
      {prompt}
      <ResultNotifier />
    </Card>
  );
};

export default AddAlertRule;
