import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "antd";
import moment from "moment-timezone";
import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchGateways,
  fetchAllGateways,
} from "../../../api/redux/action/GatewayActions";
import { exportRequest } from "../../../api/network/axios/exportRequest";
import useNotification from "../../../components/common/ResultNotifier";
import { stateColors } from "../../layout/Constants";
function getColor(status) {
  return stateColors[status].color;
}

function Gateways() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState();
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  const {
    user,
    gateways,
    allGateways,
    error,
    requestStatus,
    loading,
    userTimeZone,
    language,
    englishLang,
  } = useSelector((store) => ({
    user: store.authReducer.user,
    gateways: store.gatewayReducer.gateways,
    allGateways: store.gatewayReducer.allGateways,
    error: store.gatewayReducer.error,
    requestStatus: store.gatewayReducer.requestStatus,
    loading: store.gatewayReducer.loading,
    userTimeZone: store.authReducer.userTimeZone,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const columns = [
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
      sorter: true,
      sortField: "name",
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
    },
    {
      title: language?.DESCRIPTION?.value ?? englishLang?.DESCRIPTION?.value,
      dataIndex: "description",
      sorter: true,
      sortField: "description",
      sortOrder: sortedInfo.columnKey === "description" && sortedInfo.order,
    },
    {
      title: language?.STATUS?.value ?? englishLang?.STATUS?.value,
      dataIndex: "status",
      render: (status) => (
        <Tag color={getColor(status)} key={status}>
          {status}
        </Tag>
      ),
      sorter: true,
      sortField: "status",
      sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
    },
    {
      title:
        language?.LAST_HEALTHY_STATUS_TIMESTAMP?.value ??
        englishLang?.LAST_HEALTHY_STATUS_TIMESTAMP?.value,
      dataIndex: "lastHealthyStatusTimestamp",
      render: (lastHealthyStatusTimestamp) => (
        <span>
          {lastHealthyStatusTimestamp
            ? moment
                .tz(lastHealthyStatusTimestamp, userTimeZone)
                .format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
      sorter: true,
      sortField: "lastHealthyHeartbeatTimestamp",
      sortOrder:
        sortedInfo.columnKey === "lastHealthyHeartbeatTimestamp" &&
        sortedInfo.order,
    },
  ];

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    sortField,
    sortOrder,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "gateway",
    dataSource: gateways,
    updateEntityPath: "update-gateway",
    customActionView: "",
    searchText,
    loading: loading,
    setSortedInfo,
  });

  useEffect(() => {
    dispatch(
      fetchGateways({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-gateways";
    let options = {
      url: "/api/web/gateways/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        searchText: searchText,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  let flattenedData = [];

  if (Object.keys(gateways).length !== 0) {
    flattenedData = Object.values(gateways.content);

    flattenedData.map((data) => {
      data.lastHealthyStatusTimestampFormatted = data.lastHealthyStatusTimestamp
        ? moment
            .tz(data.lastHealthyStatusTimestamp, userTimeZone)
            .format("DD-MM-YYYY HH:mm:ss")
        : "";
    });
  }

  useEffect(() => {
    if (allGateways && Object.keys(allGateways).length !== 0) {
      let flatData = Object.values(allGateways.content);

      flatData.map((data) => {
        data.lastHealthyStatusTimestampFormatted =
          data.lastHealthyStatusTimestamp
            ? moment
                .tz(data.lastHealthyStatusTimestamp, userTimeZone)
                .format("DD-MM-YYYY HH:mm:ss")
            : "";
      });

      setFlattenedAllData(Object.values(flatData));
    }

    // if(allGateways && Object.keys(allGateways).length !== 0){
    //   setFlattenedAllData(Object.values(allGateways.content));
    // }
  }, [allGateways]);

  const headers = [
    { label: language?.NAME?.value ?? englishLang?.NAME?.value, key: "name" },
    {
      label: language?.DESCRIPTION?.value ?? englishLang?.DESCRIPTION?.value,
      key: "description",
    },
    {
      label: language?.STATUS?.value ?? englishLang?.STATUS?.value,
      key: "status",
    },
    {
      label:
        language?.LAST_HEALTHY_STATUS_TIMESTAMP?.value ??
        englishLang?.LAST_HEALTHY_STATUS_TIMESTAMP?.value,
      key: "lastHealthyStatusTimestampFormatted",
    },
  ];

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          hasSelected={hasSelected}
          exportTableTitle={
            language?.GATEWATS?.value ?? englishLang?.GATEWATS?.value
          }
          addNewText={
            language?.ADD_GATEWAY?.value ?? englishLang?.ADD_GATEWAY?.value
          }
          title={language?.GATEWAY?.value ?? englishLang?.GATEWAY?.value}
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllButtonClick={handleExportAllButtonClick}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportButton={true}
          exportAllButton={true}
          handleSearch={setSearchText}
          tooltipText={
            language?.TOOLTIP_SEARCH_GATEWAY?.value ??
            englishLang?.TOOLTIP_SEARCH_GATEWAY?.value
          }
        />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default Gateways;
