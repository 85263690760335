import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Tree } from "antd";

import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "../../layout/Style.css";
import { fetchPermissions } from "../../../api/redux/action/PermissionActions";
import { ElementIDS } from "../../../components/common/ElementsIDs";

const { TextArea } = Input;

const AddRoleForm = (props) => {
  const dispatch = useDispatch();
  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState(undefined);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const { permissions, error, requestStatus, englishLang } = useSelector(
    (store) => ({
      permissions: store.permissionReducer.permissions,
      error: store.roleReducer.error,
      requestStatus: store.roleReducer.requestStatus,
      englishLang: store.langReducer.englishLang,
    })
  );

  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item.resourceType[key]]: [
          ...(result[item.resourceType[key]] || []),
          item,
        ],
      }),
      {}
    );

  useEffect(() => {
    dispatch(fetchPermissions());
  }, []);

  useEffect(() => {
    if (permissions.content && permissions.content.length > 0) {
      let grouped = groupBy(permissions.content, "resourceTypeId");

      const entries = Object.entries(grouped);
      let data = [];

      entries.map((item) => {
        let children = [];
        item[1].map((i) => {
          children.push({
            title: i.action.displayName,
            key: i.permissionId,
          });
        });

        data.push({
          title: item[1][0].resourceType.displayName,
          key: item[0] + "-",
          children: children,
        });
      });

      setTreeData(data);
    }
  }, [permissions]);

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
    props.setDirty();
  };

  const onLoad = (loadedKeysValue) => {
    setCheckedKeys(loadedKeysValue);
  };

  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };

  useEffect(() => {
    if (checkedKeys) {
      props.form.setFieldsValue({ permissionIdList: checkedKeys });
    }
  }, [checkedKeys]);

  useEffect(() => {
    onLoad(props.form.getFieldsValue().permissionIdList);
  }, [props.form.getFieldsValue().permissionIdList]);

  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="role-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        id={ElementIDS().name}
        label={props.language?.NAME?.value ?? englishLang?.NAME?.value}
        name="name"
        rules={requiredFieldRule}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <Form.Item
        id={ElementIDS().displayName}
        label={
          props.language?.DISPLAY_NAME?.value ??
          englishLang?.DISPLAY_NAME?.value
        }
        name="displayName"
        rules={requiredFieldRule}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>

      <Form.Item
        id={ElementIDS().description}
        label={
          props.language?.DESCRIPTION?.value ?? englishLang?.DESCRIPTION?.value
        }
        name="description"
        rules={requiredFieldRule}
      >
        <TextArea
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <Form.Item
        label={
          props.language?.PERMISSION?.value ?? englishLang?.PERMISSION?.value
        }
        name="permissionIdList"
      >
        <div id={ElementIDS().permissions}>
          <Tree
            disabled={
              props.readOnly && props.readOnly.viewOption ? "disabled" : null
            }
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onLoad={onLoad}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            treeData={treeData}
          />
        </div>
      </Form.Item>
      {props.footer}
    </Form>
  );
};

export default AddRoleForm;
