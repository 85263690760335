import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchFloorPlan } from "../../../api/redux/action/FloorPlanActions";
import { hasPermission } from "../../layout/Constants";

import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import useNotification from "../../../components/common/ResultNotifier";
import FloorPlanActionView from "../../../components/common/ActionMenu";

import { Image } from "antd";

const FloorPlan = () => {
  const [searchText, setSearchText] = useState();

  const dispatch = useDispatch();

  const {
    requestStatus,
    loading,
    error,
    floorPlans,
    user,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    exportAllLoading: store.reportReducer.exportAllLoading,
    requestStatus: store.floorPlanReducer.requestStatus,
    loading: store.floorPlanReducer.loading,
    floorPlans: store.floorPlanReducer.floorPlans,
    allData: store.reportReducer.allData,
    error: store.floorPlanReducer.error,
    user: store.authReducer.user,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));
  const columns = [
    {
      title: language?.IMAGE?.value ?? englishLang?.IMAGE?.value,
      dataIndex: ["urlFloorPlanImage"],
      render: (theImageURL) => (
        <Image width={50} alt={theImageURL} src={theImageURL} />
      ),
    },
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: ["title"],
    },
  ];

  const [allowPermission, setAllowPermission] = useState({
    view: hasPermission(selectedTenant, "READ_FLOOR_PLANS"),
  });

  const { DataTable, currentPage, pageSize, searchFormHelperHandler } =
    useDataTable({
      columns,
      title: language?.FLOOR_PLAN?.value ?? englishLang?.FLOOR_PLAN?.value,
      dataSource: floorPlans,
      loading: loading,
      customActionView: allowPermission.view ? FloorPlanActionView : "",
      allowPermission,
      updateEntityPath: "view-floor-plan",
      searchText,
    });

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  useEffect(() => {
    dispatch(
      fetchFloorPlan({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
      })
    );
  }, [currentPage, pageSize, searchText, isLoading]);

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          addNewText=""
          title={language?.FLOOR_PLAN?.value ?? englishLang?.FLOOR_PLAN?.value}
          handleSearch={setSearchText}
          tooltipText={
            language?.TOOLTIP_SEARCH_NAME?.value ??
            englishLang?.TOOLTIP_SEARCH_NAME?.value
          }
        />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
};

export default FloorPlan;
