import React from "react";
import { Button, Divider, Row, Space } from "antd";
import { ElementIDS } from "./ElementsIDs";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const AddFooterSection = ({
  handleCancel,
  viewOption,
  handleEditingPermission,
  form,
  updatePath,
  showKillAssetConfirm,
  language,
}) => {
  let history = useHistory();
  const { englishLang } = useSelector((store) => ({
    englishLang: store.langReducer.englishLang,
  }));
  const editBtn = handleEditingPermission() ? (
    <Button
      type="primary"
      onClick={() => {
        history.push(updatePath);
      }}
      id={ElementIDS().edit}
    >
      {language?.EDIT?.value ?? englishLang?.EDIT?.value}
    </Button>
  ) : null;

  const saveBtn = showKillAssetConfirm ? (
    <Button
      id={ElementIDS().save}
      type="primary"
      onClick={showKillAssetConfirm}
      disabled={viewOption && viewOption.viewOption ? "disabled" : null}
    >
      {language?.SAVE?.value ?? englishLang?.SAVE?.value}
    </Button>
  ) : (
    <Button
      id={ElementIDS().save}
      type="primary"
      onClick={() => form.submit()}
      disabled={viewOption && viewOption.viewOption ? "disabled" : null}
    >
      {language?.SAVE?.value ?? englishLang?.SAVE?.value}
    </Button>
  );

  return (
    <>
      <Divider />

      <Row justify="center">
        <Space>
          <Button
            id={ElementIDS().cancel}
            type="dashed"
            htmlType="button"
            onClick={handleCancel}
          >
            {language?.CANCEL?.value ?? englishLang?.CANCEL?.value}
          </Button>
          {viewOption && viewOption.viewOption === "disabled"
            ? editBtn
            : saveBtn}
        </Space>
      </Row>
    </>
  );
};

export default AddFooterSection;
