import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.heat";

//LastSeenAnalysis

function HeatMap({ data }) {
  useEffect(() => {
    const heatMap = L.map("heatMap").setView([35.9375, 14.3754], 10);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      // attribution:
      // '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      // maxZoom: 16,
    }).addTo(heatMap);

    const points =
      data && data.assetLastDetectedDistributionOverLastNumberOfDays.length > 0
        ? data.assetLastDetectedDistributionOverLastNumberOfDays.map((p) => {
            return [p.centroid.lat, p.centroid.lon, p.count];
          })
        : [];

    // // L.heatLayer(points, { minOpacity: 0.01, max: 10 }).addTo(map);
    // setTimeout(function(){
    // map.addLayer(markers);
    // },500)

    let marks = [];
    points.map((n) => {
      marks.push(L.marker(n));
    });

    let group = L.featureGroup(marks);

    setTimeout(function () {
      heatMap.fitBounds(group.getBounds());
    }, 1000);

    const heatmapLayer = L.heatLayer(points, {});
    setTimeout(function () {
      heatMap.addLayer(heatmapLayer);
    }, 1000);
  }, []);

  return <div id="heatMap" style={{ height: "40vh" }} />;
}

export default HeatMap;
