import * as type from "../types";
import { RequestState } from "./RequestState";

const INITIAL_STATE = {
  data: {},
  allData: {},
  floorPlanAllComponents: [],
  floorPlanComponent: [],
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  exportAllLoading: false,
  apiCallDone: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.REPORT_RESET:
      state = undefined;
      return {
        ...state,
        error: {},
        data: {},
        allData: {},
      };
    case type.REPORT_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        data: {},
        loading: true,
        apiCallDone: false,
      };
    case type.REPORT_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        apiCallDone: true,
      };
    case type.REPORT_FETCH_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        apiCallDone: true,
      };
    case type.REPORT_FETCH_ALL_REQUEST:
      return {
        ...state,
        error: {},
        allData: {},
        exportAllLoading: true,
      };
    case type.REPORT_FETCH_ALL_SUCCESS:
      return {
        ...state,
        allData: action.payload,
        exportAllLoading: false,
      };
    case type.REPORT_FETCH_ALL_FAILURE:
      return {
        ...state,
        allData: {},
        exportAllLoading: false,
      };
    case type.CLEAN_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: RequestState.EMPTY,
      };
    case type.REPORT_MARK_AS_READ_SUCCESS:
      return {
        ...state,
        // data: action.payload,
      };
    case type.REPORT_MARK_AS_READ_FAILURE:
      return {
        ...state,
        // data: {},
      };
    case type.REPORT_ALL_FLOORPLAN_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        floorPlanAllComponents: [],
        exportAllLoading: true,
      };
    case type.REPORT_ALL_FLOORPLAN_FETCH_SUCCESS:
      return {
        ...state,
        floorPlanAllComponents: action.payload,
        exportAllLoading: false,
      };
    case type.REPORT_ALL_FLOORPLAN_FETCH_FAILURE:
      return {
        ...state,
        floorPlanAllComponents: [],
        exportAllLoading: false,
      };
    case type.REPORT_FLOORPLAN_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        floorPlanComponent: [],
        loading: true,
        apiCallDone: false,
      };
    case type.REPORT_FLOORPLAN_FETCH_SUCCESS:
      return {
        ...state,
        floorPlanComponent: action.payload,
        loading: false,
        apiCallDone: true,
      };
    case type.REPORT_FLOORPLAN_FETCH_FAILURE:
      return {
        ...state,
        floorPlanComponent: [],
        loading: false,
        apiCallDone: true,
      };
    default:
      return state;
  }
};

export default reducer;
