import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import SiderMenu from "./SiderMenu";
import LayoutBanner from "./LayoutBanner";
import { useSelector } from "react-redux";
import RoutingUserList from "../../router/RoutingList";

const { Content } = Layout;

function MainLayout({ user, selectedTenant }) {
  const [collapsed, setCollapsed] = useState(false);
  const [isShowBanner, setShowBanner] = useState();

  const handleOnCollapse = () => {
    setCollapsed((prevState) => !prevState);
  };

  const { banner, language } = useSelector((store) => ({
    banner: store.bannerReducer.banner,
    language: store.langReducer.language,
  }));

  useEffect(() => {
    setShowBanner(banner);
  }, [banner]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <SiderMenu
        collapsed={collapsed}
        handleOnCollapse={handleOnCollapse}
        user={user}
      />
      <Layout>
        <LayoutBanner
          collapsed={collapsed}
          language={language}
          handleOnCollapse={handleOnCollapse}
          user={user}
        />
        {isShowBanner}
        <Content style={{ margin: "24px 16px 0" }}>
          {/* <div style={{ padding: 24, background: '#fff', minHeight: 20 }}> */}
          <RoutingUserList user={user} selectedTenant={selectedTenant} />
          {/* </div> */}
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
