import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Form, Row, Button } from "antd";

import { saveAssetGroup } from "../../../api/redux/action/AssetGroupActions";
import useNotification from "../../../components/common/ResultNotifier";
import AddAssetGroupForm from "./AddAssetGroupForm";
import { useHistory } from "react-router-dom";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";
import { hasPermission } from "../../layout/Constants";
import AddFooterSection from "../../../components/common/AddFooterSection";
import { validateInput } from "../../../components/RequiredFieldRule";

const AddAssetGroup = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [prompt, setDirty, setPristine] = UnsavedChanges();

  let history = useHistory();
  const { error, requestStatus, user, language, englishLang, selectedTenant } =
    useSelector((store) => ({
      error: store.assetGroupReducer.error,
      requestStatus: store.assetGroupReducer.requestStatus,
      user: store.authReducer.user,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
      selectedTenant: store.authReducer.selectedTenant,
    }));
  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;

  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      form.setFieldsValue({
        assetGroupId: props.location.state.selectedRow.assetGroupId,
        name: props.location.state.selectedRow.name,
        ...(props.location.state.selectedRow.department && {
          department: props.location.state.selectedRow.department.departmentId,
        }),
        // department: props.location.state.selectedRow.department.departmentId
      });
    }
  }, []);

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      return history.push("/assetGroups");
    }
  }, [requestStatus]);

  const handleSave = (values) => {
    const formData = {
      name: validateInput(form.getFieldValue().name),
      ...(form.getFieldValue().department && {
        department: { departmentId: form.getFieldValue().department },
      }),
      // department:{
      // 	departmentId: form.getFieldValue().department
      // }
    };
    setPristine();
    dispatch(saveAssetGroup(formData, form.getFieldValue().assetGroupId));
  };

  const handleCancel = () => {
    history.push("/assetGroups");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_ASSET_GROUPS");
  }

  const footer = (
    <AddFooterSection
      language={language}
      viewOption={props.location.state}
      handleCancel={handleCancel}
      handleEditingPermission={handleEditingPermission}
      form={form}
      updatePath={"/update-assetGroup"}
    />
  );

  return (
    <Card
      title={language?.ASSET_GROUP?.value ?? englishLang?.ASSET_GROUP?.value}
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddAssetGroupForm
            handleSave={handleSave}
            form={form}
            footer={footer}
            setDirty={setDirty}
            readOnly={props.location.state}
          />
        </Col>
      </Row>
      {prompt}
      <ResultNotifier />
    </Card>
  );
};

export default AddAssetGroup;
