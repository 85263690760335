import * as type from "../types";
import {
  getFixedReadersService,
  saveFixedReaderService,
  deleteFixedReaderService,
  getAllFixedReadersService,
  getSingleFixedReadersService,
} from "../../network/service/FixedReaderService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchFixedReaders = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.FIXEDREADERS_FETCH_REQUEST,
      });
      const response = await dispatch(getFixedReadersService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.FIXEDREADERS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.FIXEDREADERS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.FIXEDREADERS_FETCH_FAILURE,
      });
    }
  };
};

export const fetchSingleFixedReaders = (id = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.FETCH_SINGLE_FIXEDREADERS_REQUEST,
      });
      const response = await dispatch(getSingleFixedReadersService(id));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.FETCH_SINGLE_FIXEDREADERS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.FETCH_SINGLE_FIXEDREADERS_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.FETCH_SINGLE_FIXEDREADERS_FAILURE,
        payload: ex,
      });
    }
  };
};

export const fetchAllFixedReaders = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_FIXEDREADERS_FETCH_REQUEST,
      });
      const response = await dispatch(getAllFixedReadersService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_FIXEDREADERS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_FIXEDREADERS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_FIXEDREADERS_FETCH_FAILURE,
      });
    }
  };
};

export const saveFixedReader = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.FIXEDREADER_SAVE_REQUEST,
      });
      const response = await dispatch(saveFixedReaderService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.FIXEDREADER_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.FIXEDREADER_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.FIXEDREADER_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteFixedReader = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.FIXEDREADER_DELETE_REQUEST,
      });
      const response = await dispatch(deleteFixedReaderService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.FIXEDREADER_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.FIXEDREADER_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.FIXEDREADER_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
