import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { saveUser } from "../../../api/redux/action/UserActions";
import { changePassword } from "../../../api/redux/action/UserActions";

import { Button, Card, Col, Divider, Form, Row, Space, Tabs } from "antd";
import PersonalDetailsForm from "./PersonalDetailsForm";
import UserSecurityForm from "./UserSecurityForm";

import { ElementIDS } from "../../../components/common/ElementsIDs";
import useNotification from "../../../components/common/ResultNotifier";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";
import { validateInput } from "../../../components/RequiredFieldRule";

const { TabPane } = Tabs;
const UserProfile = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [menu, setMenu] = useState("Personal_Details");
  const [edit, setEdit] = useState(false);
  const [formObject, setFormObject] = useState({});
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [prompt, setDirty, setPristine] = UnsavedChanges();

  let history = useHistory();

  const {
    error,
    requestStatus,
    user,
    language,
    englishLang,
    selectedTenant,
    loading,
  } = useSelector((store) => ({
    error: store.authReducer.error,
    requestStatus: store.userReducer.requestStatus,
    user: store.authReducer.user,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
    loading: store.userReducer.loading,
  }));
  let langSavedItem =
    language?.PASSWORD_CHANGED?.value ?? englishLang?.PASSWORD_CHANGED?.value;

  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );

  const handleSaveProfile = (values) => {
    setIsChangePassword(true);
    var department = [];
    form.getFieldValue().department &&
    form.getFieldValue().department.length > 0
      ? form.getFieldValue().department.map((d) => {
          department.push({
            departmentId: d,
          });
        })
      : (department = null);
    const formData = {
      name: validateInput(form.getFieldValue().name),
      surname: validateInput(form.getFieldValue().surname),
      username: form.getFieldValue().username,
      userGroupId: form.getFieldValue().userGroup,
      department: department,
    };
    setPristine();
    setFormObject({ ...user, ...formData });
    dispatch(saveUser(formData, form.getFieldValue().userAccountId));
  };

  const handleChangePassword = (values) => {
    setIsChangePassword(true);
    const formData = {
      ...(form.getFieldValue().newPassword && {
        newPassword: form.getFieldValue().newPassword,
      }),
      ...(form.getFieldValue().oldPassword && {
        oldPassword: form.getFieldValue().oldPassword,
      }),
    };
    setPristine();
    dispatch(changePassword(formData, form.getFieldValue().userAccountId));
  };

  useEffect(() => {
    if (user) {
      var department = [];
      if (user.department) {
        user.department.map((d) => {
          department.push(d.departmentId);
        });
      }

      form.setFieldsValue({
        userAccountId: selectedTenant.userId,
        name: user.name,
        surname: user.surname,
        username: user.username,
        department,
      });

      setEdit(true);
    }
  }, [user]);

  const handleMenuClick = (e) => {
    setMenu(e.key);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const footer = (
    <>
      <Divider />

      <Row justify="center">
        <Space>
          <Button
            id={ElementIDS().cancel}
            onClick={handleCancel}
            type="dashed"
            htmlType="button"
          >
            {language?.CANCEL?.value ?? englishLang?.CANCEL?.value}
          </Button>
          <Button
            id={ElementIDS().save}
            onClick={() => form.submit()}
            type="primary"
            loading={loading}
          >
            {language?.SAVE?.value ?? englishLang?.SAVE?.value}
          </Button>
        </Space>
      </Row>
    </>
  );

  return (
    <Card
      title={language?.PROFILE?.value ?? englishLang?.PROFILE?.value}
      loading={isLoading}
    >
      <Tabs defaultActiveKey="Personal_Details" onChange={handleMenuClick}>
        <TabPane
          tab={
            language?.PERSONAL_DETAILS?.value ??
            englishLang?.PERSONAL_DETAILS?.value
          }
          id={ElementIDS().personalDetails}
          key="Personal_Details"
        >
          <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <PersonalDetailsForm
                handleSave={handleSaveProfile}
                form={form}
                setDirty={setDirty}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane
          tab={
            language?.RESET_PASSWORD?.value ??
            englishLang?.RESET_PASSWORD?.value
          }
          id={ElementIDS().resetPsw}
          key="Security"
        >
          <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <UserSecurityForm
                handleSave={handleChangePassword}
                edit={edit}
                form={form}
                footer={footer}
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
      <ResultNotifier />
      {prompt}
      {/* {footer} */}
    </Card>
  );
};

export default UserProfile;
