import React from "react";
import { Alert } from "antd";
import { useDispatch } from "react-redux";
import { removeBanner } from "../../api/redux/action/BannerActions";

const Banner = (props) => {
  const dispatch = useDispatch();

  const closeBannerHandler = () => {
    dispatch(removeBanner());
  };

  return (
    <div>
      <Alert
        message={props.bannerProp.message}
        type={props.bannerProp.type}
        showIcon={props.bannerProp.showIcon}
        closable={props.bannerProp.closable}
        onClose={closeBannerHandler}
      />
    </div>
  );
};

export default Banner;
