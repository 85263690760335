import { gql } from "@apollo/client";

//asset groups
export const GET_ASSET_GROUPS = gql`
  query GetAssetGroups {
    assetGroups {
      id
      name
    }
  }
`;

//pie chart
export const ASSET_DETECTION_TYPE_COUNTS = gql`
  query AssetDetectionTypeCounts($assetGroupIds: [Int!]) {
    assetDetectionTypeCounts(assetGroupIds: $assetGroupIds) {
      detectionType
      count
    }
  }
`;

//bar chart
export const ASSET_DETECTION_COUNTS = gql`
  query AssetZoneDetectionCounts($assetGroupIds: [Int!]) {
    assetZoneDetectionCounts(assetGroupIds: $assetGroupIds) {
      count
      name
      zoneId: id
    }
    assetVehicleDetectionCounts(assetGroupIds: $assetGroupIds) {
      count
      name: vehicleId
    }
  }
`;

//map clustering
export const CURRENT_LOCATION = gql`
  query CurrentLocation($assetGroupIds: [Int!]) {
    currentlyDetectedAssets(assetGroupIds: $assetGroupIds) {
      asset {
        id
        name
        code
      }
      l: location {
        lat: latitude
        lon: longitude
      }
    }
  }
`;

//heatmap
export const ASSET_LAST_SEEN_DISTRIBUTION_OVER_LAST_30DAYS = gql`
  query assetLastDetectedDistributionOverLast30Days($geohashLength: Int!) {
    assetLastDetectedDistributionOverLast30Days(geohashLength: $geohashLength) {
      centroid {
        lat: latitude
        lon: longitude
      }
      count
    }
  }
`;

export const ASSET_LAST_SEEN_DISTRIBUTION_OVER_LAST_NUMBER_OF_DAYS = gql`
  query assetLastDetectedDistributionOverLastNumberOfDays(
    $geohashLength: Int!
    $maxAgeInDays: Int
  ) {
    assetLastDetectedDistributionOverLastNumberOfDays(
      geohashLength: $geohashLength
      maxAgeInDays: $maxAgeInDays
    ) {
      centroid {
        lat: latitude
        lon: longitude
      }
      count
    }
  }
`;

export const ASSET_LAST_SEEN_AGE = gql`
  query assetLastDetectedAge(
    $minAgeInDays: Int
    $maxAgeInDays: Int
    $assetGroupIds: [Int!]
  ) {
    assetLastDetectedAge(
      minAgeInDays: $minAgeInDays
      maxAgeInDays: $maxAgeInDays
      assetGroupIds: $assetGroupIds
    ) {
      asset {
        code
      }
      location {
        latitude
        longitude
      }
      lastDetected
    }
  }
`;
