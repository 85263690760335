import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select, Row, Col, Modal } from "antd";
import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "../../layout/Style.css";
import { getModalSizeByWindowSize } from "../../layout/Constants";
import AddGatewayForm from "../Gateways/AddGatewayForm";
import {
  fetchGateways,
  saveGateway,
} from "../../../api/redux/action/GatewayActions";
import { ElementIDS } from "../../../components/common/ElementsIDs";

const { Option } = Select;

const AddIpRelayCardForm = (props) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState({ gateway: false });
  const [gatewayForm] = Form.useForm();
  const [newGatewayData, setNewGatewayData] = useState(undefined);
  const [modalSize, setModalSize] = useState(getModalSizeByWindowSize());
  const [sortedGateways, setSortedGateways] = useState([]);

  const { gateways, englishLang } = useSelector((store) => ({
    gateways: store.gatewayReducer.gateways,
    englishLang: store.langReducer.englishLang,
  }));

  useEffect(() => {
    dispatch(fetchGateways());
  }, [newGatewayData]);

  useEffect(() => {
    if (gateways && gateways.content) {
      let sorted = gateways.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedGateways(sorted);
    }
  }, [gateways]);

  const handleAddNewGateway = () => {
    showModal(true);
  };

  const handleGatewayFormSave = (values) => {
    const formData = {
      name: gatewayForm.getFieldValue().name,
      description: gatewayForm.getFieldValue().description,
    };

    dispatch(saveGateway(formData)).then(() => {
      setNewGatewayData(values);
      setVisible(false);
    });
  };

  const showModal = (gateway) => {
    setVisible({ gateway: gateway });
  };

  const handleModalOk = (e) => {
    gatewayForm.submit();
  };

  const handleModalCancel = (e) => {
    setVisible(false);
  };

  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="ipRelayCard-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        label={props.language?.GATEWAY?.value ?? englishLang?.GATEWAY?.value}
        required
      >
        {/* <Row gutter={3}> */}
        {/* <Col flex={9} span={22}> */}
        <Form.Item name="gateway" rules={requiredFieldRule}>
          <Select
            id={ElementIDS().gateway}
            allowClear
            style={{ width: "100%" }}
            placeholder={
              props.language?.SELECT_GATEWAY?.value ??
              englishLang?.SELECT_GATEWAY?.value
            }
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) => {
              props.setDirty();
            }}
            disabled={
              props.readOnly && props.readOnly.viewOption ? "disabled" : null
            }
          >
            {sortedGateways
              ? sortedGateways.map((item) => (
                  <Option
                    id={ElementIDS().gatewayOption}
                    key={item.gatewayId}
                    value={item.gatewayId}
                  >
                    {item.name}
                  </Option>
                ))
              : undefined}
          </Select>
        </Form.Item>
        {/* </Col> */}
        {/* <Col flex={1} span={2}>
                  <Tooltip title="Create a new Gateway">
                    <Button
                      icon={<PlusOutlined />}
                      ghost
                      type="primary"
                      onClick={handleAddNewGateway}
                      style={{ width: '100%' }}
                    />
                  </Tooltip>
                </Col> */}
        {/* </Row> */}
      </Form.Item>

      <Form.Item
        label={
          props.language?.IP_ADDRESS?.value ?? englishLang?.IP_ADDRESS?.value
        }
        name="ipAddress"
        rules={requiredFieldRule}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
          id={ElementIDS().IP_Address}
        />
      </Form.Item>
      <Form.Item
        label={
          props.language?.PORT_NUMBER?.value ?? englishLang?.PORT_NUMBER?.value
        }
        name="portNumber"
        rules={requiredFieldRule}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
          id={ElementIDS().IP_Address}
        />
      </Form.Item>

      <Modal
        title={
          props.language?.ADD_GATEWAY?.value ?? englishLang?.ADD_GATEWAY?.value
        }
        visible={visible.gateway}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={modalSize}
      >
        <Row justify="center">
          <Col span={20}>
            <AddGatewayForm
              handleSave={handleGatewayFormSave}
              form={gatewayForm}
            />
          </Col>
        </Row>
      </Modal>

      {props.footer}
    </Form>
  );
};

export default AddIpRelayCardForm;
