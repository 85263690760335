import * as type from "../types";

const getLanguageObject = (payload, columnId) => {
  const languageObject = {};
  const jsonIds = payload?.map((item) => item.id.substring(5));

  jsonIds?.forEach((langKey) => {
    languageObject[langKey] = "";
  });

  const jsonValues = payload?.map((item) =>
    item.cells.find((x) => x.columnId === columnId)
  );

  const entries = Object.entries(languageObject);
  jsonValues?.forEach((item, idx) => {
    entries[idx][1] = item;
  });

  return Object.fromEntries(entries);
};

const INITIAL_STATE = {
  languages: JSON.parse(localStorage.getItem("languages")),
  englishLang: JSON.parse(localStorage.getItem("enLang")),
  language: {},
  error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.LANGUAGES_FETCH_REQUEST:
      return {
        ...state,
        languages: [],
      };
    case type.LANGUAGES_FETCH_SUCCESS:
      localStorage.setItem("languages", JSON.stringify(action.payload));
      const englishLang = getLanguageObject(action.payload, "translation_en");
      localStorage.setItem("enLang", JSON.stringify(englishLang));

      return {
        ...state,
        languages: action.payload,
        englishLang: englishLang,
      };
    case type.LANGUAGES_FETCH_FAILURE:
      return {
        ...state,
        languages: [],
        error: action.payload,
      };
    case type.LANG_CHANGE_SUCCESS:
      const selectedLang = action.selectedLang;
      let tenantData = localStorage.getItem("selectedTenant");
      if (tenantData != null) {
        let tenant = JSON.parse(tenantData);
        tenant.language = selectedLang;
        window.localStorage.setItem("selectedTenant", JSON.stringify(tenant));
      }

      const languageObject = getLanguageObject(action.payload, selectedLang);
      const englishLangObject = getLanguageObject(
        action.payload,
        "translation_en"
      );
      return {
        ...state,
        language: languageObject,
        englishLang: englishLangObject,
      };
    default:
      return state;
  }
};

export default reducer;
