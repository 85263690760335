import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EnvironmentOutlined } from "@ant-design/icons";

import { Button, Popover } from "antd";
import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchSites,
  deleteSite,
  fetchAllSites,
} from "../../../api/redux/action/SiteActions";
import { exportRequest } from "../../../api/network/axios/exportRequest";
import SiteActionView from "../../../components/common/ActionMenu";
import useNotification from "../../../components/common/ResultNotifier";
import { hasPermission } from "../../layout/Constants";
import PopUpSiteMap from "./PopUpSiteMap";
import { useHistory } from "react-router-dom";

function Sites() {
  const dispatch = useDispatch();
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [searchText, setSearchText] = useState();
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  let history = useHistory();

  const {
    sites,
    allSites,
    error,
    requestStatus,
    loading,
    user,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    sites: store.siteReducer.sites,
    allSites: store.siteReducer.allSites,
    error: store.siteReducer.error,
    requestStatus: store.siteReducer.requestStatus,
    loading: store.siteReducer.loading,
    user: store.authReducer.user,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const columns = [
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
      sorter: true,
      sortField: "name",
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      render: (name) => (
        <a
          onClick={() => {
            const viewOption = "disabled";

            history.push({
              pathname: "/update-site",
              state: { selectedRow, viewOption },
            });
          }}
        >
          {name}
        </a>
      ),
    },
    {
      title: language?.LOCATION?.value ?? englishLang?.LOCATION?.value,
      dataIndex: "location",
      render: (location, siteId) =>
        location && location.latitude && location.latitude ? (
          <Popover
            trigger="click"
            title={siteId.name}
            content={
              <PopUpSiteMap lat={location.latitude} long={location.longitude} />
            }
          >
            <Button
              type="primary"
              shape="circle"
              icon={<EnvironmentOutlined />}
            />
          </Popover>
        ) : (
          ""
        ),
    },
  ];

  const [allowPermission, setAllowPermission] = useState({
    edit: hasPermission(selectedTenant, "UPDATE_SITES"),
    delete: hasPermission(selectedTenant, "DELETE_SITES"),
    view: hasPermission(selectedTenant, "READ_SITES"),
  });

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const handleSingleDelete = (selected) => {
    dispatch(deleteSite(selected.siteId));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    sortField,
    sortOrder,
    selectedRow,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "site",
    dataSource: sites,
    updateEntityPath: "update-site",
    customActionView:
      allowPermission.edit || allowPermission.delete || allowPermission.view
        ? SiteActionView
        : "",
    searchText,
    loading: loading,
    handleSingleDelete,
    allowPermission,
    setSortedInfo,
    title: language?.SITES?.value ?? englishLang?.SITES?.value,
  });

  useEffect(() => {
    dispatch(
      fetchSites({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-sites";
    let options = {
      url: "/api/web/sites/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        searchText: searchText,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  let flattenedData = [];

  if (Object.keys(sites).length !== 0) {
    flattenedData = Object.values(sites.content);
  }

  useEffect(() => {
    if (allSites && Object.keys(allSites).length !== 0) {
      setFlattenedAllData(Object.values(allSites.content));
    }
  }, [allSites]);

  const headers = [
    { label: language?.NAME?.value ?? englishLang?.NAME?.value, key: "name" },
    {
      label: language?.LATITUDE?.value ?? englishLang?.LATITUDE?.value,
      key: "location.latitude",
    },
    {
      label: language?.LONGITUDE?.value ?? englishLang?.LONGITUDE?.value,
      key: "location.longitude",
    },
  ];

  const addNewPath = hasPermission(selectedTenant, "CREATE_SITES")
    ? "add-site"
    : "";

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath={addNewPath}
          hasSelected={hasSelected}
          exportTableTitle={language?.SITES?.value ?? englishLang?.SITES?.value}
          addNewText={language?.ADD_SITE?.value ?? englishLang?.ADD_SITE?.value}
          title={language?.SITES?.value ?? englishLang?.SITES?.value}
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllButtonClick={handleExportAllButtonClick}
          loading={loading}
          exportButton={true}
          exportAllButton={true}
          handleSearch={setSearchText}
          tooltipText={
            language?.TOOLTIP_SEARCH_NAME?.value ??
            englishLang?.TOOLTIP_SEARCH_NAME?.value
          }
          exportAllLoading={exportAllLoading}
        />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default Sites;
