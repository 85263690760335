import * as type from "../types";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "../action/util";
import {
  getAssetLocationService,
  getAllAssetLocationsService,
  deleteAssetLocationService,
  saveAssetLocationService,
} from "../../network/service/AssetLocationService";

export const fetchAssetLocation = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ASSETLOCATION_FETCH_REQUEST,
      });
      const response = await dispatch(getAssetLocationService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ASSETLOCATION_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ASSETLOCATION_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ASSETLOCATION_FETCH_FAILURE,
      });
    }
  };
};

export const fetchAllAssetLocation = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_ASSETLOCATION_FETCH_REQUEST,
      });
      const response = await dispatch(getAllAssetLocationsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_ASSETLOCATION_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_ASSETLOCATION_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_ASSETLOCATION_FETCH_FAILURE,
      });
    }
  };
};

export const saveAssetLocation = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ASSETLOCATION_SAVE_REQUEST,
      });
      const response = await dispatch(saveAssetLocationService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ASSETLOCATION_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.ASSETLOCATION_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ASSETLOCATION_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteAssetLocation = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ASSETLOCATION_DELETE_REQUEST,
      });
      const response = await dispatch(deleteAssetLocationService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ASSETLOCATION_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.ASSETLOCATION_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ASSETLOCATION_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
