import React from "react";
import { useHistory } from "react-router-dom";
import { Result, Button } from "antd";
import { useSelector } from "react-redux";

function ErrorFallback() {
  let history = useHistory();

  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));
  return (
    <Result
      title={
        language?.SOMETHING_WENT_WRONG?.value ??
        englishLang?.SOMETHING_WENT_WRONG?.value
      }
      extra={
        <Button
          id="rfid-error-errorBtn"
          onClick={() => {
            history.push("/");
            window.location.reload(true);
          }}
          type="primary"
          key="console"
        >
          {language?.GOBACK?.value ?? englishLang?.GOBACK?.value}
        </Button>
      }
    />
  );
}

export default ErrorFallback;
