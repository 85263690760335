import * as type from "../types";
import { RequestState } from "./RequestState";

const INITIAL_STATE = {
  alertRules: {},
  alertRule: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.ALERTRULES_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        alertRules: {},
        loading: true,
      };
    case type.ALERTRULES_FETCH_SUCCESS:
      return {
        ...state,
        alertRules: action.payload,
        loading: false,
      };
    case type.ALERTRULES_FETCH_FAILURE:
      return {
        ...state,
        alertRules: {},
        loading: false,
      };
    case type.ALERTRULE_SAVE_REQUEST:
      return {
        ...state,
        error: {},
        alertRule: {},
        requestStatus: RequestState.PENDING,
      };
    case type.ALERTRULE_SAVE_SUCCESS:
      return {
        ...state,
        alertRule: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.ALERTRULE_SAVE_FAILURE:
      return {
        ...state,
        alertRule: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
    case type.ALERTRULE_DELETE_REQUEST:
      return {
        ...state,
        requestStatus: RequestState.PENDING,
      };
    case type.ALERTRULE_DELETE_SUCCESS:
      return {
        ...state,
        alertRule: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.ALERTRULE_DELETE_FAILURE:
      return {
        ...state,
        alertRule: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
    case type.CLEAN_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: RequestState.EMPTY,
      };
    case type.ALERTRULE_ENABLE_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        requestStatus: RequestState.PENDING,
      };
    case type.ALERTRULE_ENABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        requestStatus: RequestState.SUCCESS,
      };
    case type.ALERTRULE_ENABLE_FAILURE:
      return {
        ...state,
        loading: false,
        requestStatus: RequestState.FAILURE,
      };

    default:
      return state;
  }
};

export default reducer;
