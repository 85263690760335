import * as type from "../types";
import {
  getCustomFieldsService,
  saveCustomFieldService,
  deleteCustomFieldService,
  getAllCustomFieldsService,
} from "../../network/service/CustomFieldService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchCustomFields = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.CUSTOMFIELDS_FETCH_REQUEST,
      });
      const response = await dispatch(getCustomFieldsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.CUSTOMFIELDS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.CUSTOMFIELDS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.CUSTOMFIELDS_FETCH_FAILURE,
      });
    }
  };
};

export const fetchAllCustomFields = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_CUSTOMFIELDS_FETCH_REQUEST,
      });
      const response = await dispatch(getAllCustomFieldsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_CUSTOMFIELDS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_CUSTOMFIELDS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_CUSTOMFIELDS_FETCH_FAILURE,
      });
    }
  };
};

export const saveCustomField = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.CUSTOMFIELD_SAVE_REQUEST,
      });
      const response = await dispatch(saveCustomFieldService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.CUSTOMFIELD_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.CUSTOMFIELD_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.CUSTOMFIELD_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteCustomField = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.CUSTOMFIELD_DELETE_REQUEST,
      });
      const response = await dispatch(deleteCustomFieldService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.CUSTOMFIELD_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.CUSTOMFIELD_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.CUSTOMFIELD_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
