import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";

import { saveRole } from "../../../api/redux/action/RoleActions";
import useNotification from "../../../components/common/ResultNotifier";
import AddRoleForm from "./AddRoleForm";
import { useHistory } from "react-router-dom";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";
import { hasPermission } from "../../layout/Constants";
import AddFooterSection from "../../../components/common/AddFooterSection";
import { validateInput } from "../../../components/RequiredFieldRule";

const AddRole = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [prompt, setDirty, setPristine] = UnsavedChanges();
  let history = useHistory();

  const {
    permissions,
    error,
    requestStatus,
    user,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    permissions: store.permissionReducer.permissions,
    error: store.roleReducer.error,
    requestStatus: store.roleReducer.requestStatus,
    user: store.authReducer.user,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));
  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;

  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      const permissionList = [];
      if (props.location.state.selectedRow.permissionSet) {
        props.location.state.selectedRow.permissionSet.map((item) => {
          permissionList.push(item.permissionId);
        });
      }

      form.setFieldsValue({
        roleId: props.location.state.selectedRow.roleId,
        name: props.location.state.selectedRow.name,
        displayName: props.location.state.selectedRow.displayName,
        description: props.location.state.selectedRow.description,
        permissionIdList: permissionList,
      });
    }
  }, []);

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      return history.push("/roles");
    }
  }, [requestStatus]);

  const handleSave = (values) => {
    let permissionIdListFinal = [];
    form.getFieldValue().permissionIdList.map((item) => {
      const splitItem = item.toString().split("-");
      if (splitItem.length > 1) {
      } else {
        permissionIdListFinal.push(item);
      }
    });

    const formData = {
      name: validateInput(form.getFieldValue().name),
      displayName: validateInput(form.getFieldValue().displayName),
      description: validateInput(form.getFieldValue().description),
      permissionIdList: permissionIdListFinal,
    };
    setPristine();
    dispatch(saveRole(formData, form.getFieldValue().roleId));
  };

  const handleCancel = () => {
    history.push("/roles");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_ROLES");
  }

  const footer = (
    <AddFooterSection
      language={language}
      viewOption={props.location.state}
      handleCancel={handleCancel}
      form={form}
      updatePath={"/update-role"}
      handleEditingPermission={handleEditingPermission}
    />
  );

  return (
    <Card
      title={language?.ROLE?.value ?? englishLang?.ROLE?.value}
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddRoleForm
            handleSave={handleSave}
            form={form}
            footer={footer}
            setDirty={setDirty}
            readOnly={props.location.state}
            language={language}
          />
        </Col>
      </Row>
      {prompt}
      <ResultNotifier />
    </Card>
  );
};

export default AddRole;
