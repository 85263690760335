import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchAntennas,
  deleteAntenna,
  fetchAllAntennas,
} from "../../../api/redux/action/AntennaActions";
import AntennaActionView from "../../../components/common/ActionMenu";
import useNotification from "../../../components/common/ResultNotifier";
import { hasPermission } from "../../layout/Constants";
import { useHistory } from "react-router-dom";
import { exportRequest } from "../../../api/network/axios/exportRequest";
function Antennas() {
  const dispatch = useDispatch();
  let history = useHistory();
  const [searchText, setSearchText] = useState();
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  const {
    antennas,
    allAntennas,
    error,
    requestStatus,
    loading,
    user,
    userTimeZone,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    user: store.authReducer.user,
    antennas: store.antennaReducer.antennas,
    allAntennas: store.antennaReducer.allAntennas,
    error: store.antennaReducer.error,
    requestStatus: store.antennaReducer.requestStatus,
    loading: store.antennaReducer.loading,
    userTimeZone: store.authReducer.userTimeZone,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const [allowPermission, setAllowPermission] = useState({
    edit: hasPermission(selectedTenant, "UPDATE_ANTENNAS"),
    delete: hasPermission(selectedTenant, "DELETE_ANTENNAS"),
    view: hasPermission(selectedTenant, "READ_ANTENNAS"),
  });

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const columns = [
    {
      title:
        language?.ANNTENA_NUMBER?.value ?? englishLang?.ANNTENA_NUMBER?.value,
      dataIndex: "antennaNumber",
      sorter: true,
      sortField: "antennaNumber",
      sortOrder: sortedInfo.columnKey === "antennaNumber" && sortedInfo.order,
      render: (antennaNumber) => (
        <a
          onClick={() => {
            const viewOption = "disabled";

            history.push({
              pathname: "/update-antenna",
              state: { selectedRow, viewOption },
            });
          }}
        >
          {antennaNumber}
        </a>
      ),
    },
    {
      title: language?.READER?.value ?? englishLang?.READER?.value,
      dataIndex: ["reader", "name"],
    },
    {
      title: language?.SITE?.value ?? englishLang?.SITE?.value,
      dataIndex: ["site", "name"],
      sorter: true,
      sortField: "zoneEntity.siteEntity.name",
      sortOrder:
        sortedInfo.columnKey === "zoneEntity.siteEntity.name" &&
        sortedInfo.order,
    },
    {
      title: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      dataIndex: ["zone", "name"],
      sorter: true,
      sortField: "zoneEntity.name",
      sortOrder: sortedInfo.columnKey === "zoneEntity.name" && sortedInfo.order,
    },
    {
      title:
        language?.LAST_ACTIVITY_TIMESTAMP?.value ??
        englishLang?.LAST_ACTIVITY_TIMESTAMP?.value,
      dataIndex: "lastActivityTimestamp",
      sorter: true,
      sortField: "lastActivityTimestamp",
      sortOrder:
        sortedInfo.columnKey === "lastActivityTimestamp" && sortedInfo.order,

      render: (lastActivityTimestamp) => (
        <span>
          {lastActivityTimestamp
            ? moment
                .tz(lastActivityTimestamp, userTimeZone)
                .format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
    },
  ];

  const handleSingleDelete = (selected) => {
    dispatch(deleteAntenna(selected.antennaId));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setSearchText({
      ...searchText,
      [dataIndex]: undefined,
    });
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    selectedRow,
    pageSize,
    sortField,
    sortOrder,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "antenna",
    dataSource: antennas,
    updateEntityPath: "update-antenna",
    customActionView:
      allowPermission.edit || allowPermission.delete || allowPermission.view
        ? AntennaActionView
        : "",
    searchText,
    loading: loading,
    handleSingleDelete,
    allowPermission,
    setSortedInfo,
    title: language?.ANTENNAS?.value ?? englishLang?.ANTENNAS?.value,
  });

  useEffect(() => {
    dispatch(
      fetchAntennas({
        page: searchFormHelperHandler(),
        size: pageSize,
        sortField: sortField,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-antennas";
    let options = {
      url: "/api/web/antennas/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        searchText: searchText,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  let flattenedData = [];

  if (Object.keys(antennas).length !== 0) {
    flattenedData = Object.values(antennas.content);

    flattenedData.map((data) => {
      data.lastActivityTimestampFormatted = data.lastActivityTimestamp
        ? moment
            .tz(data.lastActivityTimestamp, userTimeZone)
            .format("DD-MM-YYYY HH:mm:ss")
        : "";
    });
  }

  useEffect(() => {
    if (allAntennas && Object.keys(allAntennas).length !== 0) {
      let flatData = Object.values(allAntennas.content);

      flatData.map((data) => {
        data.lastActivityTimestampFormatted = data.lastActivityTimestamp
          ? moment
              .tz(data.lastActivityTimestamp, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedAllData(Object.values(flatData));
    }
  }, [allAntennas]);

  const headers = [
    {
      label:
        language?.ANNTENA_NUMBER?.value ?? englishLang?.ANNTENA_NUMBER?.value,
      key: "antennaNumber",
    },
    {
      label: language?.READER?.value ?? englishLang?.READER?.value,
      key: "reader.name",
    },
    {
      label: language?.SITE?.value ?? englishLang?.SITE?.value,
      key: "site.name",
    },
    {
      label: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      key: "zone.name",
    },
    {
      label:
        language?.LAST_ACTIVITY_TIMESTAMP?.value ??
        englishLang?.LAST_ACTIVITY_TIMESTAMP?.value,
      key: "lastActivityTimestampFormatted",
    },
  ];

  const addNewPath = hasPermission(selectedTenant, "CREATE_ANTENNAS")
    ? "add-antenna"
    : "";

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath={addNewPath}
          hasSelected={hasSelected}
          exportTableTitle={
            language?.ANTENNAS?.value ?? englishLang?.ANTENNAS?.value
          }
          addNewText={
            language?.ADD_ANNTENA?.value ?? englishLang?.ADD_ANNTENA?.value
          }
          title={language?.ANTENNAS?.value ?? englishLang?.ANTENNAS?.value}
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllButtonClick={handleExportAllButtonClick}
          exportAllLoading={exportAllLoading}
          handleSearch={setSearchText}
          loading={loading}
          exportButton={true}
          exportAllButton={true}
          tooltipText={
            language?.TOOLTIP_SEARCH_ANTENNAS?.value ??
            englishLang?.TOOLTIP_SEARCH_ANTENNAS?.value
          }
        />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default Antennas;
