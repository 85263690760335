import React, { useEffect, useRef } from "react";
import { Button, Col, Divider, Row } from "antd";
import { PlusOutlined, DownloadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { ElementIDS } from "./ElementsIDs";

function Header({
  addNewPath,
  hasSelected,
  handleExportTable = null,
  exportTableTitle,
  tooltipText = undefined,
  addNewText,
  title,
  csvData,
  csvAllData,
  csvHeaders,
  exportAllLoading,
  loading,
  exportAllSuccess,
  exportAllButtonClick,
}) {
  const history = useHistory();
  const csvAllLinkRef = useRef(null);

  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const handleAddNew = () => {
    history.push("/" + addNewPath);
  };

  useEffect(() => {
    if (exportAllSuccess) {
      csvAllLinkRef?.current?.link.click();
    }
  }, [exportAllSuccess]);

  const exportAllBtn = (
    <>
      <Button
        id={ElementIDS().exportBtn}
        type="dashed"
        style={{ float: "right", marginRight: 12 }}
        loading={exportAllLoading}
        onClick={() => exportAllButtonClick()}
      >
        <DownloadOutlined />
        {language?.EXPORT_ALL?.value ?? englishLang?.EXPORT_ALL?.value}
      </Button>

      <CSVLink
        ref={csvAllLinkRef}
        data={csvAllData}
        headers={csvHeaders}
        filename={`export-` + exportTableTitle + `-` + Date.now() + `.csv`}
      />
    </>
  );

  return (
    <>
      <Row>
        <Col
          style={{
            color: "rgba(0, 0, 0, 0.85)",
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          {title}
        </Col>
        <Col flex="auto">
          {addNewPath !== "" ? (
            <Button
              icon={<PlusOutlined />}
              type="primary"
              style={{ float: "right" }}
              onClick={handleAddNew}
            >
              {addNewText}
            </Button>
          ) : null}

          {exportAllBtn}
        </Col>
      </Row>
      <Divider />
    </>
  );
}

export default Header;
