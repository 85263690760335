import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircleOutlined, CloseCircleTwoTone } from "@ant-design/icons";
import { Modal, Select, Tooltip, notification } from "antd";
import { RequestState } from "../../../api/redux/reducer/RequestState";

import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchAssets,
  deleteAsset,
  killAsset,
} from "../../../api/redux/action/AssetActions";
import { fetchAssetGroups } from "../../../api/redux/action/AssetGroupActions";
import { fetchAssetLocation } from "../../../api/redux/action/AssetLocationActions";
import AssetActionView from "../../../components/common/ActionMenu";
import useNotification from "../../../components/common/ResultNotifier";
import { hasPermission, hasSubscription } from "../../layout/Constants";
import { exportRequest } from "../../../api/network/axios/exportRequest";
import { useHistory } from "react-router-dom";
import {
  removeBanner,
  showBanner,
} from "../../../api/redux/action/BannerActions";
import {
  bulkAssetUpload,
  emptyUploadState,
} from "../../../api/redux/action/AssetUploadActions";
const { Option } = Select;

function Assets() {
  const dispatch = useDispatch();
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [searchText, setSearchText] = useState();
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [uploadedAssetName, setUploadedAssetName] = useState("");
  const [dataTable, setDataTable] = useState();

  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [exportAssetGroup, setExportAssetGroup] = useState("");

  const [bulkLoading, setBulkLoading] = useState(false);
  const [killingAsset, setKillingAsset] = useState(false);
  const [bulkUploadReady, setBulkUploadReady] = useState(false);

  let history = useHistory();

  const {
    assets,
    error,
    requestStatus,
    loading,
    user,
    assetGroups,
    assetKilledActionComplete,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    assets: store.assetReducer.assets,
    error: store.assetReducer.error,
    requestStatus: store.assetReducer.requestStatus,
    loading: store.assetReducer.loading,
    user: store.authReducer.user,
    assetGroups: store.assetGroupReducer.assetGroups,
    assetKilledActionComplete: store.assetReducer.assetKilledActionComplete,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));
  const { requestStatusUpload, uploadedAsset, loadingUploadedAsset } =
    useSelector((store) => ({
      requestStatusUpload: store.assetUploadReducer.requestStatus,
      uploadedAsset: store.assetUploadReducer.savedAsset,
      loadingUploadedAsset: store.assetUploadReducer.loading,
    }));

  useEffect(() => {
    let response = uploadedAsset;
    setBulkLoading(loadingUploadedAsset);
    if (requestStatusUpload === RequestState.SUCCESS) {
      setBulkLoading(false);
      setBulkUploadReady(!bulkUploadReady);
      dispatch(removeBanner());

      let existingEpcCode = response.existingEpcCode;
      let existingAssetCode = response.existingAssetCode;

      let existingEpcCodeAssets = [];
      let existingAssetCodeAssets = [];
      let existingEpcCodeString = "";
      let existingAssetCodeString = "";

      if (existingEpcCode && existingEpcCode.length > 0) {
        existingEpcCode.map((a) => {
          existingEpcCodeAssets.push(a.epcCode);
        });
        existingEpcCodeString =
          language?.ASSETS_WITH_EPC_CODE?.value ??
          englishLang?.ASSETS_WITH_EPC_CODE?.value +
            existingEpcCodeAssets.toString() +
            language?.NOT_UPLOADED_DUE_TO_ALREADY_EXISTING_EPC_CODE?.value ??
          englishLang?.NOT_UPLOADED_DUE_TO_ALREADY_EXISTING_EPC_CODE?.value;
      }
      if (existingAssetCode && existingAssetCode.length > 0) {
        existingAssetCode.map((a) => {
          existingAssetCodeAssets.push(a.assetCode);
        });
        existingAssetCodeString =
          language?.ASSETS_WITH_ASSET_CODE?.value ??
          englishLang?.ASSETS_WITH_ASSET_CODE?.value +
            existingAssetCodeAssets.toString() +
            language?.NOT_UPLOADED_DUE_TO_ALREADY_EXISTING_ASSET_CODE?.value ??
          englishLang?.NOT_UPLOADED_DUE_TO_ALREADY_EXISTING_ASSET_CODE?.value;
      }

      if (
        existingEpcCodeAssets.length > 0 ||
        existingAssetCodeAssets.length > 0
      ) {
        notification.warning({
          key: "updatable",
          message:
            language?.SUCCESS_WITH_WARNING?.value ??
            englishLang?.SUCCESS_WITH_WARNING?.value,
          description:
            `${uploadedAssetName} ${
              language?.FILE_UPLOAD_SUCCESS?.value ??
              englishLang?.FILE_UPLOAD_SUCCESS?.value
            }` +
              existingEpcCodeString +
              existingAssetCodeString || "",
          duration: 15,
        });
      } else {
        notification.success({
          key: "updatable",
          message: language?.SUCCESS?.value ?? englishLang?.SUCCESS?.value,
          description:
            `${uploadedAssetName} ${
              language?.FILE_UPLOAD_SUCCESS?.value ??
              englishLang?.FILE_UPLOAD_SUCCESS?.value
            }` || "",
          duration: 8,
        });
      }
      dispatch(emptyUploadState());
    } else if (requestStatusUpload === RequestState.FAILURE) {
      setBulkLoading(false);
      setBulkUploadReady(!bulkUploadReady);
      dispatch(removeBanner());
      notification.error({
        key: "updatable",
        message: language?.FAILURE?.value ?? englishLang?.FAILURE?.value,
        description:
          `${uploadedAssetName} ${
            language?.FILE_UPLOAD_FAILED?.value ??
            englishLang?.FILE_UPLOAD_FAILED?.value
          }` || "",
        icon: <CloseCircleTwoTone twoToneColor="#FF0000" />,
        duration: 8,
      });
      dispatch(emptyUploadState());
    }
  }, [requestStatusUpload, uploadedAsset, loadingUploadedAsset]);

  function convertArrayToCSV(array) {
    if (array?.length > 0) {
      return array.join(",");
    }
    return array;
  }

  const [hasAssetLocationSubscription, setHasAssetLocationSubscription] =
    useState(hasSubscription(selectedTenant, "ASSET_LOCATIONS_SUBSCRIPTION"));

  const fixedColumns = [
    {
      title: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      dataIndex: "code",
      key: "code",
      sorter: true,
      sortField: "code",
      sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order,
      // render: (code) => (
      //   <a
      //     onClick={() => {
      //       const viewOption = "disabled";

      //       history.push({
      //         pathname: "/update-asset",
      //         state: { selectedRow, viewOption },
      //       });
      //     }}
      //   >
      //     {code}
      //   </a>
      // )
    },
    {
      title:
        language?.EXTERNAL_REFERENCE_NO?.value ??
        englishLang?.EXTERNAL_REFERENCE_NO?.value,
      dataIndex: "assetExternalReference",
      key: "assetExternalReference",
      sorter: true,
      sortField: "assetExternalReference",
      sortOrder:
        sortedInfo.columnKey === "assetExternalReference" && sortedInfo.order,
    },
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortField: "name",
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
    },
    {
      title: language?.ASSET_GROUP?.value ?? englishLang?.ASSET_GROUP?.value,
      dataIndex: ["assetGroup", "name"],
      key: "assetGroup",
      sorter: true,
      sortField: "assetGroupEntity.name",
      sortOrder:
        sortedInfo.columnKey === "assetGroupEntity.name" && sortedInfo.order,
    },
    ...(hasAssetLocationSubscription
      ? [
          {
            title:
              language?.ASSET_LOCATION?.value ??
              englishLang?.ASSET_LOCATION?.value,
            dataIndex: ["assetLocation", "name"],
            key: "assetLocation",
            sortField: "assetLocationEntity.name",
            sorter: true,
            sortOrder:
              sortedInfo.columnKey === "assetLocationEntity.name" &&
              sortedInfo.order,
          },
        ]
      : []),
    {
      title: language?.EPC_CODES?.value ?? englishLang?.EPC_CODES?.value,
      dataIndex: "assetCodes",
      key: "assetCodes",
      render: (assetCodes) => (
        <span>
          {assetCodes
            ? assetCodes.map((ac) => <li key={ac.epcCode}>{ac.epcCode}</li>)
            : ""}
        </span>
      ),
    },
  ];

  const [columns, setColumns] = useState(fixedColumns);
  const [customFieldsHeaders, setCustomFieldsHeaders] = useState([]);

  useEffect(() => {
    let newColumns = [];

    const distinctCustomFields = [];

    assets.content?.map((a) => {
      if (a.customFields) {
        a.customFields.map((customField) => {
          const exists = distinctCustomFields.some(
            (obj) => obj.customFieldId === customField.customFieldId
          );
          if (!exists) {
            distinctCustomFields.push(customField);
          }
        });
      }
    });

    distinctCustomFields?.map((item) => {
      let newObject = {};

      newObject = item?.name
        ? {
            title: item?.name,
            dataIndex: "customFields",
            key: item?.name,
            render: (customFields) => {
              const customField = customFields?.find(
                (field) => field?.customFieldId === item?.customFieldId
              );

              if (customField) {
                const value = customField.value
                  ? customField.value
                  : convertArrayToCSV(customField.values);
                return <span key={value}>{value}</span>;
              }
              return null;
            },
          }
        : {};

      newColumns.push(newObject);
    });

    setColumns([...fixedColumns, ...newColumns]);
  }, [assets]);

  const [allowPermission, setAllowPermission] = useState({
    edit: hasPermission(selectedTenant, "UPDATE_ASSETS"),
    delete: hasPermission(selectedTenant, "DELETE_ASSETS"),
    kill: hasPermission(selectedTenant, "KILL_ASSETS"),
    separetor: true,
  });

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const handleSingleDelete = (selected) => {
    dispatch(deleteAsset(selected.assetId));
  };

  const handleKillAsset = (selected) => {
    setKillingAsset(true);

    const data = [
      {
        op: "KILL",
        path: "/assets/" + selected.assetId,
        value: "NOW",
      },
    ];

    dispatch(killAsset(data, selected.assetId));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setSearchText({
      ...searchText,
      [dataIndex]: undefined,
    });
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    selectedRow,
    sortOrder,
    sortField,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "asset",
    dataSource: assets,
    updateEntityPath: "update-asset",
    customActionView:
      allowPermission.edit || allowPermission.delete || allowPermission.kill
        ? AssetActionView
        : "",
    searchText,
    loading: loading,
    handleSingleDelete,
    handleKillAsset,
    allowPermission,
    setSortedInfo,
    title: language?.ASSETS?.value ?? englishLang?.ASSETS?.value,
  });

  // useEffect(() => {
  //   dispatch(fetchAssetGroups());
  //   if (hasSubscription(user, "ASSET_LOCATIONS_SUBSCRIPTION") === true) {
  //     dispatch(fetchAssetLocation());
  //   }
  // }, []);

  useEffect(() => {
    if (assetKilledActionComplete === true) {
      setKillingAsset(false);
    }
  }, [assetKilledActionComplete]);

  useEffect(() => {
    dispatch(
      fetchAssets({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [
    currentPage,
    isLoading,
    searchText,
    sortedInfo,
    bulkUploadReady,
    killingAsset,
  ]);

  useEffect(() => {
    setDataTable(DataTable);
  }, [loading, selectedRow, language]);

  const handleExportAllButtonClick = () => {
    // showModal();
    handleDownload();
  };

  const handleDownload = () => {
    setExportAllLoading(true);
    const exportName = "export-assets";
    let options = {
      url: "/api/web/assets/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        searchText: searchText,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  function showBannerHandler() {
    let bannerProp = {
      message:
        language?.ASSET_BULK_IN_PROGRESS?.value ??
        englishLang?.ASSET_BULK_IN_PROGRESS?.value,
      closable: true,
      type: "info",
      showIcon: "showIcon",
    };

    dispatch(showBanner(bannerProp));
  }
  const handleCustomRequest = async ({ file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      await dispatch(bulkAssetUpload(formData));
      dispatch(removeBanner());
    } catch (error) {
      onError(error);
    }
  };

  const bulkUploadProps = {
    name: "file",
    type: ".csv",
    multiple: false,
    customRequest: handleCustomRequest,
    showUploadList: false,
    beforeUpload: (file) => {
      if (file.type !== "text/csv") {
        notification.error({
          key: "Wrong_File",
          message: language?.FAILURE?.value ?? englishLang?.FAILURE?.value,
          description:
            `${file.name} ${
              language?.FILE_UPLOAD_FAILED?.value ??
              englishLang?.FILE_UPLOAD_FAILED?.value
            }` || "",
          icon: <CloseCircleTwoTone twoToneColor="#FF0000" />,
          duration: 8,
        });
        setBulkLoading(false);
        setBulkUploadReady(true);
        return false;
      }
      setUploadedAssetName(file.name);
      showBannerHandler();
      return true;
    },
  };

  const handleExportAssetGroupChange = (value) => {
    setExportAssetGroup(value);
  };

  let flattenedData = [];
  let mergedHeaders = [];

  const addNewPath = hasPermission(selectedTenant, "CREATE_ASSETS")
    ? "add-asset"
    : "";

  const bulkUploadTooltip = (
    <>
      {language?.CSV_FILES_ALLOWED_IMPORTED_DOWNLOAD_SIMPLE_FILE?.value ??
        englishLang?.CSV_FILES_ALLOWED_IMPORTED_DOWNLOAD_SIMPLE_FILE?.value}
      <a
        href="/asset_sample_new_column_order.csv"
        download="asset_sample.csv"
        target="_blank"
      >
        {language?.DOWNLOAD?.value ?? englishLang?.DOWNLOAD?.value}
      </a>
    </>
  );

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath={addNewPath}
          hasSelected={hasSelected}
          exportTableTitle={
            language?.ASSETS?.value ?? englishLang?.ASSETS?.value
          }
          addNewText={
            language?.ADD_ASSET?.value ?? englishLang?.ADD_ASSET?.value
          }
          title={language?.ASSETS?.value ?? englishLang?.ASSETS?.value}
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={mergedHeaders}
          exportAllButtonClick={handleExportAllButtonClick}
          loading={loading}
          exportButton={true}
          exportAllButton={true}
          exportAllLoading={exportAllLoading}
          bulkLoading={bulkLoading}
          handleSearch={setSearchText}
          bulkUploadBtn={true}
          bulkUploadProps={bulkUploadProps}
          bulkUploadBtnText={
            language?.BULK_UPLOAD_ASSETS?.value ??
            englishLang?.BULK_UPLOAD_ASSETS?.value
          }
          bulkUploadTooltipText={bulkUploadTooltip}
          tooltipText={
            language?.TOOLTIP_SEARCH_ALL?.value ??
            englishLang?.TOOLTIP_SEARCH_ALL?.value
          }
        />
        {dataTable}
        <ResultNotifier />
      </div>
    </>
  );
}

export default Assets;
