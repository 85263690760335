import { combineReducers } from "redux";
import assetGroupReducer from "./assetGroupReducer";
import assetReducer from "./assetReducer";
import alertRuleReducer from "./alertRuleReducer";
import departmentReducer from "./departmentReducer";
import customFieldReducer from "./customFieldReducer";
import antennaReducer from "./antennaReducer";
import fixedReaderReducer from "./fixedReaderReducer";
import mobileReaderReducer from "./mobileReaderReducer";
import gatewayReducer from "./gatewayReducer";
import ipRelayCardReducer from "./ipRelayCardReducer";
import relaySwitchReducer from "./relaySwitchReducer";
import siteReducer from "./siteReducer";
import zoneReducer from "./zoneReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import contactReducer from "./contactReducer";
import dashboardReducer from "./dashboardReducer";
import userGroupReducer from "./userGroupReducer";
import roleReducer from "./roleReducer";
import permissionReducer from "./permissionReducer";
import langReducer from "./langReducer";
import bannerReducer from "./bannerReducer";
import assetLocationReducer from "./assetLocationReducer";
import reportReducer from "./reportReducer";
import floorPlanReducer from "./floorPlanReducer";
import assetUploadReducer from "./assetUploadReducer";
export default combineReducers({
  alertRuleReducer,
  antennaReducer,
  assetGroupReducer,
  assetReducer,
  departmentReducer,
  fixedReaderReducer,
  mobileReaderReducer,
  gatewayReducer,
  ipRelayCardReducer,
  relaySwitchReducer,
  siteReducer,
  zoneReducer,
  reportReducer,
  authReducer,
  userReducer,
  contactReducer,
  dashboardReducer,
  userGroupReducer,
  roleReducer,
  permissionReducer,
  customFieldReducer,
  langReducer,
  bannerReducer,
  assetLocationReducer,
  floorPlanReducer,
  assetUploadReducer,
});
