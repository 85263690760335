import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchIpRelayCards,
  deleteIpRelayCard,
  fetchAllIpRelayCards,
} from "../../../api/redux/action/IpRelayCardActions";
import IpRelayCardActionView from "../../../components/common/ActionMenu";
import useNotification from "../../../components/common/ResultNotifier";
import { hasPermission } from "../../layout/Constants";
import { exportRequest } from "../../../api/network/axios/exportRequest";

function IpRelayCards() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState();
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  const {
    ipRelayCards,
    allIpRelayCards,
    error,
    requestStatus,
    loading,
    user,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    ipRelayCards: store.ipRelayCardReducer.ipRelayCards,
    allIpRelayCards: store.ipRelayCardReducer.allIpRelayCards,
    error: store.ipRelayCardReducer.error,
    requestStatus: store.ipRelayCardReducer.requestStatus,
    loading: store.ipRelayCardReducer.loading,
    user: store.authReducer.user,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const columns = [
    {
      title: language?.GATEWAY?.value ?? englishLang?.GATEWAY?.value,
      dataIndex: ["gateway", "name"],
      sorter: true,
      sortField: "gatewayEntity.name",
      sortOrder:
        sortedInfo.columnKey === "gatewayEntity.name" && sortedInfo.order,
    },
    {
      title:
        language?.GATEWAY_DESCRIPTION?.value ??
        englishLang?.GATEWAY_DESCRIPTION?.value,
      dataIndex: ["gateway", "description"],
      sorter: true,
      sortField: "gatewayEntity.description",
      sortOrder:
        sortedInfo.columnKey === "gatewayEntity.description" &&
        sortedInfo.order,
    },
    {
      title: language?.IP_ADDRESS?.value ?? englishLang?.IP_ADDRESS?.value,
      dataIndex: "ipAddress",
      sorter: true,
      sortField: "ipAddress",
      sortOrder: sortedInfo.columnKey === "ipAddress" && sortedInfo.order,
    },
    {
      title: language?.PORT_NUMBER?.value ?? englishLang?.PORT_NUMBER?.value,
      dataIndex: "port",
      sorter: true,
      sortField: "port",
      sortOrder: sortedInfo.columnKey === "port" && sortedInfo.order,
    },
  ];

  const [allowPermission, setAllowPermission] = useState({
    edit: hasPermission(selectedTenant, "UPDATE_IP_RELAY_CARDS"),
    delete: hasPermission(selectedTenant, "DELETE_IP_RELAY_CARDS"),
  });

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const handleSingleDelete = (selected) => {
    dispatch(deleteIpRelayCard(selected.ipRelayCardId));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const {
    DataTable,
    hasSelected,
    selectedRow,
    currentPage,
    pageSize,
    sortField,
    sortOrder,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "ipRelayCard",
    dataSource: ipRelayCards,
    updateEntityPath: "update-ipRelayCard",
    customActionView:
      allowPermission.edit || allowPermission.delete
        ? IpRelayCardActionView
        : "",
    searchText,
    loading: loading,
    handleSingleDelete,
    allowPermission,
    setSortedInfo,
    title:
      language?.IP_RELAY_CARDS?.value ?? englishLang?.IP_RELAY_CARDS?.value,
  });

  useEffect(() => {
    dispatch(
      fetchIpRelayCards({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-ip-relay-cards";
    let options = {
      url: "/api/web/ip-relay-cards/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        searchText: searchText,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  let flattenedData = [];

  if (Object.keys(ipRelayCards).length !== 0) {
    flattenedData = Object.values(ipRelayCards.content);
  }

  useEffect(() => {
    if (allIpRelayCards && Object.keys(allIpRelayCards).length !== 0) {
      setFlattenedAllData(Object.values(allIpRelayCards.content));
    }
  }, [allIpRelayCards]);

  const headers = [
    {
      label: language?.GATEWAY?.value ?? englishLang?.GATEWAY?.value,
      key: "gateway.name",
    },
    {
      label:
        language?.GATEWAY_DESCRIPTION?.value ??
        englishLang?.GATEWAY_DESCRIPTION?.value,
      key: "gateway.description",
    },
    {
      label: language?.IP_ADDRESS?.value ?? englishLang?.IP_ADDRESS?.value,
      key: "ipAddress",
    },
    {
      label: language?.PORT_NUMBER?.value ?? englishLang?.PORT_NUMBER?.value,
      key: "port",
    },
  ];

  const addNewPath = hasPermission(selectedTenant, "CREATE_IP_RELAY_CARDS")
    ? "add-ipRelayCard"
    : "";

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath={addNewPath}
          hasSelected={hasSelected}
          exportTableTitle={
            language?.IPRELAYCARDS?.value ?? englishLang?.IPRELAYCARDS?.value
          }
          addNewText={
            language?.ADD_IP_RELAY_CARD?.value ??
            englishLang?.ADD_IP_RELAY_CARD?.value
          }
          title={
            language?.IP_RELAY_CARDS?.value ??
            englishLang?.IP_RELAY_CARDS?.value
          }
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllButtonClick={handleExportAllButtonClick}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportButton={true}
          exportAllButton={true}
          handleSearch={setSearchText}
          tooltipText={
            language?.TOOLTIP_SEARCH_ALL?.value ??
            englishLang?.TOOLTIP_SEARCH_ALL?.value
          }
        />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default IpRelayCards;
