import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "antd";
import { hasPermission } from "../../layout/Constants";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import { saveAssetLocation } from "../../../api/redux/action/AssetLocationActions";
import { validateInput } from "../../../components/RequiredFieldRule";

import useNotification from "../../../components/common/ResultNotifier";
import AddAssetLocationForm from "./AddAssetLocationForm";
import AddFooterSection from "../../../components/common/AddFooterSection";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";

const AddAssetLocation = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let history = useHistory();
  const [prompt, setDirty, setPristine] = UnsavedChanges();

  const { user, error, requestStatus, language, englishLang, selectedTenant } =
    useSelector((store) => ({
      error: store.assetLocationReducer.error,
      requestStatus: store.assetLocationReducer.requestStatus,
      user: store.authReducer.user,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
      selectedTenant: store.authReducer.selectedTenant,
    }));
  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;
  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      form.setFieldsValue(props.location.state.selectedRow);
    }
  }, []);

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      return history.push("/assetLocation");
    }
  }, [requestStatus]);

  const handleSave = () => {
    const formData = { name: validateInput(form.getFieldValue().name) };

    dispatch(saveAssetLocation(formData, form.getFieldValue().assetLocationId));
  };

  const handleCancel = () => {
    history.push("/assetLocation");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_ASSET_LOCATIONS");
  }

  const editBtn = handleEditingPermission() ? (
    <Button
      type="primary"
      onClick={() => {
        history.push("/update-assetLocation");
      }}
      id={ElementIDS().edit}
    >
      {language?.EDIT?.value ?? englishLang?.EDIT?.value}
    </Button>
  ) : null;

  const footer = (
    <AddFooterSection
      language={language}
      viewOption={props.location.state}
      handleCancel={handleCancel}
      handleEditingPermission={handleEditingPermission}
      editBtn={editBtn}
      form={form}
    />
  );

  return (
    <Card
      title={
        language?.ASSET_LOCATION?.value ?? englishLang?.ASSET_LOCATION?.value
      }
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddAssetLocationForm
            handleSave={handleSave}
            form={form}
            footer={footer}
            setDirty={setDirty}
            readOnly={props.location.state}
            language={language}
          />
        </Col>
      </Row>
      <ResultNotifier />
    </Card>
  );
};

export default AddAssetLocation;
