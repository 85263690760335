import * as type from "../types";
import {
  getSitesService,
  saveSiteService,
  deleteSiteService,
  getAllSitesService,
} from "../../network/service/SiteService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchSites = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.SITES_FETCH_REQUEST,
      });
      const response = await dispatch(getSitesService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.SITES_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.SITES_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.SITES_FETCH_FAILURE,
      });
    }
  };
};

export const fetchAllSites = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_SITES_FETCH_REQUEST,
      });
      const response = await dispatch(getAllSitesService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_SITES_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_SITES_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_SITES_FETCH_FAILURE,
      });
    }
  };
};

export const saveSite = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.SITE_SAVE_REQUEST,
      });
      const response = await dispatch(saveSiteService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.SITE_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.SITE_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.SITE_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteSite = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.SITE_DELETE_REQUEST,
      });
      const response = await dispatch(deleteSiteService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.SITE_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.SITE_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.SITE_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
