import React, { useRef, useEffect, useState } from "react";
import { Row, Card, Col, Select, Space, Tooltip, Switch, Skeleton } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";

import CustomPieChart from "./CustomPieChart";
import CustomBarChart from "./CustomBarChart";
import AssetQuantity from "./AssetQuantity";
import { GET_ASSET_GROUPS } from "../../../../api/graphQL/queries";

const { Option } = Select;

const colResponsiveProps = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
  xl: 12,
  style: { padding: 10 },
};

const CurrentAssetDetection = (props) => {
  const [barChartParentWidth, setBarChartParentWidth] = useState(0);
  const elementRef = useRef(null);

  const [selectedAssetGroup, setSelectedAssetGroup] = useState("All");
  const [selectedPieChart, setSelectedPieChart] = useState(false);

  useEffect(() => {
    if (elementRef.current) {
      setBarChartParentWidth(elementRef.current.offsetWidth);
    }
    function handleResize() {
      if (elementRef.current) {
        setBarChartParentWidth(elementRef.current.offsetWidth);
      }
    }
    window.addEventListener("resize", handleResize);
  }, []);

  const { loading, error, data } = useQuery(GET_ASSET_GROUPS, {
    fetchPolicy: "network-only",
    pollInterval: 60000,
  });

  const { totals, apiCallDone, englishLang } = useSelector((store) => ({
    totals: store.dashboardReducer.totals,
    apiCallDone: store.reportReducer.apiCallDone,
    englishLang: store.langReducer.englishLang,
  }));

  const [setupSelect, setSetupSelect] = useState(
    props.language?.FIXED?.value ?? englishLang?.FIXED?.value
  );

  const handleAssetGroupSelect = (value) => {
    setSelectedAssetGroup(value);
  };

  let languageFixed = props.language?.FIXED?.value ?? englishLang?.FIXED?.value;
  let languageMobile =
    props.language?.MOBILE?.value ?? englishLang?.MOBILE?.value;

  const handleSetupSelect = (event) => {
    if (event.data) {
      if (
        event.data.data.detectionType === languageFixed ||
        event.data.data.detectionType === languageMobile
      ) {
        setSetupSelect(event.data.data.detectionType);
      }
    }
  };

  const onChange = (checked) => {
    setSelectedPieChart(checked);
  };

  const tooltip = (
    <Tooltip
      title={
        <>
          <strong>
            {props.language?.GENERAL?.value ?? englishLang?.GENERAL?.value}
          </strong>
          <p>
            {props.language?.GENERAL_INFO?.value ??
              englishLang?.GENERAL_INFO?.value}
          </p>
          <strong>
            {props.language?.PIE_CHART?.value ?? englishLang?.PIE_CHART?.value}
          </strong>
          <p>
            {props.language?.PIE_CHART_INFO?.value ??
              englishLang?.PIE_CHART_INFO?.value}
          </p>
          <strong>
            {props.language?.BAR_CHART?.value ?? englishLang?.BAR_CHART?.value}
          </strong>
          <p>
            {props.language?.BAR_CHART_INFO?.value ??
              englishLang?.BAR_CHART_INFO?.value}
          </p>
          <strong>
            {props.language?.CURRENTLY_DETECTED_ASSETS_MAP?.value ??
              englishLang?.CURRENTLY_DETECTED_ASSETS_MAP?.value}
          </strong>
          <p>
            {props.language?.CURRENTLY_DETECTED_ASSETS_MAP_INFO?.value ??
              englishLang?.CURRENTLY_DETECTED_ASSETS_MAP_INFO?.value}
          </p>
        </>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );

  return (
    <Card
      title={
        props.language?.CURRENTLY_DETECTED_ASSETS?.value ??
        englishLang?.CURRENTLY_DETECTED_ASSETS?.value
      }
      extra={
        <Space>
          <Select
            placeholder={
              props.language?.SELECT_ASSEST_GROUP?.value ??
              englishLang?.SELECT_ASSEST_GROUP?.value
            }
            onSelect={handleAssetGroupSelect}
            defaultValue="All"
            id="rfid-dashboard-currentAssetDetection-assetsDropdown"
          >
            <Option key={0} value={"All"}>
              {props.language?.ALL_ASSETS_GROUP?.value ??
                englishLang?.ALL_ASSETS_GROUP?.value}
            </Option>

            {data && data.assetGroups
              ? data.assetGroups.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))
              : undefined}
          </Select>
          {tooltip}
        </Space>
      }
    >
      <Row>
        <Col {...colResponsiveProps}>
          <Col
            offset={18}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "25%",
            }}
          >
            <p style={{ marginRight: 5, fontSize: 16, marginBottom: 0 }}>
              {props.language?.SHOW_UNDETECTED?.value ??
                englishLang?.SHOW_UNDETECTED?.value}
            </p>
            <Switch
              onChange={onChange}
              id="rfid-dashboard-customPieChart-switchBtn"
            />
          </Col>
          <Row
            id="rfid-dashboard-customPieChart"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <CustomPieChart
              selectedAssetGroup={
                selectedAssetGroup === "All" ? null : selectedAssetGroup
              }
              handleSetupSelect={handleSetupSelect}
              selectedPieChart={selectedPieChart}
            />
          </Row>
          <Row
            id="rfid-dashboard-customBarChart"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            ref={elementRef}
          >
            <CustomBarChart
              selectedAssetGroup={
                selectedAssetGroup === "All" ? null : selectedAssetGroup
              }
              setupSelect={setupSelect}
              width={barChartParentWidth}
            />
          </Row>
        </Col>
        <Col {...colResponsiveProps}>
          <AssetQuantity
            selectedAssetGroup={
              selectedAssetGroup === "All" ? null : selectedAssetGroup
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default CurrentAssetDetection;
