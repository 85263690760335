import React, { useEffect } from "react";
import { Row, Col, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  GatewayOutlined,
  BellOutlined,
  TagOutlined,
  TagsOutlined,
  EnvironmentOutlined,
  ClusterOutlined,
  GoldOutlined,
  CarOutlined,
} from "@ant-design/icons";
import { ElementIDS } from "../../components/common/ElementsIDs";

import { fetchTotals } from "../../api/redux/action/DashboardActions";

import "./Style.css";
import CurrentAssetDetection from "./widgets/currentAssetDetection/CurrentAssetDetection";
import LastSeenAnalysis from "./widgets/lastSeenAnalysis/LastSeenAnalysis";
import LastSeenSince from "./widgets/lastSeenSince/LastSeenSince";
import LatestAlerts from "./widgets/latestAlerts/LatestAlerts";
import TotalWidget from "./widgets/totals/TotalWidget";
import MergedTotalWidget from "./widgets/totals/MergedTotalWidget";

const topColResponsiveProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 6,
  style: { marginBottom: 8 },
};

const mapsColResponsiveProps = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 12,
  style: { marginBottom: 8 },
};

function Dashboard() {
  const dispatch = useDispatch();

  const { totals, language, englishLang } = useSelector((store) => ({
    totals: store.dashboardReducer.totals,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  useEffect(() => {
    dispatch(fetchTotals());
  }, []);

  const totalMobileReaders =
    totals && totals.mobileReaders && totals.mobileReaders.total > 0 ? (
      <Col {...topColResponsiveProps}>
        <TotalWidget
          id={ElementIDS().mobileReaders}
          title={
            language?.MOBILE_READERS?.value ??
            englishLang?.MOBILE_READERS?.value
          }
          total={
            totals && totals.mobileReaders ? totals.mobileReaders.total : null
          }
          up={
            totals && totals.mobileReaders && totals.mobileReaders.up
              ? totals.mobileReaders.up.total
              : 0
          }
          down={
            totals && totals.mobileReaders && totals.mobileReaders.down
              ? totals.mobileReaders.down.total
              : 0
          }
          unknown={
            totals && totals.mobileReaders && totals.mobileReaders.unknown
              ? totals.mobileReaders.unknown.total
              : 0
          }
          clickPath={"mobileReaders"}
          toolTipTitleDown={
            totals && totals.mobileReaders && totals.mobileReaders.down
          }
          toolTipTitleUnknown={
            totals && totals.mobileReaders && totals.mobileReaders.unknown
          }
          icon={<CarOutlined />}
        />
      </Col>
    ) : null;

  const totalAlertRules =
    totals && totals.alertRules && totals.alertRules.total > 0 ? (
      <Col {...topColResponsiveProps}>
        <TotalWidget
          id={ElementIDS().alertRules}
          title={
            language?.ALERT_RULES?.value ?? englishLang?.ALERT_RULES?.value
          }
          total={totals ? totals.alertRules.total : null}
          clickPath={"alertRules"}
          icon={<BellOutlined />}
        />
      </Col>
    ) : null;

  const alertRulesWidget =
    totals && totals.alertRules && totals.alertRules.total > 0 ? (
      <Row gutter={8} type="flex">
        <Col span={24}>
          <LatestAlerts clickPath={"alertLog"} />
        </Col>
      </Row>
    ) : null;

  return (
    <div style={{ padding: 0 }}>
      <Row gutter={8} type="flex">
        <Col {...topColResponsiveProps}>
          <TotalWidget
            id={ElementIDS().assetId}
            title={language?.ASSETS?.value ?? englishLang?.ASSETS?.value}
            total={totals ? totals.assets.total : null}
            clickPath={"assets"}
            icon={<TagOutlined />}
          />
        </Col>

        <Col {...topColResponsiveProps}>
          <TotalWidget
            id={ElementIDS().assetGroupId}
            title={
              language?.ASSET_GROUPS?.value ?? englishLang?.ASSET_GROUPS?.value
            }
            total={totals ? totals.assetGroups.total : null}
            clickPath={"assetGroups"}
            icon={<TagsOutlined />}
          />
        </Col>
        <Col {...topColResponsiveProps}>
          <TotalWidget
            id={ElementIDS().siteId}
            title={language?.SITES?.value ?? englishLang?.SITES?.value}
            total={totals ? totals.sites.total : null}
            clickPath={"sites"}
            icon={<GatewayOutlined />}
          />
        </Col>
        <Col {...topColResponsiveProps}>
          <TotalWidget
            id={ElementIDS().zonesId}
            title={language?.ZONES?.value ?? englishLang?.ZONES?.value}
            total={totals ? totals.zones.total : null}
            clickPath={"zones"}
            icon={<EnvironmentOutlined />}
          />
        </Col>

        <Col {...topColResponsiveProps}>
          <TotalWidget
            id={ElementIDS().gateWaysId}
            title={language?.GATEWATS?.value ?? englishLang?.GATEWATS?.value}
            total={totals && totals.gateways ? totals.gateways.total : null}
            up={
              totals && totals.gateways && totals.gateways.up
                ? totals.gateways.up.total
                : 0
            }
            down={
              totals && totals.gateways && totals.gateways.down
                ? totals.gateways.down.total
                : 0
            }
            unknown={
              totals && totals.gateways && totals.gateways.unknown
                ? totals.gateways.unknown.total
                : 0
            }
            clickPath={"gateways"}
            icon={<ClusterOutlined />}
            toolTipTitleDown={totals && totals.gateways && totals.gateways.down}
            toolTipTitleUnknown={
              totals && totals.gateways && totals.gateways.unknown
            }
          />
        </Col>
        <Col {...topColResponsiveProps}>
          <TotalWidget
            id={ElementIDS().fixedReaders}
            title={
              language?.FIXED_READERS?.value ??
              englishLang?.FIXED_READERS?.value
            }
            total={
              totals && totals.fixedReaders ? totals.fixedReaders.total : null
            }
            up={
              totals && totals.fixedReaders && totals.fixedReaders.up
                ? totals.fixedReaders.up.total
                : 0
            }
            down={
              totals && totals.fixedReaders && totals.fixedReaders.down
                ? totals.fixedReaders.down.total
                : 0
            }
            unknown={
              totals && totals.fixedReaders && totals.fixedReaders.unknown
                ? totals.fixedReaders.unknown.total
                : 0
            }
            clickPath={"fixedReaders"}
            toolTipTitleDown={
              totals && totals.fixedReaders && totals.fixedReaders.down
            }
            toolTipTitleUnknown={
              totals && totals.fixedReaders && totals.fixedReaders.unknown
            }
            icon={<GoldOutlined />}
          />
        </Col>

        {totalMobileReaders}
        {totalAlertRules}
      </Row>

      <Row gutter={8} type="flex" style={{ marginBottom: 8 }}>
        <Col span={24}>
          <CurrentAssetDetection language={language} />
        </Col>
      </Row>

      <Row gutter={8} type="flex">
        <Col {...mapsColResponsiveProps}>
          <LastSeenAnalysis language={language} />
        </Col>
        <Col {...mapsColResponsiveProps}>
          <LastSeenSince language={language} />
        </Col>
      </Row>

      {alertRulesWidget}
    </div>
  );
}

export default Dashboard;
