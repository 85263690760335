import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Divider,
  Select,
  Space,
  Row,
  DatePicker,
  Tooltip,
  Button,
} from "antd";
import { RedoOutlined } from "@ant-design/icons";
import moment from "moment-timezone";

import useDataTable from "../../../components/common/DataTable";
import {
  fetchReport,
  fetchAllReport,
  resetReport,
} from "../../../api/redux/action/ReportActions";
import useNotification from "../../../components/common/ResultNotifier";
import { fetchUsers } from "../../../api/redux/action/UserActions";
import Header from "../../../components/common/ReportHeader";
import { toTimestamp } from "../../layout/Constants";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import { exportRequest } from "../../../api/network/axios/exportRequest";

const dateFormat = "DD-MM-YYYY";

const { RangePicker } = DatePicker;
const { Option } = Select;

function AuditLog() {
  const today = new Date();
  const todayStr =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  const dispatch = useDispatch();

  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [missingFieldsText, setMissingFieldsText] = useState();
  const [refresh, setRefresh] = useState(false);
  const [dateValue, setDateValue] = useState(false);
  const [categoryValue, setCategoryValue] = useState(false);
  const [userValue, setUserValue] = useState(false);

  const [filter, setFilter] = useState({
    fromDate: todayStr,
    toDate: todayStr,
    category: "All",
    userId: "All",
  });

  const {
    user,
    data,
    allData,
    error,
    requestStatus,
    users,
    loading,
    userTimeZone,
    language,
    englishLang,
  } = useSelector((store) => ({
    user: store.authReducer.user,
    data: store.reportReducer.data,
    allData: store.reportReducer.allData,
    error: store.reportReducer.error,
    requestStatus: store.reportReducer.requestStatus,
    users: store.userReducer.users,
    loading: store.reportReducer.loading,
    userTimeZone: store.authReducer.userTimeZone,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const columns = [
    {
      title: language?.ACTION?.value ?? englishLang?.ACTION?.value,
      dataIndex: "action",
    },
    {
      title: language?.EVENT_DATA?.value ?? englishLang?.EVENT_DATA?.value,
      dataIndex: "data",
    },
    {
      title:
        language?.DATE_AND_TIME?.value ?? englishLang?.DATE_AND_TIME?.value,
      dataIndex: "timestamp",
      render: (timestamp) => (
        <span>
          {timestamp
            ? moment.tz(timestamp, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
    },
    {
      title: language?.USER?.value ?? englishLang?.USER?.value,
      dataIndex: "user",
    },
  ];

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    if (users && users.content) {
      let sorted = users.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedUsers(sorted);
    }
  }, [users]);

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    dataSource: data,
    updateEntityPath: "",
    filter,
    loading: loading,
    missingFieldsText: missingFieldsText,
  });

  const checkNoMissingParams = () => {
    if (
      !(filter.fromDate && filter.toDate && filter.category && filter.userId)
    ) {
      setMissingFieldsText(
        language?.SELECT_DATE_RANGE_ACTION_USER?.value ??
          englishLang?.SELECT_DATE_RANGE_ACTION_USER?.value
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    // dispatch(resetReport());
    if (checkNoMissingParams()) {
      setMissingFieldsText();
      dispatch(
        fetchReport({
          page: searchFormHelperHandler(),
          size: pageSize,
          fromDate: toTimestamp(filter.fromDate, "start"),
          toDate: toTimestamp(filter.toDate, "end"),
          ...(filter.category !== "All" && { category: filter.category }),
          ...(filter.userId !== "All" && { userId: filter.userId }),
          endpoint: "audit-log",
        })
      );
    }
  }, [currentPage, filter, isLoading, refresh, pageSize]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-audit-log";
    let options = {
      url: "/api/web/reports/audit-log/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
        ...(filter.category !== "All" && { category: filter.category }),
        ...(filter.userId !== "All" && { userId: filter.userId }),
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  const handleDateRangeChange = (date, dateString) => {
    setDateValue(date);
    setFilter({
      ...filter,
      fromDate: dateString[0],
      toDate: dateString[1],
    });
  };

  let flattenedData = [];

  const handleCategoryChange = (value) => {
    setCategoryValue(value);
    setFilter({
      ...filter,
      category: value,
    });
  };

  const handleUserChange = (value) => {
    setUserValue(value);
    setFilter({
      ...filter,
      userId: value,
    });
  };

  const handleRefreshClick = () => {
    setRefresh(!refresh);
  };

  if (Object.keys(data).length !== 0) {
    let flattenedData;
    flattenedData = Object.values(data.content);
    function flatDataHandler() {
      if (data.content === undefined) {
        return (flattenedData = Object.values(data));
      } else {
        return (flattenedData = Object.values(data.content));
      }
    }
    flatDataHandler();

    flattenedData.map((data) => {
      data.timestampFormatted = data.timestamp
        ? moment.tz(data.timestamp, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
        : "";
    });
  }

  useEffect(() => {
    if (allData && Object.keys(allData).length !== 0) {
      let flatData = Object.values(allData.content);

      flatData.map((data) => {
        data.timestampFormatted = data.timestamp
          ? moment
              .tz(data.timestamp, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedAllData(Object.values(flatData));
    }
  }, [allData]);

  const headers = [
    {
      label: language?.ACTION?.value ?? englishLang?.ACTION?.value,
      key: "action",
    },
    {
      label: language?.EVENT_DATA?.value ?? englishLang?.EVENT_DATA?.value,
      key: "data",
    },
    {
      label:
        language?.DATE_AND_TIME?.value ?? englishLang?.DATE_AND_TIME?.value,
      key: "timestampFormatted",
    },
    { label: language?.USER?.value ?? englishLang?.USER?.value, key: "user" },
  ];

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          hasSelected={hasSelected}
          exportTableTitle={
            language?.AUDITLOG?.value ?? englishLang?.AUDITLOG?.value
          }
          addNewText=""
          title={language?.AUDIT_LOG?.value ?? englishLang?.AUDIT_LOG?.value}
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportAllButtonClick={handleExportAllButtonClick}
        />

        <Row>
          <Col>
            <Space>
              <Tooltip
                title={
                  language?.SELECT_A_DATE_RANGE?.value ??
                  englishLang?.SELECT_A_DATE_RANGE?.value
                }
              >
                <RangePicker
                  id={ElementIDS().calendar}
                  defaultValue={[
                    moment(todayStr, dateFormat),
                    moment(todayStr, dateFormat),
                  ]}
                  format={dateFormat}
                  onChange={handleDateRangeChange}
                />
              </Tooltip>
              <Tooltip
                title={
                  language?.SELECT_A_CATEGORY?.value ??
                  englishLang?.SELECT_A_CATEGORY?.value
                }
              >
                <Select
                  id={ElementIDS().selectCategory}
                  defaultValue="All"
                  style={{ width: 120 }}
                  placeholder={
                    language?.SELECT_CATEGORY?.value ??
                    englishLang?.SELECT_CATEGORY?.value
                  }
                  onChange={handleCategoryChange}
                >
                  <Option id={ElementIDS().optionSelectAll} value="All">
                    {language?.ALL?.value ?? englishLang?.ALL?.value}
                  </Option>
                  <Option id={ElementIDS().optionSelectAdd} value="Add">
                    {language?.ADD?.value ?? englishLang?.ADD?.value}
                  </Option>
                  <Option id={ElementIDS().optionSelectEdit} value="Edit">
                    {language?.EDIT?.value ?? englishLang?.EDIT?.value}
                  </Option>
                  <Option id={ElementIDS().optionSelectDelete} value="Delete">
                    {language?.DELETE?.value ?? englishLang?.DELETE?.value}
                  </Option>
                  <Option id={ElementIDS().optionSelectLogin} value="Login">
                    {language?.LOGIN?.value ?? englishLang?.LOGIN?.value}
                  </Option>
                  <Option id={ElementIDS().optionSelectReport} value="Report">
                    {language?.REPORT?.value ?? englishLang?.REPORT?.value}
                  </Option>
                </Select>
              </Tooltip>
              <Tooltip
                title={
                  language?.SELECT_USER?.value ??
                  englishLang?.SELECT_USER?.value
                }
              >
                <Select
                  id={ElementIDS().selectUser}
                  defaultValue="All"
                  // showSearch
                  style={{ width: 200 }}
                  placeholder={
                    language?.SELECT_USER?.value ??
                    englishLang?.SELECT_USER?.value
                  }
                  optionFilterProp="children"
                  onChange={handleUserChange}
                  // onSearch={onSearch}
                  // filterOption={(input, option) =>
                  //     option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  // // option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  // }
                >
                  <Option id={ElementIDS().optionAll} key={"All"} value={"All"}>
                    {" "}
                    {language?.ALL?.value ?? englishLang?.ALL?.value}
                  </Option>
                  {sortedUsers
                    ? sortedUsers.map((item) => (
                        <Option
                          id={ElementIDS().optionUser}
                          key={item.userAccountId}
                          value={item.userAccountId}
                        >
                          {item.name} {item.surname}
                        </Option>
                      ))
                    : undefined}
                </Select>
              </Tooltip>
              <Button
                id={ElementIDS().refresh}
                type="primary"
                onClick={() => handleRefreshClick()}
              >
                {" "}
                <RedoOutlined />
                {language?.REFRESH?.value ?? englishLang?.REFRESH?.value}
              </Button>
            </Space>
          </Col>
        </Row>
        <Divider />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default AuditLog;
