import { request } from "../axios/request";
export const getFloorPlans = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/floor-plans", getState(), {
        method: "GET",
        params,
      })
    );
  };
};
