import * as type from "../types";
import Banner from "../../../components/common/Banner";

export const showBanner = (params) => {
  return (dispatch) => {
    return dispatch({
      type: type.SHOW_BANNER,
      payload: <Banner bannerProp={params} />,
    });
  };
};

export const removeBanner = (params) => {
  return (dispatch) => {
    return dispatch({
      type: type.REMOVE_BANNER,
    });
  };
};
