import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Affix, Layout, Menu } from "antd";
import "antd/dist/antd.css";
import {
  DashboardOutlined,
  TeamOutlined,
  TableOutlined,
  BellOutlined,
  GoldOutlined,
  CarOutlined,
  AppstoreAddOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "./Style.css";
import SiderLogo from "./SiderLogo";
import { useSelector } from "react-redux";
import {
  hasPermission,
  hasHandheld,
  hasFleetSubscription,
  hasSubscription,
} from "../layout/Constants";

const { SubMenu } = Menu;

const { Sider } = Layout;

function SiderMenu({ handleOnCollapse, collapsed, user }) {
  const [openKeys, setOpenKeys] = useState(["dashboard"]);
  const theme = "light";

  const history = useHistory();
  const location = useLocation();
  const { englishLang, selectedTenant, language } = useSelector((store) => ({
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
    language: store.langReducer.language,
  }));
  const retrieveSelectedKeyFromPath = () => {
    const pathName = location.pathname.split("/")[1];
    switch (pathName) {
      case "dashboard":
        return ["dashboard"];
      case "assetGroups":
        return ["assetGroups"];
      case "assets":
        return ["assets"];
      case "assetLocation":
        return ["assetLocation"];
      case "departments":
        return ["departments"];
      case "customFields":
        return ["customFields"];
      case "sites":
        return ["sites"];
      case "zones":
        return ["zones"];
      case "gateways":
        return ["gateways"];
      case "fixedReaders":
        return ["fixedReaders"];
      case "antennas":
        return ["antennas"];
      case "ipRelayCards":
        return ["ipRelayCards"];
      case "relaySwitches":
        return ["relaySwitches"];
      case "alertRules":
        return ["alertRules"];
      case "mobileReaders":
        return ["mobileReaders"];
      case "lastDetected":
        return ["lastDetected"];
      // case 'undetectedAssets':
      //   return ['undetectedAssets'];
      case "currentlyUndetected":
        return ["currentlyUndetected"];
      case "auditLog":
        return ["auditLog"];
      case "handheldScannedAssets":
        return ["handheldScannedAssets"];
      case "alertLog":
        return ["alertLog"];
      case "fixedAssetMovement":
        return ["fixedAssetMovement"];
      case "zoneMovement":
        return ["zoneMovement"];
      case "mobileAssetMovement":
        return ["mobileAssetMovement"];
      case "vehicleMovement":
        return ["vehicleMovement"];
      case "assetJourney":
        return ["assetJourney"];
      case "users":
        return ["users"];
      case "userGroups":
        return ["userGroups"];
      case "roles":
        return ["roles"];
      case "contacts":
        return ["contacts"];
      case "killedAssets":
        return ["killedAssets"];
      case "assetAuditJourney":
        return ["assetAuditJourney"];
      case "smsConsumption":
        return ["smsConsumption"];
      case "floorPlan":
        return ["floorPlan"];
      default:
        return ["dashboard"];
    }
  };

  const retrieveOpenKeyFromPath = () => {
    const pathName = location.pathname.split("/")[1];
    if (collapsed) {
      return [];
    }
    switch (pathName) {
      case "dashboard":
        return ["dashboard"];
      case "assetGroups":
        return ["assetGroups"];
      case "assets":
        return ["assets"];
      case "assetLocation":
        return ["assetLocation"];
      case "departments":
        return ["departments"];
      case "customFields":
        return ["customFields"];
      case "sites":
        return ["sites"];
      case "zones":
        return ["zones"];
      case "gateways":
        return ["gateways"];
      case "fixedReaders":
        return ["fixedReaders"];
      case "antennas":
        return ["antennas"];
      case "ipRelayCards":
        return ["ipRelayCards"];
      case "relaySwitches":
        return ["relaySwitches"];
      case "alertRules":
        return ["alertRules"];
      case "mobileReaders":
        return ["mobileReaders"];
      case "lastDetected":
        return ["lastDetected"];
      case "currentlyUndetected":
        return ["currentlyUndetected"];
      // case 'undetectedAssets':
      //   return ['undetectedAssets'];
      case "auditLog":
        return ["auditLog"];
      case "handheldScannedAssets":
        return ["handheldScannedAssets"];
      case "alertLog":
        return ["alertLog"];
      case "fixedAssetMovement":
        return ["fixedAssetMovement"];
      case "zoneMovement":
        return ["zoneMovement"];
      case "mobileAssetMovement":
        return ["mobileAssetMovement"];
      case "vehicleMovement":
        return ["vehicleMovement"];
      case "assetJourney":
        return ["assetJourney"];
      case "users":
        return ["users"];
      case "userGroups":
        return ["userGroups"];
      case "roles":
        return ["roles"];
      case "contacts":
        return ["contacts"];
      case "killedAssets":
        return ["killedAssets"];
      case "assetAuditJourney":
        return ["assetAuditJourney"];
      case "smsConsumption":
        return ["smsConsumption"];
      case "floorPlan":
        return ["floorPlan"];
      default:
        return ["dashboard"];
    }
  };

  const handleSiderMenuClick = (action) => {
    switch (action.key) {
      case "dashboard":
        history.push("/");
        break;
      case "assetGroups":
        history.push("/assetGroups");
        break;
      case "assets":
        history.push("/assets");
        break;
      case "assetLocation":
        history.push("/assetLocation");
        break;
      case "departments":
        history.push("/departments");
        break;
      case "customFields":
        history.push("/customFields");
        break;
      case "sites":
        history.push("/sites");
        break;
      case "zones":
        history.push("/zones");
        break;
      case "gateways":
        history.push("/gateways");
        break;
      case "fixedReaders":
        history.push("/fixedReaders");
        break;
      case "antennas":
        history.push("/antennas");
        break;
      case "ipRelayCards":
        history.push("/ipRelayCards");
        break;
      case "relaySwitches":
        history.push("/relaySwitches");
        break;
      case "alertRules":
        history.push("/alertRules");
        break;
      case "mobileReaders":
        history.push("/mobileReaders");
        break;
      case "lastDetected":
        history.push("/lastDetected");
        break;
      case "currentlyUndetected":
        history.push("/currentlyUndetected");
        break;
      // case 'undetectedAssets':
      //   history.push('/undetectedAssets');
      //   break;
      case "auditLog":
        history.push("/auditLog");
        break;
      case "handheldScannedAssets":
        history.push("/handheldScannedAssets");
        break;
      case "alertLog":
        history.push("/alertLog");
        break;
      case "fixedAssetMovement":
        history.push("/fixedAssetMovement");
        break;
      case "zoneMovement":
        history.push("/zoneMovement");
        break;
      case "mobileAssetMovement":
        history.push("/mobileAssetMovement");
        break;
      case "vehicleMovement":
        history.push("/vehicleMovement");
        break;
      case "assetJourney":
        history.push("/assetJourney");
        break;
      case "users":
        history.push("/users");
        break;
      case "userGroups":
        history.push("/userGroups");
        break;
      case "roles":
        history.push("/roles");
        break;
      case "contacts":
        history.push("/contacts");
        break;
      case "killedAsset":
        history.push("/killed-assets");
        break;
      case "assetAuditJourney":
        history.push("/assetAudit-journey");
        break;
      case "smsConsumption":
        history.push("/sms-consumption");
        break;
      case "floorPlan":
        history.push("/floor-plan");
        break;
      default:
        history.push("/");
    }
  };

  const assetGroups = hasPermission(selectedTenant, "LIST_ASSET_GROUPS")
    ? [
        {
          id: "rfid-sideMenu-assetsGroups",
          key: "assetGroups",
          lang:
            language?.ASSETS_GROUPS?.value ?? englishLang?.ASSETS_GROUPS?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const assets = hasPermission(selectedTenant, "LIST_ASSETS")
    ? [
        {
          id: "rfid-sideMenu-assets",
          key: "assets",
          lang: language?.ASSETS?.value ?? englishLang?.ASSETS?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;

  const assetLocation =
    hasPermission(selectedTenant, "LIST_ASSET_LOCATIONS") &&
    hasSubscription(selectedTenant, "ASSET_LOCATIONS_SUBSCRIPTION")
      ? [
          {
            id: "rfid-sideMenu-assetLocation",
            key: "assetLocation",
            lang:
              language?.ASSET_LOCATIONS?.value ??
              englishLang?.ASSET_LOCATIONS?.value,
          },
        ].map((item) => (
          <Menu.Item key={item.key} id={item.id}>
            {item.lang}
          </Menu.Item>
        ))
      : null;
  const departments = hasPermission(selectedTenant, "LIST_DEPARTMENTS")
    ? [
        {
          id: "rfid-sideMenu-departments",
          key: "departments",
          lang: language?.DEPARTMENTS?.value ?? englishLang?.DEPARTMENTS?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const customFields = hasPermission(selectedTenant, "LIST_CUSTOM_FIELDS")
    ? [
        {
          id: "rfid-sideMenu-customFields",
          key: "customFields",
          lang:
            language?.CUSTOM_FILEDS?.value ?? englishLang?.CUSTOM_FILEDS?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const sites = hasPermission(selectedTenant, "LIST_SITES")
    ? [
        {
          id: "rfid-sideMenu-sites",
          key: "sites",
          lang: language?.SITES?.value ?? englishLang?.SITES?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const zones = hasPermission(selectedTenant, "LIST_ZONES")
    ? [
        {
          id: "rfid-sideMenu-zones",
          key: "zones",
          lang: language?.ZONES?.value ?? englishLang?.ZONES?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const gateways = hasPermission(selectedTenant, "LIST_GATEWAYS")
    ? [
        {
          id: "rfid-sideMenu-gateways",
          key: "gateways",
          lang: language?.GATEWATS?.value ?? englishLang?.GATEWATS?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const fixedReaders = hasPermission(selectedTenant, "LIST_FIXED_READERS")
    ? [
        {
          id: "rfid-sideMenu-fixedReaders",
          key: "fixedReaders",
          lang: language?.READERS?.value ?? englishLang?.READERS?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const antennas = hasPermission(selectedTenant, "LIST_ANTENNAS")
    ? [
        {
          id: "rfid-sideMenu-antennas",
          key: "antennas",
          lang: language?.ANTENNAS?.value ?? englishLang?.ANTENNAS?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const ipRelayCards = hasPermission(selectedTenant, "LIST_IP_RELAY_CARDS")
    ? [
        {
          id: "rfid-sideMenu-ipRelayCards",
          key: "ipRelayCards",
          lang:
            language?.IP_RELAY_CARDS?.value ??
            englishLang?.IP_RELAY_CARDS?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const relaySwitches = hasPermission(selectedTenant, "LIST_RELAY_SWITCHES")
    ? [
        {
          id: "rfid-sideMenu-relaySwitches",
          key: "relaySwitches",
          lang:
            language?.RELAY_SWITCHES?.value ??
            englishLang?.RELAY_SWITCHES?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const floorPlan = hasPermission(selectedTenant, "READ_FLOOR_PLANS")
    ? [
        {
          id: "rfid-sideMenu-floorPlan",
          key: "floorPlan",
          lang: language?.FLOOR_PLAN?.value ?? englishLang?.FLOOR_PLAN?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const mobileReaders = hasPermission(selectedTenant, "LIST_MOBILE_READERS")
    ? [
        {
          id: "rfid-sideMenu-mobileReaders",
          key: "mobileReaders",
          lang: language?.READERS?.value ?? englishLang?.READERS?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;

  const lastDetected =
    hasPermission(selectedTenant, "GENERATE_LAST_DETECTED_REPORT") &&
    !hasHandheld(user)
      ? [
          {
            id: "rfid-sideMenu-lastDetected",
            key: "lastDetected",
            lang:
              language?.LAST_DETECTED?.value ??
              englishLang?.LAST_DETECTED?.value,
          },
        ].map((item) => (
          <Menu.Item key={item.key} id={item.id}>
            {item.lang}
          </Menu.Item>
        ))
      : null;

  const killedAssets =
    hasSubscription(selectedTenant, "KILLING_ZONES_SUBSCRIPTION") &&
    hasPermission(selectedTenant, "GENERATE_KILLED_ASSETS_REPORT")
      ? [
          {
            id: "rfid-sideMenu-killedAssets",
            key: "killedAsset",
            lang:
              language?.KILLED_ASSETS?.value ??
              englishLang?.KILLED_ASSETS?.value,
          },
        ].map((item) => (
          <Menu.Item key={item.key} id={item.id}>
            {item.lang}
          </Menu.Item>
        ))
      : null;

  const assetAuditJourney = hasPermission(
    selectedTenant,
    "GENERATE_ASSET_AUDIT_JOURNEY_REPORT"
  )
    ? [
        {
          id: "rfid-sideMenu-assetAuditJourney",
          key: "assetAuditJourney",
          lang:
            language?.ASSET_AUDIT_JOURNEY?.value ??
            englishLang?.ASSET_AUDIT_JOURNEY?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;

  //SMS smsConsumption
  const smsConsumption = hasPermission(
    selectedTenant,
    "GENERATE_SMS_ALERT_LOG_REPORT"
  )
    ? [
        {
          id: "rfid-sideMenu-smsConsumption",
          key: "smsConsumption",
          lang:
            language?.SMS_CONSUMPTION?.value ??
            englishLang?.SMS_CONSUMPTION?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;

  // const undetectedAssets=(hasPermission(selectedTenant,'GENERATE_UNDETECTED_ASSETS_REPORT'))  ? <Menu.Item key="undetectedAssets">Undetected Assets</Menu.Item>: null;
  const handheldScannedAssets = hasPermission(
    selectedTenant,
    "GENERATE_HANDHELD_SCANNED_ASSETS_REPORT"
  )
    ? [
        {
          id: "rfid-sideMenu-handheldScannedAssets",
          key: "handheldScannedAssets",
          lang:
            language?.HANDHELD_SCANNED_ASSETS?.value ??
            englishLang?.HANDHELD_SCANNED_ASSETS?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;

  const fixedAssetMovement = hasPermission(
    selectedTenant,
    "GENERATE_FIXED_ASSET_MOVEMENT_REPORT"
  )
    ? [
        {
          id: "rfid-sideMenu-fixedAssetMovement",
          key: "fixedAssetMovement",
          lang:
            language?.ASSET_MOVEMENT?.value ??
            englishLang?.ASSET_MOVEMENT?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const zoneMovement = hasPermission(
    selectedTenant,
    "GENERATE_ZONE_MOVEMENT_REPORT"
  )
    ? [
        {
          id: "rfid-sideMenu-zoneMovement",
          key: "zoneMovement",
          lang:
            language?.ZONE_MOVEMENT?.value ?? englishLang?.ZONE_MOVEMENT?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const mobileAssetMovement = hasPermission(
    selectedTenant,
    "GENERATE_MOBILE_ASSET_MOVEMENT_REPORT"
  )
    ? [
        {
          id: "rfid-sideMenu-mobileAssetMovement",
          key: "mobileAssetMovement",
          lang:
            language?.ASSET_MOVEMENT?.value ??
            englishLang?.ASSET_MOVEMENT?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const vehicleMovement =
    hasPermission(selectedTenant, "GENERATE_VEHICLE_MOVEMENT_REPORT") &&
    hasFleetSubscription(user) &&
    !hasHandheld(user)
      ? [
          {
            id: "rfid-sideMenu-vehicleMovement",
            key: "vehicleMovement",
            lang:
              language?.VEHICLE_MOVEMENT?.value ??
              englishLang?.VEHICLE_MOVEMENT?.value,
          },
        ].map((item) => (
          <Menu.Item key={item.key} id={item.id}>
            {item.lang}
          </Menu.Item>
        ))
      : null;
  const currentlyUndetected = hasPermission(
    selectedTenant,
    "GENERATE_CURRENTLY_UNDETECTED_REPORT"
  )
    ? [
        {
          id: "rfid-sideMenu-currentlyUndetected",
          key: "currentlyUndetected",
          lang:
            language?.CURRENTLY_UNDETECTED?.value ??
            englishLang?.CURRENTLY_UNDETECTED?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const assetJourney = hasPermission(
    selectedTenant,
    "GENERATE_ASSET_JOURNEY_REPORT"
  )
    ? [
        {
          id: "rfid-sideMenu-assetJourney",
          key: "assetJourney",
          lang:
            language?.ASSET_JOURNEY?.value ?? englishLang?.ASSET_JOURNEY?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;

  const alertRules = hasPermission(selectedTenant, "LIST_ALERT_RULES")
    ? [
        {
          id: "rfid-sideMenu-alertRules",
          key: "alertRules",
          lang: language?.ALERT_RULES?.value ?? englishLang?.ALERT_RULES?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const alertLog = hasPermission(selectedTenant, "GENERATE_ALERT_LOGS_REPORT")
    ? [
        {
          id: "rfid-sideMenu-alertLog",
          key: "alertLog",
          lang: language?.ALERT_LOG?.value ?? englishLang?.ALERT_LOG?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;

  const users = hasPermission(selectedTenant, "LIST_USERS")
    ? [
        {
          id: "rfid-sideMenu-users",
          key: "users",
          lang: language?.USERS?.value ?? englishLang?.USERS?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const userGroups = hasPermission(selectedTenant, "LIST_USER_GROUPS")
    ? [
        {
          id: "rfid-sideMenu-userGroups",
          key: "userGroups",
          lang: language?.USER_GROUPS?.value ?? englishLang?.USER_GROUPS?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const roles = hasPermission(selectedTenant, "LIST_ROLES")
    ? [
        {
          id: "rfid-sideMenu-roles",
          key: "roles",
          lang: language?.ROLES?.value ?? englishLang?.ROLES?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;
  const contacts =
    hasPermission(selectedTenant, "LIST_CONTACTS") && !hasHandheld(user)
      ? [
          {
            id: "rfid-sideMenu-contacts",
            key: "contacts",
            lang: language?.CONTACTS?.value ?? englishLang?.CONTACTS?.value,
          },
        ].map((item) => (
          <Menu.Item key={item.key} id={item.id}>
            {item.lang}
          </Menu.Item>
        ))
      : null;
  const auditLog = hasPermission(selectedTenant, "GENERATE_AUDIT_LOGS_REPORT")
    ? [
        {
          id: "rfid-sideMenu-auditLog",
          key: "auditLog",
          lang: language?.AUDIT_LOG?.value ?? englishLang?.AUDIT_LOG?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id}>
          {item.lang}
        </Menu.Item>
      ))
    : null;

  // DASHBOARD
  const dashboard = !hasHandheld(user)
    ? [
        {
          id: "rfid-sideMenu-dashboard",
          key: "dashboard",
          lang: language?.DASHBOARD?.value ?? englishLang?.DASHBOARD?.value,
          icon: <DashboardOutlined />,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id} icon={item.icon}>
          {item.lang}
        </Menu.Item>
      ))
    : null;

  // MANAGE
  function manageTitleHandler() {
    if (collapsed === false) {
      return language?.MANAGE?.value ?? englishLang?.MANAGE?.value;
    } else {
      ("");
    }
  }

  const manage =
    hasPermission(selectedTenant, "LIST_ASSET_GROUPS") ||
    hasPermission(selectedTenant, "LIST_ASSETS") ||
    hasPermission(selectedTenant, "LIST_DEPARTMENTS") ||
    hasPermission(selectedTenant, "LIST_CUSTOM_FIELDS") ||
    hasPermission(selectedTenant, "LIST_SITES") ||
    hasPermission(selectedTenant, "LIST_ZONES") ||
    hasPermission(selectedTenant, "LIST_GATEWAYS") ||
    hasPermission(selectedTenant, "LIST_FIXED_READERS") ||
    hasPermission(selectedTenant, "LIST_ANTENNAS") ||
    hasPermission(selectedTenant, "LIST_IP_RELAY_CARDS") ||
    hasPermission(selectedTenant, "LIST_RELAY_SWITCHES") ||
    hasPermission(selectedTenant, "LIST_ASSET_LOCATIONS") ||
    hasPermission(selectedTenant, "LIST_MOBILE_READERS") ? (
      <SubMenu
        id="rfid-sideMenu-subMenu-setup"
        key="manage"
        icon={<AppstoreAddOutlined />}
        title={manageTitleHandler()}
      >
        {assetGroups}
        {assets}
        {assetLocation}
        {departments}
        {customFields}
      </SubMenu>
    ) : null;

  // FIXED INSTALLATION
  function fixedInstallationTitleHandler() {
    if (collapsed === false) {
      return (
        language?.FIXED_INSTALLATION?.value ??
        englishLang?.FIXED_INSTALLATION?.value
      );
    } else {
      ("");
    }
  }

  const fixedInstallation =
    hasPermission(selectedTenant, "LIST_SITES") ||
    hasPermission(selectedTenant, "LIST_ZONES") ||
    hasPermission(selectedTenant, "LIST_GATEWAYS") ||
    hasPermission(selectedTenant, "LIST_FIXED_READERS") ||
    hasPermission(selectedTenant, "LIST_ANTENNAS") ||
    hasPermission(selectedTenant, "LIST_IP_RELAY_CARDS") ||
    hasPermission(selectedTenant, "LIST_RELAY_SWITCHES") ||
    hasPermission(selectedTenant, "READ_FLOOR_PLANS") ? (
      <SubMenu
        id="rfid-sideMenu-subMenu-fixedInstallation"
        key="fixedInstallation"
        title={fixedInstallationTitleHandler()}
        icon={<GoldOutlined />}
      >
        {sites}
        {zones}
        {gateways}
        {fixedReaders}
        {antennas}
        {ipRelayCards}
        {relaySwitches}
        {floorPlan}
      </SubMenu>
    ) : null;

  // PORTABLE INSTALLATION

  function portableInstallationTitleHandler() {
    if (collapsed === false) {
      return (
        language?.MOBILE_INSTALLATION?.value ??
        englishLang?.MOBILE_INSTALLATION?.value
      );
    } else {
      ("");
    }
  }
  const portableInstallation =
    hasPermission(selectedTenant, "LIST_MOBILE_READERS") &&
    hasFleetSubscription(user) &&
    !hasHandheld(user) ? (
      <SubMenu
        id="rfid-sideMenu-subMenu-mobileReaders"
        key="portableInstallation"
        title={portableInstallationTitleHandler()}
        icon={<CarOutlined />}
      >
        {mobileReaders}
      </SubMenu>
    ) : null;

  // REPORTS
  function reportsTitleHandler() {
    if (collapsed === false) {
      return language?.REPORTS?.value ?? englishLang?.REPORTS?.value;
    } else {
      ("");
    }
  }

  const reports =
    hasPermission(selectedTenant, "GENERATE_LAST_DETECTED_REPORT") ||
    hasPermission(selectedTenant, "GENERATE_CURRENTLY_UNDETECTED_REPORT") ||
    hasPermission(selectedTenant, "GENERATE_HANDHELD_SCANNED_ASSETS_REPORT") ||
    hasPermission(selectedTenant, "GENERATE_ASSET_JOURNEY_REPORT") ||
    hasPermission(selectedTenant, "GENERATE_ZONE_MOVEMENT_REPORT") ||
    hasPermission(selectedTenant, "GENERATE_VEHICLE_MOVEMENT_REPORT") ||
    hasPermission(selectedTenant, "READ_FLOOR_PLANS") ? (
      <SubMenu
        id="rfid-sideMenu-reports"
        key="reports"
        icon={<TableOutlined />}
        title={reportsTitleHandler()}
      >
        {lastDetected}
        {killedAssets}
        {currentlyUndetected}
        {assetJourney}
        {assetAuditJourney}
        {handheldScannedAssets}
        {zoneMovement}
        {vehicleMovement}
        {smsConsumption}
      </SubMenu>
    ) : null;

  //ALARTS MENU

  function alertsMenuTitleHandler() {
    if (collapsed === false) {
      return language?.ALERTS?.value ?? englishLang?.ALERTS?.value;
    } else {
      ("");
    }
  }

  const alertsMenu =
    (hasPermission(selectedTenant, "LIST_ALERT_RULES") ||
      hasPermission(selectedTenant, "GENERATE_ALERT_LOGS_REPORT")) &&
    !hasHandheld(user) ? (
      <SubMenu
        id="rfid-sideMenu-subMenu-alerts"
        key="alerts"
        icon={<BellOutlined />}
        title={alertsMenuTitleHandler()}
      >
        {alertRules}
        {alertLog}
      </SubMenu>
    ) : null;

  //  USER MANAGEMENT MENU

  function userManagementMenuTitleHandler() {
    if (collapsed === false) {
      return (
        language?.USER_MANAGEMENT?.value ?? englishLang?.USER_MANAGEMENT?.value
      );
    } else {
      ("");
    }
  }

  const userManagementMenu =
    hasPermission(selectedTenant, "LIST_USERS") ||
    hasPermission(selectedTenant, "LIST_CONTACTS") ||
    hasPermission(selectedTenant, "GENERATE_AUDIT_LOGS_REPORT") ||
    hasPermission(selectedTenant, "LIST_USER_GROUPS") ||
    hasPermission(selectedTenant, "LIST_ROLES") ? (
      <SubMenu
        id="rfid-sideMenu-subMenu-userManagement"
        key="userManagement"
        icon={<TeamOutlined />}
        title={userManagementMenuTitleHandler()}
      >
        {users}
        {userGroups}
        {roles}
        {contacts}
        {auditLog}
      </SubMenu>
    ) : null;

  const rootSubmenuKeys = [
    "dashboard",
    "manage",
    "fixedInstallation",
    "portableInstallation",
    "reports",
    "alerts",
    "userManagement",
  ];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Affix>
      <Sider
        breakpoint="lg"
        collapsedWidth="80"
        onCollapse={handleOnCollapse}
        collapsed={collapsed}
        width="256"
        theme={theme}
      >
        <a>
          <div
            className="menu-logo-container"
            id="rfid-sideMenu-logo"
            onClick={() => {
              let dashboardMenuItem = document.getElementById(
                "rfid-sideMenu-dashboard"
              );
              dashboardMenuItem.click();
            }}
          >
            <SiderLogo collapsed={collapsed} />
          </div>
        </a>
        <Menu
          onClick={handleSiderMenuClick}
          defaultSelectedKeys={retrieveSelectedKeyFromPath()}
          defaultOpenKeys={retrieveOpenKeyFromPath()}
          mode="inline"
          theme={theme}
          id="rfid-sideMenu-menu"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          style={{ height: "100vh" }}
        >
          {dashboard}
          {manage}
          {fixedInstallation}
          {portableInstallation}
          {reports}
          {alertsMenu}
          {userManagementMenu}
        </Menu>
      </Sider>
    </Affix>
  );
}

export default SiderMenu;
