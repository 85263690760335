import * as type from "../types";

const INITAL_STATE = {
  banner: null,
};

const reducer = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case type.SHOW_BANNER:
      return {
        ...state,
        banner: action.payload,
      };
    case type.REMOVE_BANNER:
      return {
        ...state,
        banner: null,
      };
    default:
      return state;
  }
};

export default reducer;
