import { request } from "../axios/request";

export const getIpRelayCardsService = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/ip-relay-cards", getState(), {
        method: "GET",
        params,
      })
    );
  };
};

export const getSingleIpRelayCardsService = (id = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/ip-relay-cards/" + id, getState(), {
        method: "GET",
      })
    );
  };
};

export const getAllIpRelayCardsService = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/ip-relay-cards", getState(), {
        method: "GET",
        params,
      })
    );
  };
};
export const saveIpRelayCardService = (data, id = 0) => {
  if (id > 0) {
    return async (dispatch, getState) => {
      return dispatch(
        request("/ip-relay-cards/" + id, getState(), {
          method: "PUT",
          data,
        })
      );
    };
  }
  return async (dispatch, getState) => {
    return dispatch(
      request("/ip-relay-cards", getState(), {
        method: "POST",
        data,
      })
    );
  };
};

export const deleteIpRelayCardService = (id = 0) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/ip-relay-cards/" + id, getState(), {
        method: "DELETE",
      })
    );
  };
};
