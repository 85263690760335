import React, { useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { Icon } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";

const AssetJourneyFirstDetectedMap = (props) => {
  let lat = props.lat;
  let long = props.long;

  useEffect(() => {
    const AssetJourneyFirstDetected = L.map(
      "AssetJourneyFirstDetected"
    ).setView([lat, long], 20);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {}).addTo(
      AssetJourneyFirstDetected
    );

    const myIcon = new Icon({
      iconUrl: markerIconPng,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
    });

    L.marker([lat, long], { icon: myIcon }).addTo(AssetJourneyFirstDetected);
  }, [props]);

  return <div id="AssetJourneyFirstDetected" style={{ height: "40vh" }} />;
};

export default AssetJourneyFirstDetectedMap;
