import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown, Menu, Modal } from "antd";
import {
  DeleteOutlined,
  MoreOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  LockOutlined,
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { activateUser } from "../../../api/redux/action/UserActions";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import "./Style.css";

function UserActionView({
  selectedRow,
  updateEntityPath,
  handleSingleDelete,
  allowPermission,
  title,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { confirm } = Modal;

  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const handleActivateUser = (selected, activate) => {
    if (selected) {
      const formData = {
        active: activate,
        userAccountId: selected.userAccountId,
      };

      dispatch(activateUser(formData, formData.userAccountId));
    }
  };

  const handleMenuClick = (action) => {
    const updatePath = "/" + updateEntityPath;
    switch (action.key) {
      case "edit":
        history.push({
          pathname: updatePath,
          state: { selectedRow },
        });
        break;
      case "reset":
        const path = "/reset-password";
        history.push({
          pathname: path,
          state: { selectedRow },
        });
        break;
      case "view":
        const viewOption = "disabled";

        history.push({
          pathname: updatePath,
          state: { selectedRow, viewOption },
        });
        break;
      case "activate":
        handleActivateUser(selectedRow, true);
        break;
      case "disable":
        handleActivateUser(selectedRow, false);
        break;
      default:
    }
  };

  const active =
    selectedRow && selectedRow.active
      ? allowPermission.deactivate
        ? [
            {
              id: ElementIDS().deactivate,
              key: "disable",
              closeOutlined: <CloseOutlined style={{ marginRight: "5px" }} />,
              lang: language?.DISABLE?.value ?? englishLang?.DISABLE?.value,
            },
          ]
        : null
      : allowPermission.activate
      ? [
          {
            id: ElementIDS().activate,
            key: "activate",
            closeOutlined: <CloseOutlined style={{ marginRight: "5px" }} />,
            lang: language?.ENABLE?.value ?? englishLang?.ENABLE?.value,
          },
        ]
      : null;

  const editMenuItem = allowPermission.edit
    ? [
        {
          id: ElementIDS().edit,
          key: "edit",
          editOutlined: <EditOutlined style={{ marginRight: "5px" }} />,
          edit: language?.EDIT?.value ?? englishLang?.EDIT?.value,
        },
      ]
    : null;

  function convertStringToCamelCase(title) {
    return title.replace(
      /(?:^\w|[A-Z]|\b\w|\s+)/g,
      function (camelCaseMatch, i) {
        if (+camelCaseMatch === 0) return "";
        return i === 0
          ? camelCaseMatch.toLowerCase()
          : camelCaseMatch.toUpperCase();
      }
    );
  }

  const showDeleteConfirm = () => {
    confirm({
      title:
        language?.DELETE?.value ??
        englishLang?.DELETE?.value + " " + title.slice(0, -1),
      icon: <QuestionCircleOutlined />,
      onOk: () => {
        handleSingleDelete(selectedRow);
      },
      okText: language?.DELETE?.value ?? englishLang?.DELETE?.value,
      okButtonProps: {
        id:
          "rfid-" + convertStringToCamelCase(title) + "-deleteModal-deleteBtn",
      },
      okType: "danger",
      cancelText: language?.CANCEL?.value ?? englishLang?.CANCEL?.value,
      cancelButtonProps: {
        id:
          "rfid-" + convertStringToCamelCase(title) + "-deleteModal-cancelBtn",
      },
      content:
        language?.SURE_TO_DELETE_THIS_ENTRY?.value ??
        englishLang?.SURE_TO_DELETE_THIS_ENTRY?.value +
          " " +
          selectedRow.name +
          "?",
    });
  };

  const viewMenuItem = [
    {
      key: "view",
      eyeOutlined: <EyeOutlined style={{ marginRight: "5px" }} />,
      view: language?.VIEW?.value ?? englishLang?.VIEW?.value,
    },
  ];
  const deleteMenuItem = allowPermission.delete
    ? [
        {
          id: ElementIDS().delete,
          key: "delete",
          className: "action__menu__deleteBtn",
          placement: "left",
          icon: <DeleteOutlined type="delete" style={{ color: "red" }} />,
          onClick: showDeleteConfirm,
          delete: language?.DELETE?.value ?? englishLang?.DELETE?.value,
        },
      ]
    : null;

  const withSeperator = (
    <Menu.ItemGroup key="activationGroup" title="TEST">
      {active !== null
        ? active.map((item) => (
            <Menu.Item key={item.key} id={item.id}>
              {item.closeOutlined}
              {item.lang}
            </Menu.Item>
          ))
        : null}
    </Menu.ItemGroup>
  );

  const actionMenu = (
    <Menu onClick={handleMenuClick}>
      {viewMenuItem !== null
        ? viewMenuItem.map((item) => (
            <Menu.Item key={item.key}>
              {item.eyeOutlined}
              {item.view}
            </Menu.Item>
          ))
        : null}
      {editMenuItem !== null
        ? editMenuItem.map((item) => (
            <Menu.Item key={item.key} id={item.id}>
              {item.editOutlined}
              {item.edit}
            </Menu.Item>
          ))
        : null}
      {withSeperator}
      {deleteMenuItem !== null
        ? deleteMenuItem.map((item) => (
            <Menu.Item
              key={item.key}
              id={item.id}
              className={item.className}
              placement={item.placement}
              icon={item.icon}
              onClick={item.onClick}
            >
              {item.delete}
            </Menu.Item>
          ))
        : null}
    </Menu>
  );

  const actionColumnView = (
    <>
      <Dropdown overlay={actionMenu} trigger={["click"]}>
        <a id={ElementIDS().dropDown} className="ant-dropdown-link" href="#">
          <MoreOutlined style={{ fontSize: "22px" }} />
        </a>
      </Dropdown>
    </>
  );

  return actionColumnView;
}

export default UserActionView;
