import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { notification, Form, Input, Button, Spin } from "antd";
import { LockOutlined } from "@ant-design/icons";

import { resetPassword } from "../../api/redux/action/AuthActions";
import { fetchTranslations } from "../../api/redux/action/LangActions";
import { validateToken } from "../../api/redux/action/UserActions";

import "./Style.css";
import rfidLogo from "../../assets/images/rfid_logo_lhtBlue.png";

import { getVersionNumber } from "../layout/Constants";
import "antd/dist/antd.min.css";
import { getLoginPreferredLogo } from "../../api/redux/action/UserActions";

const ResetPassword = (props) => {
  const [defaultLogo, setDefaultLogo] = useState();
  const [validContent, setValidContent] = useState(<Spin size="large" />);

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    loading,
    error,
    preferredLogo,
    requestStatus,
    tokenValid,
    requestStatusUser,
  } = useSelector((store) => ({
    loading: store.authReducer.loading,
    error: store.authReducer.error,
    preferredLogo: store.userReducer.preferredLogo,
    requestStatus: store.authReducer.requestStatus,
    tokenValid: store.userReducer.tokenValid,
    requestStatusUser: store.userReducer.requestStatus,
  }));

  useEffect(() => {
    if (preferredLogo !== undefined) {
      if (preferredLogo !== undefined) {
        setDefaultLogo(preferredLogo.logoUrl);
      } else {
        setDefaultLogo(rfidLogo);
      }
    }
  }, [preferredLogo]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    dispatch(validateToken({ token: token }));
  }, []);

  useEffect(() => {
    if (requestStatusUser === "SUCCESS") {
      if (tokenValid) {
        setValidContent(validText);
      } else {
        setValidContent(expiredText);
      }
    } else if (requestStatusUser === "FAILURE") {
      setValidContent(expiredText);
    }
  }, [tokenValid, requestStatusUser]);

  useEffect(() => {
    dispatch(getLoginPreferredLogo());
  }, []);

  useEffect(() => {
    dispatch(fetchTranslations());
  }, []);

  useEffect(() => {
    if (error) {
      notification.open({
        type: "error",
        message: "Error!",
        description: error,
        duration: 10,
      });
    }
  }, [error]);

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      notification.open({
        type: "success",
        message: "Success!",
        description: "Password successfully set",
        duration: 10,
      });
      return history.push("/login");
    }
  }, [requestStatus]);

  const onFinish = (values) => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    values.token = token;

    dispatch(resetPassword(values));
  };

  const validText = (
    <div className="login_content">
      <h1>Reset Password</h1>
      <h3>Do not worry you can log in soon</h3>
      <Form
        name="normal_login"
        layout="horizontal"
        initialValues={{
          "remember-me": true,
        }}
        className="login_form"
        onFinish={onFinish}
      >
        <Form.Item className="username_form_container" name="password">
          <Input.Password
            className="password_input"
            prefix={<LockOutlined style={{ fontSize: 13 }} />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item className="username_form_container" name="confirmPassword">
          <Input.Password
            className="password_input"
            prefix={<LockOutlined style={{ fontSize: 13 }} />}
            type="password"
            placeholder="Confirm Password"
          />
        </Form.Item>

        <div className="login_button_container">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="forgot-form-button"
              block
              loading={loading}
            >
              Reset Password
            </Button>
          </Form.Item>
        </div>
      </Form>
      <div>
        <div className="handsOn_Logo_bottom">
          <a
            href="https://www.handsonsystems.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="./Images/handson-logo.png" alt="Handson" />
          </a>
        </div>
        <div className="copyright">
          <p className="copyright_description">
            © {new Date().getFullYear()} All rights reserved. RFID is a
            registered trademark of HandsOn Systems Ltd.
          </p>
          <strong>V {getVersionNumber()}</strong>
        </div>
      </div>
    </div>
  );

  const expiredText = (
    <div>
      <h1>Your token has expired</h1>
      <h3>The token has expired or is not valid. Please try again.</h3>
      <div className="login_button_container">
        <Form.Item>
          <Button
            type="primary"
            className="forgot-form-button"
            block
            loading={loading}
            onClick={() => {
              history.push("/login");
            }}
          >
            Try Again
          </Button>
        </Form.Item>
      </div>
      <div>
        <div className="handsOn_Logo_bottom">
          <a
            href="https://www.handsonsystems.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="./Images/handson-logo.png" alt="Handson" />
          </a>
        </div>
        <div className="copyright">
          <p className="copyright_description">
            © {new Date().getFullYear()} All rights reserved. RFID is a
            registered trademark of HandsOn Systems Ltd.
          </p>
          <strong>V {getVersionNumber()}</strong>
        </div>
      </div>
    </div>
  );

  return (
    <div className="login">
      <img
        className="rfid_logo"
        src="/Images/rfid_logo_lhtBlue.png"
        alt="RFID Logo"
      />
      <img
        className="rfid_logo_mobile"
        src="/Images/rfid_logo_white.png"
        alt="RFID Logo White"
      />
      <div className="left_side_screen">
        <img
          className="regular_image"
          src="/Images/left-graphic.png"
          alt="RFID Image"
        />
      </div>
      <div className="right_side_screen">{validContent}</div>
    </div>
  );
};

export default ResetPassword;
