import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";

import { saveUser } from "../../../api/redux/action/UserActions";
import useNotification from "../../../components/common/ResultNotifier";
import AddUserForm from "./AddUserForm";
import { useHistory } from "react-router-dom";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";
import { hasPermission } from "../../layout/Constants";
import AddFooterSection from "../../../components/common/AddFooterSection";
import { validateInput } from "../../../components/RequiredFieldRule";

const AddUser = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [prompt, setDirty, setPristine] = UnsavedChanges();

  let history = useHistory();

  const [edit, setEdit] = useState(false);

  const { error, requestStatus, user, language, englishLang, selectedTenant } =
    useSelector((store) => ({
      error: store.userReducer.error,
      requestStatus: store.userReducer.requestStatus,
      user: store.authReducer.user,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
      selectedTenant: store.authReducer.selectedTenant,
    }));
  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;
  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );
  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      var department = [];
      if (props.location.state.selectedRow.department) {
        props.location.state.selectedRow.department.map((d) => {
          department.push(d.departmentId);
        });
      }

      form.setFieldsValue({
        userAccountId: props.location.state.selectedRow.userAccountId,
        name: props.location.state.selectedRow.name,
        surname: props.location.state.selectedRow.surname,
        username: props.location.state.selectedRow.username,
        ...(props.location.state.selectedRow.userGroup && {
          userGroup: props.location.state.selectedRow.userGroup.userGroupId,
        }),
        // ...(props.location.state.selectedRow.department) && {department: props.location.state.selectedRow.department.departmentId},
        department,
      });

      setEdit(true);
    }
  }, []);

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      return history.push("/users");
    }
  }, [requestStatus]);

  const handleSave = (values) => {
    var department = [];
    form.getFieldValue().department &&
    form.getFieldValue().department.length > 0
      ? form.getFieldValue().department.map((d) => {
          department.push({
            departmentId: d,
          });
        })
      : (department = null);

    const formData = {
      name: validateInput(form.getFieldValue().name),
      surname: validateInput(form.getFieldValue().surname),
      username: validateInput(form.getFieldValue().username),
      userGroupId: form.getFieldValue().userGroup,
      department: department,
    };
    setPristine();
    dispatch(saveUser(formData, form.getFieldValue().userAccountId));
  };

  const handleCancel = () => {
    history.push("/users");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_USERS");
  }

  const footer = (
    <AddFooterSection
      language={language}
      viewOption={props.location.state}
      handleCancel={handleCancel}
      form={form}
      updatePath={"/update-user"}
      handleEditingPermission={handleEditingPermission}
    />
  );

  return (
    <Card
      title={language?.USER?.value ?? englishLang?.USER?.value}
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddUserForm
            handleSave={handleSave}
            form={form}
            footer={footer}
            edit={edit}
            setDirty={setDirty}
            readOnly={props.location.state}
            language={language}
          />
        </Col>
      </Row>
      <ResultNotifier />
      {prompt}
    </Card>
  );
};

export default AddUser;
