const KanvasLogic = ({ setStageSpec, selectImageComponent }) => {
  /* Zoom in and out */
  const handleZoomInOut = (e) => {
    e.evt.preventDefault();
    const scaleBy = 1.1;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };
    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    setStageSpec({
      scale: newScale,
      x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
      y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
    });
  };

  //Deselect when clicked on empty area
  const checkDeselectComponent = (e) => {
    const clickedOnStage = e.target === e.target.getStage();
    const clickedOnImage = e.target.attrs.id === "Floor_Plan_Image";
    if (clickedOnStage || clickedOnImage) {
      selectImageComponent({});
    }
  };

  //Deselect when clicked on empty area
  const unSelectComponent = (selected) => {
    selectImageComponent(selected);
  };

  return { handleZoomInOut, checkDeselectComponent, unSelectComponent };
};

export default KanvasLogic;
