import * as type from "../types";
import {
  getReportService,
  markAsReadService,
  getExportReportService,
} from "../../network/service/ReportService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchReport = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.REPORT_FETCH_REQUEST,
      });
      const response = await dispatch(getReportService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.REPORT_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.REPORT_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.REPORT_FETCH_FAILURE,
      });
    }
  };
};

export const resetReport = () => {
  return async (dispatch) => {
    dispatch({
      type: type.REPORT_RESET,
    });
  };
};

export const fetchAllReport = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.REPORT_FETCH_ALL_REQUEST,
      });
      const response = await dispatch(getReportService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.REPORT_FETCH_ALL_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.REPORT_FETCH_ALL_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.REPORT_FETCH_ALL_FAILURE,
      });
    }
  };
};

export const fetchAllFloorPlanComponentsReport = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.REPORT_ALL_FLOORPLAN_FETCH_REQUEST,
      });
      const response = await dispatch(getReportService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.REPORT_ALL_FLOORPLAN_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.REPORT_ALL_FLOORPLAN_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.REPORT_ALL_FLOORPLAN_FETCH_FAILURE,
      });
    }
  };
};

export const fetchFloorPlanComponentReport = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.REPORT_FLOORPLAN_FETCH_REQUEST,
      });
      const response = await dispatch(getReportService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.REPORT_FLOORPLAN_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.REPORT_FLOORPLAN_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.REPORT_FLOORPLAN_FETCH_FAILURE,
      });
    }
  };
};

export const markAsRead = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.REPORT_MARK_AS_READ_REQUEST,
      });
      const response = await dispatch(markAsReadService(data));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.REPORT_MARK_AS_READ_SUCCESS,
        });
      } else {
        dispatch({
          type: type.REPORT_MARK_AS_READ_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.REPORT_MARK_AS_READ_FAILURE,
        payload: ex,
      });
    }
  };
};
