import * as type from '../types';
import { RequestState } from './RequestState';

const INITIAL_STATE = {
  departments: {},
  allDepartments:{},
  department: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  exportAllLoading:false,
};

const reducer =  (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.DEPARTMENTS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        departments: {},
        loading: true
      };
    case type.DEPARTMENTS_FETCH_SUCCESS:
      return {
        ...state,
        departments: action.payload,
        loading: false
      };
    case type.DEPARTMENTS_FETCH_FAILURE:
      return {
        ...state,
        departments: {},
        loading: false
      };
      case type.ALL_DEPARTMENTS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        allDepartments: {},
        exportAllLoading: true
      };
    case type.ALL_DEPARTMENTS_FETCH_SUCCESS:
      return {
        ...state,
        allDepartments: action.payload,
        exportAllLoading: false
      };
    case type.ALL_DEPARTMENTS_FETCH_FAILURE:
      return {
        ...state,
        allDepartments: {},
        exportAllLoading: false
      };
      case type.DEPARTMENT_SAVE_REQUEST:
        return {
          ...state,
          error: {},
          department: {},
          requestStatus: RequestState.PENDING,
        };
   
    case type.DEPARTMENT_SAVE_SUCCESS:
      return {
        ...state,
        department: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.DEPARTMENT_SAVE_FAILURE:
      return {
        ...state,
        department: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
     
      case type.DEPARTMENT_DELETE_REQUEST:
        return{
          ...state,
          requestStatus: RequestState.PENDING,
        }
        case type.DEPARTMENT_DELETE_SUCCESS:
          return {
            ...state,
            department: action.payload,
            error: {},
            requestStatus: RequestState.SUCCESS,
          };
        case type.DEPARTMENT_DELETE_FAILURE:
          return {
            ...state,
            department: {},
            error: action.payload,
            requestStatus: RequestState.FAILURE,
          };
           case type.CLEAN_REQUEST_STATUS:
        return {
          ...state,
          requestStatus: RequestState.EMPTY
        };
    default:
      return state;
  }
};


export default reducer;