import { ResponseStatus } from "../../network/axios/ResponseStatus";
import { UNAUTHORIZED } from "../types";
import { notification } from "antd";
const removeUserFromLocalStorage = (name) => {
  localStorage.clear();
  notification.info({
    key: "updatable",
    message: "Logged out",
    duration: 6,
  });
};

export default function dispatchStateIfUnauthorized(ex, dispatch) {
  if (ex.response && ex.response.status === ResponseStatus.UNAUTHORIZED) {
    removeUserFromLocalStorage("userAPP");
    dispatch({
      type: UNAUTHORIZED,
    });
  }
}
