import React, { useState, useEffect } from "react";
import {
  Col,
  Divider,
  Row,
  Form,
  Select,
  Space,
  DatePicker,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment-timezone";

import { toTimestamp } from "../../../layout/Constants";
import { fetchFloorPlan } from "../../../../api/redux/action/FloorPlanActions";
import { fetchZones } from "../../../../api/redux/action/ZoneActions";
import { fetchFloorPlanComponentReport } from "../../../../api/redux/action/ReportActions";

import Header from "../../../../components/common/Header";
import LastDetectedFloorPlan from "./LastDetectedFloorPlan";

import { ElementIDS } from "../../../../components/common/ElementsIDs";
import { fetchAssetGroups } from "../../../../api/redux/action/AssetGroupActions";
import { fetchAssets } from "../../../../api/redux/action/AssetActions";

const LastDetected2D = (props) => {
  const params = new URLSearchParams(window.location.search);
  const { Option } = Select;
  const dateFormat = "DD-MM-YYYY";
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();

  const getToday = () => {
    const today = new Date();
    return setDateToString(today);
  };

  const setDateToString = (date) => {
    return (
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
    );
  };

  const [sortedFloorPlans, setSortedFloorPlans] = useState([]);
  const [sortedAssets, setSortedAssets] = useState([]);
  const [sortedAssetGroups, setSortedAssetGroups] = useState([]);
  const [selectedFloorPlan, setSelectedFloorPlan] = useState("");
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedAssetGroup, setSelectedAssetGroup] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [floorPlan, setFloorPlan] = useState({});
  const [zonesIds, setZonesIds] = useState([]);

  const [startDate, setStartDate] = useState(
    params.get("start") ? params.get("start") : getToday()
  );
  const [endDate, setEndDate] = useState(
    params.get("end") ? params.get("end") : getToday()
  );

  const [filter, setFilter] = useState({
    fromDate: startDate,
    toDate: endDate,
    includeDeleted: false,
    assetGroupIds: null,
    assetIds: null,
  });

  const {
    zones,
    error,
    loading,
    floorPlans,
    floorPlanComponent,
    assetGroups,
    assets,
    userTimeZone,
    language,
    englishLang,
  } = useSelector((store) => ({
    zones: store.zoneReducer.zones,
    error: store.floorPlanReducer.error,
    loading: store.reportReducer.loading,
    floorPlans: store.floorPlanReducer.floorPlans,
    floorPlanComponent: store.reportReducer.floorPlanComponent,
    assetGroups: store.assetGroupReducer.assetGroups,
    assets: store.assetReducer.assets,
    userTimeZone: store.authReducer.userTimeZone,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const filteredComponents = floorPlanComponent?.filter(
    (component) => component.zoneId === selectedComponent
  );

  const selectedObject = filteredComponents?.find(
    (component) => component.zoneId === selectedComponent
  );

  const handleFloorPlanChange = (value) => {
    setSelectedFloorPlan(value);
    setFilter({
      ...filter,
      floorPlanId: value,
    });
  };

  const handleAssetChange = (value) => {
    setSelectedAsset(value);
    setSelectedAssetGroup(null);
    setFilter({
      ...filter,
      assetIds: value,
      assetGroupIds: null,
    });
  };

  const handleAssetGroupChange = (value) => {
    setSelectedAssetGroup(value);
    setSelectedAsset(null);
    setFilter({
      ...filter,
      assetIds: null,
      assetGroupIds: value,
    });
  };

  const handleDateRangeChange = (date, dateString) => {
    setFilter({
      ...filter,
      fromDate: dateString[0],
      toDate: dateString[1],
    });
  };

  useEffect(() => {
    let obj = floorPlans.content?.find(
      (floorPlan) => floorPlan.title === selectedFloorPlan
    );
    setFloorPlan(obj);
  }, [floorPlans, selectedFloorPlan]);

  useEffect(() => {
    dispatch(fetchAssetGroups());
    dispatch(fetchAssets());
    dispatch(fetchFloorPlan());
    dispatch(fetchZones());
  }, []);

  // Extrancting all zonesIds by there Names
  useEffect(() => {
    const zoneIds = [];
    floorPlan?.components?.map((component) => {
      if (component.type === "Antenna") {
        zoneIds.push(component.zoneId);
      }
    });
    setZonesIds(zoneIds);
  }, [floorPlan?.components, selectedFloorPlan]);

  //Passing Single ZoneId to fetch all assetes detected by that zone
  useEffect(() => {
    dispatch(
      fetchFloorPlanComponentReport({
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
        endpoint: "last-detected-by-zone",
        zoneIds: zonesIds ? zonesIds?.join(",") : undefined,
        includeDeleted: filter.includeDeleted,
        ...(filter.assetIds && { assetIds: filter.assetIds }),
        ...(filter.assetGroupIds && { assetGroupIds: filter.assetGroupIds }),
      })
    );
  }, [filter, selectedFloorPlan]);

  useEffect(() => {
    if (floorPlans && floorPlans.content) {
      let sorted = floorPlans.content.sort((a, b) =>
        a.title.localeCompare(b.title, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedFloorPlans(sorted);
    }
  }, [floorPlans]);

  useEffect(() => {
    if (assets && assets.content) {
      let sorted = assets.content.sort((a, b) =>
        a.code.localeCompare(b.code, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedAssets(sorted);
    }
  }, [assets]);

  useEffect(() => {
    if (assetGroups && assetGroups.content) {
      let sorted = assetGroups.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedAssetGroups(sorted);
    }
  }, [assetGroups]);

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Row>
          {props.modeSelector}
          <Header
            addNewPath=""
            addNewText=""
            title=""
            exportTableTitle={
              language?.FLOOR_PLAN_SIDE_MENU?.value ??
              englishLang?.FLOOR_PLAN_SIDE_MENU?.value
            }
          />
        </Row>
        <Row>
          <Col>
            <Form>
              <Space>
                <>
                  <Tooltip
                    title={
                      language?.SELECT_A_DATE_RANGE?.value ??
                      englishLang?.SELECT_A_DATE_RANGE?.value
                    }
                  >
                    <RangePicker
                      id={ElementIDS().calendar}
                      defaultValue={[
                        moment(startDate, dateFormat),
                        moment(endDate, dateFormat),
                      ]}
                      format={dateFormat}
                      onChange={handleDateRangeChange}
                    />
                  </Tooltip>
                  <Tooltip
                    title={
                      language?.SELECT_ASSET_GROUP?.value ??
                      englishLang?.SELECT_ASSET_GROUP?.value
                    }
                  >
                    <Select
                      id={ElementIDS().selectAssetGroup}
                      allowClear
                      style={{ width: 200 }}
                      placeholder={
                        language?.SELECT_ASSET_GROUP?.value ??
                        englishLang?.SELECT_ASSET_GROUP?.value
                      }
                      optionFilterProp="children"
                      onChange={handleAssetGroupChange}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={selectedAssetGroup || undefined}
                    >
                      {sortedAssetGroups
                        ? sortedAssetGroups.map((item) => (
                            <Option
                              id={ElementIDS().option}
                              key={item.assetGroupId}
                              value={item.assetGroupId}
                            >
                              {item.name}
                            </Option>
                          ))
                        : undefined}
                    </Select>
                  </Tooltip>
                  <Tooltip
                    title={
                      language?.SELECT_AN_ASSET?.value ??
                      englishLang?.SELECT_AN_ASSET?.value
                    }
                  >
                    <Select
                      id={ElementIDS().selectAsset}
                      allowClear
                      style={{ width: 200 }}
                      placeholder={
                        language?.SELECT_AN_ASSET?.value ??
                        englishLang?.SELECT_AN_ASSET?.value
                      }
                      optionFilterProp="children"
                      onChange={handleAssetChange}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={selectedAsset || undefined}
                    >
                      {sortedAssets
                        ? sortedAssets.map((item) => (
                            <Option
                              id={ElementIDS().optionAsset}
                              key={item.assetId}
                              value={item.assetId}
                            >
                              {item.code}
                            </Option>
                          ))
                        : undefined}
                    </Select>
                  </Tooltip>
                  <Tooltip
                    title={
                      language?.SELECT_FLOOR_PLAN?.value ??
                      englishLang?.SELECT_FLOOR_PLAN?.value
                    }
                  >
                    <Select
                      id={ElementIDS().selectFloorPlan}
                      allowClear
                      style={{ width: 200 }}
                      placeholder={
                        language?.SELECT_FLOOR_PLAN?.value ??
                        englishLang?.SELECT_FLOOR_PLAN?.value
                      }
                      optionFilterProp="children"
                      showSearch
                      onChange={handleFloorPlanChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={selectedFloorPlan || undefined}
                    >
                      {sortedFloorPlans
                        ? sortedFloorPlans.map((item) => (
                            <Option
                              id={ElementIDS().option}
                              key={item.title}
                              value={item.title}
                            >
                              {item.title}
                            </Option>
                          ))
                        : undefined}
                    </Select>
                  </Tooltip>
                </>
              </Space>
            </Form>
          </Col>
        </Row>
        <Divider />
        <LastDetectedFloorPlan
          floorPlan={floorPlan}
          setSelectedComponent={setSelectedComponent}
          detectedBySingleZone={selectedObject}
          detectedByAllZones={floorPlanComponent}
          userTimeZone={userTimeZone}
          loading={loading}
        />
      </div>
    </>
  );
};

export default LastDetected2D;
