import * as type from "../types";
import {
  // deletePermissionService,
  getPermissionsService,
  // savePermissionService
} from "../../network/service/PermissionService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchPermissions = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.PERMISSIONS_FETCH_REQUEST,
      });
      const response = await dispatch(getPermissionsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.PERMISSIONS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.PERMISSIONS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.PERMISSIONS_FETCH_FAILURE,
      });
    }
  };
};

// export const savePermission = (data, id = 0) => {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: type.PERMISSION_SAVE_REQUEST,
//       });
//       const response = await dispatch(savePermissionService(data, id));
//       if (response.status === ResponseStatus.OK || response.status === ResponseStatus.CREATED) {
//         dispatch({
//           type: type.PERMISSION_SAVE_SUCCESS,
//         });
//       } else {
//         dispatch({
//           type: type.PERMISSION_SAVE_FAILURE,
//         });
//       }
//     } catch (ex) {
//       dispatchStateIfUnauthorized(ex, dispatch);
//       dispatch({
//         type: type.PERMISSION_SAVE_FAILURE,
//         payload: ex,
//       });
//     }
//   };
// };

// export const deletePermission = (data, id = 0) => {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: type.PERMISSION_DELETE_REQUEST,
//       });
//       const response = await dispatch(deletePermissionService(data));
//       if (response.status === ResponseStatus.OK || response.status === ResponseStatus.CREATED) {
//         dispatch({
//           type: type.PERMISSION_DELETE_SUCCESS,
//         });
//       } else {
//         dispatch({
//           type: type.PERMISSION_DELETE_FAILURE,
//         });
//       }
//     } catch (ex) {
//       dispatchStateIfUnauthorized(ex, dispatch);
//       dispatch({
//         type: type.PERMISSION_DELETE_FAILURE,
//         payload: ex,
//       });
//     }
//   };
// };
