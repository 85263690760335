import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Skeleton } from "antd";
import Map from "./Map";
import { CURRENT_LOCATION } from "../../../../api/graphQL/queries";
import { Empty, Result } from "antd";
import { useSelector } from "react-redux";

function AssetQuantity({ selectedAssetGroup }) {
  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const { loading, error, data, startPolling, stopPolling } = useQuery(
    CURRENT_LOCATION,
    {
      variables: {
        assetGroupIds: selectedAssetGroup ? parseInt(selectedAssetGroup) : [],
      },
      pollInterval: 60000,
      fetchPolicy: "network-only",
    }
  );

  if (loading) return <Skeleton active />;
  // if (error) return `Error!`;
  if (error) {
    // window.location.reload();
    return (
      <Result
        status="warning"
        title={
          language?.ERROR_FETCHING_DATA?.value ??
          englishLang?.ERROR_FETCHING_DATA?.value
        }
      />
    );
  }

  // check if we got data from api response
  function CheckForData() {
    if (data.currentlyDetectedAssets.length > 0) {
      return <Map data={data} />;
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return CheckForData();
}

export default AssetQuantity;
