import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchContacts,
  deleteContact,
} from "../../../api/redux/action/ContactActions";
import ContactActionView from "../../../components/common/ActionMenu";
import useNotification from "../../../components/common/ResultNotifier";
import { hasPermission } from "../../layout/Constants";
import { useHistory } from "react-router-dom";

function Contacts() {
  const dispatch = useDispatch();
  let history = useHistory();
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  const [searchText, setSearchText] = useState();

  const {
    contacts,
    error,
    requestStatus,
    loading,
    user,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    contacts: store.contactReducer.contacts,
    error: store.contactReducer.error,
    requestStatus: store.contactReducer.requestStatus,
    loading: store.contactReducer.loading,
    user: store.authReducer.user,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const columns = [
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
      sorter: true,
      sortField: "name",
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      render: (name) => (
        <a
          onClick={() => {
            const viewOption = "disabled";

            history.push({
              pathname: "/update-contact",
              state: { selectedRow, viewOption },
            });
          }}
        >
          {name}
        </a>
      ),
    },
    {
      title: language?.SURNAME?.value ?? englishLang?.SURNAME?.value,
      dataIndex: "surname",
      sorter: true,
      sortField: "surname",
      sortOrder: sortedInfo.columnKey === "surname" && sortedInfo.order,
    },

    {
      title: language?.MOBILE?.value ?? englishLang?.MOBILE?.value,
      dataIndex: "mobile",
    },
    {
      title: language?.EMAIL?.value ?? englishLang?.EMAIL?.value,
      dataIndex: "email",
    },
  ];

  const [allowPermission, setAllowPermission] = useState({
    edit: hasPermission(selectedTenant, "UPDATE_CONTACTS"),
    delete: hasPermission(selectedTenant, "DELETE_CONTACTS"),
    view: hasPermission(selectedTenant, "READ_CONTACTS"),
  });

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const handleSingleDelete = (selected) => {
    dispatch(deleteContact(selected.contactId));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const {
    DataTable,
    hasSelected,
    selectedRow,
    currentPage,
    pageSize,
    sortField,
    sortOrder,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "contact",
    dataSource: contacts,
    updateEntityPath: "update-contact",
    customActionView:
      allowPermission.edit || allowPermission.delete || allowPermission.view
        ? ContactActionView
        : "",
    searchText,
    loading: loading,
    handleSingleDelete,
    allowPermission,
    setSortedInfo,
    title: language?.CONTACTS?.value ?? englishLang?.CONTACTS?.value,
  });

  useEffect(() => {
    dispatch(
      fetchContacts({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo]);

  let flattenedData = [];

  if (Object.keys(contacts).length !== 0) {
    flattenedData = Object.values(contacts.content);

    flattenedData.forEach((contact) => {
      const mobile = contact.contactDetails.filter((obj) => {
        return obj.contactType === "MOBILE";
      });

      const fixed = contact.contactDetails.filter((obj) => {
        return obj.contactType === "FIXED_LINE";
      });
      const email = contact.contactDetails.filter((obj) => {
        return obj.contactType === "EMAIL";
      });

      contact.mobile = mobile[0] ? mobile[0].contactValue : "";
      contact.fixed = fixed[0] ? fixed[0].contactValue : "";
      contact.email = email[0] ? email[0].contactValue : "";
    });
  }

  const headers = [
    {
      label: language?.NAME?.value ?? englishLang?.NAME?.value,
      key: "name",
    },
    {
      label: language?.SURNAME?.value ?? englishLang?.SURNAME?.value,
      key: "surname",
    },
    // { label: 'Fixed Line', key: 'contactDetails.fixedLine' },
    {
      label: language?.MOBILE?.value ?? englishLang?.MOBILE?.value,
      key: "mobile",
    },
    {
      label: language?.EMAIL?.value ?? englishLang?.EMAIL?.value,
      key: "email",
    },
  ];

  const addNewPath = hasPermission(selectedTenant, "CREATE_CONTACTS")
    ? "add-contact"
    : "";

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath={addNewPath}
          hasSelected={hasSelected}
          exportTableTitle={
            language?.CONTACTS?.value ?? englishLang?.CONTACTS?.value
          }
          addNewText={
            language?.ADD_CONTACT?.value ?? englishLang?.ADD_CONTACT?.value
          }
          title={language?.CONTACTS?.value ?? englishLang?.CONTACTS?.value}
          csvData={flattenedData}
          csvHeaders={headers}
          handleSearch={setSearchText}
          tooltipText={
            language?.TOOLTIP_SEARCH_ALL?.value ??
            englishLang?.TOOLTIP_SEARCH_ALL?.value
          }
        />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default Contacts;
