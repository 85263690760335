import * as type from "../types";
import { RequestState } from "./RequestState";

const INITIAL_STATE = {
  assetLocation: {},
  assetLocations: {},
  allAssetLocation: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  exportAllLoading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.ASSETLOCATION_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        assetLocations: {},
        loading: true,
      };
    case type.ASSETLOCATION_FETCH_SUCCESS:
      return {
        ...state,
        assetLocations: action.payload,
        loading: false,
      };
    case type.ASSETLOCATION_FETCH_FAILURE:
      return {
        ...state,
        assetLocations: {},
        loading: false,
      };
    //ALL ASSET LOCATION
    //Start
    case type.ALL_ASSETLOCATION_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        allAssetLocation: {},
        exportAllLoading: true,
      };
    case type.ALL_ASSETLOCATION_FETCH_SUCCESS:
      return {
        ...state,
        allAssetLocation: action.payload,
        loading: false,
        exportAllLoading: false,
      };
    case type.ALL_ASSETLOCATION_FETCH_FAILURE:
      return {
        ...state,
        allAssetLocation: {},
        exportAllLoading: false,
      };
    // END
    // ASSET LOCATION SAVE
    // START
    case type.ASSETLOCATION_SAVE_REQUEST:
      return {
        ...state,
        error: {},
        assetLocation: {},
        requestStatus: RequestState.PENDING,
      };
    case type.ASSETLOCATION_SAVE_SUCCESS:
      return {
        ...state,
        assetLocation: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.ASSETLOCATION_SAVE_FAILURE:
      return {
        ...state,
        assetLocation: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
    case type.ASSETLOCATION_DELETE_REQUEST:
      return {
        ...state,
        requestStatus: RequestState.PENDING,
      };
    case type.ASSETLOCATION_DELETE_SUCCESS:
      return {
        ...state,
        assetLocation: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.ASSETLOCATION_DELETE_FAILURE:
      return {
        ...state,
        assetLocation: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
    case type.CLEAN_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: RequestState.EMPTY,
      };
    default:
      return state;
  }
};

export default reducer;
