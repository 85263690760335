import * as axios from "axios";

const host = window.location.host;

function getPublicRootUrl(reduxState) {
  let rootURL = host;
  if (reduxState !== null) {
    rootURL = "/api/public";
  }
  return rootURL;
}

const requestPublic = (url, reduxState, options) => {
  return async () => {
    const config = {
      url: getPublicRootUrl(reduxState) + url,
      ...options,
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    let response;

    try {
      response = axios(config);
    } catch (error) {
      console.error(error);
    }
    return response;
  };
};

const requestPublicWithTenant = (url, reduxState, options) => {
  return async () => {
    const config = {
      url: getPublicRootUrl(reduxState) + url,
      ...options,
      withCredentials: true,
      headers: {
        "X-Tenant-ID": reduxState.authReducer.selectedTenant.domain,
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    let response;

    try {
      response = axios(config);
    } catch (error) {
      console.error(error);
    }
    return response;
  };
};

export { requestPublic, requestPublicWithTenant };
