import { request } from "../axios/request";
import { format } from "react-string-format";

export const authService = (params, data) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/login", getState(), {
        method: "POST",
        headers: {
          Authorization: format(
            "Basic {0}",
            new Buffer(
              format("{0}:{1}", data.username, data.password)
            ).toString("base64")
          ),
        },
        params,
      })
    );
  };
};

export const logoutService = () => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/logout", getState(), {
        method: "DELETE",
      })
    );
  };
};

export const getFleetActiveSessionService = (params, data) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/fleet/sessions", getState(), {
        method: "GET",
        params,
      })
    );
  };
};
