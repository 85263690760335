import * as type from '../types';
import { RequestState } from './RequestState';


const INITIAL_STATE = {
  roles: {},
  role: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false
};

const reducer= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.ROLES_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        roles: {},
        loading: true
      };
    case type.ROLES_FETCH_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        loading: false
      };
    case type.ROLES_FETCH_FAILURE:
      return {
        ...state,
        roles: {},
        loading: false
      };

      case type.ROLE_SAVE_REQUEST:
			return {
				...state,
				error: {},
				role: {},
				requestStatus: RequestState.PENDING,
			};
		case type.ROLE_SAVE_SUCCESS:
			return {
				...state,
				role: action.payload,
				error: {},
				requestStatus: RequestState.SUCCESS,
			};
		case type.ROLE_SAVE_FAILURE:
			return {
				...state,
				role: {},
				error: action.payload,
				requestStatus: RequestState.FAILURE,
			};
		case type.ROLE_DELETE_REQUEST:
			return {
				...state,
				requestStatus: RequestState.PENDING,
			};
        case type.ROLE_DELETE_SUCCESS:
			return {
				...state,
				role: action.payload,
				error: {},
				requestStatus: RequestState.SUCCESS,
			};
        case type.ROLE_DELETE_FAILURE:
			return {
				...state,
				role: {},
				error: action.payload,
				requestStatus: RequestState.FAILURE,
			};
          case type.CLEAN_REQUEST_STATUS:
            return {
              ...state,
              requestStatus: RequestState.EMPTY
            };
    default:
      return state;
  }
};


export default reducer;