import React from "react";
import { Form, Input } from "antd";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import { useSelector } from "react-redux";
import "../../layout/Style.css";

const AddAssetLocationForm = (props) => {
  const { englishLang } = useSelector((store) => ({
    englishLang: store.langReducer.englishLang,
  }));
  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="assetLocation-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        label={props.language?.NAME?.value ?? englishLang?.NAME?.value}
        name="name"
        id={ElementIDS().name}
        rules={requiredFieldRule}
      >
        <Input
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
          onChange={(e) => {
            props.setDirty();
          }}
        />
      </Form.Item>

      {props.footer}
    </Form>
  );
};

export default AddAssetLocationForm;
