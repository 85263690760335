import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";

import { saveGateway } from "../../../api/redux/action/GatewayActions";
import useNotification from "../../../components/common/ResultNotifier";
import AddGatewayForm from "./AddGatewayForm";
import history from "../../../router/history";
import AddFooterSection from "../../../components/common/AddFooterSection";

const AddGateway = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { error, requestStatus, language, englishLang } = useSelector(
    (store) => ({
      error: store.gatewayReducer.error,
      requestStatus: store.gatewayReducer.requestStatus,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
    })
  );

  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM.value;

  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      form.setFieldsValue({
        gatewayId: props.location.state.selectedRow.gatewayId,
        name: props.location.state.selectedRow.name,
        description: props.location.state.selectedRow.description,
      });
    }
  }, []);

  const handleSave = (values) => {
    const formData = {
      name: form.getFieldValue().name,
      description: form.getFieldValue().description,
    };

    dispatch(saveGateway(formData, form.getFieldValue().gatewayId));
  };

  const handleCancel = () => {
    history.goBack();
  };

  const footer = (
    <AddFooterSection
      viewOption={props.location.state}
      handleCancel={handleCancel}
      form={form}
      // updatePath={"update"}
      // handleEditingPermission={handleEditingPermission}
    />
  );

  return (
    <Card
      title={language?.GATEWAY?.value ?? englishLang?.GATEWAY?.value}
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddGatewayForm
            language={language}
            handleSave={handleSave}
            form={form}
            footer={footer}
          />
        </Col>
      </Row>
      <ResultNotifier />
    </Card>
  );
};

export default AddGateway;
