import * as type from '../types';
import { RequestState } from './RequestState';


const INITIAL_STATE = {
  permissions: {},
  permission: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false
};

const reducer  = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.PERMISSIONS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        permissions: {},
        loading: true
      };
    case type.PERMISSIONS_FETCH_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
        loading: false
      };
    case type.PERMISSIONS_FETCH_FAILURE:
      return {
        ...state,
        permissions: {},
        loading: false
      };

      case type.PERMISSION_SAVE_REQUEST:
			return {
				...state,
				error: {},
				permission: {},
				requestStatus: RequestState.PENDING,
			};
		case type.PERMISSION_SAVE_SUCCESS:
			return {
				...state,
				permission: action.payload,
				error: {},
				requestStatus: RequestState.SUCCESS,
			};
		case type.PERMISSION_SAVE_FAILURE:
			return {
				...state,
				permission: {},
				error: action.payload,
				requestStatus: RequestState.FAILURE,
			};
		case type.PERMISSION_DELETE_REQUEST:
			return {
				...state,
				requestStatus: RequestState.PENDING,
			};
        case type.PERMISSION_DELETE_SUCCESS:
			return {
				...state,
				permission: action.payload,
				error: {},
				requestStatus: RequestState.SUCCESS,
			};
        case type.PERMISSION_DELETE_FAILURE:
			return {
				...state,
				permission: {},
				error: action.payload,
				requestStatus: RequestState.FAILURE,
			};
          case type.CLEAN_REQUEST_STATUS:
            return {
              ...state,
              requestStatus: RequestState.EMPTY
            };
    default:
      return state;
  }
};



export default reducer;