import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import * as axios from "axios";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
  Observable,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { store } from "./api/redux/store";
import history from "./router/history";
import SecuredLayout from "./page/layout/SecuredLayout";
import Login from "./page/Login/Login";
import NotFound from "./page/common/404";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./page/common/ErrorFallback";
import "./App.css";

import ForgotPassword from "./page/Login/ForgotPassword";
import ResetPassword from "./page/Login/ResetPassword";

const initializeClient = (domain) => {
  const authLink = setContext((_, { headers }) => {
    const accessToken = localStorage.getItem("accToken");

    return {
      headers: {
        ...headers,
        "X-Tenant-ID": domain,
        Authorization: accessToken ? `${accessToken}` : "",
      },
    };
  });

  const httpLink = createHttpLink({
    uri: "/graphql",
  });

  const errorLink = onError(
    ({ networkError, graphQLErrors, operation, forward }) => {
      if (networkError && networkError.statusCode === 401) {
        return new Observable((observer) => {
          const refreshToken = localStorage.getItem("refreshToken");

          axios
            .post("/api/token/refresh", {
              headers: {
                "Content-Type": "application/json",
                "X-Tenant-ID": domain,
                Authorization: refreshToken,
              },
            })
            .then((response) => {
              let newAccToken = response.headers.authorization;
              localStorage.setItem("accToken", newAccToken);
              localStorage.setItem(
                "refreshToken",
                response.headers["x-auth-refresh-token"]
              );

              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  Authorization: newAccToken,
                },
              }));

              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };

              forward(operation).subscribe(subscriber);
            })
            .catch((error) => {
              console.log(error);
              localStorage.clear();
              location.reload();
            });
        });
      }

      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          if (err.extensions && err.extensions.code === "UNAUTHENTICATED") {
            return new Observable((observer) => {
              const refreshToken = localStorage.getItem("refreshToken");

              axios
                .post("/api/token/refresh", {
                  headers: {
                    "Content-Type": "application/json",
                    "X-Tenant-ID": domain,
                    Authorization: refreshToken,
                  },
                })
                .then((response) => {
                  let newAccToken = response.headers.authorization;
                  localStorage.setItem("accToken", newAccToken);
                  localStorage.setItem(
                    "refreshToken",
                    response.headers["x-auth-refresh-token"]
                  );

                  operation.setContext(({ headers = {} }) => ({
                    headers: {
                      ...headers,
                      Authorization: newAccToken,
                    },
                  }));

                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };

                  forward(operation).subscribe(subscriber);
                })
                .catch((error) => {
                  console.log(error);
                  localStorage.clear();
                  location.reload();
                });
            });
          }
        }
      }
      return forward(operation);
    }
  );

  const retryLink = new RetryLink({
    attempts: {
      max: 3,
      retryIf: (error) => !!error,
    },
  });

  const client = new ApolloClient({
    link: from([retryLink, errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
  });

  return client;
};
function App() {
  return (
    <ApolloProvider
      client={initializeClient(
        JSON.parse(localStorage.getItem("selectedTenant"))?.domain
      )}
    >
      <Provider store={store}>
        <Router history={history}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={() => {
              return history.push("/error");
            }}
          >
            <Switch>
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route exact path="/error" component={ErrorFallback} />
              <Route exact path="/login" component={Login} />
              <Route path="/" component={SecuredLayout} />
              <Route component={NotFound} />
            </Switch>
          </ErrorBoundary>
        </Router>
      </Provider>
    </ApolloProvider>
  );
}

export default App;
