import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDataTable from "../../../components/common/DataTable";
import Header from "../../../components/common/ReportHeader";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import { RedoOutlined } from "@ant-design/icons";
import {
  fetchReport,
  resetReport,
} from "../../../api/redux/action/ReportActions";
import useNotification from "../../../components/common/ResultNotifier";
import { toTimestamp } from "../../layout/Constants";

import {
  Col,
  Divider,
  Space,
  Tooltip,
  Row,
  DatePicker,
  Button,
  Tag,
} from "antd";
import { exportRequest } from "../../../api/network/axios/exportRequest";
import { killAsset } from "../../../api/redux/action/AssetActions";

const dateFormat = "DD-MM-YYYY";
const { RangePicker } = DatePicker;

const KilledAssets = () => {
  const today = new Date();
  const todayStr =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  const dispatch = useDispatch();

  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [dataTable, setDataTable] = useState();

  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState({
    fromDate: todayStr,
    toDate: todayStr,
  });
  const [dateValue, setDateValue] = useState([]);
  const [missingFieldsText, setMissingFieldsText] = useState();
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  const {
    requestStatus,
    loading,
    data,
    allData,
    error,
    userTimeZone,
    language,
    englishLang,
  } = useSelector((store) => ({
    requestStatus: store.reportReducer.requestStatus,
    loading: store.reportReducer.loading,
    data: store.reportReducer.data,
    allData: store.reportReducer.allData,
    error: store.reportReducer.error,
    userTimeZone: store.authReducer.userTimeZone,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const columns = [
    {
      key: "timeOfDeath",
      sorter: true,
      sortField: "timeOfDeath",
      sortOrder: sortedInfo.columnKey === "timeOfDeath" && sortedInfo.order,
      title: language?.KILLED_TIME?.value ?? englishLang?.KILLED_TIME?.value,
      dataIndex: ["timeOfDeath"],
      render: (timeOfDeath) => (
        <span>
          {timeOfDeath
            ? moment(timeOfDeath, "YYYY-MM-DD'T'HH:mm:ssZ")
                .tz(userTimeZone, false)
                ?.format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
    },
    {
      key: "assetEntity.name",
      sorter: true,
      sortField: "assetEntity.name",
      sortOrder:
        sortedInfo.columnKey === "assetEntity.name" && sortedInfo.order,
      title: language?.ASSET_NAME?.value ?? englishLang?.ASSET_NAME?.value,
      dataIndex: ["asset", "name"],
    },
    {
      key: "ze.killAssets",
      sorter: true,
      sortField: "zoneEntity.killAssets",
      sortOrder:
        sortedInfo.columnKey === "zoneEntity.killAssets" && sortedInfo.order,
      title: language?.TYPE?.value ?? englishLang?.TYPE?.value,
      dataIndex: ["zone", "killAssets"],
      render: (killAssets) =>
        killAssets ? (
          <Tag>
            {language?.AUTOMATIC?.value ?? englishLang?.AUTOMATIC?.value}
          </Tag>
        ) : (
          <Tag>{language?.MANUAL?.value ?? englishLang?.MANUAL?.value}</Tag>
        ),
    },
    {
      key: "assetEntity.code",
      sorter: true,
      sortField: "assetEntity.code",
      sortOrder:
        sortedInfo.columnKey === "assetEntity.code" && sortedInfo.order,
      title: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      dataIndex: ["asset", "code"],
    },
    {
      key: "age.name",
      sorter: true,
      sortField: "age.name",
      sortOrder: sortedInfo.columnKey === "age.name" && sortedInfo.order,
      title: language?.ASSET_GROUP?.value ?? englishLang?.ASSET_GROUP?.value,
      dataIndex: ["asset", "assetGroup", "name"],
    },
    {
      key: "ale.name",
      sorter: true,
      sortField: "ale.name",
      sortOrder: sortedInfo.columnKey === "ale.name" && sortedInfo.order,
      title:
        language?.ASSET_LOCATION?.value ?? englishLang?.ASSET_LOCATION?.value,
      dataIndex: ["asset", "assetLocation", "name"],
    },
    {
      key: "se.name",
      sorter: true,
      sortField: "se.name",
      sortOrder: sortedInfo.columnKey === "se.name" && sortedInfo.order,
      title: language?.SITE?.value ?? englishLang?.SITE?.value,
      dataIndex: ["zone", "site", "name"],
    },
    {
      key: "ze.name",
      sorter: true,
      sortField: "ze.name",
      sortOrder: sortedInfo.columnKey === "ze.name" && sortedInfo.order,
      title: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      dataIndex: ["zone", "name"],
    },
    {
      key: "zoneEntity",
      sorter: true,
      sortField: "zoneEntity",
      sortOrder: sortedInfo.columnKey === "zoneEntity" && sortedInfo.order,
      title: language?.USER?.value ?? englishLang?.USER?.value,
      dataIndex: "username",
    },
  ];

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    dataSource: data,
    updateEntityPath: "",
    filter,
    loading: loading,
    missingFieldsText: missingFieldsText,
    setSortedInfo: setSortedInfo,
  });

  function missingFieldsTextCheck() {
    if (dateValue == null) {
      return (
        language?.SELECT_DATE_RANGE?.value ??
        englishLang?.SELECT_DATE_RANGE?.value
      );
    } else if (dateValue != null && data.totalElements == 0) {
      return language?.NO_DATA?.value ?? englishLang?.NO_DATA?.value;
    }
  }

  const handleDateRangeChange = (date, dateString) => {
    setDateValue(date);
    setFilter({
      ...filter,
      fromDate: dateString[0],
      toDate: dateString[1],
    });
  };

  const handleRefreshClick = () => {
    setRefresh(!refresh);
  };

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const checkNoMissingParams = () => {
    if (!(filter.fromDate && filter.toDate)) {
      setMissingFieldsText(
        language?.SELECT_DATE_RANGE?.value ??
          englishLang?.SELECT_DATE_RANGE?.value
      );
      return false;
    }
    return true;
  };
  const ascDesc = () => {
    if (sortedInfo.order === "ascend") {
      return "ASC";
    } else {
      return "DESC";
    }
  };

  useEffect(() => {
    dispatch(resetReport());
  }, []);

  useEffect(() => {
    let endpoint = sortedInfo.columnKey
      ? `killed-assets-report?sort=${sortedInfo.columnKey},${ascDesc()}`
      : "killed-assets-report";

    // dispatch(resetReport());
    if (checkNoMissingParams()) {
      setMissingFieldsText();
      dispatch(
        fetchReport({
          page: searchFormHelperHandler(),
          size: pageSize,
          fromDate: toTimestamp(filter.fromDate, "start"),
          toDate: toTimestamp(filter.toDate, "end"),
          endpoint: endpoint,
        })
      );
    }
  }, [currentPage, filter, isLoading, refresh, pageSize, sortedInfo]);

  useEffect(() => {
    setDataTable(DataTable);
  }, [loading, language]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-killed-assets";

    let options = {
      url: "/api/web/reports/killed-assets-report/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      let flatData;
      function flatDataHandler() {
        if (data.content === undefined) {
          return (flatData = Object.values(data));
        } else {
          return (flatData = Object.values(data.content));
        }
      }
      flatDataHandler();
      flatData.map((item) => {
        item.killTimestamp = item.killTimestamp
          ? moment(item.killTimestamp, "YYYY-MM-DD'T'HH:mm:ssZ")
              .tz(userTimeZone, false)
              ?.format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedAllData(Object.values(flatData));
    }
  }, [data]);

  const headers = [
    {
      label: language?.KILLED_TIME?.value ?? englishLang?.KILLED_TIME?.value,
      key: "timeOfDeath",
    },
    {
      label: language?.ASSET_NAME?.value ?? englishLang?.ASSET_NAME?.value,
      key: "asset.name",
    },
    {
      label: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      key: "asset.code",
    },
    {
      label: language?.ASSET_GROUP?.value ?? englishLang?.ASSET_GROUP?.value,
      key: "asset.assetGroup.name",
    },
    {
      label:
        language?.ASSET_LOCATION?.value ?? englishLang?.ASSET_LOCATION?.value,
      key: "asset.assetLocation.name",
    },
    {
      label: language?.SITE?.value ?? englishLang?.SITE?.value,
      key: "zone.site.name",
    },
    {
      label: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      key: "zone.name",
    },
    {
      label: language?.USER?.value ?? englishLang?.USER?.value,
      key: "username",
    },
  ];

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          hasSelected={hasSelected}
          exportTableTitle={
            language?.KILLED_ASSETS?.value ?? englishLang?.KILLED_ASSETS?.value
          }
          addNewText=""
          title={
            language?.KILLED_ASSETS?.value ?? englishLang?.KILLED_ASSETS?.value
          }
          loading={loading}
          exportAllLoading={exportAllLoading}
          exportAllButtonClick={handleExportAllButtonClick}
          csvHeaders={headers}
          csvAllData={flattenedAllData}
        />
        <Row>
          <Col>
            <Space>
              <Tooltip>
                <RangePicker
                  defaultValue={[
                    moment(todayStr, dateFormat),
                    moment(todayStr, dateFormat),
                  ]}
                  format={dateFormat}
                  onChange={handleDateRangeChange}
                  style={{ width: 250 }}
                  id={ElementIDS().calendar}
                />
              </Tooltip>
              <Button
                type="primary"
                onClick={() => handleRefreshClick()}
                id={ElementIDS().refresh}
              >
                <RedoOutlined />
                {language?.REFRESH?.value ?? englishLang?.REFRESH?.value}
              </Button>
            </Space>
          </Col>
        </Row>
        <Divider />
        {dataTable}
        <ResultNotifier />
      </div>
    </>
  );
};

export default KilledAssets;
