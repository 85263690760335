import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Modal, Form, Input, Spin } from "antd";
import "antd/dist/antd.min.css";

import moment from "moment-timezone";

const FloorPlanComponentModal = ({
  isComponentModalOpen,
  setComponentModalOpen,
  dataComponent,
  userTimeZone,
  loading,
}) => {
  const [form] = Form.useForm();
  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));
  const handleModalOk = () => {
    setComponentModalOpen(false);
  };

  const handleModalCancel = () => {
    setComponentModalOpen(false);
  };

  useEffect(() => {
    if (dataComponent) {
      switch (dataComponent.type) {
        case "Reader":
          form.setFieldsValue({
            name: dataComponent.data?.name,
            gateway: dataComponent.data?.gateway?.name,
            ipAddress: dataComponent.data?.ipAddress,
            portNumber: dataComponent.data?.portNumber,
            status: dataComponent.data?.status,
            lastConnectionTimestamp: dataComponent.data?.lastConnectionTimestamp
              ? moment
                  .tz(dataComponent.data?.lastConnectionTimestamp, userTimeZone)
                  .format("YYYY-MM-DD HH:mm:ss")
              : "",
          });
          break;
        case "Antenna":
          form.setFieldsValue({
            antennaNumber: dataComponent.data?.antennaNumber,
            reader: dataComponent.data?.reader?.name,
            site: dataComponent.data?.site?.name,
            zone: dataComponent.data?.zone?.name,
            lastActivityTimestamp: dataComponent.data?.lastActivityTimestamp
              ? moment
                  .tz(dataComponent.data?.lastActivityTimestamp, userTimeZone)
                  .format("YYYY-MM-DD HH:mm:ss")
              : "",
          });
          break;
        case "Denkovi":
          form.setFieldsValue({
            gateway: dataComponent.data?.gateway?.name,
            ipAddress: dataComponent.data?.ipAddress,
            port: dataComponent.data?.port,
          });
          break;
        case "Mini PC":
          form.setFieldsValue({
            name: dataComponent.data?.name,
            description: dataComponent.data?.description,
            status: dataComponent.data?.status,
            lastHealthyStatusTimestamp: dataComponent.data
              ?.lastHealthyStatusTimestamp
              ? moment
                  .tz(
                    dataComponent.data?.lastHealthyStatusTimestamp,
                    userTimeZone
                  )
                  .format("YYYY-MM-DD HH:mm:ss")
              : "",
          });
          break;
        default:
          form.resetFields();
      }
    }
  }, [dataComponent]);

  const handleComponentsForms = () => {
    if (loading) {
      return (
        <Spin
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      );
    } else {
      switch (dataComponent.type) {
        case "Reader":
          return (
            <>
              <Form
                labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
                wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
                name="reader_form"
                form={form}
              >
                <Form.Item
                  name="name"
                  label={language?.NAME?.value ?? englishLang?.NAME?.value}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  name="gateway"
                  label={
                    language?.GATEWAY?.value ?? englishLang?.GATEWAY?.value
                  }
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  name="ipAddress"
                  label={
                    language?.IP_ADDRESS?.value ??
                    englishLang?.IP_ADDRESS?.value
                  }
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  name="portNumber"
                  label={
                    language?.PORT_NUMBER?.value ??
                    englishLang?.PORT_NUMBER?.value
                  }
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  name="status"
                  label={language?.STATUS?.value ?? englishLang?.STATUS?.value}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  name="lastConnectionTimestamp"
                  label={
                    language?.LAST_CONNECTION_TIMESTAMP?.value ??
                    englishLang?.LAST_CONNECTION_TIMESTAMP?.value
                  }
                >
                  <Input disabled={true} />
                </Form.Item>
              </Form>
            </>
          );
        case "Antenna":
          return (
            <Form
              labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
              wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
              name="antenna_form"
              form={form}
            >
              <Form.Item
                name="antennaNumber"
                label={
                  language?.ANTENNA_NUMBER?.value ??
                  englishLang?.ANTENNA_NUMBER?.value
                }
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                name="reader"
                label={language?.READER?.value ?? englishLang?.READER?.value}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                name="site"
                label={language?.SITE?.value ?? englishLang?.SITE?.value}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                name="zone"
                label={language?.ZONE?.value ?? englishLang?.ZONE?.value}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                name="lastActivityTimestamp"
                label={
                  language?.LAST_ACTIVITY_TIMESTAMP?.value ??
                  englishLang?.LAST_ACTIVITY_TIMESTAMP?.value
                }
              >
                <Input disabled={true} />
              </Form.Item>
            </Form>
          );
        case "Denkovi":
          return (
            <Form
              labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
              wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
              name="iP_Relay_Card"
              form={form}
            >
              <Form.Item
                name="gateway"
                label={language?.GATEWAY?.value ?? englishLang?.GATEWAY?.value}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                name="ipAddress"
                label={
                  language?.IP_ADDRESS?.value ?? englishLang?.IP_ADDRESS?.value
                }
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                name="port"
                label={
                  language?.PORT_NUMBER?.value ??
                  englishLang?.PORT_NUMBER?.value
                }
              >
                <Input disabled={true} />
              </Form.Item>
            </Form>
          );
        case "Mini PC":
          return (
            <Form
              labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
              wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
              name="gateway_form"
              form={form}
            >
              <Form.Item
                name="name"
                label={language?.NAME?.value ?? englishLang?.NAME?.value}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                name="description"
                label={
                  language?.DESCRIPTION?.value ??
                  englishLang?.DESCRIPTION?.value
                }
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                name="status"
                label={language?.STATUS?.value ?? englishLang?.STATUS?.value}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                name="lastHealthyStatusTimestamp"
                label={
                  language?.LAST_HEALTHY_STATUS_TIMESTAMP?.value ??
                  englishLang?.LAST_HEALTHY_STATUS_TIMESTAMP?.value
                }
              >
                <Input disabled={true} />
              </Form.Item>
            </Form>
          );
        default:
          return null;
      }
    }
  };

  const handleTitleName = () => {
    if (dataComponent.type === "Reader" && dataComponent.data?.name) {
      return `${dataComponent.type} ${dataComponent.data?.name}`;
    } else if (
      dataComponent.type === "Antenna" &&
      dataComponent.data?.zone?.name
    ) {
      return `${dataComponent.type} ${dataComponent.data?.zone?.name}`;
    } else if (
      dataComponent.type === "Denkovi" &&
      dataComponent.data?.gateway?.name
    ) {
      return `${dataComponent.type} ${dataComponent.data?.gateway?.name}`;
    } else if (dataComponent.type === "Mini PC" && dataComponent.data?.name) {
      return `${dataComponent.type} ${dataComponent.data?.name}`;
    } else {
      return "";
    }
  };

  return (
    <>
      <Modal
        title={handleTitleName()}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        open={isComponentModalOpen}
        width={900}
        footer={null}
        style={{
          top: 5,
        }}
      >
        {handleComponentsForms()}
      </Modal>
    </>
  );
};

export default FloorPlanComponentModal;
