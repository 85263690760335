import * as type from "../types";
import { bulkUploadAssets } from "../../network/service/AssetService";
import dispatchStateIfUnauthorized from "./util";
import { ResponseStatus } from "../../network/axios/ResponseStatus";

export const bulkAssetUpload = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.POST_UPLOAD_ASSETS_REQUEST,
      });
      const response = await dispatch(bulkUploadAssets(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        await dispatch({
          type: type.POST_UPLOAD_ASSETS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.POST_UPLOAD_ASSETS_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.POST_UPLOAD_ASSETS_FAILURE,
        payload: ex,
      });
    }
  };
};

export const emptyUploadState = () => {
  return (dispatch) => {
    dispatch({
      type: type.RESET_UPLOAD_STORE,
    });
  };
};
