import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Form,
  Input,
  Select,
  Tooltip,
  Row,
  Col,
  Button,
  Modal,
  InputNumber,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { ElementIDS } from "../../../components/common/ElementsIDs";
import { hasSubscription } from "../../layout/Constants";
import { getModalSizeByWindowSize } from "../../layout/Constants";
import { requiredFieldRule } from "../../../components/RequiredFieldRule";

import AddAssetGroupForm from "../AssetGroups/AddAssetGroupForm";
import AddCustomFieldForm from "../CustomFields/AddCustomFieldForm";
import AddAssetLocationForm from "../AssetLocation/AddAssetLocationForm";
import useNotification from "../../../components/common/ResultNotifier";

import {
  fetchAssetLocation,
  saveAssetLocation,
} from "../../../api/redux/action/AssetLocationActions";
import {
  fetchAssetGroups,
  saveAssetGroup,
  fetchAssetGroupWithCustomFields,
} from "../../../api/redux/action/AssetGroupActions";

import "../../layout/Style.css";

const AddAssetForm = (props) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { TextArea } = Input;

  const [visible, setVisible] = useState(
    { assetGroup: false },
    { assetLocation: false }
  );
  const [assetGroupForm] = Form.useForm();
  const [assetLocationForm] = Form.useForm();
  const [newAssetGroupData, setNewAssetGroupData] = useState(undefined);
  const [newAssetLocationData, setNewAssetLocationData] = useState(undefined);
  const [modalSize, setModalSize] = useState(getModalSizeByWindowSize());

  const [sorted, setSorted] = useState({
    assetGroup: [],
    assetLocation: [],
    customField: [],
  });

  const [selectedAssetGroup, setSelectedAssetGroup] = useState(
    props.readOnly ? props.readOnly.selectedRow?.assetGroup.assetGroupId : null
  );

  const {
    assetGroups,
    error,
    requestStatus,
    language,
    assetLocations,
    user,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    assetGroups: store.assetGroupReducer.assetGroups,
    error: store.assetReducer.error,
    requestStatus: store.assetReducer.requestStatus,
    assetLocations: store.assetLocationReducer.assetLocations,
    language: store.langReducer.language,
    user: store.authReducer.user,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  // Dispatching API's for fetching
  useEffect(() => {
    dispatch(fetchAssetGroups());
    if (
      hasSubscription(selectedTenant, "ASSET_LOCATIONS_SUBSCRIPTION") === true
    ) {
      dispatch(fetchAssetLocation());
    }
  }, [newAssetGroupData, newAssetLocationData, isLoading]);

  useEffect(() => {
    if (selectedAssetGroup !== null) {
      dispatch(fetchAssetGroupWithCustomFields(selectedAssetGroup?.key));
    }
  }, [selectedAssetGroup]);

  // Sorting data from API
  useEffect(() => {
    if (assetLocations && assetLocations.content) {
      let sortedLocations = assetLocations.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSorted((prevState) => ({
        ...prevState,
        assetLocation: sortedLocations,
      }));
    }
  }, [assetLocations]);

  useEffect(() => {
    if (assetGroups && assetGroups.content) {
      let sortedGroups = assetGroups.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSorted((prevState) => ({
        ...prevState,
        assetGroup: sortedGroups,
      }));
    }
  }, [assetGroups]);

  useEffect(() => {
    if (props.assetGroupWithField && props.assetGroupWithField) {
      let sortedCustomFields = props.assetGroupWithField.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSorted((prevState) => ({
        ...prevState,
        customField: sortedCustomFields,
      }));
    }
  }, [props.assetGroupWithField]);

  // Seting new Data from Modals
  useEffect(() => {
    const updateFormData = (content, newData, setNewData, key) => {
      if (content && content.length && newData && newData.name) {
        const item = content.find((item) => item.name === newData.name);
        if (item) {
          props.form.setFieldsValue({
            ...(newData && {
              [key]: item[`${key}Id`],
            }),
          });
          setNewData(undefined);
        }
      }
    };

    updateFormData(
      assetLocations?.content,
      newAssetLocationData,
      setNewAssetLocationData,
      "assetLocation"
    );
    updateFormData(
      assetGroups?.content,
      newAssetGroupData,
      setNewAssetGroupData,
      "assetGroup"
    );
  }, [assetLocations, assetGroups]);

  // Saving new Data from Modal
  const handleAssetGroupFormSave = (values) => {
    const formData = {
      name: assetGroupForm.getFieldValue().name,
      department: assetGroupForm.getFieldValue().department
        ? { departmentId: assetGroupForm.getFieldValue().department }
        : undefined,
    };

    if (formData.name) {
      dispatch(saveAssetGroup(formData))
        .then(() => {
          setNewAssetGroupData(values);
          assetGroupForm.resetFields();
          setVisible(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleAssetLocationSave = (values) => {
    const formData = {
      name: assetLocationForm.getFieldValue("name"),
    };

    if (formData.name) {
      dispatch(
        saveAssetLocation(
          formData,
          assetLocationForm.getFieldValue("assetLocationId")
        )
      )
        .then(() => {
          setNewAssetLocationData(values);
          assetLocationForm.resetFields();
          setVisible(false);
        })
        .catch((err) => console.log(err));
    }
  };

  // Submiting data when clicked on OK modal
  const handleAssetGroupModalOk = (e) => {
    assetGroupForm.submit();
  };

  const handleAssetLocationModalOk = (e) => {
    assetLocationForm.submit();
  };

  const handleModalCancel = (e) => {
    setVisible(false);
  };

  const assetLocation = hasSubscription(
    selectedTenant,
    "ASSET_LOCATIONS_SUBSCRIPTION"
  ) ? (
    <Form.Item
      label={
        language?.ASSET_LOCATION?.value ?? englishLang?.ASSET_LOCATION?.value
      }
    >
      <Row gutter={3}>
        <Col flex={9} span={22}>
          <Form.Item name="assetLocation" noStyle>
            <Select
              onChange={(e) => {
                props.setDirty();
              }}
              allowClear
              style={{ width: "100%" }}
              placeholder={
                language?.ASSET_LOCATION?.value ??
                englishLang?.ASSET_LOCATION?.value
              }
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              id={ElementIDS().assetLocationForm}
              disabled={
                props.readOnly && props.readOnly.viewOption ? "disabled" : null
              }
            >
              {sorted.assetLocation.length > 0 &&
                sorted.assetLocation.map((item) => (
                  <Option
                    id={ElementIDS().assetLocationOptions}
                    key={item.assetLocationId}
                    value={item.assetLocationId}
                  >
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={1} span={2}>
          <Tooltip title={language.CREATE_NEW_ASSET_LOCATION?.value}>
            <Button
              id={ElementIDS().assetLocationBtn}
              icon={<PlusOutlined />}
              ghost
              type="primary"
              onClick={() => setVisible({ assetLocation: true })}
              style={{ width: "100%" }}
              disabled={
                props.readOnly && props.readOnly.viewOption ? "disabled" : null
              }
            ></Button>
          </Tooltip>
        </Col>
      </Row>
    </Form.Item>
  ) : null;

  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="asset-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        id={ElementIDS().assetCode}
        label={language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value}
        name="code"
        rules={[
          {
            required: true,
            message:
              language?.REQUIRED_FIELD?.value ??
              englishLang?.REQUIRED_FIELD?.value,
          },
          () => ({
            validator(_, value) {
              const patten = /\s/g;
              let isSpace = patten.test(value);
              if (isSpace) {
                return Promise.reject(
                  new Error(
                    language?.SPACES_ARE_NOT_ALLOWED?.value ??
                      englishLang?.SPACES_ARE_NOT_ALLOWED?.value
                  )
                );
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <Form.Item
        id={ElementIDS().assetExternal}
        label={
          language?.ASSET_EXTERNAL_REFERENCE_NO?.value ??
          englishLang?.ASSET_EXTERNAL_REFERENCE_NO?.value
        }
        name="assetExternalReference"
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <Form.Item
        id={ElementIDS().name}
        label={language?.NAME?.value ?? englishLang?.NAME?.value}
        name="name"
        rules={requiredFieldRule}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <Form.Item
        label={language?.ASSET_GROUP?.value ?? englishLang?.ASSET_GROUP?.value}
        required
      >
        <Row gutter={3}>
          <Col flex={9} span={22}>
            <Form.Item name="assetGroup" noStyle rules={requiredFieldRule}>
              <Select
                onChange={(e, value) => {
                  setSelectedAssetGroup(value);
                  props.setDirty();
                }}
                id={ElementIDS().assetGroup}
                allowClear
                style={{ width: "100%" }}
                placeholder={
                  language?.SELECT_ASSEST_GROUP?.value ??
                  englishLang?.SELECT_ASSEST_GROUP?.value
                }
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
                defaultValue={
                  props.readOnly?.selectedRow
                    ? props.readOnly?.selectedRow.assetGroupId
                    : null
                }
              >
                {sorted.assetGroup.length > 0 &&
                  sorted.assetGroup.map((item) => (
                    <Option
                      id={ElementIDS().assetGroupOptions}
                      key={item.assetGroupId}
                      value={item.assetGroupId}
                    >
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col flex={1} span={2}>
            <Tooltip
              title={
                language?.CREATE_NEW_ASSET_GROUP?.value ??
                englishLang?.CREATE_NEW_ASSET_GROUP?.value
              }
            >
              <Button
                id={ElementIDS().create}
                icon={<PlusOutlined />}
                ghost
                type="primary"
                onClick={() => setVisible({ assetGroup: true })}
                style={{ width: "100%" }}
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              />
            </Tooltip>
          </Col>
        </Row>
      </Form.Item>
      {sorted.customField &&
        selectedAssetGroup !== null &&
        sorted.customField.map((item) =>
          item.type == "MULTI_SELECT" ? (
            <Form.Item
              id={ElementIDS().customFieldForm}
              label={item.name}
              rules={item.required ? requiredFieldRule : null}
              name={item.name}
            >
              <Select
                onChange={(e) => {
                  props.setDirty();
                }}
                id={ElementIDS().customFieldForm}
                allowClear
                mode="multiple"
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              >
                {item.options?.length > 0 &&
                  item.options?.map((option) => (
                    <Option
                      id={ElementIDS().customFieldOptions}
                      key={option}
                      value={option}
                    >
                      {option}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          ) : item.type == "SINGLE_SELECT" ? (
            <Form.Item
              id={ElementIDS().customFieldForm}
              label={item.name}
              rules={item.required ? requiredFieldRule : null}
              name={item.name}
            >
              <Select
                onChange={(e) => {
                  props.setDirty();
                }}
                id={ElementIDS().customFieldForm}
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              >
                {item.options?.length > 0 &&
                  item.options?.map((option) => (
                    <Option
                      id={ElementIDS().customFieldOptions}
                      key={option}
                      value={option}
                    >
                      {option}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          ) : item.type == "STRING" ? (
            <Form.Item
              id={ElementIDS().customFieldForm}
              label={item.name}
              rules={item.required ? requiredFieldRule : null}
              name={item.name}
            >
              <Input
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              />
            </Form.Item>
          ) : item.type == "NUMBER" ? (
            <Form.Item
              id={ElementIDS().customFieldForm}
              label={item.name}
              rules={item.required ? requiredFieldRule : null}
              name={item.name}
            >
              <InputNumber
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              />
            </Form.Item>
          ) : item.type == "DATE" ? (
            <Form.Item
              id={ElementIDS().customFieldForm}
              label={item.name}
              rules={item.required ? requiredFieldRule : null}
              name={item.name}
            >
              <DatePicker
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              />
            </Form.Item>
          ) : (
            <></>
          )
        )}

      {assetLocation}
      <Form.Item
        id={ElementIDS().epcCodes}
        name="epcCodes"
        label={language?.EPC_CODES?.value ?? englishLang?.EPC_CODES?.value}
        tooltip={
          language?.INPUT_EPC_CODES_ON_MULTIPLE_LINES?.value ??
          englishLang?.INPUT_EPC_CODES_ON_MULTIPLE_LINES?.value
        }
      >
        <TextArea
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>

      <Modal
        title={
          language?.ADD_ASSET_GROUP?.value ??
          englishLang?.ADD_ASSET_GROUP?.value
        }
        visible={visible.assetGroup}
        onOk={handleAssetGroupModalOk}
        onCancel={handleModalCancel}
        width={modalSize}
      >
        <Row justify="center">
          <Col span={20}>
            <AddAssetGroupForm
              setDirty={props.setDirty}
              handleSave={handleAssetGroupFormSave}
              form={assetGroupForm}
              language={language}
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        title={
          language?.ADD_ASSET_LOCATION?.value ??
          englishLang?.ADD_ASSET_LOCATION?.value
        }
        visible={visible.assetLocation}
        width={modalSize}
        onCancel={handleModalCancel}
        onOk={handleAssetLocationModalOk}
      >
        <Row justify="center">
          <Col span={20}>
            <AddAssetLocationForm
              handleSave={handleAssetLocationSave}
              form={assetLocationForm}
              language={language}
              setDirty={props.setDirty}
            />
          </Col>
        </Row>
      </Modal>
      <ResultNotifier />
      {props.footer}
    </Form>
  );
};

export default AddAssetForm;
