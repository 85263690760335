import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { Row, Card, Col, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { toTimestamp } from "../../../layout/Constants";

import useDataTable from "../../../../components/common/DataTable";
import { fetchReport } from "../../../../api/redux/action/ReportActions";

const LatestAlerts = ({ clickPath }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const today = new Date();
  const todayStr =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  const { data, user, userTimeZone, language, englishLang } = useSelector(
    (store) => ({
      data: store.reportReducer.data,
      user: store.authReducer.user,
      userTimeZone: store.authReducer.userTimeZone,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
    })
  );

  const columns = [
    {
      title:
        language?.DATE_AND_TIME?.value ?? englishLang?.DATE_AND_TIME?.value,
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp) => (
        <span>
          {timestamp
            ? moment.tz(timestamp, userTimeZone)?.format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
    },
    {
      title: language?.ASSET?.value ?? englishLang?.ASSET?.value,
      dataIndex: ["asset"],
      key: "asset",
      render: (a) => (
        <>
          {" "}
          <strong>{a?.code}</strong> {a?.name}
        </>
      ),
    },
    {
      title: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      key: "zone",
      dataIndex: ["zone", "name"],
    },
    {
      title: language?.RULE?.value ?? englishLang?.RULE?.value,
      key: "alertRule",
      dataIndex: ["alertRule", "name"],
    },
  ];
  const { DataTable, currentPage, pageSize, searchFormHelperHandler } =
    useDataTable({
      columns,
      dataSource: data,
      updateEntityPath: "",
      loading: false,
    });

  const handleClick = (path) => {
    history.push({
      pathname: path,
    });
  };

  useEffect(() => {
    dispatch(
      fetchReport({
        page: searchFormHelperHandler(),
        size: pageSize,
        includeRead: false,
        fromDate: toTimestamp(todayStr, "start"),
        toDate: toTimestamp(todayStr, "end"),
        endpoint: "alert-log",
      })
    );
  }, [currentPage, pageSize]);

  const tooltip = (
    <Tooltip
      title={
        language?.SHOW_LAST_TRIGGERED_ALERT_RULES?.value ??
        englishLang?.SHOW_LAST_TRIGGERED_ALERT_RULES?.value
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );

  return (
    <div>
      <Card
        title={
          language?.LAST_TRIGGERED_ALERTS?.value ??
          englishLang?.LAST_TRIGGERED_ALERTS?.value
        }
        extra={tooltip}
      >
        <Row gutter={24}>
          <Col span={24}>
            <DataTable size="small" />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default LatestAlerts;
