import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Divider,
  Select,
  Space,
  Row,
  DatePicker,
  Tooltip,
  Table,
  Button,
  Image,
  Dropdown,
  Menu,
  Checkbox,
} from "antd";
import moment from "moment-timezone";
import { EnvironmentOutlined, RedoOutlined } from "@ant-design/icons";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchReport,
  resetReport,
} from "../../../api/redux/action/ReportActions";
import { fetchAllCustomFields } from "../../../api/redux/action/CustomFieldActions";
import useNotification from "../../../components/common/ResultNotifier";
import {
  fetchUsers,
  fetchPreferredColumns,
  savePreferredColumns,
} from "../../../api/redux/action/UserActions";
import Header from "../../../components/common/ReportHeader";
import { toTimestamp } from "../../layout/Constants";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import { hasSubscription } from "../../layout/Constants";
import { exportRequest } from "../../../api/network/axios/exportRequest";

const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

const dateFormat = "DD-MM-YYYY";
const { RangePicker } = DatePicker;
const { Option } = Select;

function HandheldScannedAssets() {
  const [dataTable, setDataTable] = useState();

  const {
    user,
    data,
    allData,
    error,
    requestStatus,
    users,
    loading,
    allCustomFields,
    userTimeZone,
    preferredColumns,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    user: store.authReducer.user,
    data: store.reportReducer.data,
    allData: store.reportReducer.allData,
    error: store.reportReducer.error,
    requestStatus: store.reportReducer.requestStatus,
    users: store.userReducer.users,
    loading: store.reportReducer.loading,
    allCustomFields: store.customFieldReducer.allCustomFields,
    userTimeZone: store.authReducer.userTimeZone,
    preferredColumns: store.userReducer.preferredColumns,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const dispatch = useDispatch();

  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  let assetLocationColumn = hasSubscription(
    selectedTenant,
    "ASSET_LOCATIONS_SUBSCRIPTION"
  )
    ? {
        key: "assetLocationEntity.name",
        sorter: true,
        sortField: "assetLocationEntity.name",
        sortOrder:
          sortedInfo.columnKey === "assetLocationEntity.name" &&
          sortedInfo.order,
        title:
          language?.ASSET_LOCATION?.value ?? englishLang?.ASSET_LOCATION?.value,
        dataIndex: ["assetLocation", "name"],
        key: "assetLocation",
      }
    : {};

  const [columnToShow, setColumnToShow] = useState([]);
  const [selection, setSelection] = useState([]);
  /**
   * On first load get call APIs to get preferred columns, custom fields and users.
   */
  useEffect(() => {
    dispatch(fetchPreferredColumns());
    dispatch(fetchAllCustomFields());
    dispatch(fetchUsers());
    dispatch(resetReport());
  }, []);

  /**
   * Determines whether a column is preferred or not
   * @param {string} value The string the identifies the concerned column
   * @returns A boolean value that determines whether to show or hide the column based on the preferredColumns stored in redux
   */
  const hasPreferredColumn = (value) => {
    if (
      Object.keys(preferredColumns).length !== 0 &&
      preferredColumns.constructor === Object
    ) {
      return preferredColumns.columnDescriptors?.some(
        ({ label }) => label == value
      );
    }
    return false;
  };

  /**
   * Structures an array of column with all custom fields
   * @returns An list of custom fields columns
   */
  const customFieldsColumns = () => {
    let customFieldColumns = [];

    Object.keys(allCustomFields).length !== 0 &&
      allCustomFields.constructor === Object &&
      allCustomFields.content.map((row, index) => {
        let column = {};

        switch (row.type) {
          case "SINGLE_SELECT":
            column = {
              title: row.name,
              dataIndex: camelize(row.name.toString()),
              render: (value) => <span>{value}</span>,
              canHide: true,
              show: hasPreferredColumn(row.name),
            };
            break;
          case "MULTI_SELECT":
            column = {
              title: row.name,
              dataIndex: camelize(row.name.toString()),
              render: (values) => (
                <span>
                  {values ? values.map((ac) => <li key={ac}>{ac}</li>) : ""}
                </span>
              ),
              canHide: true,
              show: hasPreferredColumn(row.name),
            };
            break;
          case "DATE":
            column = {
              title: row.name,
              dataIndex: camelize(row.name.toString()),
              render: (value) => <span>{value}</span>,
              canHide: true,
              show: hasPreferredColumn(row.name),
            };
            break;
          case "IMAGE":
            column = {
              title: row.name,
              dataIndex: camelize(row.name.toString()),
              render: (value) => {
                const image = value ? <Image width={50} src={value} /> : "";
                return image;
              },
              canHide: true,
              show: hasPreferredColumn(row.name),
            };
            break;
        }
        customFieldColumns.push(column);
      });
    return customFieldColumns;
  };

  /**
   * Sets the list of columns depending on the the preferred columns
   */
  const setPreferredColumnsInitialState = () => {
    setColumnToShow([
      {
        title:
          language?.REPORT_START_TIMESTAMP?.value ??
          englishLang?.REPORT_START_TIMESTAMP?.value,
        dataIndex: "reportTimestamp",
        key: "reportTimestamp",
        render: (reportTimestamp) => (
          <span>
            {reportTimestamp
              ? moment(reportTimestamp, "YYYY-MM-DD'T'HH:mm:ssZ")
                  .tz(userTimeZone)
                  .format("DD-MM-YYYY HH:mm:ss")
              : ""}
          </span>
        ),
        canHide: false,
        show: true,
      },
      {
        title:
          language?.REPORT_END_TIMESTAMP?.value ??
          englishLang?.REPORT_END_TIMESTAMP?.value,
        dataIndex: "reportClosedTimestamp",
        key: "reportClosedTimestamp",
        render: (reportClosedTimestamp) => (
          <span>
            {reportClosedTimestamp
              ? moment(reportClosedTimestamp, "YYYY-MM-DD'T'HH:mm:ssZ")
                  .tz(userTimeZone)
                  .format("DD-MM-YYYY HH:mm:ss")
              : ""}
          </span>
        ),
        canHide: false,
        show: true,
      },
      {
        title:
          language?.ASSET_LOCATION?.value ?? englishLang?.ASSET_LOCATION?.value,
        dataIndex: ["assetLocation", "name"],
        key: "assetLocation",
        canHide: hasSubscription(
          selectedTenant,
          "ASSET_LOCATIONS_SUBSCRIPTION"
        ),
        show:
          hasSubscription(selectedTenant, "ASSET_LOCATIONS_SUBSCRIPTION") &&
          hasPreferredColumn(
            language?.ASSET_LOCATION?.value ??
              englishLang?.ASSET_LOCATION?.value
          ),
      },
      {
        title: language?.USER?.value ?? englishLang?.USER?.value,
        dataIndex: "user",
        key: "user",
        canHide: true,
        show: hasPreferredColumn(
          language?.USER?.value ?? englishLang?.USER?.value
        ),
      },
      {
        title: language?.COMMENTS?.value ?? englishLang?.COMMENTS?.value,
        dataIndex: "comment",
        key: "comment",
        canHide: true,
        show: hasPreferredColumn(
          language?.COMMENTS?.value ?? englishLang?.COMMENTS?.value
        ),
      },
      {
        title: language?.COUNT?.value ?? englishLang?.COUNT?.value,
        dataIndex: "handheldScans",
        key: "handheldScans",
        render: (handheldScans) => (
          <span>{handheldScans ? handheldScans.length : ""}</span>
        ),
        canHide: false,
        show: true,
      },
      // add the custom fields columns
      ...customFieldsColumns(),
    ]);
  };

  const expandColumns = [
    {
      title: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      dataIndex: ["asset", "code"],
      key: "Code",
      render(text, record) {
        return {
          props: {
            style: { background: record.backgroundColor },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title:
        language?.EXTERNAL_REFERENCE_NO?.value ??
        englishLang?.EXTERNAL_REFERENCE_NO?.value,
      dataIndex: ["asset", "assetExternalReference"],
      key: "assetExternalReference",
      render(text, record) {
        return {
          props: {
            style: { background: record.backgroundColor },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: ["asset", "name"],
      key: "asset-name",
      render(text, record) {
        return {
          props: {
            style: { background: record.backgroundColor },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title:
        language?.SCAN_TIMESTAMP?.value ?? englishLang?.SCAN_TIMESTAMP?.value,
      key: "detectedTimestamp",
      dataIndex: ["detectedTimestamp"],
      render(detectedTimestamp, record) {
        return {
          props: {
            style: { background: record.backgroundColor },
          },
          children: (
            <span>
              {detectedTimestamp
                ? moment(detectedTimestamp, "YYYY-MM-DD'T'HH:mm:ssZ")
                    .tz(userTimeZone, false)
                    ?.format("DD-MM-YYYY HH:mm:ss")
                : ""}
            </span>
          ),
        };
      },
    },
    {
      title:
        language?.FIRST_DETECTED_LOCATION?.value ??
        englishLang?.FIRST_DETECTED_LOCATION?.value,
      key: "location",
      dataIndex: ["location"],
      render(location, record) {
        return {
          props: {
            style: { background: record.backgroundColor },
          },
          children:
            location && location.latitude && location.longitude ? (
              <Button
                type="primary"
                shape="circle"
                icon={<EnvironmentOutlined />}
                onClick={() => {
                  window.open(
                    "http://www.google.com/maps/place/" +
                      location.latitude +
                      "," +
                      location.longitude,
                    "_blank"
                  );
                }}
              />
            ) : (
              ""
            ),
        };
      },
    },
  ];

  const today = new Date();
  const todayStr =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  const [exportAllLoading, setExportAllLoading] = useState(false);

  const [filter, setFilter] = useState({
    fromDate: todayStr,
    toDate: todayStr,
    userId: "All",
  });
  const [refresh, setRefresh] = useState(false);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [checkedColumns, setCheckedColumns] = useState([]);
  const [dateValue, setDateValue] = useState([]);
  const [userValue, setUserValue] = useState();
  const [missingFieldsText, setMissingFieldsText] = useState();

  useEffect(() => {
    dispatch(fetchAllCustomFields({ host: "HANDHELD" }));
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    if (users && users.content) {
      let sorted = users.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedUsers(sorted);
    }
  }, [users]);

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const expandedRowRender = (record, i) => {
    return <Table columns={expandColumns} dataSource={record.handheldScans} />;
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    mouseHover,
    searchFormHelperHandler,
  } = useDataTable({
    columns: columnToShow?.filter((column) => column.show), //only shows columns that have show values as true
    mouseHover: false,
    dataSource: data,
    updateEntityPath: "",
    filter,
    mouseHover: false,
    loading: loading,
    expandedRowRender,
    setSortedInfo: setSortedInfo,
    missingFieldsText: missingFieldsText,
  });

  useEffect(() => {
    data.content?.map((parent) => {
      parent?.handheldScans?.map((child) => {
        if (parent?.assetLocation?.name) {
          if (
            parent?.assetLocation?.name === child?.asset?.assetLocation?.name
          ) {
            child.backgroundColor = "";
          } else {
            child.backgroundColor = "#f8ddd5";
          }
        } else {
          child.backgroundColor = "";
        }
      });
    });
  }, [data.content]);

  const checkNoMissingParams = () => {
    if (!(filter.fromDate && filter.toDate)) {
      setMissingFieldsText(
        language?.SELECT_DATE_RANGE?.value ??
          englishLang?.SELECT_DATE_RANGE?.value
      );
      return false;
    }
    return true;
  };
  const ascDesc = () => {
    if (sortedInfo.order === "ascend") {
      return "ASC";
    } else {
      return "DESC";
    }
  };
  useEffect(() => {
    let endpoint = sortedInfo.columnKey
      ? `handheld-scanned-assets?sort=${sortedInfo.columnKey},${ascDesc()}`
      : "handheld-scanned-assets";

    // dispatch(resetReport());
    if (checkNoMissingParams()) {
      setMissingFieldsText();
      dispatch(
        fetchReport({
          page: searchFormHelperHandler(),
          size: pageSize,
          fromDate: toTimestamp(filter.fromDate, "start"),
          toDate: toTimestamp(filter.toDate, "end"),
          ...(filter.userId !== "All" && { userId: filter.userId }),
          endpoint: endpoint,
        })
      );
    }
  }, [currentPage, filter, isLoading, refresh, pageSize, sortedInfo]);

  useEffect(() => {
    setDataTable(DataTable);
  }, [loading, columnToShow, language]);

  /**
   * Whenever there is a change in allCustomFields or preferredColumns, re-render the table columns
   */
  useEffect(() => {
    setPreferredColumnsInitialState();
  }, [allCustomFields, preferredColumns]);
  useEffect(() => {
    let newColumns = [];
    if (
      Object.keys(allCustomFields).length !== 0 &&
      allCustomFields.constructor === Object
    ) {
      allCustomFields.content?.map((row) => {
        // if(row.customFields){
        let object = {};
        switch (row.type) {
          case "SINGLE_SELECT":
            object = {
              title: row.name,
              dataIndex: row.name.toLowerCase(),
              render: (value) => {
                return <li>{value}</li>;
              },
            };
            break;
          case "MULTI_SELECT":
            object = {
              title: row.name,
              dataIndex: row.name.toLowerCase(),
              render: (values) => {
                return (
                  <li>
                    {values ? values?.map((ac) => <li key={ac}>{ac}</li>) : ""}
                  </li>
                );
              },
            };
            break;
          case "DATE":
            object = {
              title: row.name,
              dataIndex: camelize(row.name.toString()),
              render: (value) => <span>{value}</span>,
            };
            break;
          case "IMAGE":
            object = {
              title: row.name,
              dataIndex: camelize(row.name.toString()),
              render: (value) => {
                const image = value ? <Image width={50} src={value} /> : "";

                return image;
              },
            };
            break;
        }
        newColumns.push(object);
      });
    }
  }, [allCustomFields]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "handheld-scanned-assets";
    let options = {
      url: "/api/web/reports/handheld-scanned-assets/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
        ...(filter.userId !== "All" && { userId: filter.userId }),
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  const handleDateRangeChange = (date, dateString) => {
    setDateValue(date);
    setFilter({
      ...filter,
      fromDate: dateString[0],
      toDate: dateString[1],
    });
  };

  const handleUserChange = (value) => {
    setUserValue(value);
    setFilter({
      ...filter,
      userId: value,
    });
  };

  const handleRefreshClick = () => {
    setRefresh(!refresh);
  };

  /**
   * Whenever there is a change in the preferred columns, set as columnToShow and save in the DB
   * @param {*} selectedBoxes
   */
  const onCheckboxChange = (selectedBoxes) => {
    console.log(selectedBoxes, "selectedBoxes");
    setCheckedColumns(selectedBoxes);
    // set the userPreferredColumns to the selected boxes without duplicates
    setSelection(Array.from(new Set([...selectedBoxes])));

    const columnToShowCopy = columnToShow.filter((c) => c);

    selectedBoxes.map((columnTitle) => {
      const column = columnToShowCopy?.find((c) => c.title === columnTitle);
      if (column) {
        column.show = true;
      }
    });

    columnToShowCopy.map((column) => {
      if (!selectedBoxes.includes(column?.title) && column.canHide) {
        column.show = false;
      }
    });

    setColumnToShow(columnToShowCopy);

    if (selectedBoxes.length == 0 && !preferredColumns.preferredPageColumnsId) {
    } else {
      //save change to DB
      const columnDescriptors = [];
      selectedBoxes.map((item) => {
        columnDescriptors.push({
          label: item,
          dataIndex: item,
          key: item,
          value: item,
        });
      });

      const formData = {
        pageName: "handheld-scan-report",
        columnDescriptors: columnDescriptors,
        ...(preferredColumns.preferredPageColumnsId && {
          preferredPageColumnsId: preferredColumns.preferredPageColumnsId,
        }),
      };
      dispatch(
        savePreferredColumns(
          formData,
          preferredColumns.preferredPageColumnsId
            ? preferredColumns.preferredPageColumnsId
            : 0
        )
      );
    }
  };

  /**
   * Generates a list of option for the checkbox group
   * @returns A list of column names to show in the checkbox group
   */

  const getColumnOptions = () => {
    var columnNames = [];

    columnToShow?.map((item) => {
      if (item.canHide) {
        columnNames.push({
          label: item.title,
          dataIndex: item.dataIndex,
          key: item.title,
          value: item.title,
        });
      }
    });
    return columnNames;
  };

  /**
   * A checkbox group component with all allowed options to show or hide and with all preferences
   */
  const preferredColumnsComponent = (
    <Menu>
      <Menu.Item>
        <Checkbox.Group
          style={{ display: "flex", flexDirection: "column" }}
          options={getColumnOptions()}
          value={columnToShow
            .filter((c) => c.show)
            .map((column) => column.title)}
          onChange={onCheckboxChange}
        />
      </Menu.Item>
    </Menu>
  );

  let flattenedData = [];

  if (Object.keys(data).length !== 0) {
    let flattenedData;

    function flatDataHandler() {
      if (data.content === undefined) {
        return (flattenedData = Object.values(data));
      } else {
        return (flattenedData = Object.values(data.content));
      }
    }
    flatDataHandler();

    //

    let scanReport = [];
    flattenedData.map(function (report) {
      if (report.handheldScans && report.handheldScans.length > 0) {
        report.handheldScans.map(function (scan) {
          scanReport.push({
            startTimestamp: report.reportTimestamp,
            startTimestampFormatted: report.reportTimestamp
              ? moment
                  .tz(report.reportTimestamp, userTimeZone)
                  ?.format("DD-MM-YYYY HH:mm:ss")
              : "",
            endTimestamp: report.reportClosedTimestamp,
            endTimestampFormatted: report.reportClosedTimestamp
              ? moment
                  .tz(report.reportClosedTimestamp, userTimeZone)
                  ?.format("DD-MM-YYYY HH:mm:ss")
              : "",
            user: report.user,
            comment: report.comment,
            assetLocation: report.assetLocation
              ? report.assetLocation.name
              : "",
            assetCode: scan.asset.code,
            assetExternalReference: scan.asset.assetExternalReference,
            assetName: scan.asset.name,
            scanTimestamp: scan.detectedTimestamp,
            scanTimestampFormatted: scan.detectedTimestamp
              ? moment
                  .tz(scan.detectedTimestamp, userTimeZone)
                  ?.format("DD-MM-YYYY HH:mm:ss")
              : "",
            "location-latitude": scan.location.latitude,
            "location-longitude": scan.location.longitude,
          });
        });
      }
    });

    flattenedData = Object.values(scanReport);
  }

  let flattenedAllData = [];

  if (Object.keys(allData).length !== 0) {
    flattenedAllData = Object.values(allData.content);
    let scanAllReport = [];

    flattenedAllData.map(function (report) {
      console.log(report, "report");

      if (report.handheldScans && report.handheldScans.length > 0) {
        report.handheldScans.map(function (scan) {
          scanAllReport.push({
            startTimestamp: report.reportTimestamp,
            startTimestampFormatted: report.reportTimestamp
              ? moment
                  .tz(report.reportTimestamp, userTimeZone)
                  ?.format("DD-MM-YYYY HH:mm:ss")
              : "",
            endTimestamp: report.reportClosedTimestamp,
            endTimestampFormatted: report.reportClosedTimestamp
              ? moment
                  .tz(report.reportClosedTimestamp, userTimeZone)
                  ?.format("DD-MM-YYYY HH:mm:ss")
              : "",
            user: report.user,
            comment: report.comment,

            assetLocation: report.assetLocation
              ? report.assetLocation.name
              : "",
            assetCode: scan.asset.code,
            assetExternalReference: scan.asset.assetExternalReference,
            assetName: scan.asset.name,
            scanTimestamp: scan.detectedTimestamp,
            scanTimestampFormatted: scan.detectedTimestamp
              ? moment
                  .tz(scan.detectedTimestamp, userTimeZone)
                  ?.format("DD-MM-YYYY HH:mm:ss")
              : "",
            "location-latitude": scan.location.latitude,
            "location-longitude": scan.location.longitude,
          });
        });
      }
      let keys = Object.keys(report);

      preferredColumns?.columnDescriptors.map((column) => {
        let resultKey = keys.find(
          (item) => item === column.label.toLowerCase()
        );
        let resultValue = report[resultKey];

        scanAllReport = [...scanAllReport, { [resultKey]: resultValue }];
      });
    });

    flattenedAllData = Object.values(scanAllReport);
  }
  // console.log(flattenedAllData, "flattenedAllData");

  // create an Main array with non change Data
  let headers = [
    {
      label:
        language?.REPORT_START_TIMESTAMP?.value ??
        englishLang?.REPORT_START_TIMESTAMP?.value,
      key: "startTimestampFormatted",
    },
    {
      label:
        language?.REPORT_END_TIMESTAMP?.value ??
        englishLang?.REPORT_END_TIMESTAMP?.value,
      key: "endTimestampFormatted",
    },
    {
      label: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      key: "assetCode",
    },
    {
      label:
        language?.EXTERNAL_REFERENCE_NO?.value ??
        englishLang?.EXTERNAL_REFERENCE_NO?.value,
      key: "assetExternalReference",
    },
    {
      label: language?.ASSET_NAME?.value ?? englishLang?.ASSET_NAME?.value,
      key: "assetName",
    },
    {
      label:
        language?.SCAN_TIMESTAMP?.value ?? englishLang?.SCAN_TIMESTAMP?.value,
      key: "scanTimestampFormatted",
    },
    {
      label: language?.LATITUDE?.value ?? englishLang?.LATITUDE?.value,
      key: "location-latitude",
    },
    {
      label: language?.LONGITUDE?.value ?? englishLang?.LONGITUDE?.value,
      key: "location-longitude",
    },
  ];

  console.log(preferredColumns, "preferredColumns");
  // console.log(checkedColumns);
  //So we are checking inital state values in checkedColumns if 1 is greater
  if (checkedColumns.length < 1) {
    //If 1 is greater then checkedColumns length, then use Data from Api
    preferredColumns.columnDescriptors?.map((prefColumnTitle) => {
      let result = columnToShow.find(
        (item) => item.title === prefColumnTitle.label
      );
      headers.push({ label: result?.title, key: result?.title });
    });
  } else {
    //If checkedColumns length is greater than 1 we use checkedColumns state values
    checkedColumns.map((prefColumnTitle) => {
      let result = columnToShow.find((item) => item.title === prefColumnTitle);

      headers.push({
        label: result?.title,
        key: result?.title,
      });
    });
  }

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          hasSelected={hasSelected}
          exportTableTitle={
            language?.HANDHELDSCANNEDASSETS?.value ??
            englishLang?.HANDHELDSCANNEDASSETS?.value
          }
          addNewText=""
          title={
            language?.HANDHELD_SCANNED_ASSETS?.value ??
            englishLang?.HANDHELD_SCANNED_ASSETS?.value
          }
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportAllButtonClick={handleExportAllButtonClick}
        />
        <Row>
          <Col>
            <Space>
              <Tooltip
                title={
                  language?.SELECT_A_DATE_RANGE?.value ??
                  englishLang?.SELECT_A_DATE_RANGE?.value
                }
              >
                <RangePicker
                  id={ElementIDS().calendar}
                  defaultValue={[
                    moment(todayStr, dateFormat),
                    moment(todayStr, dateFormat),
                  ]}
                  format={dateFormat}
                  onChange={handleDateRangeChange}
                />
              </Tooltip>
              <Tooltip
                title={
                  language?.SELECT_USER?.value ??
                  englishLang?.SELECT_USER?.value
                }
              >
                <Select
                  id={ElementIDS().selectUser}
                  defaultValue="All"
                  style={{ width: 200 }}
                  placeholder={
                    language?.SELECT_A_PERSON?.value ??
                    englishLang?.SELECT_A_PERSON?.value
                  }
                  optionFilterProp="children"
                  onChange={handleUserChange}
                >
                  <Option
                    id={ElementIDS().optionUser}
                    key={"All"}
                    value={"All"}
                  >
                    {language?.ALL?.value ?? englishLang?.ALL?.value}
                  </Option>
                  {sortedUsers
                    ? sortedUsers.map((item) => (
                        <Option
                          key={item.userAccountId}
                          value={item.userAccountId}
                        >
                          {item.name} {item.surname}
                        </Option>
                      ))
                    : undefined}
                </Select>
              </Tooltip>
              <Button
                id={ElementIDS().refresh}
                type="primary"
                onClick={() => handleRefreshClick()}
              >
                {" "}
                <RedoOutlined />
                {language?.REFRESH?.value ?? englishLang?.REFRESH?.value}
              </Button>
              <Dropdown
                overlay={preferredColumnsComponent}
                placement="bottomLeft"
              >
                <Button>
                  {language?.COLUMNS?.value ?? englishLang?.COLUMNS?.value}
                </Button>
              </Dropdown>
            </Space>
          </Col>
        </Row>
        <Divider />
        {dataTable}
        <ResultNotifier />
      </div>
    </>
  );
}

export default HandheldScannedAssets;
