import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectTenant } from "../../api/redux/action/AuthActions";
import MainLayout from "./MainLayout";

import { languageChanger } from "../../api/redux/action/LangActions";
import TenantLogin from "./TenantLogin";
import { notification, Space, Typography } from "antd";
const { Text } = Typography;
function SecuredLayout(props) {
  const dispatch = useDispatch();

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const spinner = (
    <Row align="middle" justify="center" style={{ height: "100vh" }}>
      <Spin tip="Loading" indicator={antIcon} />
    </Row>
  );
  const [screen, setScreen] = useState(spinner);

  const {
    user,
    isUnauth,
    error,
    loading,
    languages,
    language,
    selectedTenant,
    tenants,
  } = useSelector((store) => ({
    user: store.authReducer.user,
    isUnauth: store.authReducer.isUnauth,
    error: store.authReducer.error,
    loading: store.authReducer.loading,
    languages: store.langReducer.languages,
    language: store.langReducer.language,
    selectedTenant: store.authReducer.selectedTenant,
    tenants: store.authReducer.tenants,
  }));

  useEffect(() => {
    dispatch(languageChanger(languages, selectedTenant?.language));
  }, []);

  useEffect(() => {
    if (error) {
      notification.open({
        type: "error",
        message: "Error!",
        description: error,
        duration: 8,
      });
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      if (tenants?.length > 1) {
        setScreen(<TenantLogin user={user} />);
      } else {
        if (selectedTenant === null) {
          dispatch(selectTenant(tenants[0]));
          location.reload();
        }
        setScreen(<MainLayout user={user} selectedTenant={selectedTenant} />);
      }
    } else {
      setScreen(<Redirect push to="/login" />);
    }
  }, [isUnauth, user, language]);

  return <>{screen}</>;
}

export default SecuredLayout;
