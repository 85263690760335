import { request } from "../axios/request";

export const getRolesService = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/roles", getState(), {
        method: "GET",
        params,
      })
    );
  };
};

export const saveRoleService = (data, id = 0) => {
  if (id > 0) {
    return async (dispatch, getState) => {
      return dispatch(
        request("/roles/" + id, getState(), {
          method: "PUT",
          data,
        })
      );
    };
  }
  return async (dispatch, getState) => {
    return dispatch(
      request("/roles", getState(), {
        method: "POST",
        data,
      })
    );
  };
};

export const deleteRoleService = (id = 0) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/roles/" + id, getState(), {
        method: "DELETE",
      })
    );
  };
};
