import * as type from '../types';
import { RequestState } from './RequestState';

const INITIAL_STATE = {
  ipRelayCards: {},
  allIpRelayCards:{},
  ipRelayCard: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  exportAllLoading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.IPRELAYCARDS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        ipRelayCards: {},
        loading: true
      };
    case type.IPRELAYCARDS_FETCH_SUCCESS:
      return {
        ...state,
        ipRelayCards: action.payload,
        loading: false
      };
    case type.IPRELAYCARDS_FETCH_FAILURE:
      return {
        ...state,
        ipRelayCards: {},
        loading: false
      };
      case type.ALL_IPRELAYCARDS_FETCH_REQUEST:
        return {
          ...state,
          error: {},
          allIpRelayCards: {},
          exportAllLoading: true
        };
      case type.ALL_IPRELAYCARDS_FETCH_SUCCESS:
        return {
          ...state,
          allIpRelayCards: action.payload,
          exportAllLoading: false
        };
      case type.ALL_IPRELAYCARDS_FETCH_FAILURE:
        return {
          ...state,
          allIpRelayCards: {},
          exportAllLoading: false
        };
      case type.IPRELAYCARD_SAVE_REQUEST:
        return {
          ...state,
          error: {},
          ipRelayCards: {},
          requestStatus: RequestState.PENDING,
        };
    case type.IPRELAYCARD_SAVE_SUCCESS:
      return {
        ...state,
        ipRelayCard: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.IPRELAYCARD_SAVE_FAILURE:
      return {
        ...state,
        ipRelayCard: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
      case type.IPRELAYCARD_DELETE_REQUEST:
        return {
          ...state,
          requestStatus: RequestState.PENDING,
        };
        case type.IPRELAYCARD_DELETE_SUCCESS:
          return {
            ...state,
            ipRelayCard: action.payload,
            error: {},
            requestStatus: RequestState.SUCCESS,
          };
        case type.IPRELAYCARD_DELETE_FAILURE:
          return {
            ...state,
            ipRelayCard: {},
            error: action.payload,
            requestStatus: RequestState.FAILURE,
          };
          case type.CLEAN_REQUEST_STATUS:
            return {
              ...state,
              requestStatus: RequestState.EMPTY
            };
    default:
      return state;
  }
};


export default reducer;