import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";

import { saveUserGroup } from "../../../api/redux/action/UserGroupActions";
import useNotification from "../../../components/common/ResultNotifier";
import AddUserGroupForm from "./AddUserGroupForm";
import { useHistory } from "react-router-dom";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";
import { hasPermission } from "../../layout/Constants";
import AddFooterSection from "../../../components/common/AddFooterSection";
import { validateInput } from "../../../components/RequiredFieldRule";

const AddUserGroup = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [prompt, setDirty, setPristine] = UnsavedChanges();
  let history = useHistory();

  const {
    error,
    requestStatus,
    user,
    userGroup,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    error: store.userGroupReducer.error,
    requestStatus: store.userGroupReducer.requestStatus,
    userGroup: store.userGroupReducer.userGroup,
    user: store.authReducer.user,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));
  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;
  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      if (props.location.state.selectedRow.roleSet) {
        let tempSelectedRoleSet = [];
        props.location.state.selectedRow.roleSet.map((item) =>
          tempSelectedRoleSet.push(item.roleId)
        );

        form.setFieldsValue({ roleIdList: tempSelectedRoleSet });
      }

      form.setFieldsValue({
        userGroupId: props.location.state.selectedRow.userGroupId,
        name: props.location.state.selectedRow.name,
        system: props.location.state.selectedRow.system,
      });
    }
  }, []);

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      return history.push("/userGroups");
    }
  }, [requestStatus]);

  const handleSave = (values) => {
    const formData = {
      name: validateInput(form.getFieldValue().name),
      // system : form.getFieldValue().system,
      system: true,
      roleIdList: form.getFieldValue().roleIdList,
    };
    setPristine();
    dispatch(saveUserGroup(formData, form.getFieldValue().userGroupId));
  };

  const handleCancel = () => {
    history.push("/userGroups");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_USER_GROUPS");
  }

  const footer = (
    <AddFooterSection
      language={language}
      viewOption={props.location.state}
      handleCancel={handleCancel}
      form={form}
      updatePath={"/update-userGroup"}
      handleEditingPermission={handleEditingPermission}
    />
  );

  return (
    <Card
      title={language?.USER_GROUP?.value ?? englishLang?.USER_GROUP?.value}
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddUserGroupForm
            handleSave={handleSave}
            form={form}
            footer={footer}
            setDirty={setDirty}
            readOnly={props.location.state}
            language={language}
          />
        </Col>
      </Row>
      <ResultNotifier />
      {prompt}
    </Card>
  );
};

export default AddUserGroup;
