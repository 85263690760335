import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import { fetchRoles, deleteRole } from "../../../api/redux/action/RoleActions";
import RoleActionView from "../../../components/common/ActionMenu";
import useNotification from "../../../components/common/ResultNotifier";
import { hasPermission } from "../../layout/Constants";
import { useHistory } from "react-router-dom";

function Roles() {
  const dispatch = useDispatch();

  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  let history = useHistory();

  const [searchText, setSearchText] = useState();

  const {
    roles,
    error,
    requestStatus,
    loading,
    user,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    roles: store.roleReducer.roles,
    error: store.roleReducer.error,
    requestStatus: store.roleReducer.requestStatus,
    loading: store.roleReducer.loading,
    user: store.authReducer.user,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const columns = [
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
      sorter: true,
      sortField: "name",
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      render: (name) => (
        <a
          onClick={() => {
            const viewOption = "disabled";

            history.push({
              pathname: "/update-role",
              state: { selectedRow, viewOption },
            });
          }}
        >
          {name}
        </a>
      ),
    },
    {
      title: language?.DISPLAY_NAME?.value ?? englishLang?.DISPLAY_NAME?.value,
      dataIndex: "displayName",
      sorter: true,
      sortField: "displayName",
      sortOrder: sortedInfo.columnKey === "displayName" && sortedInfo.order,
    },
    {
      title: language?.DESCRIPTION?.value ?? englishLang?.DESCRIPTION?.value,
      dataIndex: "description",
      sorter: true,
      sortField: "description",
      sortOrder: sortedInfo.columnKey === "description" && sortedInfo.order,
    },
  ];

  const [allowPermission, setAllowPermission] = useState({
    edit: hasPermission(selectedTenant, "UPDATE_ROLES"),
    delete: hasPermission(selectedTenant, "DELETE_ROLES"),
    view: hasPermission(selectedTenant, "READ_ROLES"),
  });

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const handleSingleDelete = (selected) => {
    dispatch(deleteRole(selected.roleId));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    sortField,
    sortOrder,
    selectedRow,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "role",
    dataSource: roles,
    updateEntityPath: "update-role",
    customActionView:
      allowPermission.edit || allowPermission.delete || allowPermission.view
        ? RoleActionView
        : "",
    searchText,
    loading: loading,
    handleSingleDelete,
    allowPermission,
    setSortedInfo,
    title: language?.ROLES?.value ?? englishLang?.ROLES?.value,
  });

  useEffect(() => {
    dispatch(
      fetchRoles({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo]);

  let flattenedData = [];

  if (Object.keys(roles).length !== 0) {
    flattenedData = Object.values(roles.content);
  }
  if (Object.keys(roles).length !== 0) {
    flattenedData = Object.values(roles.content);
    flattenedData.forEach((role) => {
      let permissionSet = "";
      if (role.permissionSet) {
        for (var x = 0; x < role.permissionSet.length; x++) {
          permissionSet += x > 0 ? "," : "";
          permissionSet +=
            role.permissionSet[x].action.displayName +
            " " +
            role.permissionSet[x].resourceType.displayName;
        }
      }

      role.permissionSet1 = permissionSet;
    });
  }

  const headers = [
    { label: language?.NAME?.value ?? englishLang?.NAME?.value, key: "name" },
    {
      label: language?.DISPLAY_NAME?.value ?? englishLang?.DISPLAY_NAME?.value,
      key: "displayName",
    },
    {
      label: language?.DESCRIPTION?.value ?? englishLang?.DESCRIPTION?.value,
      key: "description",
    },
    {
      label:
        language?.PERMISSION_SET?.value ?? englishLang?.PERMISSION_SET?.value,
      key: "permissionSet1",
    },
  ];

  const addNewPath = hasPermission(selectedTenant, "CREATE_ROLES")
    ? "add-role"
    : "";

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath={addNewPath}
          hasSelected={hasSelected}
          exportTableTitle={language?.ROLES?.value ?? englishLang?.ROLES?.value}
          addNewText={language?.ADD_ROLE?.value ?? englishLang?.ADD_ROLE?.value}
          title={language?.ROLES?.value ?? englishLang?.ROLES?.value}
          csvData={flattenedData}
          csvHeaders={headers}
          handleSearch={setSearchText}
          tooltipText={
            language?.TOOLTIP_SEARCH_ALL?.value ??
            englishLang?.TOOLTIP_SEARCH_ALL?.value
          }
        />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default Roles;
