import * as type from '../types';

const INITIAL_STATE = {
  totals:null,
  loading:false,
  error:{},
};

// function getTotalsData(loading = false, totals = {}, error = {}) {
//   return {
//     totals,
//     loading,
//     error,
//   };
// }


const reducer =  (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.TOTALS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.TOTALS_FETCH_SUCCESS:
      return {
        ...state,
        totals: action.payload.totals,
        loading: false
      };
    case type.TOTALS_FETCH_FAILURE:
      return {
        ...state,
        loading:false,
        error:action.payload
      };
   
    default:
      return state;
  }
};


export default reducer;