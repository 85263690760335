import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Switch,
  Radio,
  Button,
  DatePicker,
  Tooltip,
  InputNumber,
} from "antd";
import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "../../layout/Style.css";
import { useSelector } from "react-redux";
const AddCustomFieldForm = (props) => {
  const { Option } = Select;
  // const dateFormat = "DD-MM-YYYY";
  const [formValues, setFormValues] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [formFields, setFormFields] = useState();
  const [checked, setChecked] = useState(false);
  const [requiredField, setRequiredField] = useState(
    props?.readOnly?.selectedRow?.required
  );
  const { englishLang } = useSelector((store) => ({
    englishLang: store.langReducer.englishLang,
  }));
  const [radioValue, setRadioValue] = useState(
    props.readOnly?.selectedRow ? props.readOnly?.selectedRow.host : null
  );

  const formItemLayout = {
    labelCol: {
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  };

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 2 },
      sm: { span: 15, offset: 6 },
    },
  };

  const customFieldTypes = {
    content: [
      {
        customFieldId: "SINGLE_SELECT",
        name: "Single-select",
      },
      {
        customFieldId: "MULTI_SELECT",
        name: "Multi-select",
      },
    ],
  };

  if (radioValue === "HANDHELD") {
    customFieldTypes.content.push(
      {
        customFieldId: "DATE",
        name: "Date",
      },
      {
        customFieldId: "IMAGE",
        name: "Image",
      }
    );
  } else if (radioValue === "ASSET") {
    customFieldTypes.content.push(
      {
        customFieldId: "STRING",
        name: "String",
      },
      {
        customFieldId: "NUMBER",
        name: "Number",
      }
    );
  }

  // THIS one changes the state
  useEffect(() => {
    switch (props.form.getFieldValue().type) {
      case "SINGLE_SELECT": //single-select
        setShowOptions(true);
        // setFormValues(props.form.getFieldValue().options);
        break;
      case "MULTI_SELECT": //multi-select (HandHeld)
        setShowOptions(true);
        // setFormValues(props.form.getFieldValue().options);
        break;
      case "IMAGE": //image
        setShowOptions(false);
        break;
      case "DATE": //date
        setShowOptions(false);
        break;
      case "STRING": //String (Asset's)
        setShowOptions(false);
        break;
      case "NUMBER": //Number (Asset's)
        setShowOptions(false);
        break;
    }

    setChecked(props.form.getFieldValue().required);
  });

  // This function
  const onFieldTypeChange = (value, element) => {
    switch (value) {
      case "SINGLE_SELECT": //single-select
        setShowOptions(true);
        props.setDirty();
        break;
      case "MULTI_SELECT": //multi-select
        setShowOptions(true);
        props.setDirty();
        break;
      case "IMAGE": //image
        setShowOptions(false);
        props.form.setFieldsValue({ options: undefined });
        props.setDirty();
        break;
      case "DATE": //date
        setShowOptions(false);
        props.form.setFieldsValue({ options: undefined });
        props.setDirty();
        break;
      case "STRING": //string (Asset's)
        setShowOptions(false);
        props.setDirty();
        break;
      case "NUMBER": //number (Asset's)
        setShowOptions(false);
        props.setDirty();
        break;
    }
  };

  const handleCustomFieldPicker = (e) => {
    setRadioValue(e.target.value);
    props.form.setFieldsValue({
      name: null,
      type: null,
      assetGroup: undefined,
      options: null,
    });
    setRequiredField(false);
  };

  const handleFormValuesChange = (changedValues, allValues) => {
    setFormValues(allValues);
    props.setFieldType(allValues);
  };

  useEffect(() => {
    let formItems = (
      <>
        <Form.Item
          label={props.language?.NAME?.value ?? englishLang?.NAME?.value}
          name="name"
          rules={[
            {
              required: true,
              message:
                props.language?.REQUIRED_FIELD?.value ??
                englishLang?.REQUIRED_FIELD?.value,
            },
            () => ({
              validator(_, value) {
                if (value && value.includes("_")) {
                  return Promise.reject(
                    new Error(
                      props.language?.UNDERSCORES_ARE_NOT_ALLOWED?.value ??
                        englishLang?.UNDERSCORES_ARE_NOT_ALLOWED?.value
                    )
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input
            disabled={
              props.readOnly && props.readOnly.viewOption ? "disabled" : null
            }
            onChange={(e) => {
              props.setDirty();
            }}
          />
        </Form.Item>
        <Form.Item
          label={
            props.language?.FIELD_TYPE?.value ?? englishLang?.FIELD_TYPE?.value
          }
          name="type"
          rules={requiredFieldRule}
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder={
              props.language?.SELECT_FIELD_TYPE?.value ??
              englishLang?.SELECT_FIELD_TYPE?.value
            }
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => {
              onFieldTypeChange(value);
            }}
            disabled={
              props.readOnly && props.readOnly.viewOption ? "disabled" : null
            }
          >
            {customFieldTypes && customFieldTypes.content
              ? customFieldTypes.content.map((item) => (
                  <Option key={item.customFieldId} value={item.customFieldId}>
                    {item.name}
                  </Option>
                ))
              : undefined}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            props.language?.REQUIRED?.value ?? englishLang?.REQUIRED?.value
          }
          name="required"
        >
          <Switch
            onChange={() => {
              setRequiredField(!requiredField);
            }}
            checked={requiredField}
            disabled={
              props.readOnly && props.readOnly.viewOption ? "disabled" : null
            }
          />
        </Form.Item>

        {radioValue === "ASSET" ? (
          <>
            <Form.Item
              label={
                props.language?.ASSET_GROUPS?.value ??
                englishLang?.ASSET_GROUPS?.value
              }
              name="assetGroup"
            >
              <Select
                allowClear
                mode="multiple"
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              >
                {props.assetGroups &&
                  props.assetGroups?.content?.map((item) => (
                    <Option key={item.assetGroupId} value={item.assetGroupId}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </>
        ) : null}

        <Form.List
          style={!showOptions ? { display: "none" } : {}}
          name="options"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  (getFieldValue("type") === "SINGLE_SELECT" ||
                    getFieldValue("type") === "MULTI_SELECT") &&
                  (getFieldValue("options") == "" ||
                    getFieldValue("options") == undefined)
                ) {
                  return Promise.reject(new Error("Please enter an option!"));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  style={!showOptions ? { display: "none" } : {}}
                  {...(index === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={index === 0 ? "Options" : ""}
                  required={false}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input option or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      disabled={
                        props.readOnly && props.readOnly.viewOption
                          ? "disabled"
                          : null
                      }
                      placeholder={
                        props.language?.OPTION?.value ??
                        englishLang?.OPTION?.value
                      }
                      style={{ width: "90%" }}
                      onChange={(e) => {
                        props.setDirty();
                      }}
                    />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      style={
                        props.readOnly && props.readOnly.viewOption
                          ? { display: "none" }
                          : { marginLeft: "10px" }
                      }
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item
                style={
                  !showOptions
                    ? { display: "none" }
                    : { float: "right", marginRight: "150px" }
                }
              >
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  disabled={
                    props.readOnly && props.readOnly.viewOption
                      ? "disabled"
                      : null
                  }
                >
                  {props.language?.ADD_OPTION?.value ??
                    englishLang?.ADD_OPTION?.value}
                </Button>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        {props.footer}
      </>
    );

    setFormFields(formItems);
  }, [radioValue, props]);

  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="customField-form"
      onFinish={props.handleSave}
      onValuesChange={handleFormValuesChange}
    >
      <Form.Item
        label={props.language?.TYPE?.value ?? englishLang?.TYPE?.value}
        name="host"
        rules={requiredFieldRule}
      >
        <Radio.Group
          onChange={handleCustomFieldPicker}
          value={radioValue}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        >
          <Radio value="ASSET">
            {props.language?.ASSET?.value ?? englishLang?.ASSET?.value}
          </Radio>
          <Radio value="HANDHELD">
            {props.language?.HANDHELD?.value ?? englishLang?.HANDHELD?.value}
          </Radio>
        </Radio.Group>
      </Form.Item>
      {formFields}
    </Form>
  );
};

export default AddCustomFieldForm;
