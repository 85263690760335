import * as type from '../types';
import { RequestState } from './RequestState';

const INITIAL_STATE = {
  fixedReaders: {},
  allFixedReaders: {},
  fixedReader: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  exportAllLoading:false,
};

const reducer=(state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.FIXEDREADERS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        fixedReaders: {},
        loading: true
      };
    case type.FIXEDREADERS_FETCH_SUCCESS:
      return {
        ...state,
        fixedReaders: action.payload,
        loading: false
      };
    case type.FIXEDREADERS_FETCH_FAILURE:
      return {
        ...state,
        fixedReaders: {},
        loading: false
      };
      case type.ALL_FIXEDREADERS_FETCH_REQUEST:
        return {
          ...state,
          error: {},
          allFixedReaders: {},
          exportAllLoading: true
        };
      case type.ALL_FIXEDREADERS_FETCH_SUCCESS:
        return {
          ...state,
          allFixedReaders: action.payload,
          exportAllLoading: false
        };
      case type.ALL_FIXEDREADERS_FETCH_FAILURE:
        return {
          ...state,
          allFixedReaders: {},
          exportAllLoading: false
        };
      case type.FIXEDREADER_SAVE_REQUEST:
        return {
          ...state,
          error: {},
          fixedReader: {},
          requestStatus: RequestState.PENDING,
        };
    case type.FIXEDREADER_SAVE_SUCCESS:
      return {
        ...state,
        fixedReader: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.FIXEDREADER_SAVE_FAILURE:
      return {
        ...state,
        fixedReader: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
      case type.FIXEDREADER_DELETE_REQUEST:
        return {
          ...state,
          requestStatus: RequestState.PENDING,
        };
        case type.FIXEDREADER_DELETE_SUCCESS:
          return {
            ...state,
            fixedReader: action.payload,
            error: {},
            requestStatus: RequestState.SUCCESS,
          };
        case type.FIXEDREADER_DELETE_FAILURE:
          return {
            ...state,
            fixedReader: {},
            error: action.payload,
            requestStatus: RequestState.FAILURE,
          };
          case type.CLEAN_REQUEST_STATUS:
            return {
              ...state,
              requestStatus: RequestState.EMPTY
            };
    default:
      return state;
  }
};


export default reducer;