import React from "react";
import { Skeleton, Typography, Tooltip } from "antd";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import ChartCard from "../../../../components/ChartCard/ChartCard";

const { Text } = Typography;

function TotalWidget({
  title,
  total,
  down,
  up,
  unknown,
  clickPath,
  icon,
  id,
  toolTipTitleDown,
  toolTipTitleUnknown,
  toolTipTitle,
}) {
  const history = useHistory();

  const handleClick = (path) => {
    history.push({
      pathname: path,
    });
  };

  const footer =
    down > 0 || up > 0 || unknown > 0 ? (
      <>
        {down > 0 ? (
          <span style={{ color: "red", float: "right", marginLeft: "10px" }}>
            <Tooltip
              title={toolTipTitleDown.items.map((item) => (
                <div>
                  <p>
                    {item.name}
                    {item.gateway && item.gateway.description
                      ? " (" + item.gateway.description + ")"
                      : ""}
                  </p>
                </div>
              ))}
            >
              {down} <ExclamationCircleOutlined />
            </Tooltip>
          </span>
        ) : (
          ""
        )}

        {unknown > 0 ? (
          <span
            style={{ color: "#d48806", float: "right", marginLeft: "10px" }}
          >
            <Tooltip
              title={toolTipTitleUnknown.items.map((item) => (
                <div
                  key={Math.floor(
                    Math.random() * toolTipTitleUnknown.items.length
                  )}
                >
                  <p>
                    {item.name}
                    {item.gateway && item.gateway.description
                      ? " (" + item.gateway.description + ")"
                      : ""}
                  </p>
                </div>
              ))}
            >
              {unknown} <WarningOutlined />
            </Tooltip>
          </span>
        ) : (
          ""
        )}

        {up > 0 ? (
          <span style={{ color: "green", float: "right" }}>
            {up} <CheckCircleOutlined />
          </span>
        ) : (
          ""
        )}
      </>
    ) : (
      <div>
        <Text type="secondary"> </Text> <br />
      </div>
    );

  if (total == null)
    return (
      <div style={{ background: "#fff", padding: "20px" }}>
        <Skeleton active />
      </div>
    );

  return (
    <div onClick={() => handleClick(clickPath)}>
      <ChartCard
        bordered
        title={title}
        contentHeight={8}
        id={id}
        total={total}
        footer={footer}
        icon={icon}
      ></ChartCard>
    </div>
  );
}

export default TotalWidget;
