import React, { useState, useEffect } from "react";
import { Table, Empty } from "antd";
import "./Style.css";
import { useSelector } from "react-redux";

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;
const pageSizeOptions = ["10", "25", "50", "75", "100"];

function useDataTable({
  columns,
  source,
  dataSource,
  updateEntityPath,
  customActionView,
  searchText,
  loading = null,
  handleSingleDelete,
  handleKillAsset,
  expandedRowRender,
  rowSelectors,
  allowPermission,
  setSortedInfo,
  title,
  missingFieldsText,
  mouseHover,
  pagination,
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  // const [sortOrder, setSortOrder] = useState(null);
  // const [sortField, setSortField] = useState(null);

  // const [sorter, setSorter] = useState({});

  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const [actionColumnView] = customActionView
    ? [
        customActionView({
          selectedRow,
          currentPage,
          pageSize,
          pageSizeOptions,
          searchText,
          updateEntityPath,
          handleSingleDelete,
          handleKillAsset,
          selectedRowKeys,
          allowPermission,
          title,
          missingFieldsText,
          // sortOrder,
          // sortField
        }),
      ]
    : [];
  // : useActionMenu({ selectedRow, updateEntityPath });

  const hasSelected = selectedRowKeys.length > 0;

  const selected = hasSelected ? selectedRowKeys : [];
  const updatedColumns = customActionView
    ? [
        ...columns,
        {
          key: "action",
          render: () => actionColumnView,
          fixed: "right",
          width: "10%",
        },
      ]
    : columns;

  const resetPagination = () => {
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current - 1);
    setPageSize(pagination.pageSize);

    if (setSortedInfo) {
      setSortedInfo({
        order: sorter.order,
        columnKey: sorter.column ? sorter.column.sortField : null,
      });
    }

    // setSortOrder(sorter.order);
    // setSortField(sorter.column ? sorter.column.sortField : null);
  };

  const rowSelection = {
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
    selectedRowKeys,
    onChange: (selected) => {
      setSelectedRowKeys(selected);
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function DataTable() {
    let locale = {
      emptyText: missingFieldsText ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>{missingFieldsText}</span>}
        ></Empty>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ),
    };

    return (
      <Table
        locale={locale}
        rowKey={(record, index) =>
          record[source + "Id"] ? record[source + "Id"] : index
        }
        columns={updatedColumns}
        dataSource={pagination === false ? dataSource : dataSource.content}
        loading={loading || false}
        onRow={(record) => {
          if (mouseHover === undefined) {
            return {
              onMouseOver: () => {
                setSelectedRow(record);
              },
            };
          } else if (mouseHover === false) {
            return null;
          }
        }}
        scroll={
          pagination === false
            ? {
                y: 250,
                x: 1000,
              }
            : { x: 1000 }
        }
        onChange={handleTableChange}
        expandRowByClick
        tableLayout="auto"
        pagination={{
          pageSizeOptions: pageSizeOptions,
          defaultPageSize: pageSize,
          defaultCurrent: DEFAULT_PAGE_NUMBER,
          current: currentPage + 1,
          total: dataSource?.totalElements,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} ${
              language?.OF?.value ?? englishLang?.OF?.value
            } ${total} ${language?.ITEMS?.value ?? englishLang?.ITEMS?.value}`;
          },
          showSizeChanger: true,
        }}
        expandable={{
          expandedRowRender: expandedRowRender ? expandedRowRender : null,
        }}
      />
    );
  }

  function searchFormHelperHandler() {
    return currentPage;
  }

  return {
    DataTable,
    hasSelected,
    selectedRow,
    selectedRowKeys,
    currentPage,
    pageSize,
    pageSizeOptions,
    resetPagination,
    expandedRowRender,
    selected,
    mouseHover,
    searchFormHelperHandler,
    // sortOrder,
    // sortField
  };
}

export default useDataTable;
