import * as type from "../types";
import {
  getMobileReadersService,
  saveMobileReaderService,
  deleteMobileReaderService,
  getAllMobileReadersService,
} from "../../network/service/MobileReaderService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchMobileReaders = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.MOBILEREADERS_FETCH_REQUEST,
      });
      const response = await dispatch(getMobileReadersService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.MOBILEREADERS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.MOBILEREADERS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.MOBILEREADERS_FETCH_FAILURE,
      });
    }
  };
};

export const fetchAllMobileReaders = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_MOBILEREADERS_FETCH_REQUEST,
      });
      const response = await dispatch(getAllMobileReadersService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_MOBILEREADERS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_MOBILEREADERS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_MOBILEREADERS_FETCH_FAILURE,
      });
    }
  };
};

export const saveMobileReader = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.MOBILEREADER_SAVE_REQUEST,
      });
      const response = await dispatch(saveMobileReaderService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.MOBILEREADER_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.MOBILEREADER_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.MOBILEREADER_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteMobileReader = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.MOBILEREADER_DELETE_REQUEST,
      });
      const response = await dispatch(deleteMobileReaderService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.MOBILEREADER_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.MOBILEREADER_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.MOBILEREADER_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
