import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select, Tooltip, Row, Col, Divider, Switch } from "antd";

import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "../../layout/Style.css";
import { fetchAssetGroups } from "../../../api/redux/action/AssetGroupActions";
import { fetchAssets } from "../../../api/redux/action/AssetActions";
import { fetchSites } from "../../../api/redux/action/SiteActions";
import { fetchZones } from "../../../api/redux/action/ZoneActions";
import { fetchRelaySwitches } from "../../../api/redux/action/RelaySwitchActions";
import { fetchContacts } from "../../../api/redux/action/ContactActions";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import "./Style.css";

const { Option } = Select;

const AddAlertRuleForm = (props) => {
  const dispatch = useDispatch();

  const [sortedAssetGroups, setSortedAssetGroups] = useState([]);
  const [sortedAssets, setSortedAssets] = useState([]);
  const [sortedSites, setSortedSites] = useState([]);
  const [sortedZones, setSortedZones] = useState([]);
  const [sortedRelaySwitches, setSortedRelaySwitches] = useState([]);
  const [sortedContacts, setSortedContacts] = useState([]);

  const {
    assetGroups,
    assets,
    sites,
    zones,
    relaySwitches,
    contacts,
    englishLang,
  } = useSelector((store) => ({
    assetGroups: store.assetGroupReducer.assetGroups,
    assets: store.assetReducer.assets,
    sites: store.siteReducer.sites,
    zones: store.zoneReducer.zones,
    relaySwitches: store.relaySwitchReducer.relaySwitches,
    contacts: store.contactReducer.contacts,
    englishLang: store.langReducer.englishLang,
  }));

  useEffect(() => {
    dispatch(fetchAssetGroups());
    dispatch(fetchAssets());
    dispatch(fetchSites());
    dispatch(fetchZones());
    dispatch(fetchRelaySwitches());
    dispatch(
      fetchContacts({
        onlyWithEmailOrNumber: true,
      })
    );
  }, []);

  useEffect(() => {
    if (assetGroups && assetGroups.content) {
      let sorted = assetGroups.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedAssetGroups(sorted);
    }
  }, [assetGroups]);

  useEffect(() => {
    if (assets && assets.content) {
      let sorted = assets.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedAssets(sorted);
    }
  }, [assets]);

  useEffect(() => {
    if (sites && sites.content) {
      let sorted = sites.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedSites(sorted);
    }
  }, [sites]);

  useEffect(() => {
    if (zones && zones.content) {
      let sorted = zones.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedZones(sorted);
    }
  }, [zones]);

  useEffect(() => {
    if (relaySwitches && relaySwitches.content) {
      let sorted = relaySwitches.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedRelaySwitches(sorted);
    }
  }, [relaySwitches]);

  useEffect(() => {
    if (contacts && contacts.content) {
      let sorted = contacts.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedContacts(sorted);
    }
  }, [contacts]);

  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="assetGroup-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        label={props.language?.NAME?.value ?? englishLang?.NAME?.value}
        name="name"
        rules={requiredFieldRule}
      >
        <Input
          id={ElementIDS().name}
          placeholder={
            props.language?.ENTER_NAME?.value ?? englishLang?.ENTER_NAME?.value
          }
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <>
        <Divider className="dividerBorder" />
      </>
      <Tooltip
        title={
          props.language?.SELECT_ASSET_GROUP_AND_OR_ASSET?.value ??
          englishLang?.SELECT_ASSET_GROUP_AND_OR_ASSET?.value
        }
      >
        <div>
          <Form.Item
            label={
              props.language?.ASSET_GROUPS?.value ??
              englishLang?.ASSET_GROUPS?.value
            }
          >
            <Row gutter={3}>
              <Col flex={12} span={24}>
                <Form.Item
                  name="assetGroups"
                  noStyle
                  onChange={(e) => {
                    props.setDirty();
                  }}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          (value && value.length > 0) ||
                          (getFieldValue("assets") &&
                            getFieldValue("assets").length > 0)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            props.language?.ASSET_GROUP_OR_ASSET_IS_REQUIRED
                              ?.value ??
                              englishLang?.ASSET_GROUP_OR_ASSET_IS_REQUIRED
                                ?.value
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Select
                    onChange={(e) => {
                      props.setDirty();
                    }}
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={
                      props.language?.SELECT_ASSET_GROUP?.value ??
                      englishLang?.SELECT_ASSET_GROUP?.value
                    }
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    id={ElementIDS().assetGroup}
                    disabled={
                      props.readOnly && props.readOnly.viewOption
                        ? "disabled"
                        : null
                    }
                  >
                    {sortedAssetGroups
                      ? sortedAssetGroups.map((item) => (
                          <Option
                            key={item.assetGroupId}
                            value={item.assetGroupId}
                          >
                            {item.name}
                          </Option>
                        ))
                      : undefined}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={props.language?.ASSETS?.value ?? englishLang?.ASSETS?.value}
          >
            <Row gutter={3}>
              <Col flex={12} span={24}>
                <Form.Item
                  name="assets"
                  noStyle
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          (value && value.length > 0) ||
                          (getFieldValue("assetGroups") &&
                            getFieldValue("assetGroups").length > 0)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            props.language?.ASSET_GROUP_OR_ASSET_IS_REQUIRED
                              ?.value ??
                              englishLang?.ASSET_GROUP_OR_ASSET_IS_REQUIRED
                                ?.value
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Select
                    onChange={(e) => {
                      props.setDirty();
                    }}
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={
                      props.language?.SELECT_ASSETS?.value ??
                      englishLang?.SELECT_ASSETS?.value
                    }
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    id={ElementIDS().asset}
                    disabled={
                      props.readOnly && props.readOnly.viewOption
                        ? "disabled"
                        : null
                    }
                  >
                    {sortedAssets
                      ? sortedAssets.map((item) => (
                          <Option key={item.assetId} value={item.assetId}>
                            {item.name}
                          </Option>
                        ))
                      : undefined}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </div>
      </Tooltip>
      <div className="divider">
        <Divider className="dividerBorder" />
      </div>{" "}
      <Tooltip
        title={
          props.language?.SELECT_ZONE?.value ?? englishLang?.SELECT_ZONE?.value
        }
      >
        <div>
          <Form.Item
            label={props.language?.ZONES?.value ?? englishLang?.ZONES?.value}
          >
            <Row gutter={3}>
              <Col flex={12} span={24}>
                <Form.Item
                  name="zones"
                  noStyle
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          (value && value.length > 0) ||
                          (getFieldValue("sites") &&
                            getFieldValue("sites").length > 0)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            props.language?.ZONE_IS_REQUIRED?.value ??
                              englishLang?.ZONE_IS_REQUIRED?.value
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Select
                    onChange={(e) => {
                      props.setDirty();
                    }}
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={
                      props.language?.SELECT_ZONES?.value ??
                      englishLang?.SELECT_ZONES?.value
                    }
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    id={ElementIDS().zones}
                    disabled={
                      props.readOnly && props.readOnly.viewOption
                        ? "disabled"
                        : null
                    }
                  >
                    {sortedZones
                      ? sortedZones.map((item) => (
                          <Option key={item.zoneId} value={item.zoneId}>
                            {item.name}
                          </Option>
                        ))
                      : undefined}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </div>
      </Tooltip>
      <div className="divider">
        <Divider className="dividerBorder" />
      </div>{" "}
      <Tooltip
        title={
          props.language?.SELECT_NOTIFICATION_METHOD?.value ??
          englishLang?.SELECT_NOTIFICATION_METHOD?.value
        }
      >
        <div>
          <Form.Item
            label={
              props.language?.RELAY_SWITCHES?.value ??
              englishLang?.RELAY_SWITCHES?.value
            }
          >
            <Row gutter={3}>
              <Col flex={12} span={24}>
                <Form.Item name="relaySwitches" noStyle>
                  <Select
                    onChange={(e) => {
                      props.setDirty();
                    }}
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={
                      props.language?.SELECT_RELAY_SWITCH?.value ??
                      englishLang?.SELECT_RELAY_SWITCH?.value
                    }
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    id={ElementIDS().relaySwitches}
                    disabled={
                      props.readOnly && props.readOnly.viewOption
                        ? "disabled"
                        : null
                    }
                  >
                    {sortedRelaySwitches
                      ? sortedRelaySwitches.map((item) => (
                          <Option
                            key={item.relaySwitchId}
                            value={item.relaySwitchId}
                          >
                            {item.name}
                          </Option>
                        ))
                      : undefined}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={
              props.language?.CONTACTS?.value ?? englishLang?.CONTACTS?.value
            }
          >
            <Row gutter={3}>
              <Col flex={12} span={24}>
                <Form.Item name="contacts" noStyle>
                  <Select
                    onChange={(e) => {
                      props.setDirty();
                    }}
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={
                      props.language?.SELECT_CONTACTS?.value ??
                      englishLang?.SELECT_CONTACTS?.value
                    }
                    showSearch
                    filterOption={(input, option) => {
                      const wholeOpt = (option.children || [])
                        .join("")
                        .toLowerCase();
                      return (
                        wholeOpt.includes(input.toLowerCase()) && input !== ""
                      );
                    }}
                    id={ElementIDS().contacts}
                    disabled={
                      props.readOnly && props.readOnly.viewOption
                        ? "disabled"
                        : null
                    }
                  >
                    {sortedContacts
                      ? sortedContacts.map((item) => (
                          <Option key={item.contactId} value={item.contactId}>
                            {item.name} {item.surname}
                          </Option>
                        ))
                      : undefined}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={
              props.language?.ACTIVATE_ALERT_RULE?.value ??
              englishLang?.ACTIVATE_ALERT_RULE?.value
            }
          >
            <Col gutter={3}>
              <Row flex={12} span={24}>
                <Form.Item name="active" noStyle>
                  <Switch
                    disabled={
                      props.readOnly && props.readOnly.viewOption
                        ? "disabled"
                        : null
                    }
                    onChange={props.onActive}
                    checked={props.active}
                  ></Switch>
                </Form.Item>
              </Row>
            </Col>
          </Form.Item>
        </div>
      </Tooltip>
      {props.footer}
    </Form>
  );
};

export default AddAlertRuleForm;
