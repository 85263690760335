import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "antd";
import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchUserGroups,
  deleteUserGroup,
} from "../../../api/redux/action/UserGroupActions";
import UserGroupActionView from "../../../components/common/ActionMenu";
import useNotification from "../../../components/common/ResultNotifier";
import { hasPermission } from "../../layout/Constants";
import { useHistory } from "react-router-dom";
function UserGroups() {
  const dispatch = useDispatch();

  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  let history = useHistory();

  const [searchText, setSearchText] = useState();

  const {
    userGroups,
    error,
    requestStatus,
    loading,
    user,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    userGroups: store.userGroupReducer.userGroups,
    error: store.userGroupReducer.error,
    requestStatus: store.userGroupReducer.requestStatus,
    loading: store.userGroupReducer.loading,
    user: store.authReducer.user,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const columns = [
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
      sorter: true,
      sortField: "name",
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      render: (name) => (
        <a
          onClick={() => {
            const viewOption = "disabled";

            history.push({
              pathname: "/update-userGroup",
              state: { selectedRow, viewOption },
            });
          }}
        >
          {name}
        </a>
      ),
    },
  ];

  const [allowPermission, setAllowPermission] = useState({
    edit: hasPermission(selectedTenant, "UPDATE_USER_GROUPS"),
    delete: hasPermission(selectedTenant, "DELETE_USER_GROUPS"),
    view: hasPermission(selectedTenant, "READ_USER_GROUPS"),
  });

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const handleSingleDelete = (selected) => {
    dispatch(deleteUserGroup(selected.userGroupId));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    selectedRow,
    sortField,
    sortOrder,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "userGroup",
    dataSource: userGroups,
    updateEntityPath: "update-userGroup",
    customActionView:
      allowPermission.edit || allowPermission.delete || allowPermission.view
        ? UserGroupActionView
        : "",
    searchText,
    loading: loading,
    handleSingleDelete,
    allowPermission,
    setSortedInfo,
    title: language?.USER_GROUPS?.value ?? englishLang?.USER_GROUPS?.value,
  });

  useEffect(() => {
    dispatch(
      fetchUserGroups({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo]);

  let flattenedData = [];

  if (Object.keys(userGroups).length !== 0) {
    flattenedData = Object.values(userGroups.content);
  }
  if (Object.keys(userGroups).length !== 0) {
    flattenedData = Object.values(userGroups.content);
    flattenedData.forEach((userGroup) => {
      let roleSet = "";
      if (userGroup.roleSet) {
        for (var x = 0; x < userGroup.roleSet.length; x++) {
          roleSet += x > 0 ? "," : "";
          roleSet += userGroup.roleSet[x].name;
        }
      }

      userGroup.roleSet1 = roleSet;
    });
  }

  const headers = [
    { label: language?.NAME?.value ?? englishLang?.NAME?.value, key: "name" },
    {
      label: language?.SYSTEM?.value ?? englishLang?.SYSTEM?.value,
      key: "system",
    },
    {
      label: language?.ROLE_SET?.value ?? englishLang?.ROLE_SET?.value,
      key: "roleSet1",
    },
  ];

  const addNewPath = hasPermission(selectedTenant, "CREATE_USER_GROUPS")
    ? "add-userGroup"
    : "";

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath={addNewPath}
          hasSelected={hasSelected}
          exportTableTitle={
            language?.USERGROUPS?.value ?? englishLang?.USERGROUPS?.value
          }
          addNewText={
            language?.ADD_USER_GROUP?.value ??
            englishLang?.ADD_USER_GROUP?.value
          }
          title={
            language?.USER_GROUPS?.value ?? englishLang?.USER_GROUPS?.value
          }
          csvData={flattenedData}
          csvHeaders={headers}
          handleSearch={setSearchText}
          tooltipText={
            language?.TOOLTIP_SEARCH_NAME?.value ??
            englishLang?.TOOLTIP_SEARCH_NAME?.value
          }
        />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default UserGroups;
