import React from "react";

import useDataTable from "../../../../components/common/DataTable";

import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import "antd/dist/antd.min.css";

const ComponentModal = ({
  selectedImageComponent,
  isComponentModalOpen,
  setComponentModalOpen,
  detectedBySingleZone,
  userTimeZone,
  loading,
}) => {
  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));
  const columns = [
    {
      title: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      dataIndex: "code",
      key: "code",
    },
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
      key: "name",
    },
    {
      title: language?.ASSET_GROUP?.value ?? englishLang?.ASSET_GROUP?.value,
      dataIndex: "assetGroup",
      key: "assetGroup",
    },
    {
      title:
        language?.LAST_DETECTED_TIMESTAMP?.value ??
        englishLang?.LAST_DETECTED_TIMESTAMP?.value,
      dataIndex: "lastDetectedTimestamp",
      key: "lastDetectedTimestamp",
      render: (lastDetectedTimestamp) => (
        <span>
          {lastDetectedTimestamp
            ? moment
                .tz(lastDetectedTimestamp, userTimeZone)
                .format("YYYY-MM-DD HH:mm:ss")
            : ""}
        </span>
      ),
    },
  ];

  const dataSource = detectedBySingleZone?.lastDetectedReportRecordList
    .map((record) => ({
      key: record.asset.assetId,
      code: record.asset.code,
      name: record.asset.name,
      assetGroup: record.asset.assetGroup.name,
      lastDetectedTimestamp: record.lastDetectedTimestamp,
    }))
    .flat();

  const { DataTable } = useDataTable({
    columns,
    dataSource: dataSource,
    updateEntityPath: "",
    loading: loading,
    pagination: false,
    mouseHover: false,
  });

  const handleModalOk = () => {
    setComponentModalOpen(false);
  };

  const handleModalCancel = () => {
    setComponentModalOpen(false);
  };
  const handleHeightModal = () => {
    if (dataSource?.length > 3) {
      return { height: 400 };
    } else if (dataSource?.length > 2) {
      return { height: 300 };
    }
  };

  return (
    <>
      <Modal
        title={`Last Detected Assets in ${selectedImageComponent?.zone}`}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        open={isComponentModalOpen}
        width={1300}
        footer={null}
        bodyStyle={handleHeightModal()}
        style={{
          top: 5,
        }}
      >
        <DataTable />
      </Modal>
    </>
  );
};

export default ComponentModal;
