import * as type from '../types';
import { RequestState } from './RequestState';

const INITIAL_STATE = {
  zones: {},
  allZones: {},
  zone: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  exportAllLoading: false
};

const reducer =  (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.ZONES_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        zones: {},
        loading: true
      };
    case type.ZONES_FETCH_SUCCESS:
      return {
        ...state,
        zones: action.payload,
        loading: false
      };
    case type.ZONES_FETCH_FAILURE:
      return {
        ...state,
        zones: {},
        loading: false
      };
      case type.ALL_ZONES_FETCH_REQUEST:
        return {
          ...state,
          error: {},
          allZones: {},
          exportAllLoading: true
        };
      case type.ALL_ZONES_FETCH_SUCCESS:
        return {
          ...state,
          allZones: action.payload,
          exportAllLoading: false
        };
      case type.ALL_ZONES_FETCH_FAILURE:
        return {
          ...state,
          allZones: {},
          exportAllLoading: false
        };
      case type.ZONE_SAVE_REQUEST:
        return {
          ...state,
          error: {},
          zone: {},
          requestStatus: RequestState.PENDING,
        };
    case type.ZONE_SAVE_SUCCESS:
      return {
        ...state,
        zone: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.ZONE_SAVE_FAILURE:
      return {
        ...state,
        zone: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
      case type.ZONE_DELETE_REQUEST:
          return {
            ...state,
            requestStatus: RequestState.PENDING,
          };
        case type.ZONE_DELETE_SUCCESS:
          return {
            ...state,
            zone: action.payload,
            error: {},
            requestStatus: RequestState.SUCCESS,
          };
        case type.ZONE_DELETE_FAILURE:
          return {
            ...state,
            zone: {},
            error: action.payload,
            requestStatus: RequestState.FAILURE,
          };
          case type.CLEAN_REQUEST_STATUS:
        return {
          ...state,
          requestStatus: RequestState.EMPTY
        };
    default:
      return state;
  }
};


export default reducer;