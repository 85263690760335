import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "antd";
import moment from "moment-timezone";
import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchMobileReaders,
  fetchAllMobileReaders,
} from "../../../api/redux/action/MobileReaderActions";
import useNotification from "../../../components/common/ResultNotifier";
import { stateColors } from "../../layout/Constants";
import { exportRequest } from "../../../api/network/axios/exportRequest";

function getColor(status) {
  return stateColors[status].color;
}

function MobileReaders() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState();
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [flattenedData, setFlattenedData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  const {
    user,
    mobileReaders,
    allMobileReaders,
    error,
    requestStatus,
    loading,
    userTimeZone,
    language,
    englishLang,
  } = useSelector((store) => ({
    user: store.authReducer.user,
    mobileReaders: store.mobileReaderReducer.mobileReaders,
    allMobileReaders: store.mobileReaderReducer.allMobileReaders,
    error: store.mobileReaderReducer.error,
    requestStatus: store.mobileReaderReducer.requestStatus,
    loading: store.mobileReaderReducer.loading,
    userTimeZone: store.authReducer.userTimeZone,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));
  const columns = [
    {
      title:
        language?.VEHICLE_REGISTRATION_NUMBER?.value ??
        englishLang?.VEHICLE_REGISTRATION_NUMBER?.value,
      dataIndex: "vehicleRegistrationNumber",
    },
    {
      title:
        language?.LAST_CONNECTION_TIMESTAMP?.value ??
        englishLang?.LAST_CONNECTION_TIMESTAMP?.value,
      dataIndex: "lastConnectionTimestamp",
      render: (lastConnectionTimestamp) => (
        <span>
          {lastConnectionTimestamp
            ? moment
                .tz(lastConnectionTimestamp, userTimeZone)
                .format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
      sorter: true,
      sortField: "lastConnectionTimestamp",
      sortOrder:
        sortedInfo.columnKey === "lastConnectionTimestamp" && sortedInfo.order,
    },
    {
      title: language?.STATUS?.value ?? englishLang?.STATUS?.value,
      dataIndex: "status",
      render: (status) => (
        <Tag color={getColor(status)} key={status}>
          {status}
        </Tag>
      ),
      sorter: true,
      sortField: "status",
      sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
    },
  ];

  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    sortField,
    sortOrder,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "reader",
    dataSource: mobileReaders,
    updateEntityPath: "update-mobileReader",
    customActionView: "",
    searchText,
    loading: loading,
    setSortedInfo,
  });

  useEffect(() => {
    dispatch(
      fetchMobileReaders({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-ip-relay-cards";
    let options = {
      url: "/api/web/readers/export?setup=mobile",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        searchText: searchText,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  useEffect(() => {
    if (mobileReaders && Object.keys(mobileReaders).length !== 0) {
      let flatData = Object.values(mobileReaders.content);

      flatData.map((data) => {
        data.lastConnectionTimestampFormatted = data.lastConnectionTimestamp
          ? moment
              .tz(data.lastConnectionTimestamp, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedData(Object.values(flatData));
    }
  }, [mobileReaders]);

  useEffect(() => {
    if (allMobileReaders && Object.keys(allMobileReaders).length !== 0) {
      let flatData = Object.values(allMobileReaders.content);

      flatData.map((data) => {
        data.lastConnectionTimestampFormatted = data.lastConnectionTimestamp
          ? moment
              .tz(data.lastConnectionTimestamp, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedAllData(Object.values(flatData));
    }
  }, [allMobileReaders]);

  const headers = [
    {
      label:
        language?.VEHICLE_REGISTRATION_NUMBER?.value ??
        englishLang?.VEHICLE_REGISTRATION_NUMBER?.value,
      key: "vehicleRegistrationNumber",
    },
    {
      label:
        language?.LAST_CONNECTION_TIMESTAMP?.value ??
        englishLang?.LAST_CONNECTION_TIMESTAMP?.value,
      key: "lastConnectionTimestampFormatted",
    },
    {
      label: language?.STATUS?.value ?? englishLang?.STATUS?.value,
      key: "status",
    },
  ];

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          hasSelected={hasSelected}
          exportTableTitle={
            language?.MOBILEREADERS?.value ?? englishLang?.MOBILEREADERS?.value
          }
          addNewText=""
          title={
            language?.MOBILE_READERS?.value ??
            englishLang?.MOBILE_READERS?.value
          }
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllButtonClick={handleExportAllButtonClick}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportButton={true}
          exportAllButton={true}
          handleSearch={setSearchText}
          tooltipText={
            language?.TOOLTIP_SEARCH_MOBILE_READERS?.value ??
            englishLang?.TOOLTIP_SEARCH_MOBILE_READERS?.value
          }
        />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default MobileReaders;
