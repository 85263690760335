import { Form, Input } from "antd";
import React, { useState } from "react";
import "../../layout/Style.css";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import "../../layout/Style.css";
import validator from "validator";
import { useSelector } from "react-redux";
const ResetPasswordForm = (props) => {
  const [success, setSuccess] = useState("");
  const { englishLang } = useSelector((store) => ({
    englishLang: store.langReducer.englishLang,
  }));
  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="resetPassword-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        id={ElementIDS().userName}
        label={props.language?.USERNAME?.value ?? englishLang?.USERNAME?.value}
        name="username"
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        id={ElementIDS().password}
        hasFeedback
        validateStatus={success}
        label={props.language?.PASSWORD?.value ?? englishLang?.PASSWORD?.value}
        name="password"
        rules={[
          {
            required: true,
            message:
              props.language?.REQUIRED_FIELD?.value ??
              englishLang?.REQUIRED_FIELD?.value,
          },
          () => ({
            validator(_, value) {
              if (
                value &&
                validator.isStrongPassword(value, {
                  minLength: 8,
                  minLowercase: 1,
                  minUppercase: 1,
                  minNumbers: 1,
                  minSymbols: 1,
                })
              ) {
                setSuccess("success");
                return Promise.resolve();
              } else {
                setSuccess("error");
                return Promise.reject(
                  new Error(
                    props.language?.PSW_VALIDATION?.value ??
                      englishLang?.PSW_VALIDATION?.value
                  )
                );
              }
            },
          }),
        ]}
      >
        <Input type="password" />
      </Form.Item>
      <Form.Item
        id={ElementIDS().confirmPassword}
        label={
          props.language?.CONFIRM_PASSWORD?.value ??
          englishLang?.CONFIRM_PASSWORD?.value
        }
        name="confirmPassword"
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message:
              props.language?.REQUIRED_FIELD?.value ??
              englishLang?.REQUIRED_FIELD?.value,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  props.language?.ERR__PASSWORD?.value ??
                    englishLang?.ERR__PASSWORD?.value
                )
              );
            },
          }),
        ]}
      >
        <Input type="password" />
      </Form.Item>

      {props.footer}
    </Form>
  );
};

export default ResetPasswordForm;
