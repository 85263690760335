import React, { useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

// currentAsssetsDetection

function Map({ data }) {

    useEffect(() => {
        const clusterMap = L.map('clusterMap').setView([35.9375, 14.3754], 10);

  
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        //   attribution:
        //     '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          // maxZoom: 16,
        }).addTo(clusterMap);
    
        var markers = L.markerClusterGroup({
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: false,
            zoomToBoundsOnClick: true,
            maxClusterRadius: function (zoom) {
              return 20;
            },
        });
    
        const points = data && data.currentlyDetectedAssets.length>0
          ? data.currentlyDetectedAssets.map((p) => {
          
            if(p.l){
              var latlng=L.latLng(p.l.lat,p.l.lon);
              var label =p.asset.code;
              
              var  pMarker = new L.CircleMarker(latlng, {
                radius: 5,
                color: '#FFFFFF',
                weight: 2,
                fillOpacity: 1,
                fillColor: 'red'
              });
              
              pMarker.bindPopup(label);
              markers.addLayer(pMarker);
            return [p.l.lat, p.l.lon];
    }

    })
    : [];

    // get assets location,map them and put in one array
    let marks = []
        points.map((n) => {
            marks.push(L.marker(n))
        })

    // group the assets
    let group = L.featureGroup(marks)
    // set 1s to get close to the corresponding location
    setTimeout(function () {
        clusterMap.fitBounds(group.getBounds());
      }, 1000);



        // setTimeout(function(){
            clusterMap.addLayer(markers);
        // },500)

        }, []);

        return <div id="clusterMap" style={{ height: '500px'}} />;
    }

    export default Map;