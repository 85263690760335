import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select, Tooltip, Row, Col, Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "../../layout/Style.css";
import { getModalSizeByWindowSize } from "../../layout/Constants";
import AddRoleForm from "../Roles/AddRoleForm";
import { fetchRoles, saveRole } from "../../../api/redux/action/RoleActions";
import { ElementIDS } from "../../../components/common/ElementsIDs";

const { Option } = Select;

const AddUserGroupForm = (props) => {
  const dispatch = useDispatch();

  function onChange(checked) {}
  const [visible, setVisible] = useState({ role: false });
  const [roleForm] = Form.useForm();
  const [newRoleData, setNewRoleData] = useState(undefined);
  const [modalSize, setModalSize] = useState(getModalSizeByWindowSize());
  const [sortedRoles, setSortedRoles] = useState([]);

  const { roles, error, requestStatus, englishLang } = useSelector((store) => ({
    roles: store.roleReducer.roles,
    error: store.userGroupReducer.error,
    requestStatus: store.userGroupReducer.requestStatus,
    englishLang: store.langReducer.englishLang,
  }));

  useEffect(() => {
    dispatch(fetchRoles());
  }, [newRoleData]);

  useEffect(() => {
    if (roles && roles.content && newRoleData && newRoleData.name) {
      roles.content.map((item) => {
        if (item.name == newRoleData.name) {
          props.form.setFieldsValue({
            ...(newRoleData && {
              roleIdList: item.roleId,
            }),
          });
        }
      });
    }
  }, [roles]);

  useEffect(() => {
    if (roles && roles.content) {
      let sorted = roles.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedRoles(sorted);
    }
  }, [roles]);

  const handleAddNewRole = () => {
    showModal(true);
  };

  const handleRoleFormSave = (values) => {
    let permissionIdListFinal = [];
    roleForm.getFieldValue().permissionIdList.map((item) => {
      const splitItem = item.toString().split("-");
      if (splitItem.length > 1) {
      } else {
        permissionIdListFinal.push(item);
      }
    });

    const formData = {
      name: roleForm.getFieldValue().name,
      displayName: roleForm.getFieldValue().displayName,
      description: roleForm.getFieldValue().description,
      permissionIdList: permissionIdListFinal,
    };
    if (Object.keys(formData).length > 0) {
      dispatch(saveRole(formData))
        .then(() => {
          setNewRoleData(formData);
          formData = {
            name: roleForm.resetFields().name,
            displayName: roleForm.resetFields().displayName,
            description: roleForm.resetFields().description,
            permissionIdList: roleForm.resetFields().permissionIdList,
          };
        })
        .catch((err) => console.log(err));
    }
    setVisible(false);
  };

  const showModal = (role) => {
    setVisible({ role: role });
  };

  const handleModalOk = (e) => {
    roleForm.submit();
  };

  const handleModalCancel = (e) => {
    setVisible(false);
  };
  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="userGroup-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        id={ElementIDS().name}
        label={props.language?.NAME?.value ?? englishLang?.NAME?.value}
        name="name"
        rules={requiredFieldRule}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>

      <Form.Item
        label={props.language?.ROLE?.value ?? englishLang?.ROLE?.value}
      >
        <Row gutter={3}>
          <Col flex={9} span={22}>
            <Form.Item name="roleIdList" noStyle>
              <Select
                onChange={(e) => {
                  props.setDirty();
                }}
                id={ElementIDS().role}
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder={
                  props.language?.SELECT_ROLE?.value ??
                  englishLang?.SELECT_ROLE?.value
                }
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {sortedRoles
                  ? sortedRoles.map((item) => (
                      <Option
                        id={ElementIDS().option}
                        key={item.roleId}
                        value={item.roleId}
                      >
                        {item.name}
                      </Option>
                    ))
                  : undefined}
              </Select>
            </Form.Item>
          </Col>
          <Col flex={1} span={2}>
            <Tooltip
              title={
                props.language?.CREATE_NEW_ROLE?.value ??
                englishLang?.CREATE_NEW_ROLE?.value
              }
            >
              <Button
                id={ElementIDS().create}
                icon={<PlusOutlined />}
                ghost
                type="primary"
                onClick={handleAddNewRole}
                style={{ width: "100%" }}
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              />
            </Tooltip>
          </Col>
        </Row>
      </Form.Item>

      <Modal
        title={props.language?.ADD_ROLE?.value ?? englishLang?.ADD_ROLE?.value}
        visible={visible.role}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={modalSize}
        style={{ height: "90vh" }}
      >
        <Row justify="center">
          <Col span={20}>
            <AddRoleForm
              handleSave={handleRoleFormSave}
              form={roleForm}
              setDirty={props.setDirty}
            />
          </Col>
        </Row>
      </Modal>

      {props.footer}
    </Form>
  );
};

export default AddUserGroupForm;
