import React from "react";
import { notification, Space, Typography } from "antd";
import { useDispatch } from "react-redux";
import { RequestState } from "../../api/redux/reducer/RequestState";
import { CLEAN_REQUEST_STATUS } from "../../api/redux/types";
import { ResponseStatus } from "../../api/network/axios/ResponseStatus";
import { useSelector } from "react-redux";

const { Text } = Typography;

function useNotification(
  error,
  requestStatus,
  message = null,
  errorMessage = null
) {
  const dispatch = useDispatch();
  const key = "updatable";

  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  function getErrorMessage() {
    if (Object.keys(error).length !== 0) {
      if (error?.status === ResponseStatus.INTERNAL_SERVER_ERR) {
        // return error.response.data.message;
        return language["INTERNAL_SERVER_ERROR"]?.value;
      }
      // return error.response.data.message
      return language[error?.message]?.value;
    } else {
      return (
        language[error?.message]?.value ?? englishLang[error?.message]?.value
      );
    }
  }

  const openNotification = () => {
    if (
      requestStatus === RequestState.SUCCESS ||
      requestStatus === RequestState.CREATED
    ) {
      notification.success({
        key,
        message: "Success",
        description: message || "",
        duration: 8,
      });
    } else if (requestStatus === RequestState.FAILURE) {
      let textError = getErrorMessage();
      notification.error({
        key,
        message: "Failure",
        description: (
          <Space direction="vertical">
            {/* <Text>Save Failed with the following error:</Text> */}
            <Text type="danger">{textError}</Text>
          </Space>
        ),
        duration: 6,
      });
    }
  };

  const isLoading = requestStatus === RequestState.PENDING;

  function requestCompleted() {
    let completed = false;

    if (
      requestStatus === RequestState.SUCCESS ||
      requestStatus === RequestState.FAILURE
    ) {
      completed = true;
      dispatch({
        type: CLEAN_REQUEST_STATUS,
      });
    }

    return completed;
  }

  const ResultNotifier = () => <>{requestCompleted() && openNotification()}</>;

  return [ResultNotifier, isLoading, requestCompleted, openNotification];
}

export default useNotification;
