import * as type from '../types';
import { RequestState } from './RequestState';


const INITIAL_STATE = {
  userGroups: {},
  userGroup: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false
};

const reducer= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.USERGROUPS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        userGroups: {},
        loading: true
      };
    case type.USERGROUPS_FETCH_SUCCESS:
      return {
        ...state,
        userGroups: action.payload,
        loading: false
      };
    case type.USERGROUPS_FETCH_FAILURE:
      return {
        ...state,
        userGroups: {},
        loading: false
      };

      case type.USERGROUP_SAVE_REQUEST:
			return {
				...state,
				error: {},
				userGroup: {},
				requestStatus: RequestState.PENDING,
			};
		case type.USERGROUP_SAVE_SUCCESS:
			return {
				...state,
				userGroup: action.payload,
				error: {},
				requestStatus: RequestState.SUCCESS,
			};
		case type.USERGROUP_SAVE_FAILURE:
			return {
				...state,
				userGroup: {},
				error: action.payload,
				requestStatus: RequestState.FAILURE,
			};
		case type.USERGROUP_DELETE_REQUEST:
			return {
				...state,
				requestStatus: RequestState.PENDING,
			};
        case type.USERGROUP_DELETE_SUCCESS:
			return {
				...state,
				userGroup: action.payload,
				error: {},
				requestStatus: RequestState.SUCCESS,
			};
        case type.USERGROUP_DELETE_FAILURE:
			return {
				...state,
				userGroup: {},
				error: action.payload,
				requestStatus: RequestState.FAILURE,
			};
          case type.CLEAN_REQUEST_STATUS:
            return {
              ...state,
              requestStatus: RequestState.EMPTY
            };
    default:
      return state;
  }
};


export default reducer;