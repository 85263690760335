import { request } from "../axios/request";

export const getContactsService = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/contacts", getState(), {
        method: "GET",
        params,
      })
    );
  };
};

export const saveContactService = (data, id = 0) => {
  if (id > 0) {
    return async (dispatch, getState) => {
      return dispatch(
        request("/contacts/" + id, getState(), {
          method: "PUT",
          data,
        })
      );
    };
  }
  return async (dispatch, getState) => {
    return dispatch(
      request("/contacts", getState(), {
        method: "POST",
        data,
      })
    );
  };
};

export const deleteContactService = (id = 0) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/contacts/" + id + "?forceDelete=true", getState(), {
        method: "DELETE",
      })
    );
  };
};
