import React, { useState } from "react";
import validator from "validator";
import { useSelector } from "react-redux";
import { Form, Input } from "antd";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "./style.css";

const UserSecurityForm = (props) => {
  const [success, setSuccess] = useState("");

  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  return (
    <div>
      <Form
        labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
        wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
        form={props.form}
        name="user-form"
        onFinish={props.handleSave}
      >
        <Form.Item
          id={ElementIDS().userNamePSW}
          label={language?.USERNAME?.value ?? englishLang?.USERNAME?.value}
          name="username"
          rules={[
            {
              required: true,
              message:
                language?.REQUIRED_FIELD?.value ??
                englishLang?.REQUIRED_FIELD?.value,
            },
            { type: "email" },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          id={ElementIDS().oldPassword}
          hasFeedback
          label={
            language?.OLD_PASSWORD?.value ?? englishLang?.OLD_PASSWORD?.value
          }
          name="oldPassword"
          rules={[
            {
              requiredFieldRule,
            },
          ]}
        >
          <Input.Password type="password" />
        </Form.Item>
        <Form.Item
          id={ElementIDS().password}
          hasFeedback
          validateStatus={success}
          label={
            language?.NEW_PASSWORD?.value ?? englishLang?.NEW_PASSWORD?.value
          }
          name="newPassword"
          rules={[
            {
              required: true,
              message:
                language?.REQUIRED_FIELD?.value ??
                englishLang?.REQUIRED_FIELD?.value,
            },
            () => ({
              validator(_, value) {
                if (
                  value &&
                  validator.isStrongPassword(value, {
                    minLength: 8,
                    minLowercase: 1,
                    minUppercase: 1,
                    minNumbers: 1,
                    minSymbols: 1,
                  })
                ) {
                  setSuccess("success");
                  return Promise.resolve();
                } else {
                  setSuccess("error");
                  return Promise.reject(
                    new Error(
                      language?.PSW_VALIDATION?.value ??
                        englishLang?.PSW_VALIDATION?.value
                    )
                  );
                }
              },
            }),
          ]}
        >
          <Input.Password type="password" />
        </Form.Item>

        <Form.Item
          id={ElementIDS().confirmPassword}
          hasFeedback
          label={
            language?.CONFIRM_PASSWORD?.value ??
            englishLang?.CONFIRM_PASSWORD?.value
          }
          name="confirmPassword"
          dependencies={["newPassword"]}
          rules={[
            {
              requiredFieldRule,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    language?.ERR__PASSWORD?.value ??
                      englishLang?.ERR__PASSWORD?.value
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password type="password" />
        </Form.Item>
      </Form>
      {props.footer}
    </div>
  );
};

export default UserSecurityForm;
