export const enLang = {
  "Internal Server Error": "Internal Server Error",
  ERROR: "Error",
  CANNOT_LOGIN: "Cannot log you in.",
  BAD_CREDENTIALS: "Incorrect username or password.",
  UNRECOGNISED_DOMAIN:
    "Unrecognised domain in request.  Please check that domain is correct.",
  MISSING_PERMISSION: "You do not have permission to do this action.",
  BAD_REQUEST: "Bad Request",
  REQUEST_TIMEOUT: "Request Timeout",
  TOO_MANY_REQUESTS: "Too Many Requests",
  DATABASE_ERROR: "Database Connection Error. Please try again later.",
  UNEXPECTED_ERROR: "Something went wrong. Please refresh and try again later.",
  DATA_EXTRACTION_ERROR: "An error occurred while trying to extract data.",
  YOU_DO_NOT_HAVE_PERMISSION:
    "You do not have permission for this feature.  Please contact your administrator",
  NO_GPS_COVERAGE: "No GPS coverage",
  GATEWAY_TIMEOUT: "Gateway Timeout.  Please try again later.",
  DUPLICATE_ASSET_GROUP_NAME: "Asset Group name already exists.",
  ASSET_GROUP_DOES_NOT_EXIST: "Asset Group does not exist.",
  INEXISTENT_ASSET_GROUPS: "Alert rule contains inexistent asset group(s).",
  DUPLICATE_ASSET_CODE: "Asset code already exists.",
  ASSET_DOES_NOT_EXIST: "Asset does not exist.",
  ASSETS_NOT_DELETED: "The asset group has non-deleted assets.",
  INEXISTENT_ASSETS: "Alert rule contains inexistent asset(s).",
  DUPLICATE_CODE: "EPC Code already exists.",
  DUPLICATE_CONTACT: "Contact name already exists.",
  CODE_CANNOT_BE_REGISTERED: "EPC code cannot be registered.",
  USER_DOES_NOT_EXIST: "User does not exist.",
  INCORRECT_PASSWORD: "Old password is not correct.",
  DUPLICATE_USERNAME: "Username already exists.",
  USER_GROUP_DOES_NOT_EXIST: "User group does not exist.",
  CANNOT_UPDATE_SELF_USER_GROUP: "Cannot update your own user group.",
  UNAUTHORIZED_ACCESS_TO_USER_GROUP: "No permission to access user group.",
  DUPLICATE_USER_GROUP_NAME: "User Group name already exists.",
  ALERT_RULE_DOES_NOT_EXIST: "Alert rule does not exist.",
  DUPLICATE_ALERT_RULE_NAME: "Alert rule name already exists.",
  ALERT_RULE_ALREADY_LOGGED:
    "Alert rule cannot be modified because alerts have already been triggered.  Only Relay Switches and Contacts can be modified.",
  DUPLICATE_GATEWAY_NAME: "Gateway name already exists.",
  GATEWAY_DOES_NOT_EXIST: "Gateway does not exist.",
  READER_DOES_NOT_EXIST: "Reader does not exist.",
  READERS_NOT_DELETED: "The gateway has non-deleted readers.",
  DUPLICATE_READER_NAME: "Reader name already exists.",
  DUPLICATE_GATEWAY_READER_IP_PORT_NUMBER:
    "Port number for this IP address already exists for the specified gateway.",
  INVALID_READER_IP: "Invalid reader IP.",
  INVALID_READER_PORT: "Invalid reader port.",
  IP_RELAY_CARD_DOES_NOT_EXIST: "IP relay card does not exist.",
  DUPLICATE_IP_RELAY_CARD_IP_PORT_NUMBER_FOR_GATEWAY:
    "Port number of this IP address already exists for the specified gateway.",
  INVALID_IP_RELAY_CARD_IP: "Invalid IP address for IP relay card.",
  INVALID_IP_RELAY_CARD_PORT: "Invalid IP relay card port number.",
  RELAY_SWITCHES_NOT_DELETED:
    "The IP relay card has non-deleted relay switches.",
  RELAY_SWITCH_DOES_NOT_EXIST: "Relay switch does not exist.",
  DUPLICATE_RELAY_SWITCH_NAME_IN_IP_RELAY_CARD:
    "Relay switch name already exists for the specified IP relay card.",
  DUPLICATE_RELAY_SWITCH_NO_IN_IP_RELAY_CARD:
    "Switch number already exists for this relay card.",
  INEXISTENT_RELAY_SWITCHES: "Alert rule contains inexistent relay switch(es).",
  ALERT_RULES_CONNECTED_TO_RELAY_SWITCH:
    "The relay switch is connected to an Alert Rule",
  DUPLICATE_SITE_NAME: "Site name already exists.",
  SITE_DOES_NOT_EXIST: "Site does not exist.",
  INEXISTENT_SITES: "Alert rule contains inexistent site(s).",
  ZONE_DOES_NOT_EXIST: "Zone does not exist.",
  ZONES_NOT_DELETED: "The site contains non-deleted zones.",
  DUPLICATE_ZONE_NAME_IN_SITE: "Zone name already exists in site.",
  INEXISTENT_ZONES: "Alert rule contains inexistent zone(s).",
  ZONE_LINKED_TO_ANOTHER_GATEWAY:
    "The specified zone is already linked to another gateway.",
  DUPLICATE_ANTENNA_NUMBER_IN_READER:
    "Antenna number already exists for this reader.",
  ANTENNAS_NOT_DELETED: "The reader has non-deleted antennas.",
  ANTENNA_DOES_NOT_EXIST: "Antenna does not exist.",
  INVALID_PHONE_NUMBER: "Invalid phone number",
  MISSING_CONTACT_VALUE: "Invalid contact",
  INVALID_EMAIL: "Invalid email address",
  ALERT_RULES_CONNECTED_TO_CONTACT: "The contact is connected to an Alert Rule",
  DEPARTMENT_DOES_NOT_EXIST: "Department does not exist.",
  DUPLICATE_DEPARTMENT_NAME: "Department name already exists.",
  UNAUTHORIZED_ACCESS_TO_DEPARTMENT: "Unauthorized access to departments",
  ASSET_GROUPS_NOT_DELETED: "Asset Groups not deleted",
  EXISTING_USERS: "Existing users",
  ADDRESS_WILL_BE_AVAILABLE_SHORTLY: "Address will be available shortly",
  ADDRESS_IS_UNRESOLVABLE: "Address is unresolvable",
  //
  NAME: "Name",
  DEPARTMENT: "Department",
  SAVED_ITEM: "Item saved successfully",
  CANCEL: "Cancel",
  SAVE: "Save",
  TYPE: "Type",
  REQUIRED: "Required",
  SELECT_ASSEST_GROUP: "Select an Asset Group",
  DATA_TABLE_TAB: "Table",
  FLOOR_PLAN_TAB: "Floor plan",
  ASSET_CODE: "Asset Code",
  ASSET_EXTERNAL_REFERENCE_NO: "Asset External Reference No.",
  ASSET_GROUP: "Asset Group",
  EPC_CODES: "EPC Codes",
  EXTERNAL_REFERENCE_NO: "External Reference No.",
  ASSETS_WITH_EPC_CODE: " Assets with EPC code ",
  ASSETS_WITH_ASSET_CODE: " Assets with asset code ",
  FILE_UPLOAD_SUCCESS: "file uploaded successfully.",
  FILE_UPLOAD_FAILED: "file upload failed.",
  ASSETS: "Assets",
  SELECT_ASSET_GROUPS: "Select Asset Groups",
  SELECT_A_READER: "Please select a Reader",
  SELECT_A_ZONE: "Please select a Zone",
  SELECT_FIELD_TYPE: "Please select a Field Type",
  FIELD_TYPE: "Field Type",
  INPUT_OPTION_OR_DELETE_FIELD: "Please input option or delete this field.",
  CUSTOM_FILEDS: "Custom Fields",
  DEPARTMENTS: "Departments",
  SELECT_GATEWAY: "Please select a Gateway",
  CREATE_GATEWAY: "Create a new Gateway",
  ADD_GATEWAY: "Add Gateway",
  GATEWAY: "Gateway",
  IP_ADDRESS: "IP Address",
  PORT_NUMBER: "Port Number",
  STATUS: "Status",
  LAST_CONNECTION_TIMESTAMP: "Last Connection Timestamp",
  FIXED_READER: "Fixed Reader",
  DESCRIPTION: "Description",
  LAST_HEALTHY_STATUS_TIMESTAMP: "Last healthy status timestamp",
  IP_RELAY_CARD: "IP Relay Card",
  GATEWAY_DESCRIPTION: "Gateway Description",
  IP_RELAY_CARDS: "IP Relay Cards",
  ADD_IP_RELAY_CARD: "Add IP Relay Card",
  VEHICLE_REGISTRATION_NUMBER: "Vehicle Registration Number",
  MOBILE_READERS: "Mobile Readers",
  SWITCH_NUMBER: "Switch Number",
  SELECT_IP_RELAY_CARD: "Please select an IP Relay Card",
  CREATE_IP_RELAY_CARD: "Create a new IP Relay Card",
  PUSLE_IN_MILLISECONDS: "Pulse in Milliseconds",
  LOCATION: "Location",
  LATITUDE: "Latitude",
  LONGITUDE: "Longitude",
  SITES: "Sites",
  ADD_SITE: "Add Site",
  CREATE_NEW_SITE: "Create a new Site",
  SITE: "Site",
  ZONES: "Zones",
  ACTION: "Action",
  EVENT_DATA: "Event Data",
  DATE_AND_TIME: "Date & Time",
  USER: "User",
  AUDIT_LOG: "Audit Log",
  SELECT_CATEGORY: "Select Category",
  SELECT_USER: "Select a User",
  ALL: "All",
  ADD: "Add",
  EDIT: "Edit",
  DELETE: "Delete",
  LOGIN: "Login",
  REPORT: "Report",
  SURNAME: "Surname",
  MOBILE: "MOBILE",
  MOBILE_NOTIFICATIONS: "Mobile Notifications",
  EMAIL: "Email",
  EMAIL_NOTIFICATIONS: "Email Notifications",
  CONTACTS: "Contacts",
  ROLE: "Role",
  DISPLAY_NAME: "Display Name",
  PERMISSION: "Permission",
  PERMISSION_SET: "Permission Set",
  ROLES: "Roles",
  ADD_ROLE: "Add Role",
  SELECT_ROLE: "Please select a Role",
  CREATE_NEW_ROLE: "Create a new role",
  SYSTEM: "System",
  ROLE_SET: "Role Set",
  USER_GROUPS: "User Groups",
  ADD_USER_GROUP: "Add User Group",
  DE_ACTIVATE: "De-activate",
  ACTIVATE: "Activate",
  EDIT: "Edit",
  RESET_PASSWORD: "Reset Password",
  ACTIONS: "Actions",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm Password",
  MSG_CONFIRM_PASSWORD: "Please confirm your password!",
  ERR__PASSWORD: "The two passwords that you entered do not match!",
  USERNAME: "Username",
  USER_GROUP: "User Group",
  SELECT_USER_GROUP: "Please select a User Group",
  SELECT_DEPARTMENT: "Please select a Department",
  CREATE_NEW_DEPARTMENT: "Create a new department",
  ADD_DEPARTMENT: "Add Department",
  ADD_USER: "Add User",
  USERS: "Users",
  ASSET: "Asset",
  ZONE: "Zone",
  RULE: "Rule",
  READ: "Read",
  SELECT_DATE_RANGE: "Select Date Range",
  SHOW_READ: "SHOW READ",
  SHOW_HIDE_READ_ALERT_LOGS: "Show/Hide read alert logs",
  ALERT_LOG: "Alert Log",
  SELECT_ASSETS: "Select Assets",
  SELECT_EITHER_ASSET_GRUOPS_OR_ASSETS: "Select either Asset Groups or Assets",
  SELECT_SITES: "Select Sites",
  SELECT_SITES_OR_ZONES: "Select either Sites or Zones",
  SELECT_A_ZONE: "Select a Zone",
  RELAY_SWITCHES: "Relay Switches",
  SELECT_RELAY_SWITCH: "Select a Relay Switch",
  SELECT_CONTACTS: "Select Contacts",
  ASSET_GROUPS: "Asset Groups",
  ALERT_RULE: "Alert Rule",
  SUBMIT_FAILED: "Submit Failed",
  PREVIOUS: "Previous",
  NEXT: "Next",
  SUBMIT: "Submit",
  IS_TRIGGERD_WHEN: "is triggered when",
  ASSETS_S: "assets/s :",
  ANY_ASSET_IN_ASSET_GROUP_S: "any asset in asset group/s:",
  DETECTED: "DETECTED",
  ARE: "are",
  IN: "in",
  ZONE_S: "zone/s:",
  ANY_ZONE_IN_SITE_S: "any zone in site/s:",
  RELAY_SWITCHES_LIST: "Relay Switches:",
  WILL_BE_OFF_AND_CONTACTS: "will be off and contacts :",
  WILL_BE_NOTIFIED: "will be notified.",
  WILL_BE_OFF: "will be off.",
  CONTACTS_LIST: "Contacts:",
  UN_DETECTED: "Un/Detected",
  ALERT_RULES: "Alert Rules",
  NO_PERMISSION: "Sorry, you are not authorized to access this page.",
  PAGE_DOSENT_EXIST: "Sorry, the page you visited does not exist.",
  GENERAL: "General",
  GENERAL_INFO:
    "Shows information about assets that are currently detected within a Zone or on a Vehicle i.e. assets that have been detected entering a Zone or a Vehicle and are still within that Zone or Vehicle.",
  PIE_CHART: "Pie Chart",
  PIE_CHART_INFO:
    "Shows the currently detected assets vs the currently undetected assets. The currently detected assets are further subdivided into assets detected in the Fixed Setup (i.e. Zones) and those detected in the Mobile Setup (i.e. Vehicles).",
  BAR_CHART: "Bar Chart",
  BAR_CHART_INFO:
    "Shows the currently detected asset counts by Zone or by Vehicle. The view can be toggled by selecting the relevant slice on the Pie Chart.",
  CURRENTLY_DETECTED_ASSETS_MAP: "Currently Detected Assets Map",
  CURRENTLY_DETECTED_ASSETS_MAP_INFO:
    "The map shows the geographical distribution of the currently detected assets. For assets detected in a Zone, the location is the fixed geographic location of that zone. For assets detected on a Vehicle, the location is the current location of the Vehicle.",
  ALL_ASSETS_GROUP: "All Asset Groups",
  CURRENTLY_DETECTED_ASSETS: "Currently Detected Assets",
  MAP_DISTRIBUTION_DETECTED:
    "The map shows the distribution of the Last Detected Geographic Location over the last specified number of days. The number of days can be specified by changing the slider.  All Last Detected Geographic Locations are grouped by an area of 0.02km",
  COUNT_OCCURRENCES_HEATMAP:
    "(152.9m x 152.4m) and the count of these occurrences for each area is shown on the heatmap.",
  LAST_DETECTED_DISTR_HEATMAP: "Last Detected Distribution Heatmap",
  MAP_DIS_DETECTED_AND_COUNT_OCCUR_HEATMAP:
    "The map shows the geographical distribution of the assets that were last detected between the supplied range. For assets detected in a Zone, the location is the fixed geographic location of that zone. For assets detected on a Vehicle, the location is the vehicle location as at the last detected timestamp.",
  GO_TO_LAST_DETECTED_REPORT: "Go to Last Detected Report",
  LAST_DETECTED_SINCE: "Last Detected Since",
  LAST_DETECTED: "Last Detected",
  SHOW_LAST_TRIGGERED_ALERT_RULES: "Shows the last triggered Alert Rules",
  LAST_TRIGGERED_ALERTS: "Last Triggered Alerts",
  FIXED_READERS: "Fixed Readers",
  VERSION: "Version",
  PLEASE_INPUT_YOUR_USERNAME: "Please input your Username!",
  PLEASE_INPUT_YOUR_PASSWORD: "Please input your Password!",
  FIRST_DETECTED: "First Detected",
  LAST_DETECTED: "Last Detected",
  FIRST_DETECTED_LOCATION: "First Detected Location",
  LAST_DETECTED_LOCATION: "Last Detected Location",
  ASSET_JOURNEY: "Asset Journey",
  SELECT_AN_ASSET_GRUOP: "Select an Asset Group",
  SELECT_AN_ASSET: "Select an Asset",
  LAST_DETECTED_ZONE: "Last Detected Zone",
  LAST_DETECTED_VEHICLE: "Last Detected Vehicle",
  FIXED_MOBILE: "Fixed/Mobile",
  COUNT: "Count",
  VEHICLE: "Vehicle",
  CURRENTLY_UNDETECTED: "Currently Undetected",
  REFRESH: "Refresh",
  ASSET_NAME: "Asset Name",
  REPORT_START_TIMESTAMP: "Report Start Timestamp",
  REPORT_END_TIMESTAMP: "Report End Timestamp",
  COMMENTS: "Comments",
  SCAN_TIMESTAMP: "Scan Timestamp",
  HANDHELD_SCANNED_ASSETS: "Handheld Scanned Assets",
  SELECT_A_DATE_RANGE: "Select a Date Range",
  SELECT_A_DATE: "Select a Date",
  SELECT_A_PERSON: "Select a person",
  LAST_DETECTED_TIMESTAMP: "Last Detected Timestamp",
  LAST_DETECTED_SITE: "Last Detected Site",
  CHOOSE_EITHER_AN_ASSET_GROUP_OR_AN_ASSET:
    "Choose either an Asset Group or an Asset",
  SHOW_DELETED: "Show Deleted",
  MOBILE_ASSET_MOVEMENT: "Mobile Asset Movement",
  UNDETECTED_ASSETS: "Undetected Assets",
  FIRST_DETECTED_ADDRESS: "First Detected Address",
  LAST_DETECTED_ADDRESS: "Last Detected Address",
  VEHICLE_MOVEMENT: "Vehicle Movement",
  SELECT_A_VEHICLE: "Select a Vehicle",
  ZONE_MOVEMENT: "Zone Movement",
  SELECT_A_SITE: "Select a Site",
  FLEET: "fleet",
  LOGOUT: "Logout",
  DASHBOARD: "Dashboard",
  GATEWATS: "Gateways",
  READERS: "Readers",
  READER: "Reader",
  ANTENNAS: "Antennas",
  ANTENNA: "Antenna",
  ASSET_MOVEMENT: "Asset Movement",
  ALERTRULES: "alertRules",
  ASSETJORNEY: "assetJourney",
  CURRENTLYUNDETECTED: "currentlyUndetected",
  FIXEDASSETMOVEMENT: "fixedAssetMovement",
  HANDHELDSCANNEDASSETS: "handheldScannedAssets",
  LASTDETECTED: "lastDetected",
  MOBILEASSETMOVEMENT: "mobileAssetMovement",
  UNDETECTEDASSETS: "undetectedAssets",
  VEHICLEMOVEMENT: "vehicleMovement",
  ZONEMOVEMENT: "zoneMovement",
  ASSETGROUPS: "assetGroups",
  ADD_ASSET: "Add Asset",
  EXPORT_ASSET_GROUP:
    "Choose an Asset Group to export all Assets in the selected Asset Group.",
  EXPORT_ASSETS: "Export Assets",
  CSV_FILES_ALLOWED_IMPORTED_DOWNLOAD_SIMPLE_FILE:
    "CSV files are allowed to be imported.  Download the sample file and follow the required format. ",
  ADD_ASSET_GROUP: "Add Asset Group",
  ADD_ANNTENA: "Add Antenna",
  ANTENNA_NUMBER: "Antenna Number",
  LAST_ACTIVITY_TIMESTAMP: "Last Activity Timestamp",
  CREATE_A_NEW_ZONE: "Create a new zone",
  ADD_ZONE: "Add Zone",
  CUSTOMFILEDS: "customFields",
  ADD_CUSTOM_FILED: "Add Custom Field",
  CUSTOM_FILED: "Custom Field",
  FIXEDREADERS: "fixedReaders",
  ADD_FIXED_READER: "Add Fixed Reader",
  IPRELAYCARDS: "ipRelayCards",
  MOBILEREADERS: "mobileReaders",
  IP_RELAT_CARD: "IP Relay Card",
  PULSE_IN_MS: "Pulse in ms",
  RELAYSWITCHES: "relaySwitches",
  ADD_RELAY_SWITCH: "Add Relay Switch",
  AUDITLOG: "auditLog",
  SELECT_A_CATEGORY: "Select a Category",
  ADD_CONTACT: "Add Contact",
  USERGROUPS: "userGroups",
  SURE_TO_DELETE: "Sure to delete",
  LAST_THREE_MONTS: "Last 3 Months",
  LAST_SIX_MONTS: "Last 6 Months",
  LAST_YEAR: "Last Year",
  ALL_WEEK: "All Week",
  ALL_MONTS: "All Month",
  ALL_YEAR: "All Year",
  EXPORT: "Export",
  SEARCH: "Search",
  EXPORT_ALL: "Export All",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  months: "months",
  year: "year",
  week: "week",
  ALERTLOG: "alertLog",
  NAME_IS_REQUIRED: "Name is required",
  INPUT_EPC_CODES_ON_MULTIPLE_LINES: "Input EPC Codes on multiple lines",
  CREATE_NEW_ASSET_GROUP: "Create a new Asset Group",
  NOT_UPLOADED_DUE_TO_ALREADY_EXISTING_EPC_CODE:
    " were not uploaded due to already existing EPC code.",
  NOT_UPLOADED_DUE_TO_ALREADY_EXISTING_ASSET_CODE:
    " were not uploaded due to already existing Asset code.",
  SUCCESS_WITH_WARNING: "Success with warning",
  SUCCESS: "Success",
  FAILURE: "Failure",
  DOWNLOAD: "Download",
  UNDETECTED: "UNDETECTED",
  FIXED: "FIXED",
  MOBILE: "MOBILE",
  ERROR: "Error!",
  FIXED_SETUP: "Fixed Setup",
  MOBILE_SETUP: "Mobile Setup",
  SETUP: "Setup",
  REPORTS: "Reports",
  ALERTS: "Alerts",
  USER_MANAGEMENT: "User Management",
  REMEMBER_ME: "Remember me",
  LOG_IN: "Log in",
  FORGOT_PASSWORD: "Forgot password",
  BULK_UPLOAD_ASSETS: "Import Assets",
  RELAY_SWITCH: "Relay Switch",
  PLEASE_SELECT_A_SITE: "Please select a Site",
  OF: "of",
  ITEMS: "items",
  ADD_ALERT_RULE: "Add Alert Rule",
  PSW_VALIDATION:
    "Password should be at least 8 characters long and contain at least 1 uppercase character, 1 lowercase character, 1 number and 1 symbol",
  REQUIRED_FIELD: "Required Field",
  PHONE_NUMBER_ERROR: "Please enter a valid mobile phone number",
  ASSETS_GROUPS: "Assets Groups",
  ASSETS: "Assets",
  GOBACK: "Go back",
  SOMETHING_WENT_WRONG: "Something is wrong...",
  ENABLE: "Enable",
  DISABLE: "Disable",
  ALERT_RULE: "Alert Rule",
  ENTER_NAME: "Enter a name",
  SELECT_ASSET_GROUP_AND_OR_ASSET: "Select either Asset Groups or Assets",
  ASSET_GROUP_OR_ASSET_IS_REQUIRED: "Asset Group or Asset is required",
  SELECT_ASSET_GROUP: "Select Asset Groups",
  SELECT_SITE_AND_OR_ZONE: "Select either Sites or Zones",
  SITE_OR_ZONE_IS_REQUIRED: "Site or Zone is required",
  SELECT_ZONES: "Select Zones",
  SELECT_NOTIFICATION_METHOD: "Select a notification method",
  CLICK_ON_THE_MAP_TO_SET_LOCATION: "Click on the map to set location",
  INACTIVITY:
    "You have been logged out due to inactivity. Please log in again.",
  MY_PROFILE: "My profile",
  PROFILE: "Profile",
  PERSONAL_DETAILS: "Personal Details",
  SECURITY: "Security",
  ALERT_RULE_NOTIFICATION_SUCCESS:
    "Please note that it might take up to 5 minutes for changes to take effect.",
  SPACES_ARE_NOT_ALLOWED: "Spaces are not allowed",
  UNDERSCORES_ARE_NOT_ALLOWED: "Underscores are not allowed",
  VIEW: "View",
  SURE_TO_DELETE_THIS_ENTRY: "Are you sure you want to delete",
  MANAGE: "Manage",
  FIXED_INSTALLATION: "Fixed Installation",
  MOBILE_INSTALLATION: "Mobile Installation",
  SHOW_DETECTED: "Show detected",
  SHOW_UNDETECTED: "Show undetected",
  MOBILE_NUMBER: "Mobile Number",
  ZONE_IS_REQUIRED: "Zone is required",
  SELECT_ZONE: "Select Zones",
  ACTIVATE_ALERT_RULE: "Activate Alert Rule",
  MOBILE_LOWER_CASE: "Mobile",
  KILL_ASSET: "Kill Asset",
  KILL: "Kill",
  SURE_TO_KILL_THIS_ENTRY: "Are you sure you want to kill",
  KILLED_ASSETS: "Killed Assets",
  KILLED_TIME: "Killed Time",
  ASSET_AUDIT_JOURNEY: "Asset Audit Journey",
  CREATED: "created",
  SCANNED: "Scanned",
  DETECTED_BY_ZONE: "detected by Zones",
  KILLED_ASSET: "Killed Asset",
  TIMESTAMP: "timestamp",
  DUPLICATE_ASSET_LOCATION_NAME: "Asset Location name already exists",
  ZONE_ABATTOIR_NOTIFICATION_SUCCESS:
    "Please note that it might take up to 5 minutes for changes to take effect.",
  EVENT: "Event",
  ASSET_LOCATIONS: "Asset Locations",
  ASSET_LOCATION: "Asset Location",
  ADD_ASSET_LOCATION: "Add Asset Location",
  SELECT_LOCATION: "Select a Location",
  CREATE_LOCATION: "Create a new Location",
  KILL_ASSET_SWITCH: "Kill Assets",
  KILL_ASSET_NOTE:
    "When 'Kill Assets' is enabled, assets detected by this zone will not be detected anymore",
  KILL_ASSET_TRUE: "ENABLED",
  KILL_ASSET_FALSE: "DISABLED",
  KILL_ASSET_ZONE_TRUE: "This is an Asset Killing zone",
  KILL_ASSET_ZONE_FALSE: "This is not an Asset Killing zone",
  KILL_ASSET_MODEL_DESCRIPTION:
    "The Kill Asset feature is enabled for this Zone.  Would you like to proceed?",
  KILL_ASSET_MODEL_TITLE: "Asset Killing Zone",
  COLUMNS: "Columns",
  TOOLTIP_SEARCH_NAME: "Search can be done on the Name field",
  TOOLTIP_SEARCH_ALL: "Search can be done on all of the fields",
  TOOLTIP_SEARCH_ANTENNAS:
    "Search can be done on the Reader, Site and Zone fields",
  TOOLTIP_SEARCH_FIXED_READERS:
    "Search can be done on the Name, Gateway, IP Address and Port Number fields",
  TOOLTIP_SEARCH_MOBILE_READERS:
    "Search can be done on the Vehicle Registration Number field",
  TOOLTIP_SEARCH_GATEWAY:
    "Search can be done on the Name and Description fields",
  TOOLTIP_SEARCH_USER:
    "Search can be done on the Name, Surname, User Group and Department fields",
  TOOLTIP_SEARCH_ASSET_GROUP:
    "Search can be done on the Name and Department fields",
  CONTACT_NAME: "Contact Name",
  SMS_CONSUMPTION: "SMS Consumption",
  AUTOMATIC: "AUTOMATIC",
  MANUAL: "MANUAL",
  SETTINGS: "Settings",
  USER_SETTINGS: "User Settings",
  UPLOAD_YOUR_LOGO: "Upload Your Logo",
  UPLOAD: "Upload",
  MANUALY_KILLED: "MANUALLY ",
  NO_DATA: "No Data",
  SELECT_ASSET_GROUP_ASSET: "Select either an Asset Group or Asset",
  SELECT_DATE_RANGE_USER: "Select Date Range and User",
  SELECT_DATE_RANGE_VEHICLE: "Select Date Range and Vehicle",
  SELECT_VEHICLE: "Select a Vahicle",
  SELECT_DATE_RANGE_SITE_ZONE: "Select Date Range, Site and Zone",
  SELECT_SITE_ZONE: "Select a Site and Zone",
  SELECT_SITE: "Select a Site",
  SELECT_ASSET: "Select an Asset",
  SELECT_ASSETGROUP_ASSET: "Select Asset Group and Asset",
  SELECT_DATE_RANGE_ASSETGROUP_ASSET:
    "Select Date Range, Asset Group and Asset",
  SELECT_DATE_RANGE_ACTION_USER: "Select Date Range, Action and User",
  SELECT_CATEGORY_USER: "Select a Category and/or User",
  SELECT_AN_ACTION: "Select an Action",
  LOGO_UPLOADED_SUCC: "Logo uploaded successfully",
  AVATAR: "Avatar",
  CUSTOM_LOGO: "Custom Logo",
  FLOOR_PLAN: "Floor Plans",
  FLOOR_PLAN_SIDE_MENU: "Last Detected Map",
  LAST_DETECTED_2D: "Last Detected 2D",
  SELECT_FLOOR_PLAN: "Select Floor Plan",
  ASSET_GROUP: "Asset Group",
  ASSET_BULK_IN_PROGRESS: "Bulk import of assets is in progress",
  CHOOSE_TO_APPLY_ON: "Choose To Apply On",
  HANDHELD: "Handheld",
  DEFAULT_VALUE: "Default Value",
  SELECT_CUSTOM_FIELD: "Select Custom Field",
  CREATE_NEW_CUSTOM_FIELD: "Create a new Custom Field",
  FORBIDDEN_EMPTY_INPUT: "Please provide valid field input",
};
