import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { enableAlertRule } from "../../../api/redux/action/AlertRuleActions";
import { Dropdown, Menu, Popconfirm, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { ElementIDS } from "../../../components/common/ElementsIDs";
const AlertRuleActionMenu = ({
  selectedRow,
  updateEntityPath,
  handleSingleDelete,
  allowPermission,
  title,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { confirm } = Modal;

  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const handleActivateAlertRule = (selected, active) => {
    if (selected) {
      const formData = {
        active: active,
        userAccountId: selected.alertRuleId,
      };

      dispatch(enableAlertRule(formData, formData.userAccountId));
    }
  };

  const handleMenuClick = (action) => {
    const updatePath = "/" + updateEntityPath;
    switch (action.key) {
      case "edit":
        history.push({
          pathname: updatePath,
          state: { selectedRow },
        });
        break;
      case "view":
        const viewOption = "disabled";
        history.push({
          pathname: updatePath,
          state: { selectedRow, viewOption },
        });
        break;
      case "enable":
        handleActivateAlertRule(selectedRow, true);
        break;
      case "disable":
        handleActivateAlertRule(selectedRow, false);
        break;
      default:
    }
  };

  const active =
    selectedRow && selectedRow.active
      ? allowPermission.deactivate
        ? [
            {
              id: "rfid-actionMenu-disableBtn",
              key: "disable",
              pop: (
                <Popconfirm
                  title="Disabled"
                  placement="left"
                  icon={<CloseOutlined />}
                >
                  <CloseOutlined type="Disable" />{" "}
                  {language?.DISABLE?.value ?? englishLang?.DISABLE?.value}
                </Popconfirm>
              ),
            },
          ].map((item) => (
            <Menu.Item id={item.id} key={item.key}>
              {item.pop}
            </Menu.Item>
          ))
        : null
      : allowPermission.activate
      ? [
          {
            id: "rfid-actionMenu-enableBtn",
            key: "enable",
            pop: (
              <Popconfirm
                title="Enable"
                placement="left"
                icon={<CheckOutlined />}
              >
                <CheckOutlined type="Enable" />
                {language?.ENABLE?.value ?? englishLang?.ENABLE?.value}
              </Popconfirm>
            ),
          },
        ].map((item) => (
          <Menu.Item id={item.id} key={item.key}>
            {item.pop}
          </Menu.Item>
        ))
      : null;

  const viewMenuItem = [
    {
      id: ElementIDS().view,
      key: "view",
      icon: <EyeOutlined style={{ marginRight: "5px" }} />,
      lang: language?.VIEW?.value ?? englishLang?.VIEW?.value,
    },
  ].map((item) => (
    <Menu.Item id={item.id} key={item.key}>
      {item.icon}
      {item.lang}
    </Menu.Item>
  ));

  const editMenuItem = allowPermission.edit
    ? [
        {
          id: ElementIDS().dropDownEdit,
          key: "edit",
          icon: <EditOutlined style={{ marginRight: "5px" }} />,
          lang: language?.EDIT?.value ?? englishLang?.EDIT?.value,
        },
      ].map((item) => (
        <Menu.Item id={item.id} key={item.key}>
          {item.icon}
          {item.lang}
        </Menu.Item>
      ))
    : null;

  function convertStringToCamelCase(title) {
    return title.replace(
      /(?:^\w|[A-Z]|\b\w|\s+)/g,
      function (camelCaseMatch, i) {
        if (+camelCaseMatch === 0) return "";
        return i === 0
          ? camelCaseMatch.toLowerCase()
          : camelCaseMatch.toUpperCase();
      }
    );
  }

  const showDeleteConfirm = () => {
    confirm({
      title:
        language?.DELETE?.value ??
        englishLang?.DELETE?.value + " " + title.slice(0, -1),
      icon: <QuestionCircleOutlined />,
      onOk: () => {
        handleSingleDelete(selectedRow);
      },
      okText: language?.DELETE?.value ?? englishLang?.DELETE?.value,
      okButtonProps: {
        id:
          "rfid-" + convertStringToCamelCase(title) + "-deleteModal-deleteBtn",
      },
      okType: "danger",
      cancelText: language?.CANCEL?.value ?? englishLang?.CANCEL?.value,
      cancelButtonProps: {
        id:
          "rfid-" + convertStringToCamelCase(title) + "-deleteModal-cancelBtn",
      },
      content:
        language?.SURE_TO_DELETE_THIS_ENTRY?.value ??
        englishLang?.SURE_TO_DELETE_THIS_ENTRY?.value +
          " " +
          selectedRow.name +
          "?",
    });
  };

  const deleteMenuItem = allowPermission.delete ? (
    <>
      {[
        {
          id: ElementIDS().dropDownEdit,
          className: "action__menu__deleteBtn",
          placement: "left",
          icon: <DeleteOutlined type="delete" style={{ color: "red" }} />,
          onClick: showDeleteConfirm,
          key: "delete",
          lang: language?.DELETE?.value ?? englishLang?.DELETE?.value,
        },
      ].map((item) => (
        <Menu.Item
          id={item.id}
          className={item.className}
          placement={item.placement}
          icon={item.icon}
          onClick={item.onClick}
          key={item.key}
        >
          {item.lang}
        </Menu.Item>
      ))}
    </>
  ) : null;

  const withSeperator = <Menu.ItemGroup>{active}</Menu.ItemGroup>;

  const actionMenu = (
    <Menu onClick={handleMenuClick}>
      {viewMenuItem}
      {editMenuItem}
      {withSeperator}
      {deleteMenuItem}
    </Menu>
  );

  const actionColumnView = (
    <>
      <Dropdown overlay={actionMenu} trigger={["click"]}>
        <a id={ElementIDS().dropDown} className="ant-dropdown-link" href="#">
          <MoreOutlined style={{ fontSize: "22px" }} />
        </a>
      </Dropdown>
    </>
  );

  return actionColumnView;
};

export default AlertRuleActionMenu;
