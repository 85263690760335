import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "antd";
import moment from "moment-timezone";
import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchFixedReaders,
  deleteFixedReader,
  fetchAllFixedReaders,
} from "../../../api/redux/action/FixedReaderActions";
import { exportRequest } from "../../../api/network/axios/exportRequest";
import { stateColors } from "../../layout/Constants";
import useNotification from "../../../components/common/ResultNotifier";

function getColor(status) {
  return stateColors[status].color;
}

function FixedReaders() {
  const dispatch = useDispatch();
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [searchText, setSearchText] = useState();
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [flattenedData, setFlattenedData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });
  const {
    user,
    fixedReaders,
    allFixedReaders,
    error,
    requestStatus,
    loading,
    userTimeZone,
    language,
    englishLang,
  } = useSelector((store) => ({
    user: store.authReducer.user,
    fixedReaders: store.fixedReaderReducer.fixedReaders,
    allFixedReaders: store.fixedReaderReducer.allFixedReaders,
    error: store.fixedReaderReducer.error,
    requestStatus: store.fixedReaderReducer.requestStatus,
    loading: store.fixedReaderReducer.loading,
    userTimeZone: store.authReducer.userTimeZone,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const columns = [
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
      sorter: true,
      sortField: "name",
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
    },
    {
      title: language?.GATEWAY?.value ?? englishLang?.GATEWAY?.value,
      dataIndex: ["gateway", "name"],
      sorter: true,
      sortField: "gatewayEntity.name",
      sortOrder:
        sortedInfo.columnKey === "gatewayEntity.name" && sortedInfo.order,
    },
    {
      title: language?.IP_ADDRESS?.value ?? englishLang?.IP_ADDRESS?.value,
      dataIndex: "ipAddress",
      sorter: true,
      sortField: "ipAddress",
      sortOrder: sortedInfo.columnKey === "ipAddress" && sortedInfo.order,
    },

    {
      title: language?.PORT_NUMBER?.value ?? englishLang?.PORT_NUMBER?.value,
      dataIndex: "portNumber",
      sorter: true,
      sortField: "port",
      sortOrder: sortedInfo.columnKey === "port" && sortedInfo.order,
    },
    {
      title: language?.STATUS?.value ?? englishLang?.STATUS?.value,
      dataIndex: "status",
      render: (status) => (
        <Tag color={getColor(status)} key={status}>
          {status}
        </Tag>
      ),
      sorter: true,
      sortField: "status",
      sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
    },
    {
      title:
        language?.LAST_CONNECTION_TIMESTAMP?.value ??
        englishLang?.LAST_CONNECTION_TIMESTAMP?.value,
      dataIndex: "lastConnectionTimestamp",
      render: (lastConnectionTimestamp) => (
        <span>
          {lastConnectionTimestamp
            ? moment
                .tz(lastConnectionTimestamp, userTimeZone)
                .format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
      sorter: true,
      sortField: "lastConnectionTimestamp",
      sortOrder:
        sortedInfo.columnKey === "lastConnectionTimestamp" && sortedInfo.order,
    },
  ];

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const handleSingleDelete = (selected) => {
    dispatch(deleteFixedReader(selected.assetGroupId));
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    sortField,
    sortOrder,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "reader",
    dataSource: fixedReaders,
    updateEntityPath: "update-fixedReader",
    customActionView: "",
    searchText,
    loading: loading,
    handleSingleDelete,
    setSortedInfo,
  });

  useEffect(() => {
    dispatch(
      fetchFixedReaders({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-readers";
    let options = {
      url: "/api/web/readers/export?setup=fixed",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        searchText: searchText,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  useEffect(() => {
    if (fixedReaders && Object.keys(fixedReaders).length !== 0) {
      let flatData = Object.values(fixedReaders.content);

      flatData.map((data) => {
        data.lastConnectionTimestampFormatted = data.lastConnectionTimestamp
          ? moment
              .tz(data.lastConnectionTimestamp, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedData(Object.values(flatData));
    }
  }, [fixedReaders]);

  useEffect(() => {
    if (allFixedReaders && Object.keys(allFixedReaders).length !== 0) {
      let flatData = Object.values(allFixedReaders.content);

      flatData.map((data) => {
        data.lastConnectionTimestampFormatted = data.lastConnectionTimestamp
          ? moment
              .tz(data.lastConnectionTimestamp, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedAllData(Object.values(flatData));
    }
  }, [allFixedReaders]);

  const headers = [
    { label: language?.NAME?.value ?? englishLang?.NAME?.value, key: "name" },
    {
      label: language?.GATEWAY?.value ?? englishLang?.GATEWAY?.value,
      key: "gateway.name",
    },
    {
      label: language?.IP_ADDRESS?.value ?? englishLang?.IP_ADDRESS?.value,
      key: "ipAddress",
    },
    {
      label: language?.PORT_NUMBER?.value ?? englishLang?.PORT_NUMBER?.value,
      key: "portNumber",
    },
    {
      label: language?.STATUS?.value ?? englishLang?.STATUS?.value,
      key: "status",
    },
    {
      label:
        language?.LAST_CONNECTION_TIMESTAMP?.value ??
        englishLang?.LAST_CONNECTION_TIMESTAMP?.value,
      key: "lastConnectionTimestampFormatted",
    },
  ];

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          hasSelected={hasSelected}
          exportTableTitle={
            language?.FIXEDREADERS?.value ?? englishLang?.FIXEDREADERS?.value
          }
          addNewText={
            language?.ADD_FIXED_READER?.value ??
            englishLang?.ADD_FIXED_READER?.value
          }
          title={
            language?.FIXED_READER?.value ?? englishLang?.FIXED_READER?.value
          }
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllButtonClick={handleExportAllButtonClick}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportButton={true}
          exportAllButton={true}
          handleSearch={setSearchText}
          tooltipText={
            language?.TOOLTIP_SEARCH_FIXED_READERS?.value ??
            englishLang?.TOOLTIP_SEARCH_FIXED_READERS?.value
          }
        />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default FixedReaders;
