import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  InputNumber,
  Select,
  Tooltip,
  Row,
  Col,
  Button,
  Modal,
  Spin,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "../../layout/Style.css";
import { getModalSizeByWindowSize } from "../../layout/Constants";
import AddZoneForm from "../Zones/AddZoneForm";
import { fetchFixedReaders } from "../../../api/redux/action/FixedReaderActions";
import { fetchZones, saveZone } from "../../../api/redux/action/ZoneActions";

import { ElementIDS } from "../../../components/common/ElementsIDs";

const { Option } = Select;

const AddAntennaForm = (props) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState({ reader: false, zone: false });
  const [zoneForm] = Form.useForm();
  const [newZoneData, setNewZoneData] = useState(undefined);
  const [modalSize, setModalSize] = useState(getModalSizeByWindowSize());
  const [sortedFixedReaders, setSortedFixedReaders] = useState([]);
  const [sortedZones, setSortedZones] = useState([]);
  const [zoneMapReset, setZoneMapReset] = useState(false);

  const { fixedReaders, zones, language, englishLang } = useSelector(
    (store) => ({
      fixedReaders: store.fixedReaderReducer.fixedReaders,
      zones: store.zoneReducer.zones,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
    })
  );

  useEffect(() => {
    dispatch(fetchFixedReaders());
  }, []);

  useEffect(() => {
    dispatch(fetchZones());
  }, [newZoneData]);

  useEffect(() => {
    if (zones && zones.content && newZoneData && newZoneData.name) {
      zones.content.map((item) => {
        if (item.name == newZoneData.name) {
          props.form.setFieldsValue({
            ...(newZoneData && {
              zone: item.zoneId,
            }),
          });
        }
      });
    }
  }, [zones]);

  useEffect(() => {
    if (fixedReaders && fixedReaders.content) {
      let sorted = fixedReaders.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedFixedReaders(sorted);
    }

    if (zones && zones.content) {
      let sorted = zones.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedZones(sorted);
    }
  }, [fixedReaders, zones]);

  const handleAddNewZone = () => {
    showModal(false, true);
    setZoneMapReset(true);
  };

  const handleZoneFormSave = (values) => {
    const formData = {
      name: zoneForm.getFieldValue().name,
      siteId: zoneForm.getFieldValue().site,
      location: zoneForm.getFieldValue().location,
    };
    if (Object.keys(formData).length > 0) {
      dispatch(saveZone(formData))
        .then(() => {
          setNewZoneData(values);
          formData = {
            name: zoneForm.resetFields().name,
            siteId: zoneForm.resetFields().site,
            location: zoneForm.resetFields().location,
          };
        })
        .catch((err) => console.log(err));
    }
    setVisible(false);
  };

  const showModal = (reader, zone) => {
    setVisible({ reader: reader, zone: zone });
  };

  const handleModalOk = (e) => {
    zoneForm.submit();
    setZoneMapReset(false);
  };

  const handleModalCancel = (e) => {
    setVisible(false);
    setZoneMapReset(false);
  };

  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="antenna-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        id={ElementIDS().antennaNum}
        label={
          language?.ANNTENA_NUMBER?.value ?? englishLang?.ANNTENA_NUMBER?.value
        }
        name="antennaNumber"
        rules={requiredFieldRule}
      >
        <InputNumber
          min={1}
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <Form.Item label={language?.READER?.value ?? englishLang?.READER?.value}>
        <Row gutter={3}>
          <Col flex={9}>
            <Form.Item name="reader" rules={requiredFieldRule} noStyle>
              <Select
                onChange={(e) => {
                  props.setDirty();
                }}
                allowClear
                style={{ width: "100%" }}
                placeholder={
                  language?.SELECT_A_READER?.value ??
                  englishLang?.SELECT_A_READER?.value
                }
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                id={ElementIDS().reader}
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              >
                {sortedFixedReaders
                  ? sortedFixedReaders.map((item) => (
                      <Option
                        id={ElementIDS().readerOption}
                        key={item.readerId}
                        value={item.readerId}
                      >
                        {item.name}
                      </Option>
                    ))
                  : undefined}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col flex={1}>
                  <Tooltip title="Create a new reader">
                    <Button
                      icon={<PlusOutlined />}
                      ghost
                      type="primary"
                      onClick={handleAddNewReader}
                      style={{ width: '100%' }}
                    />
                  </Tooltip>
                </Col> */}
        </Row>
      </Form.Item>

      <Form.Item label={language?.ZONE?.value ?? englishLang?.ZONE?.value}>
        <Row gutter={3}>
          <Col flex={9} span={22}>
            <Form.Item name="zone" rules={requiredFieldRule} noStyle>
              <Select
                onChange={(e) => {
                  props.setDirty();
                }}
                id={ElementIDS().zone}
                allowClear
                style={{ width: "100%" }}
                placeholder={
                  language?.SELECT_A_ZONE?.value ??
                  englishLang?.SELECT_A_ZONE?.value
                }
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              >
                {sortedZones
                  ? sortedZones.map((item) => (
                      <Option
                        id={ElementIDS().zoneOption}
                        key={item.zoneId}
                        value={item.zoneId}
                      >
                        {item.name}
                      </Option>
                    ))
                  : undefined}
              </Select>
            </Form.Item>
          </Col>
          <Col flex={1} span={2}>
            <Tooltip
              title={
                language?.CREATE_A_NEW_ZONE?.value ??
                englishLang?.CREATE_A_NEW_ZONE?.value
              }
            >
              <Button
                icon={<PlusOutlined />}
                id={ElementIDS().addBtn}
                ghost
                type="primary"
                onClick={handleAddNewZone}
                style={{ width: "100%" }}
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              />
            </Tooltip>
          </Col>
        </Row>
      </Form.Item>

      <Modal
        title={language?.ADD_ZONE?.value ?? englishLang?.ADD_ZONE?.value}
        visible={visible.zone}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={modalSize}
      >
        <Row justify="center">
          <Col span={20}>
            <AddZoneForm
              setDirty={props.setDirty}
              handleSave={handleZoneFormSave}
              form={zoneForm}
              zoneMapReset={zoneMapReset}
            />
          </Col>
        </Row>
      </Modal>

      {props.footer}
    </Form>
  );
};

export default AddAntennaForm;
