import * as type from "../types";
import {
  languageSaveChangerService,
  fetchTranslationsService,
} from "../../network/service/UserService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";

export const languageSaveChanger = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.LANG_SAVE_REQUEST,
      });
      const response = await dispatch(languageSaveChangerService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.LANG_SAVE_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.LANG_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatch({
        type: type.LANG_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const fetchTranslations = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.LANGUAGES_FETCH_REQUEST,
      });
      const response = await dispatch(fetchTranslationsService());
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.LANGUAGES_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.LANGUAGES_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatch({
        type: type.LANGUAGES_FETCH_FAILURE,
      });
    }
  };
};

export const languageChanger = (data, params) => {
  return (dispatch) => {
    switch (params) {
      case "translation_en":
        return dispatch({
          type: type.LANG_CHANGE_SUCCESS,
          selectedLang: params,
          payload: data,
        });
      case "translation_it":
        return dispatch({
          type: type.LANG_CHANGE_SUCCESS,
          selectedLang: params,
          payload: data,
        });
      case "translation_rs":
        return dispatch({
          type: type.LANG_CHANGE_SUCCESS,
          selectedLang: params,
          payload: data,
        });
      case "translation_fr":
        return dispatch({
          type: type.LANG_CHANGE_SUCCESS,
          selectedLang: params,
          payload: data,
        });
      default:
        return dispatch({
          type: type.LANG_CHANGE_FAILURE,
        });
    }
  };
};
