import * as type from "../types";
import { RequestState } from "./RequestState";
import { enLang } from "../../../locale/locale-en";

const INITIAL_STATE = {
  accToken: null,
  user: JSON.parse(localStorage.getItem("userAPP")),
  userTimeZone: JSON.parse(localStorage.getItem("userTimeZone"))
    ? JSON.parse(localStorage.getItem("userTimeZone"))
    : "UTC",
  error: null,
  loading: false,
  requestStatus: RequestState.EMPTY,
  isUnauth: true,
  fleetSession: null,
  tenants: JSON.parse(localStorage.getItem("userAPP"))?.tenantConfigurations,
  selectedTenant: JSON.parse(localStorage.getItem("selectedTenant")) ?? null,
  // subscriptions: ["ASSET_LOCATIONS_SUBSCRIPTION", "KILLING_ZONES_SUBSCRIPTION"],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.AUTH_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        requestStatus: RequestState.PENDING,
      };
    case type.AUTH_SUCCESS:
      localStorage.setItem("userAPP", JSON.stringify(action.payload));

      return {
        ...state,
        user: action.payload,
        error: null,
        loading: false,
        requestStatus: RequestState.SUCCESS,
        isUnauth: false,
        tenants: action.payload.tenantConfigurations,
      };
    case type.LOGOUT_JWT:
      localStorage.clear();
      location.reload();
      return {
        ...state,
        accToken: null,
        user: null,
        userTimeZone: "UTC",
        error: null,
        loading: false,
        requestStatus: RequestState.SUCCESS,
        isUnauth: true,
        fleetSession: null,
        tenants: null,
        selectedTenant: null,
      };
    case type.SELECTED_TENANT:
      localStorage.setItem("selectedTenant", JSON.stringify(action.payload));
      localStorage.setItem(
        "userTimeZone",
        JSON.stringify(
          action.payload.timeZone ? action.payload.timeZone : "UTC"
        )
      );

      return {
        ...state,
        selectedTenant: action.payload,
        userTimeZone: action.payload.timeZone,
      };
    case type.GET_JWT_SUCCESS:
      localStorage.setItem("accToken", action.payload);
      return {
        ...state,
        accToken: action.payload,
        error: null,
        loading: false,
        requestStatus: RequestState.SUCCESS,
        isUnauth: true,
      };
    case type.GET_JWT_REFRESH_SUCCESS:
      localStorage.setItem("refreshToken", action.payload);
      return {
        ...state,
        refreshToken: action.payload,
        error: null,
        loading: false,
        requestStatus: RequestState.SUCCESS,
        isUnauth: true,
      };
    case type.AUTH_FAILURE:
      return {
        ...state,
        user: null,
        userTimeZone: "UTC",
        error: action.payload,
        loading: false,
        requestStatus: RequestState.FAILURE,
      };
    case type.LOGOUT_REQUEST:
      return {
        ...state,
        requestStatus: RequestState.PENDING,
      };
    case type.LOGOUT_SUCCESS:
      localStorage.removeItem("userAPP");
      localStorage.removeItem("accToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userTimeZone");
      localStorage.removeItem("preferredLogo");
      return {
        ...state,
        user: null,
        userTimeZone: "UTC",
        isUnauth: true,
        requestStatus: RequestState.SUCCESS,
      };
    case type.LOGOUT_FAILURE:
      return {
        ...state,
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
    case type.AUTH_LOGOUT:
      return {
        ...state,
        requestStatus: RequestState.FAILURE,
      };
    case type.UNAUTHORIZED:
      return {
        ...state,
        isUnauth: true,
        user: null,
        userTimeZone: "UTC",
        loading: false,
        error: enLang.INACTIVITY,
        requestStatus: RequestState.FAILURE,
      };
    case type.FLEET_SESSION_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case type.FLEET_SESSION_SUCCESS:
      return {
        ...state,
        fleetSession: action.payload,
        error: null,
        loading: false,
        requestStatus: RequestState.SUCCESS,
      };
    case type.FLEET_SESSION_FAILURE:
      return {
        ...state,
        alertRule: action.payload,
        error: null,
        requestStatus: RequestState.SUCCESS,
      };
    case type.CLEAN_REQUEST_STATUS:
      return {
        ...state,
        fleetSession: null,
        error: action.payload,
        loading: false,
        requestStatus: RequestState.FAILURE,
      };
    case type.LOGGGED_IN_USER_SAVE_SUCCESS:
      localStorage.setItem("userAPP", JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
        error: null,
        loading: false,
        isUnauth: false,
      };
    case type.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        requestStatus: RequestState.PENDING,
      };
    case type.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        requestStatus: RequestState.SUCCESS,
      };
    case type.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        requestStatus: RequestState.FAILURE,
      };
    case type.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        requestStatus: RequestState.PENDING,
      };
    case type.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        error: {},
        loading: false,
        requestStatus: RequestState.SUCCESS,
      };
    case type.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        requestStatus: RequestState.FAILURE,
      };
    default:
      return state;
  }
};

export default reducer;
