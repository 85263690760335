import React from "react";
import { Avatar } from "antd";

function getColor(username) {
  const colors = [
    "#ffa38a",
    "#a9a7e0",
    "#D686D4",
    "#96CE56",
    "#4A90E2",
    "#62b3d0",
    "#ef7676",
    "#8764c6",
  ];

  const firstChar = username.charCodeAt(0);
  const secondChar = username.charCodeAt(1);
  const thirdChar = username.charCodeAt(2);

  // can be used different colors for different users
  return colors[(firstChar + secondChar + thirdChar) % 7];
}

export const getUsernameAvatar = (username, surname, size = "large") => {
  let nickName = username.charAt(0) + surname.charAt(0);

  return (
    <div id="rfid-nav-avatar">
      <Avatar
        style={{
          backgroundColor: getColor(username),
          verticalAlign: "middle",
        }}
        size={size}
      >
        {nickName ? nickName.toUpperCase() : ""}
      </Avatar>
    </div>
  );
};
