import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Col, Divider, Space, Row, Button } from "antd";
import { RedoOutlined } from "@ant-design/icons";

import useDataTable from "../../../components/common/DataTable";
import {
  fetchReport,
  resetReport,
} from "../../../api/redux/action/ReportActions";
import useNotification from "../../../components/common/ResultNotifier";
import Header from "../../../components/common/ReportHeader";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import { exportRequest } from "../../../api/network/axios/exportRequest";
function CurrentlyUndetected() {
  const dispatch = useDispatch();

  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [refresh, setRefresh] = useState(false);

  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  const [dataTable, setDataTable] = useState();

  const {
    data,
    allData,
    error,
    requestStatus,
    loading,
    language,
    englishLang,
  } = useSelector((store) => ({
    data: store.reportReducer.data,
    allData: store.reportReducer.allData,
    error: store.reportReducer.error,
    requestStatus: store.reportReducer.requestStatus,
    loading: store.reportReducer.loading,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const columns = [
    {
      key: "zoneName",
      sorter: true,
      sortField: "zoneName",
      sortOrder: sortedInfo.columnKey === "zoneName" && sortedInfo.order,
      title:
        language?.LAST_DETECTED_ZONE?.value ??
        englishLang?.LAST_DETECTED_ZONE?.value,
      dataIndex: ["zoneName"],
      render: (text, record) =>
        text ? (
          <span>
            {text} <i> ({record.siteName})</i>
          </span>
        ) : (
          ""
        ),
    },
    {
      title:
        language?.LAST_DETECTED_VEHICLE?.value ??
        englishLang?.LAST_DETECTED_VEHICLE?.value,
      dataIndex: ["vehicleRegNumber"],
    },
    {
      title: language?.FIXED_MOBILE?.value ?? englishLang?.FIXED_MOBILE?.value,
      dataIndex: ["zoneName"],
      render: (text, record) =>
        record.zoneName ? <Tag>FIXED</Tag> : <Tag>MOBILE</Tag>,
    },
    {
      key: "assetGroupName", //ne radi
      sorter: true,
      sortField: "assetGroupName",
      sortOrder: sortedInfo.columnKey === "assetGroupName" && sortedInfo.order,
      title: language?.ASSET_GROUP?.value ?? englishLang?.ASSET_GROUP?.value,
      dataIndex: ["assetGroupName"],
    },
    {
      key: "count", //ne radi
      sorter: true,
      sortField: "count",
      sortOrder: sortedInfo.columnKey === "count" && sortedInfo.order,
      title: language?.COUNT?.value ?? englishLang?.COUNT?.value,
      dataIndex: ["count"],
    },
  ];

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    dataSource: data,
    updateEntityPath: "",
    filter,
    loading: loading,
    missingFieldsText: language?.NO_DATA?.value ?? englishLang?.NO_DATA?.value,
    setSortedInfo: setSortedInfo,
  });

  const ascDesc = () => {
    if (sortedInfo.order === "ascend") {
      return "ASC";
    } else {
      return "DESC";
    }
  };

  useEffect(() => {
    dispatch(resetReport());
  }, []);

  useEffect(() => {
    let endpoint = sortedInfo.columnKey
      ? `currently-undetected-assets?sort=${sortedInfo.columnKey},${ascDesc()}`
      : "currently-undetected-assets";

    dispatch(
      fetchReport({
        page: searchFormHelperHandler(),
        size: pageSize,
        endpoint: endpoint,
      })
    );
  }, [currentPage, filter, isLoading, refresh, pageSize, sortedInfo]);

  useEffect(() => {
    setDataTable(DataTable);
  }, [loading, language]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-currently-undetected-assets";
    let options = {
      url: "/api/web/reports/currently-undetected-assets/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  const handleRefreshClick = () => {
    setRefresh(!refresh);
  };

  let flattenedData = [];

  if (Object.keys(data).length !== 0) {
    let flattenedData;
    function flatDataHandler() {
      if (data.content === undefined) {
        return (flattenedData = Object.values(data));
      } else {
        return (flattenedData = Object.values(data.content));
      }
    }
    flatDataHandler();
  }

  let flattenedAllData = [];

  if (Object.keys(allData).length !== 0) {
    flattenedAllData = Object.values(allData.content);
  }

  const headers = [
    {
      label: language?.SITE?.value ?? englishLang?.SITE?.value,
      key: "siteName",
    },
    {
      label: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      key: "zoneName",
    },
    {
      label: language?.VEHICLE?.value ?? englishLang?.VEHICLE?.value,
      key: "vehicleRegNumber",
    },
    {
      label: language?.ASSET_GROUP?.value ?? englishLang?.ASSET_GROUP?.value,
      key: "assetGroupName",
    },
    {
      label: language?.COUNT?.value ?? englishLang?.COUNT?.value,
      key: "count",
    },
  ];

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          hasSelected={hasSelected}
          exportTableTitle={
            language?.CURRENTLYUNDETECTED?.value ??
            englishLang?.CURRENTLYUNDETECTED?.value
          }
          addNewText=""
          title={
            language?.CURRENTLY_UNDETECTED?.value ??
            englishLang?.CURRENTLY_UNDETECTED?.value
          }
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          loading={loading}
          exportAllLoading={exportAllLoading}
          exportAllButtonClick={handleExportAllButtonClick}
        />
        <Row>
          <Col>
            <Space>
              <Button
                id={ElementIDS().refresh}
                type="primary"
                onClick={() => handleRefreshClick()}
              >
                {" "}
                <RedoOutlined />
                {language?.REFRESH?.value ?? englishLang?.REFRESH?.value}
              </Button>
            </Space>
          </Col>
        </Row>
        <Divider />

        {dataTable}
        <ResultNotifier />
      </div>
    </>
  );
}

export default CurrentlyUndetected;
