import * as type from '../types';
import { RequestState } from './RequestState';

const INITIAL_STATE = {
  mobileReaders: {},
  allMobileReaders:{},
  mobileReader: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  exportAllLoading:false

};

const reducer= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.MOBILEREADERS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        mobileReaders: {},
        loading: true
      };
    case type.MOBILEREADERS_FETCH_SUCCESS:
      return {
        ...state,
        mobileReaders: action.payload,
        loading: false
      };
    case type.MOBILEREADERS_FETCH_FAILURE:
      return {
        ...state,
        mobileReaders: {},
        loading: false
      };
      case type.ALL_MOBILEREADERS_FETCH_REQUEST:
        return {
          ...state,
          error: {},
          allMobileReaders: {},
          exportAllLoading: true
        };
      case type.ALL_MOBILEREADERS_FETCH_SUCCESS:
        return {
          ...state,
          allMobileReaders: action.payload,
          exportAllLoading: false
        };
      case type.ALL_MOBILEREADERS_FETCH_FAILURE:
        return {
          ...state,
          allMobileReaders: {},
          exportAllLoading: false
        };
      case type.MOBILEREADER_SAVE_REQUEST:
        return {
          ...state,
          error: {},
          mobileReader: {},
          requestStatus: RequestState.PENDING,
        };
    case type.MOBILEREADER_SAVE_SUCCESS:
      return {
        ...state,
        mobileReader: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.MOBILEREADER_SAVE_FAILURE:
      return {
        ...state,
        mobileReader: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
      case type.MOBILEREADER_DELETE_REQUEST:
          return {
            ...state,
            requestStatus: RequestState.PENDING,
          };
        case type.MOBILEREADER_DELETE_SUCCESS:
          return {
            ...state,
            mobileReader: action.payload,
            error: {},
            requestStatus: RequestState.SUCCESS,
          };
        case type.MOBILEREADER_DELETE_FAILURE:
          return {
            ...state,
            mobileReader: {},
            error: action.payload,
            requestStatus: RequestState.FAILURE,
          };
          case type.CLEAN_REQUEST_STATUS:
            return {
              ...state,
              requestStatus: RequestState.EMPTY
            };
    default:
      return state;
  }
};


export default reducer;