import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { Skeleton, Empty, Result } from "antd";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import { useHistory } from "react-router-dom";

import { logout } from "../../../../api/redux/action/AuthActions";
import { ASSET_DETECTION_TYPE_COUNTS } from "../../../../api/graphQL/queries";

const CustomPieChart = ({
  selectedAssetGroup,
  handleSetupSelect,
  selectedPieChart,
}) => {
  const history = useHistory();
  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const { loading, error, data, startPolling, stopPolling } = useQuery(
    ASSET_DETECTION_TYPE_COUNTS,
    {
      variables: {
        assetGroupIds: selectedAssetGroup ? parseInt(selectedAssetGroup) : [],
      },
      pollInterval: 60000,
      fetchPolicy: "network-only",
    }
  );

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  //Adding ToolTip's over pieChart
  function CustomTooltip({ active, payload, label }) {
    if (active && payload && payload.length) {
      return (
        <div
          style={{ color: "#fff" }}
        >{`${payload[0].name} : ${payload[0].value}`}</div>
      );
    }

    return null;
  }

  //Rendering Custom Label for PieChart
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {selectedPieChart === true
          ? `${data.assetDetectionTypeCounts[index]?.count}`
          : `${newFixAndMobileArray[index]?.count}`}
      </text>
    );
  };

  if (loading) return <Skeleton active />;

  if (error) {
    return (
      <Result
        status="warning"
        title={
          language?.ERROR_FETCHING_DATA?.value ??
          englishLang?.ERROR_FETCHING_DATA?.value
        }
      />
    );
  }

  //Combine arrays
  const newFixAndMobileArray = [];
  const handleRenderPieChartData = () => {
    if (selectedPieChart === false) {
      data?.assetDetectionTypeCounts?.map((item) => {
        if (item.detectionType === "FIXED" || item.detectionType === "MOBILE") {
          return newFixAndMobileArray.push({
            count: item.count,
            detectionType: item.detectionType,
          });
        }
      });
    }
  };

  //Basic Switch state
  const handlePieSelect = (event) => {
    handleSetupSelect(event);
  };

  // Rendering only data inside of AssetDetectionType with undetected assets
  const CheckForData = () => {
    if (data.assetDetectionTypeCounts.length > 0) {
      return (
        <>
          <PieChart width={400} height={250}>
            <Pie
              data={data.assetDetectionTypeCounts}
              cx="50%"
              cy="50%"
              labelLine={false}
              onClick={handlePieSelect}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="count"
              nameKey="detectionType"
            >
              {data.assetDetectionTypeCounts?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              marginRight={50}
              align="right"
              verticalAlign="middle"
              iconType="square"
              iconSize={6}
              layout="vertical"
            />
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </>
      );
    } else {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
  };

  //Rendering Fixed and Mobile Assets
  const checkForDefiendData = () => {
    if (newFixAndMobileArray.length > 0) {
      return (
        <>
          <PieChart width={400} height={250}>
            <Pie
              data={newFixAndMobileArray}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              onClick={handlePieSelect}
              outerRadius={80}
              fill="#8884d8"
              dataKey="count"
              nameKey="detectionType"
            >
              {newFixAndMobileArray?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              marginRight={50}
              align="right"
              verticalAlign="middle"
              iconType="square"
              iconSize={6}
              layout="vertical"
            />
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </>
      );
    } else {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
  };

  function ChoosePieChartHelper() {
    if (selectedPieChart === true) {
      return CheckForData();
    } else {
      handleRenderPieChartData();
      return checkForDefiendData();
    }
  }

  return <>{ChoosePieChartHelper()}</>;
};

export default CustomPieChart;
