import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";

import { saveFixedReader } from "../../../api/redux/action/FixedReaderActions";
import useNotification from "../../../components/common/ResultNotifier";
import AddFixedReaderForm from "./AddFixedReaderForm";
import history from "../../../router/history";

const AddFixedReader = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { error, requestStatus, language, englishLang } = useSelector(
    (store) => ({
      error: store.fixedReaderReducer.error,
      requestStatus: store.fixedReaderReducer.requestStatus,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
    })
  );
  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;

  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      form.setFieldsValue({
        readerId: props.location.state.selectedRow.readerId,
        name: props.location.state.selectedRow.name,
        gateway: props.location.state.selectedRow.gateway,
        ipAddress: props.location.state.selectedRow.ipAddress,
        portNumber: props.location.state.selectedRow.portNumber,
      });
    }
  }, []);

  const handleSave = (values) => {
    const formData = {
      name: form.getFieldValue().name,
      gateway: {
        gatewayId: form.getFieldValue().gateway,
      },
      ipAddress: form.getFieldValue().ipAddress,
      portNumber: form.getFieldValue().portNumber,
    };

    dispatch(saveFixedReader(formData, form.getFieldValue().readerId));
  };

  const handleCancel = () => {
    history.goBack();
  };

  const footer = (
    <>
      <Divider />

      <Row justify="center">
        <Space>
          <Button type="dashed" htmlType="button" onClick={handleCancel}>
            {language?.CANCEL?.value ?? englishLang?.CANCEL?.value}
          </Button>
          <Button type="primary" onClick={() => form.submit()}>
            {language?.SAVE?.value ?? englishLang?.SAVE?.value}
          </Button>
        </Space>
      </Row>
    </>
  );

  return (
    <Card
      title={language?.FIXED_READER?.value ?? englishLang?.FIXED_READER?.value}
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddFixedReaderForm
            handleSave={handleSave}
            form={form}
            footer={footer}
            language={language}
          />
        </Col>
      </Row>
      <ResultNotifier />
    </Card>
  );
};

export default AddFixedReader;
