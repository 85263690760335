export const requiredFieldRule = [
  {
    required: true,
    message: "Required Field",
    validator: (_, value) => {
      if (value) {
        if (value.toString().trim() === "") {
          return Promise.reject("Required Field");
        } else {
          return Promise.resolve();
        }
      }
      return Promise.reject("Required Field");
    },
  },
];

export const validateInput = (value) => {
  return value
    .trim()
    .replace(/^\s+|\s+$/g, "")
    .replace(/\s+/g, " ");
};
