import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../../components/common/ReportHeader";
import useNotification from "../../../components/common/ResultNotifier";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import {
  fetchReport,
  resetReport,
} from "../../../api/redux/action/ReportActions";
import {
  RedoOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  UserOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import moment from "moment-timezone";

import {
  Col,
  Divider,
  Space,
  Row,
  Select,
  Button,
  Timeline,
  Empty,
  Spin,
} from "antd";
import "./style.css";
import { fetchAllWithKilledAssets } from "../../../api/redux/action/AssetActions";
import { exportRequest } from "../../../api/network/axios/exportRequest";

const AssetAuditJourney = () => {
  const dispatch = useDispatch();
  const [selectedAssetAuditJourney, setSelectedAssetAuditJourney] =
    useState("");
  const [fetchedData, setFetchData] = useState([]);
  const [sortedAuditAssetsJourney, setSortedAuditAssetsJourney] = useState([]);
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState({
    assetAuditIds: null,
  });

  const [missingFieldsText, setMissingFieldsText] = useState();

  const {
    requestStatus,
    error,
    userTimeZone,
    data,
    allData,
    allWithKilledAssets,
    language,
    loading,
    englishLang,
  } = useSelector((store) => ({
    language: store.langReducer.language,
    allWithKilledAssets: store.assetReducer.allWithKilledAssets,
    requestStatus: store.reportReducer.requestStatus,
    error: store.reportReducer.error,
    userTimeZone: store.authReducer.userTimeZone,
    data: store.reportReducer.data,
    allData: store.reportReducer.allData,
    loading: store.reportReducer.loading,
    englishLang: store.langReducer.englishLang,
  }));

  useEffect(() => {
    dispatch(resetReport());
    dispatch(fetchAllWithKilledAssets());
  }, []);

  useEffect(() => {
    let formatedData = Array.from(allWithKilledAssets);

    if (formatedData && formatedData) {
      let sorted = formatedData.sort((a, b) =>
        a.code.localeCompare(b.code, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedAuditAssetsJourney(sorted);
    }
  }, [allWithKilledAssets]);

  useEffect(() => {
    let formatedData = Array.from(data);
    setFetchData(formatedData);
  }, [data]);

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const checkNoMissingParams = () => {
    if (!filter.assetAuditIds) {
      setMissingFieldsText(
        language?.SELECT_ASSET?.value ?? englishLang?.SELECT_ASSET?.value
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    // dispatch(resetReport());
    if (checkNoMissingParams()) {
      setMissingFieldsText();
      dispatch(
        fetchReport({
          assetId: filter.assetAuditIds ? filter.assetAuditIds : undefined,
          endpoint: "asset-audit-journey",
        })
      );
    }
  }, [isLoading, refresh, filter]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-asset-audit-journey";
    let options = {
      url: "/api/web/reports/asset-audit-journey/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        assetId: filter.assetAuditIds ? filter.assetAuditIds : undefined,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  const handleAssetAuditChange = (value) => {
    setSelectedAssetAuditJourney(value);
    setFilter({
      ...filter,
      assetAuditIds: value,
    });
  };

  const handleRefreshClick = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (allData && Object.keys(allData).length !== 0) {
      let flatData = Object.values(allData);

      flatData.map((item) => {
        item.timestampFormatted = item.timestamp
          ? moment
              .tz(item.timestamp, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedAllData(Object.values(flatData));
    }
  }, [allData]);
  const headers = [
    {
      label: language?.EVENT?.value ?? englishLang?.EVENT?.value,
      key: "eventType",
    },
    {
      label: language?.TIMESTAMP?.value ?? englishLang?.TIMESTAMP?.value,
      key: "timestampFormatted",
    },
    {
      label: language?.USER?.value ?? englishLang?.USER?.value,
      key: "username",
    },
    {
      label:
        language?.ASSET_LOCATION?.value ?? englishLang?.ASSET_LOCATION?.value,
      key: "assetLocation.name",
    },
    {
      label: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      key: "zone.name",
    },
  ];

  let iconsSize = { fontSize: "20px" };

  return (
    <>
      <div className="globa_page_div">
        <Header
          addNewPath=""
          addNewText=""
          title={
            language?.ASSET_AUDIT_JOURNEY?.value ??
            englishLang?.ASSET_AUDIT_JOURNEY?.value
          }
          exportTableTitle={
            language?.ASSET_AUDIT_JOURNEY?.value ??
            englishLang?.ASSET_AUDIT_JOURNEY?.value
          }
          exportAllLoading={exportAllLoading}
          csvHeaders={headers}
          csvAllData={flattenedAllData}
          exportAllButtonClick={handleExportAllButtonClick}
        />
        <Row>
          <Col>
            <Space>
              <Select
                className="select_asset"
                placeholder={
                  language?.SELECT_AN_ASSET?.value ??
                  englishLang?.SELECT_AN_ASSET?.value
                }
                optionFilterProp="children"
                showSearch
                onChange={handleAssetAuditChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={selectedAssetAuditJourney || undefined}
              >
                {sortedAuditAssetsJourney
                  ? sortedAuditAssetsJourney.map((item) => (
                      <Select.Option key={item.assetId} value={item.assetId}>
                        {item.name}
                      </Select.Option>
                    ))
                  : undefined}
              </Select>
              <Button
                id={ElementIDS().refresh}
                type="primary"
                onClick={() => handleRefreshClick()}
              >
                <RedoOutlined />
                {language?.REFRESH?.value ?? englishLang?.REFRESH?.value}
              </Button>
            </Space>
          </Col>
        </Row>
        <Divider />

        {loading ? (
          <Spin className="spinner" />
        ) : (
          <Row className="timeLine">
            <Col className="timeLine_content">
              {missingFieldsText ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>{missingFieldsText}</span>}
                />
              ) : (
                <Timeline mode={"left"}>
                  {fetchedData ? (
                    fetchedData.map((item) => (
                      <Timeline.Item
                        className="timeLine_list"
                        label={
                          <label className="timeLine_style">
                            {item.timestamp
                              ? moment(item.timestamp, "YYYY-MM-DD'T'HH:mm:ssZ")
                                  .tz(userTimeZone, false)
                                  .format("DD-MM-YYYY HH:mm:ss")
                              : ""}
                          </label>
                        }
                        color={
                          item.eventType == "CREATED"
                            ? "blue"
                            : item.eventType == "SCANNED"
                            ? "green"
                            : item.eventType == "DETECTED"
                            ? "gray"
                            : "red"
                        }
                        dot={
                          item.eventType == "CREATED" ? (
                            <PlusCircleOutlined style={iconsSize} />
                          ) : item.eventType == "SCANNED" ? (
                            <CheckCircleOutlined style={iconsSize} />
                          ) : item.eventType == "DETECTED" ? (
                            <InfoCircleOutlined style={iconsSize} />
                          ) : (
                            <CloseCircleOutlined style={iconsSize} />
                          )
                        }
                      >
                        <a>
                          {item.username && item.eventType === "KILLED"
                            ? language?.MANUALY_KILLED?.value ??
                              englishLang?.MANUALY_KILLED?.value +
                                item.eventType
                            : item.eventType}
                        </a>
                        <br />
                        {item.username ? (
                          <>
                            <UserOutlined style={{ marginRight: 5 }} />
                            {item.username}
                          </>
                        ) : (
                          <>
                            <EnvironmentOutlined style={{ marginRight: 5 }} />
                            {item.zone && item.zone.name}
                          </>
                        )}
                        <br />
                        {item.assetLocation && item.assetLocation.name ? (
                          <>
                            <GlobalOutlined style={{ marginRight: 5 }} />
                            {item.assetLocation.name}
                          </>
                        ) : null}
                      </Timeline.Item>
                    ))
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </Timeline>
              )}
            </Col>
          </Row>
        )}
        <ResultNotifier />
      </div>
    </>
  );
};

export default AssetAuditJourney;
