import * as type from '../types';
import { RequestState } from './RequestState';

const INITIAL_STATE = {
  relaySwitches: {},
  allRelaySwitches:{},
  relaySwitch: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  exportAllLoading:false

};

const reducer =  (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.RELAYSWITCHES_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        relaySwitch: {},
        loading: true
      };
    case type.RELAYSWITCHES_FETCH_SUCCESS:
      return {
        ...state,
        relaySwitches: action.payload,
        loading: false
      };
    case type.RELAYSWITCHES_FETCH_FAILURE:
      return {
        ...state,
        relaySwitches: {},
        loading: false
      }; 
      case type.ALL_RELAYSWITCHES_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        allRelaySwitches: {},
        exportAllLoading: true
      };
    case type.ALL_RELAYSWITCHES_FETCH_SUCCESS:
      return {
        ...state,
        allRelaySwitches: action.payload,
        exportAllLoading: false
      };
    case type.ALL_RELAYSWITCHES_FETCH_FAILURE:
      return {
        ...state,
        allRelaySwitches: {},
        exportAllLoading: false
      };
      case type.RELAYSWITCH_SAVE_REQUEST:
        return {
          ...state,
          error: {},
          relaySwitch: {},
          requestStatus: RequestState.PENDING,
        };
    case type.RELAYSWITCH_SAVE_SUCCESS:
      return {
        ...state,
        relaySwitch: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.RELAYSWITCH_SAVE_FAILURE:
      return {
        ...state,
        relaySwitch: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
      case type.RELAYSWITCH_DELETE_REQUEST:
        return {
          ...state,
          requestStatus: RequestState.PENDING,
        };
        case type.RELAYSWITCH_DELETE_SUCCESS:
          return {
            ...state,
            relaySwitch: action.payload,
            error: {},
            requestStatus: RequestState.SUCCESS,
          };
        case type.RELAYSWITCH_DELETE_FAILURE:
          return {
            ...state,
            relaySwitch: {},
            error: action.payload,
            requestStatus: RequestState.FAILURE,
          };
          case type.CLEAN_REQUEST_STATUS:
            return {
              ...state,
              requestStatus: RequestState.EMPTY
            };
    default:
      return state;
  }
};


export default reducer;