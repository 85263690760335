import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Form, Row } from "antd";

import { saveAsset } from "../../../api/redux/action/AssetActions";
import useNotification from "../../../components/common/ResultNotifier";

import AddAssetForm from "./AddAssetForm";
import { useHistory } from "react-router-dom";
import AddFooterSection from "../../../components/common/AddFooterSection";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";
import { hasPermission, hasSubscription } from "../../layout/Constants";
import { validateInput } from "../../../components/RequiredFieldRule";
import { fetchAssetGroupWithCustomFields } from "../../../api/redux/action/AssetGroupActions";

const AddAsset = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [prompt, setDirty, setPristine] = UnsavedChanges();

  let history = useHistory();

  const {
    error,
    requestStatus,
    language,
    user,
    assetGroupWithField,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    error: store.assetReducer.error,
    requestStatus: store.assetReducer.requestStatus,
    language: store.langReducer.language,
    user: store.authReducer.user,
    assetGroupWithField: store.assetGroupReducer.assetGroupWithField,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));
  let langSaveItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;
  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSaveItem
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      dispatch(
        fetchAssetGroupWithCustomFields(
          props.location.state.selectedRow?.assetGroupId
        )
      );

      let epcCodes = "";
      if (props.location.state.selectedRow.assetCodes) {
        for (
          var x = 0;
          x < props.location.state.selectedRow.assetCodes.length;
          x++
        ) {
          if (props.location.state.selectedRow.assetCodes.length > 1) {
            epcCodes =
              epcCodes +
              (epcCodes === "" ? "" : "\n") +
              props.location.state.selectedRow.assetCodes[x].epcCode;
          } else {
            epcCodes =
              epcCodes + props.location.state.selectedRow.assetCodes[x].epcCode;
          }
        }
      }

      //store the selected customFields in variable
      const customFields = props.location.state.selectedRow?.customFields;

      const customFieldsObj = {};

      customFields?.map((cf) => {
        customFieldsObj[cf.name] = cf.value || cf.values;
      });

      const formObj = {
        assetId: props.location.state.selectedRow.assetId,
        name: props.location.state.selectedRow.name,
        code: props.location.state.selectedRow.code,
        assetExternalReference:
          props.location.state.selectedRow.assetExternalReference,
        assetGroup: props.location.state.selectedRow.assetGroup.assetGroupId,
        assetLocation:
          props.location.state.selectedRow.assetLocation?.assetLocationId,
        epcCodes: epcCodes,
      };

      // pass down all other data to coresponding form
      form.setFieldsValue({
        ...formObj,
        ...customFieldsObj,
      });
    }
  }, []);

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      history.push("/assets");
    }
  }, [requestStatus]);

  const handleSave = (values) => {
    //convert EPC Codes to what backend needs
    const epcCodesArray = values.epcCodes
      ? values.epcCodes.replace(/\r/g, "").split("\n")
      : [];
    const epcCodes = [];
    for (var x = 0; x < epcCodesArray.length; x++) {
      epcCodes.push({
        epcCode: epcCodesArray[x],
      });
    }

    // create formData with fixed data
    const formData = {
      assetCodes: epcCodes,
      assetExternalReference: form.getFieldValue().assetExternalReference,
      assetGroup: {
        assetGroupId: form.getFieldValue().assetGroup,
      },
      code: validateInput(form.getFieldValue().code),
      name: validateInput(form.getFieldValue().name),
      customFields: [],
    };
    //Checking if we have Abattoir Subscription
    if (hasSubscription(selectedTenant, "ASSET_LOCATIONS_SUBSCRIPTION")) {
      formData.assetLocation = form.getFieldValue().assetLocation
        ? {
            assetLocationId: form.getFieldValue().assetLocation,
          }
        : null;
    }

    //Saving customFields inside asset
    assetGroupWithField.forEach((item) => {
      const fieldValue = form.getFieldValue(item.name);
      const customField = {
        customFieldId: item.customFieldId,
      };
      //Checking what type is customField
      if (item.type === "MULTI_SELECT") {
        //If multi select we put array
        customField.name = item.name;
        customField.values = fieldValue;
      } else if (item.type === "SINGLE_SELECT") {
        customField.name = item.name;
        customField.value = fieldValue;
      } else {
        //If is anything else add string
        customField.name = item.name;
        customField.value = fieldValue;
      }
      formData.customFields.push(customField);
    });

    setPristine();
    dispatch(saveAsset(formData, form.getFieldValue().assetId));
  };

  const handleCancel = () => {
    history.push("/assets");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_ASSETS");
  }

  const footer = (
    <AddFooterSection
      language={language}
      viewOption={props.location.state}
      handleCancel={handleCancel}
      handleEditingPermission={handleEditingPermission}
      form={form}
      updatePath={"/update-asset"}
    />
  );

  return (
    <Card
      title={language?.ASSET?.value ?? englishLang?.ASSET?.value}
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddAssetForm
            handleSave={handleSave}
            form={form}
            footer={footer}
            setDirty={setDirty}
            readOnly={props.location.state}
            assetGroupWithField={assetGroupWithField}
          />
        </Col>
      </Row>
      <ResultNotifier />
      {prompt}
    </Card>
  );
};

export default AddAsset;
