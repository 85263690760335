import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Skeleton, Empty, Result } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useSelector } from "react-redux";
import { ASSET_DETECTION_COUNTS } from "../../../../api/graphQL/queries";

const CustomBarChart = ({ selectedAssetGroup, setupSelect, width }) => {
  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const { loading, error, data, startPolling, stopPolling } = useQuery(
    ASSET_DETECTION_COUNTS,
    {
      variables: {
        assetGroupIds: selectedAssetGroup ? parseInt(selectedAssetGroup) : [],
      },
      pollInterval: 60000,
      fetchPolicy: "network-only",
    }
  );

  if (loading) return <Skeleton active />;

  if (error) {
    return (
      <Result
        status="warning"
        title={
          language?.ERROR_FETCHING_DATA?.value ??
          englishLang?.ERROR_FETCHING_DATA?.value
        }
      />
    );
  }

  function showDataHandler() {
    if (data.assetVehicleDetectionCounts.length > 0) {
      return data.assetVehicleDetectionCounts;
    } else {
      return data.assetZoneDetectionCounts;
    }
  }

  function checkBarChartData() {
    if (
      data.assetVehicleDetectionCounts.length > 0 ||
      data.assetZoneDetectionCounts.length > 0
    ) {
      return (
        <>
          <BarChart
            width={width * 0.9}
            height={250}
            data={showDataHandler()}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#21A0E3" />
          </BarChart>
        </>
      );
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return <>{checkBarChartData()}</>;
};

export default CustomBarChart;
