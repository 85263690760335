import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EnvironmentOutlined } from "@ant-design/icons";
import { Button, Popover, Tooltip, Tag } from "antd";
import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchZones,
  deleteZone,
  fetchAllZones,
} from "../../../api/redux/action/ZoneActions";
import { exportRequest } from "../../../api/network/axios/exportRequest";
import ZoneActionView from "../../../components/common/ActionMenu";
import useNotification from "../../../components/common/ResultNotifier";
import { hasPermission, hasSubscription } from "../../layout/Constants";
import ZonesMap from "./ZonesMap";
import { useHistory } from "react-router-dom";
import "./Style.css";
function Zones() {
  const dispatch = useDispatch();
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [searchText, setSearchText] = useState();
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  let history = useHistory();

  const {
    zones,
    allZones,
    error,
    requestStatus,
    loading,
    user,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    zones: store.zoneReducer.zones,
    allZones: store.zoneReducer.allZones,
    error: store.zoneReducer.error,
    requestStatus: store.zoneReducer.requestStatus,
    loading: store.zoneReducer.loading,
    user: store.authReducer.user,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const KillAsset = hasSubscription(
    selectedTenant,
    "KILLING_ZONES_SUBSCRIPTION"
  )
    ? {
        title: language.KILL_ASSET?.value,
        dataIndex: "killAssets",
        render: (killedAssets) =>
          killedAssets === true ? (
            <Tooltip
              title={
                language?.KILL_ASSET_ZONE_TRUE?.value ??
                englishLang?.KILL_ASSET_ZONE_TRUE?.value
              }
            >
              <Tag color="green">
                {language?.KILL_ASSET_TRUE?.value ??
                  englishLang?.KILL_ASSET_TRUE?.value}
              </Tag>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                language?.KILL_ASSET_ZONE_FALSE?.value ??
                englishLang?.KILL_ASSET_ZONE_FALSE?.value
              }
            >
              <Tag color="red">
                {language?.KILL_ASSET_FALSE?.value ??
                  englishLang?.KILL_ASSET_FALSE?.value}
              </Tag>
            </Tooltip>
          ),
      }
    : {};

  const columns = [
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
      sorter: true,
      sortField: "name",
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      render: (name) => (
        <a
          onClick={() => {
            const viewOption = "disabled";

            history.push({
              pathname: "/update-zone",
              state: { selectedRow, viewOption },
            });
          }}
        >
          {name}
        </a>
      ),
    },
    {
      title: language?.SITE?.value ?? englishLang?.SITE?.value,
      dataIndex: ["site", "name"],
      sorter: true,
      sortField: "siteEntity.name",
      sortOrder: sortedInfo.columnKey === "siteEntity.name" && sortedInfo.order,
    },
    {
      title: language?.LOCATION?.value ?? englishLang?.LOCATION?.value,
      dataIndex: "location",
      render: (location, zoneId) =>
        location && location.latitude && location.latitude ? (
          <Popover
            trigger="click"
            title={zoneId.name}
            content={
              <ZonesMap lat={location.latitude} long={location.longitude} />
            }
          >
            <Button
              type="primary"
              shape="circle"
              icon={<EnvironmentOutlined />}
            />
          </Popover>
        ) : (
          ""
        ),
    },

    KillAsset,
  ];

  const [allowPermission, setAllowPermission] = useState({
    edit: hasPermission(selectedTenant, "UPDATE_ZONES"),
    delete: hasPermission(selectedTenant, "DELETE_ZONES"),
    view: hasPermission(selectedTenant, "READ_ZONES"),
  });

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const handleSingleDelete = (selected) => {
    dispatch(deleteZone(selected.zoneId));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    sortField,
    sortOrder,
    selectedRow,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "zone",
    dataSource: zones,
    updateEntityPath: "update-zone",
    customActionView:
      allowPermission.edit || allowPermission.delete || allowPermission.view
        ? ZoneActionView
        : "",
    searchText,
    loading: loading,
    handleSingleDelete,
    allowPermission,
    setSortedInfo,
    title: language?.ZONES?.value ?? englishLang?.ZONES?.value,
  });

  useEffect(() => {
    dispatch(
      fetchZones({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-zones";
    let options = {
      url: "/api/web/zones/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        searchText: searchText,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  let flattenedData = [];

  if (Object.keys(zones).length !== 0) {
    flattenedData = Object.values(zones.content);
  }

  useEffect(() => {
    if (allZones && Object.keys(allZones).length !== 0) {
      setFlattenedAllData(Object.values(allZones.content));
    }
  }, [allZones]);

  let headers = [];

  const KillAssetExport = hasSubscription(
    selectedTenant,
    "KILLING_ZONES_SUBSCRIPTION"
  )
    ? (headers = [
        {
          label: language?.NAME?.value ?? englishLang?.NAME?.value,
          key: "name",
        },
        {
          label: language?.SITE?.value ?? englishLang?.SITE?.value,
          key: "site.name",
        },
        {
          label: language?.LATITUDE?.value ?? englishLang?.LATITUDE?.value,
          key: "location.latitude",
        },
        {
          label: language?.LONGITUDE?.value ?? englishLang?.LONGITUDE?.value,
          key: "location.longitude",
        },
        {
          label: language?.KILL_ASSET?.value ?? englishLang?.KILL_ASSET?.value,
          key: "killAssets",
        },
      ])
    : (headers = [
        {
          label: language?.NAME?.value ?? englishLang?.NAME?.value,
          key: "name",
        },
        {
          label: language?.SITE?.value ?? englishLang?.SITE?.value,
          key: "site.name",
        },
        {
          label: language?.LATITUDE?.value ?? englishLang?.LATITUDE?.value,
          key: "location.latitude",
        },
        {
          label: language?.LONGITUDE?.value ?? englishLang?.LONGITUDE?.value,
          key: "location.longitude",
        },
      ]);

  const addNewPath = hasPermission(selectedTenant, "CREATE_ZONES")
    ? "add-zone"
    : "";

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath={addNewPath}
          hasSelected={hasSelected}
          exportTableTitle={language?.ZONES?.value ?? englishLang?.ZONES?.value}
          addNewText={language?.ADD_ZONE?.value ?? englishLang?.ADD_ZONE?.value}
          title={language?.ZONES?.value ?? englishLang?.ZONES?.value}
          csvData={flattenedData}
          csvHeaders={headers}
          csvAllData={flattenedAllData}
          exportAllButtonClick={handleExportAllButtonClick}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportButton={true}
          exportAllButton={true}
          handleSearch={setSearchText}
          tooltipText={
            language?.TOOLTIP_SEARCH_ALL?.value ??
            englishLang?.TOOLTIP_SEARCH_ALL?.value
          }
        />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default Zones;
