import * as type from '../types';
import { RequestState } from './RequestState';

const INITIAL_STATE = {
  antennas: {},
  allAntennas: {},
  antenna: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  exportAllLoading:false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.ANTENNAS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        antennas: {},
        loading: true
      };
    case type.ANTENNAS_FETCH_SUCCESS:
      return {
        ...state,
        antennas: action.payload,
        loading: false
      };
    case type.ANTENNAS_FETCH_FAILURE:
      return {
        ...state,
        antennas: {},
        loading: false
      };
      case type.ALL_ANTENNAS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        allAntennas: {},
        exportAllLoading: true
      };
    case type.ALL_ANTENNAS_FETCH_SUCCESS:
      return {
        ...state,
        allAntennas: action.payload,
        exportAllLoading: false
      };
    case type.ALL_ANTENNAS_FETCH_FAILURE:
      return {
        ...state,
        allAntennas: {},
        exportAllLoading: false
      };
      case type.ANTENNA_SAVE_REQUEST:
        return {
          ...state,
          error: {},
          antenna: {},
          requestStatus: RequestState.PENDING,
        };
    case type.ANTENNA_SAVE_SUCCESS:
      return {
        ...state,
        antenna: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.ANTENNA_SAVE_FAILURE:
      return {
        ...state,
        antenna: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
      case type.ANTENNA_DELETE_REQUEST:
        return {
          ...state,
          requestStatus: RequestState.PENDING,
        };
        case type.ANTENNA_DELETE_SUCCESS:
          return {
            ...state,
            antenna: action.payload,
            error: {},
            requestStatus: RequestState.SUCCESS,
          };
        case type.ANTENNA_DELETE_FAILURE:
          return {
            ...state,
            antenna: {},
            error: action.payload,
            requestStatus: RequestState.FAILURE,
          };
          case type.CLEAN_REQUEST_STATUS:
            return {
              ...state,
              requestStatus: RequestState.EMPTY
            };
    default:
      return state;
  }
};


export default reducer;