import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Divider,
  Tooltip,
  Row,
  Form,
  Select,
  Space,
  Checkbox,
  DatePicker,
  Button,
  Popover,
  Empty,
} from "antd";
import {
  InfoCircleOutlined,
  RedoOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import moment from "moment-timezone";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchReport,
  resetReport,
} from "../../../api/redux/action/ReportActions";
import useNotification from "../../../components/common/ResultNotifier";
import { fetchAssets } from "../../../api/redux/action/AssetActions";
import { fetchAssetGroups } from "../../../api/redux/action/AssetGroupActions";
import Header from "../../../components/common/ReportHeader";
import { toTimestamp } from "../../layout/Constants";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import LastdetectedMap from "./LastDetectedMap";
import { exportRequest } from "../../../api/network/axios/exportRequest";

import { useLocation } from "react-router-dom";

const { Option } = Select;

const dateFormat = "DD-MM-YYYY";

const { RangePicker } = DatePicker;

const LastDetected = (props) => {
  const getToday = () => {
    const today = new Date();
    return setDateToString(today);
  };

  const page = useLocation();

  const setDateToString = (date) => {
    return (
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
    );
  };

  const params = new URLSearchParams(window.location.search);

  const [startDate, setStartDate] = useState(
    params.get("start") ? params.get("start") : getToday()
  );
  const [endDate, setEndDate] = useState(
    params.get("end") ? params.get("end") : getToday()
  );

  const dispatch = useDispatch();

  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState("");
  const [selectedAssetGroup, setSelectedAssetGroup] = useState("");
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [flattenedData, setFlattenedData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [sortedAssetGroups, setSortedAssetGroups] = useState([]);
  const [sortedAssets, setSortedAssets] = useState([]);
  const [dateValue, setDateValue] = useState([]);
  const [missingFieldsText, setMissingFieldsText] = useState();
  const [dataTable, setDataTable] = useState();

  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });
  const [filter, setFilter] = useState({
    fromDate: startDate,
    toDate: endDate,
    assetGroupIds: null,
    assetIds: null,
    includeDeleted: false,
  });

  const {
    user,
    data,
    allData,
    error,
    requestStatus,
    assets,
    assetGroups,
    loading,
    userTimeZone,
    language,
    englishLang,
  } = useSelector((store) => ({
    user: store.authReducer.user,
    data: store.reportReducer.data,
    allData: store.reportReducer.allData,
    error: store.reportReducer.error,
    requestStatus: store.reportReducer.requestStatus,
    assets: store.assetReducer.assets,
    assetGroups: store.assetGroupReducer.assetGroups,
    loading: store.reportReducer.loading,
    userTimeZone: store.authReducer.userTimeZone,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const columns = [
    {
      key: "code",
      sorter: true,
      sortField: "code",
      sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order,
      title: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      dataIndex: ["asset", "code"],
    },
    {
      key: "name",
      sorter: true,
      sortField: "name",
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: ["asset", "name"],
    },
    {
      key: "lastDetectedTimestamp",
      sorter: true,
      sortField: "lastDetectedTimestamp",
      sortOrder:
        sortedInfo.columnKey === "lastDetectedTimestamp" && sortedInfo.order,
      title:
        language?.LAST_DETECTED_TIMESTAMP?.value ??
        englishLang?.LAST_DETECTED_TIMESTAMP?.value,
      dataIndex: "lastDetectedTimestamp",
      render: (lastDetectedTimestamp) => (
        <span>
          {lastDetectedTimestamp
            ? moment
                .tz(lastDetectedTimestamp, userTimeZone)
                .format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
    },
    {
      key: "site",
      sorter: true,
      sortField: "site",
      sortOrder: sortedInfo.columnKey === "site" && sortedInfo.order,
      title:
        language?.LAST_DETECTED_SITE?.value ??
        englishLang?.LAST_DETECTED_SITE?.value,
      dataIndex: "site",
    },
    {
      key: "zone",
      sorter: true,
      sortField: "zone",
      sortOrder: sortedInfo.columnKey === "zone" && sortedInfo.order,
      title:
        language?.LAST_DETECTED_ZONE?.value ??
        englishLang?.LAST_DETECTED_ZONE?.value,
      dataIndex: "zone",
    },
    {
      title:
        language?.LAST_DETECTED_LOCATION?.value ??
        englishLang?.LAST_DETECTED_LOCATION?.value,
      dataIndex: ["lastDetectedLocation"],
      render: (lastDetectedLocation, Id) => (
        <span>
          {lastDetectedLocation &&
          lastDetectedLocation.latitude &&
          lastDetectedLocation.latitude ? (
            <Popover
              trigger="click"
              title={
                Id.asset.name ? Id.asset.name : Id.lastDetectedLocation.address
              }
              content={
                <LastdetectedMap
                  lat={lastDetectedLocation.latitude}
                  long={lastDetectedLocation.longitude}
                />
              }
            >
              <EnvironmentOutlined
                style={{ marginRight: "10px", color: "#1890ff" }}
              />
            </Popover>
          ) : (
            ""
          )}
          {lastDetectedLocation && lastDetectedLocation.address}
        </span>
      ),
    },
    {
      key: "vehicleRegNo",
      sorter: true,
      sortField: "vehicleRegNo",
      sortOrder: sortedInfo.columnKey === "vehicleRegNo" && sortedInfo.order,
      title:
        language?.LAST_DETECTED_VEHICLE?.value ??
        englishLang?.LAST_DETECTED_VEHICLE?.value,
      dataIndex: "vehicleRegNo",
    },
  ];

  useEffect(() => {
    dispatch(resetReport());
    dispatch(fetchAssetGroups());
    dispatch(fetchAssets());
  }, []);

  useEffect(() => {
    if (assetGroups && assetGroups.content) {
      let sorted = assetGroups.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedAssetGroups(sorted);
    }
  }, [assetGroups]);

  useEffect(() => {
    if (assets && assets.content) {
      let sorted = assets.content.sort((a, b) =>
        a.code.localeCompare(b.code, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedAssets(sorted);
    }
  }, [assets]);

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    dataSource: data,
    updateEntityPath: "",
    filter,
    loading: loading,
    missingFieldsText: missingFieldsText,
    setSortedInfo: setSortedInfo,
  });

  const handleAssetChange = (value) => {
    setSelectedAsset(value);
    setSelectedAssetGroup(null);
    setFilter({
      ...filter,
      assetIds: value,
      assetGroupIds: null,
    });
  };

  const handleAssetGroupChange = (value) => {
    setSelectedAssetGroup(value);
    setSelectedAsset(null);
    setFilter({
      ...filter,
      assetIds: null,
      assetGroupIds: value,
    });
  };

  const handleShowDeletedChange = (value) => {
    setFilter({
      ...filter,
      includeDeleted: value.target.checked,
    });
  };

  const checkNoMissingParams = () => {
    if (!(filter.fromDate && filter.toDate)) {
      setMissingFieldsText(
        language?.SELECT_DATE_RANGE?.value ??
          englishLang?.SELECT_DATE_RANGE?.value
      );
      return false;
    }
    return true;
  };

  const ascDesc = () => {
    if (sortedInfo.order === "ascend") {
      return "ASC";
    } else {
      return "DESC";
    }
  };

  useEffect(() => {
    let endpoint = sortedInfo.columnKey
      ? `last-detected?sort=${sortedInfo.columnKey},${ascDesc()}`
      : "last-detected";

    // dispatch(resetReport());
    if (checkNoMissingParams()) {
      setMissingFieldsText();
      dispatch(
        fetchReport({
          page: searchFormHelperHandler(),
          size: pageSize,
          fromDate: toTimestamp(filter.fromDate, "start"),
          toDate: toTimestamp(filter.toDate, "end"),
          includeDeleted: filter.includeDeleted,
          ...(filter.assetIds && { assetIds: filter.assetIds }),
          ...(filter.assetGroupIds && { assetGroupIds: filter.assetGroupIds }),
          endpoint: endpoint,
        })
      );
    }
  }, [currentPage, filter, isLoading, refresh, pageSize, sortedInfo]);

  useEffect(() => {
    setDataTable(DataTable);
  }, [loading, language]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-last-detected";
    let options = {
      url: "/api/web/reports/last-detected/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
        includeDeleted: filter.includeDeleted,
        ...(filter.assetIds && { assetIds: filter.assetIds }),
        ...(filter.assetGroupIds && { assetGroupIds: filter.assetGroupIds }),
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  const handleDateRangeChange = (date, dateString) => {
    setDateValue(date);
    setFilter({
      ...filter,
      fromDate: dateString[0],
      toDate: dateString[1],
    });
  };

  const handleRefreshClick = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      let flatData;
      function flatDataHandler() {
        if (data.content === undefined) {
          return (flatData = Object.values(data));
        } else {
          return (flatData = Object.values(data.content));
        }
      }
      flatDataHandler();
      flatData &&
        flatData.map((item) => {
          if (typeof item === "object") {
            item.lastDetectedTimestampFormatted = item.lastDetectedTimestamp
              ? moment
                  .tz(item.lastDetectedTimestamp, userTimeZone)
                  .format("DD-MM-YYYY HH:mm:ss")
              : "";
          }
        });
      setFlattenedData(Object.values(flatData));
    }
  }, [data]);

  useEffect(() => {
    if (allData && Object.keys(allData).length !== 0) {
      let flatData = Object.values(allData.content);

      flatData &&
        flatData.map((item) => {
          if (typeof item === "object") {
            item.lastDetectedTimestampFormatted = item.lastDetectedTimestamp
              ? moment
                  .tz(item.lastDetectedTimestamp, userTimeZone)
                  .format("DD-MM-YYYY HH:mm:ss")
              : "";
          }
        });

      setFlattenedAllData(Object.values(flatData));
    }
  }, [allData]);

  const headers = [
    {
      label: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      key: "asset.code",
    },
    {
      label: language?.NAME?.value ?? englishLang?.NAME?.value,
      key: "asset.name",
    },
    {
      label:
        language?.LAST_DETECTED_TIMESTAMP?.value ??
        englishLang?.LAST_DETECTED_TIMESTAMP?.value,
      key: "lastDetectedTimestampFormatted",
    },
    {
      label:
        language?.LAST_DETECTED_SITE?.value ??
        englishLang?.LAST_DETECTED_SITE?.value,
      key: "site",
    },
    {
      label:
        language?.LAST_DETECTED_ZONE?.value ??
        englishLang?.LAST_DETECTED_ZONE?.value,
      key: "zone",
    },
    {
      label:
        language?.LAST_DETECTED_LOCATION?.value ??
        englishLang?.LAST_DETECTED_LOCATION?.value,
      key: "lastDetectedLocation.address",
    },
    {
      label:
        language?.LAST_DETECTED_VEHICLE?.value ??
        englishLang?.LAST_DETECTED_VEHICLE?.value,
      key: "vehicleRegNo",
    },
  ];

  const tooltip = (
    <Tooltip
      title={
        <>
          <p>
            {language?.CHOOSE_EITHER_AN_ASSET_GROUP_OR_AN_ASSET?.value ??
              englishLang?.CHOOSE_EITHER_AN_ASSET_GROUP_OR_AN_ASSET?.value}
          </p>
        </>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Row>
          {props.modeSelector}
          <Header
            addNewPath=""
            hasSelected={hasSelected}
            exportTableTitle={
              language?.LASTDETECTED?.value ?? englishLang?.LASTDETECTED?.value
            }
            addNewText=""
            title=""
            csvData={flattenedData}
            csvAllData={flattenedAllData}
            csvHeaders={headers}
            exportAllLoading={exportAllLoading}
            loading={loading}
            exportAllButtonClick={handleExportAllButtonClick}
          />
        </Row>
        <Row>
          <Col>
            <Form>
              <Space>
                <Tooltip
                  title={
                    language?.SELECT_A_DATE_RANGE?.value ??
                    englishLang?.SELECT_A_DATE_RANGE?.value
                  }
                >
                  <RangePicker
                    id={ElementIDS().calendar}
                    defaultValue={[
                      moment(startDate, dateFormat),
                      moment(endDate, dateFormat),
                    ]}
                    format={dateFormat}
                    onChange={handleDateRangeChange}
                  />
                </Tooltip>
                <Tooltip
                  title={
                    language?.SELECT_ASSEST_GROUP?.value ??
                    englishLang?.SELECT_ASSEST_GROUP?.value
                  }
                >
                  <Select
                    id={ElementIDS().selectAssetGroup}
                    allowClear
                    style={{ width: 200 }}
                    placeholder={
                      language?.SELECT_ASSEST_GROUP?.value ??
                      englishLang?.SELECT_ASSEST_GROUP?.value
                    }
                    optionFilterProp="children"
                    onChange={handleAssetGroupChange}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={selectedAssetGroup || undefined}
                  >
                    {sortedAssetGroups
                      ? sortedAssetGroups.map((item) => (
                          <Option
                            id={ElementIDS().option}
                            key={item.assetGroupId}
                            value={item.assetGroupId}
                          >
                            {item.name}
                          </Option>
                        ))
                      : undefined}
                  </Select>
                </Tooltip>

                <Tooltip
                  title={
                    language?.SELECT_AN_ASSET?.value ??
                    englishLang?.SELECT_AN_ASSET?.value
                  }
                >
                  <Select
                    id={ElementIDS().selectAsset}
                    allowClear
                    style={{ width: 200 }}
                    placeholder={
                      language?.SELECT_AN_ASSET?.value ??
                      englishLang?.SELECT_AN_ASSET?.value
                    }
                    optionFilterProp="children"
                    onChange={handleAssetChange}
                    // mode= 'multiple'
                    // maxTagCount= 'responsive'
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={selectedAsset || undefined}
                  >
                    {sortedAssets
                      ? sortedAssets.map((item) => (
                          <Option
                            id={ElementIDS().optionAsset}
                            key={item.assetId}
                            value={item.assetId}
                          >
                            {item.code}
                          </Option>
                        ))
                      : undefined}
                  </Select>
                </Tooltip>

                <Checkbox
                  id={ElementIDS().checkBox}
                  onChange={handleShowDeletedChange}
                >
                  {language?.SHOW_DELETED?.value ??
                    englishLang?.SHOW_DELETED?.value}
                </Checkbox>
                {tooltip}
                <Button
                  id={ElementIDS().refresh}
                  type="primary"
                  onClick={() => handleRefreshClick()}
                >
                  {" "}
                  <RedoOutlined />
                  {language?.REFRESH?.value ?? englishLang?.REFRESH?.value}
                </Button>
              </Space>
            </Form>
          </Col>
        </Row>
        <Divider />
        {dataTable}
        <ResultNotifier />
      </div>
    </>
  );
};

export default LastDetected;
