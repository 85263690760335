import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Divider, Row, Tooltip, Input, Upload } from "antd";
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { ElementIDS } from "./ElementsIDs";
import { useDispatch } from "react-redux";
import { showBanner } from "../../api/redux/action/BannerActions";

const { Search } = Input;

function Header({
  addNewPath,
  hasSelected,
  handleExportTable = null,
  exportTableTitle,
  tooltipText = undefined,
  addNewText,
  title,
  csvData,
  csvAllData,
  csvHeaders,
  exportAllLoading,
  bulkLoading,
  loading,
  exportAllButtonClick,
  exportButton,
  exportAllButton,
  handleSearch,
  bulkUploadProps,
  bulkUploadBtn,
  bulkUploadBtnText,
  bulkUploadTooltipText,
}) {
  const history = useHistory();

  const dispatch = useDispatch();

  const csvAllLinkRef = useRef(null);

  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const handleAddNew = () => {
    history.push("/" + addNewPath);
  };

  const bulkUploadButton = bulkUploadBtn ? (
    <>
      <Tooltip title={bulkUploadTooltipText}>
        <Upload {...bulkUploadProps} className={"bulkUploadBtn"} accept=".csv">
          <Button
            loading={bulkLoading}
            id={ElementIDS().upLoadBtn}
            icon={<UploadOutlined />}
          >
            {bulkUploadBtnText}
          </Button>
        </Upload>
      </Tooltip>
    </>
  ) : (
    ""
  );

  const exportAllBtn = exportAllButton ? (
    <>
      {" "}
      <Button
        id={ElementIDS().exportBtn}
        type="dashed"
        style={{ float: "right", marginRight: 12 }}
        loading={exportAllLoading}
        onClick={() => exportAllButtonClick()}
      >
        <DownloadOutlined />
        {language?.EXPORT?.value ?? englishLang?.EXPORT?.value}
      </Button>
      <CSVLink
        ref={csvAllLinkRef}
        data={csvAllData}
        headers={csvHeaders}
        filename={`export-` + exportTableTitle + `-` + Date.now() + `.csv`}
      />
    </>
  ) : (
    ""
  );

  const search = handleSearch ? (
    <>
      {" "}
      <Col>
        <Tooltip title={tooltipText}>
          <Search
            id={ElementIDS().searchForm}
            placeholder={language?.SEARCH?.value ?? englishLang?.SEARCH?.value}
            onSearch={handleSearch}
            allowClear
            style={{ float: "left", width: 350, marginLeft: "20px" }}
          />
        </Tooltip>
      </Col>
    </>
  ) : (
    ""
  );

  return (
    <>
      <Row>
        <Col
          style={{
            color: "rgba(0, 0, 0, 0.85)",
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          {title}
        </Col>
        {search}
        <Col flex="auto">
          {addNewPath !== "" ? (
            <Button
              id={ElementIDS().addBtn}
              icon={<PlusOutlined />}
              type="primary"
              style={{ float: "right" }}
              onClick={handleAddNew}
            >
              {addNewText}
            </Button>
          ) : null}
          {bulkUploadButton}
          {exportAllBtn}
          {/* {exportBtn} */}
        </Col>
      </Row>
      <Divider />
    </>
  );
}

export default Header;
