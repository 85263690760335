import { useLocation } from "react-router-dom";

export const ElementIDS = () => {
  const location = useLocation();
  const pathName = location.pathname.split("/")[1];

  switch (pathName) {
    case "":
      return {
        assetId: "rfid-widget-assetBtn",
        zonesId: "rfid-widget-zonesBtn",
        assetGroupId: "rfid-widget-assetGroupBtn",
        siteId: "rfid-widget-SitesBtn",
        gateWaysId: "rfid-totalWidget-gateWaysBtn",
        fixedReaders: "rfid-totalWidget-fixedReadersBtn",
        mobileReaders: "rfid-totalWidget-mobileReadersBtn",
        alertRules: "rfid-totalWidget-alertRules",
      };
    case "assetGroups":
      return {
        searchForm: "rfid-assetGroups-assetGroupSearchFiled",
        addBtn: "rfid-assetGroups-addAssetGroupBtn",
        exportBtn: "rfid-assetGroups-exportAssetGroup",
        dropDown: "rfid-assetGroups-dropDownAssetGroupBtn",
        dropDownEdit: "rfid-assetGroups-dropDownAssetGroupEditBtn",
        dropDownDelete: "rfid-assetGroups-dropDownAssetGroupDeleteBtn",
        dataTableIds: "rfid-assetGroups-dataTable",
        view: "rfid-assetGroups-dropDownAssetGroupViewBtn",
      };
    case "assets":
      return {
        searchForm: "rfid-asset-assetSearchFiled",
        addBtn: "rfid-asset-addAssetBtn",
        exportBtn: "rfid-asset-exportAssetBtn",
        upLoadBtn: "rfid-asset-uploadAssetBtn",
        dropDown: "rfid-asset-dropDownAssetsBtn",
        dropDownEdit: "rfid-asset-dropDownAssetsEditBtn",
        dropDownDelete: "rfid-asset-dropDownAssetsDeleteBtn",
        dataTableIds: "rfid-assets-dataTable",
        view: "rfid-asset-dropDownAssetsViewBtn",
        killBtn: "rfid-assets-killAssetsBtn",
      };
    case "departments":
      return {
        searchForm: "rfid-departments-departmentSearchFiled",
        addBtn: "rfid-departments-addDepartmentsBtn",
        exportBtn: "rfid-departments-exportDepartmentsBtn",
        dropDown: "rfid-departments-dropDownDepartmentsBtn",
        dropDownEdit: "rfid-departments-dropDownDepartmentsEditBtn",
        dropDownDelete: "rfid-departments-dropDownDepartmentsDeleteBtn",
        dataTableIds: "rfid-departments-dataTable",
        view: "rfid-departments-dropDownDepartmentsViewBtn",
      };
    case "sites":
      return {
        searchForm: "rfid-sites-siteSearchFiled",
        addBtn: "rfid-sites-addSiteBtn",
        exportBtn: "rfid-sites-exportSiteBtn",
        dropDown: "rfid-sites-dropDownSitesBtn",
        dropDownEdit: "rfid-sites-dropDownSitesEditBtn",
        dropDownDelete: "rfid-sites-dropDownSitesDeleteBtn",
        view: "rfid-sites-dropDownSitesViewBtn",
        dataTableIds: "rfid-sites-dataTable",
      };
    case "zones":
      return {
        searchForm: "rfid-zone-zoneSearchFiled",
        addBtn: "rfid-zone-addZoneBtn",
        exportBtn: "rfid-zone-exportZoneBtn",
        dropDown: "rfid-zone-dropDownZoneBtn",
        dropDownEdit: "rfid-zone-dropDownZoneEditBtn",
        dropDownDelete: "rfid-zone-dropDownZoneDeleteBtn",
        dataTableIds: "rfid-zone-dataTable",
        view: "rfid-updateAlertRule-viewBtn",
      };
    case "gateways":
      return {
        searchForm: "rfid-gateways-gateWaysSearchFiled",
        exportBtn: "rfid-gateways-exportGateWaysBtn",
        dataTableIds: "rfid-gateways-dataTable",
      };
    case "fixedReaders":
      return {
        searchForm: "rfid-reader-readerSearchFiled",
        exportBtn: "rfid-reader-exportReaderBtn",
        dataTableIds: "rfid-reader-dataTable",
      };
    case "antennas":
      return {
        searchForm: "rfid-antennas-antennasSearchFiled",
        exportBtn: "rfid-antennas-exportAntennasBtn",
        dataTableIds: "rfid-antennas-dataTable",
        view: "rfid-antennas-dropDownViewBtn",
        dropDown: "rfid-antennas-dropDownBtn",
        dropDownEdit: "rfid-antennas-dropDownEditBtn",
        dropDownDelete: "rfid-antennas-dropDownDeleteBtn",
      };
    case "update-antenna":
      return {
        save: "rfid-updateAntenna-saveBtn",
        cancel: "rfid-updateAntenna-cancelBtn",
        edit: "rfid-updateAntenna-editBtn",
        antennaNum: "rfid-updateAntenna-antennaNumForm",
        reader: "rfid-updateAntenna-readerForm",
        readerOption: "rfid-updateAntenna-readerOption",
        zone: "rfid-updateAntenna-zoneForm",
        zoneOption: "rfid-updateAntenna-zoneAddOption",
        addBtn: "rfid-updateAntenna-addZoneBtn",
      };
    case "add-antenna":
      return {
        save: "rfid-addAntenna-saveBtn",
        cancel: "rfid-addAntenna-cancelBtn",
        edit: "rfid-addAntenna-editBtn",
        antennaNum: "rfid-addAntenna-antennaNumForm",
        reader: "rfid-addAntenna-readerForm",
        readerOption: "rfid-addAntenna-readerOption",
        zone: "rfid-addAntenna-zoneForm",
        zoneOption: "rfid-addAntenna-zoneAddOption",
        addBtn: "rfid-addAntenna-addZoneBtn",
      };
    case "ipRelayCards":
      return {
        searchForm: "rfid-ipRelayCards-ipRelayCardsSearchFiled",
        exportBtn: "rfid-ipRelayCards-exportIpRelayCardsBtn",
        dataTableIds: "rfid-ipRelayCards-dataTable",
        dropDown: "rfid-ipRelayCards-dropDownBtn",
        dropDownEdit: "rfid-ipRelayCards-dropDownEditBtn",
        dropDownDelete: "rfid-ipRelayCards-dropDownDeleteBtn",
        view: "rfid-ipRelayCards-dropDownViewBtn",
      };
    case "update-ipRelayCard":
      return {
        gateway: "rfid-updateIpRelayCard-gateWayForm",
        gatewayOption: "rfid-updateIpRelayCard-gateWayOption",
        IP_Address: "rfid-updateIpRelayCard-ipAdressForm",
        portNumber: "rfid-updateIpRelayCard-portNumberForm",
        save: "rfid-updateIpRelayCard-saveBtn",
        cancel: "rfid-updateIpRelayCard-cancelBtn",
        edit: "rfid-updateIpRelayCard-editBtn",
      };
    case "add-ipRelayCard":
      return {
        gateway: "rfid-addIpRelayCard-gateWayForm",
        gatewayOption: "rfid-addIpRelayCard-gateWayOption",
        IP_Address: "rfid-addIpRelayCard-ipAdressForm",
        portNumber: "rfid-addIpRelayCard-portNumberForm",
        save: "rfid-addIpRelayCard-saveBtn",
        cancel: "rfid-addIpRelayCard-cancelBtn",
        edit: "rfid-addIpRelayCard-editBtn",
      };
    case "relaySwitches":
      return {
        searchForm: "rfid-relaySwitches-relaySwitchesSearchFiled",
        exportBtn: "rfid-relaySwitches-exportRelaySwitchesBtn",
        dataTableIds: "rfid-relaySwitches-dataTable",
        dropDown: "rfid-relaySwitches-dropDownBtn",
        dropDownEdit: "rfid-relaySwitches-dropDownEditBtn",
        dropDownDelete: "rfid-relaySwitches-dropDownDeleteBtn",
        view: "rfid-relaySwitches-dropDownViewBtn",
      };
    case "update-relaySwitch":
      return {
        name: "rfid-updateRelaySwitch-nameForm",
        switchNumber: "rfid-updateRelaySwitch-switchNumber",
        ipRelayCard: "rfid-updateRelaySwitch-ipRelayCard",
        ipRelayCardOption: "rfid-updateRelaySwitch-ipRelayCardOption",
        ipRelayCardCreate: "rfid-updateRelaySwitch-ipRelayCardCreatebtn",
        pulseInMilliseconds: "rfid-updateRelaySwitch-pulseInMilliseconds",
        save: "rfid-updateRelaySwitch-saveBtn",
        cancel: "rfid-updateRelaySwitch-cancelBtn",
        edit: "rfid-updateRelaySwitch-editBtn",
      };
    case "add-relaySwitch":
      return {
        name: "rfid-addRelaySwitch-nameForm",
        switchNumber: "rfid-addRelaySwitch-switchNumber",
        ipRelayCard: "rfid-addRelaySwitch-ipRelayCard",
        ipRelayCardOption: "rfid-addRelaySwitch-ipRelayCardOption",
        ipRelayCardCreate: "rfid-addRelaySwitch-ipRelayCardCreatebtn",
        pulseInMilliseconds: "rfid-addRelaySwitch-pulseInMilliseconds",
        save: "rfid-addRelaySwitch-saveBtn",
        cancel: "rfid-addRelaySwitch-cancelBtn",
        edit: "rfid-addRelaySwitch-editBtn",
      };
    case "lastDetected":
      return {
        exportBtn: "rfid-lastDetected-exportLastDetectedBtn",
        dataTableIds: "rfid-lastDetected-dataTable",
        selectAssetGroup: "rfid-lastDetected-selectAssetGroup",
        selectAsset: "rfid-lastDetected-selectAsset",
        checkBox: "rfid-lastDetected-checkBox",
        refresh: "rfid-lastDetected-refreshBtn",
        calendar: "rfid-lastDetected-calendarInput",
        optionAssetGroup: "rfid-lastDetected-optionAssetGroups",
        optionAsset: "rfid-lastDetected-optionAsset",
      };
    case "currentlyUndetected":
      return {
        exportBtn: "rfid-currentlyUndetected-exportCurrentlyUndetectedBtn",
        dataTableIds: "rfid-currentlyUndetected-dataTable",
        refresh: "rfid-currentlyUndetected-refreshBtn",
      };
    case "handheldScannedAssets":
      return {
        exportBtn: "rfid-handheldScannedAssets-exportHandheldScannedAssetsBtn",
        dataTableIds: "rfid-handheldScannedAssets-dataTable",
        refresh: "rfid-handheldScannedAssets-refreshBtn",
        calendar: "rfid-handheldScannedAssets-calendarInput",
        selectUser: "rfid-handheldScannedAssets-selectUser",
        optionUser: "rfidhandheldScannedAssets-optionUser",
      };
    case "fixedAssetMovement":
      return {
        exportBtn: "rfid-fixedAssetMovement-exportFixedAssetMovementBtn",
        dataTableIds: "rfid-fixedAssetMovement-dataTable",
        refresh: "rfid-fixedAssetMovement-refreshBtn",
        calendar: "rfid-fixedAssetMovement-calendarInput",
        selectAssetGroup: "rfid-fixedAssetMovement-selectAssetGroup",
        selectAsset: "rfid-fixedAssetMovement-selectAsset",
        optionAssetGroup: "rfid-fixedAssetMovement-optionAssetGroups",
        optionAsset: "rfid-fixedAssetMovement-optionAsset",
      };
    case "zoneMovement":
      return {
        exportBtn: "rfid-zoneMovement-exportZoneMovementBtn",
        dataTableIds: "rfid-zoneMovement-dataTable",
        refresh: "rfid-zoneMovement-refreshBtn",
        calendar: "rfid-zoneMovement-calendarInput",
        selectSite: "rfid-zoneMovement-selectSite",
        selectZone: "rfid-zoneMovement-selectZone",
        optionSite: "rfid-zoneMovement-optionSite",
        optionZone: "rfid-zoneMovement-optionZone",
      };
    case "alertRules":
      return {
        exportBtn: "rfid-alertRules-exportAlertRulesBtn",
        addBtn: "rfid-alertRules-addAlertRulesBtn",
        dropDown: "rfid-alertRules-dropDownAlertRulesBtn",
        dropDownEdit: "rfid-alertRules-dropDownAlertRulesEditBtn",
        view: "rfid-alertRules-dropDownViewBtn",
        dropDownDelete: "rfid-alertRules-dropDownAlertRulesDeleteBtn",
        dataTableIds: "rfid-alertRules-dataTable",
      };
    case "alertLog":
      return {
        exportBtn: "rfid-alertLog-exportAlertLogBtn",
        dataTableIds: "rfid-alertLog-dataTable",
        calendar: "rfid-alertLog-calendarInput",
        checkBox: "rfid-alertLog-checkBox",
        refresh: "rfid-alertLog-refreshBtn",
      };
    case "users":
      return {
        searchForm: "rfid-users-usersSearchFiled",
        addBtn: "rfid-users-addUsersBtn",
        dropDown: "rfid-users-dropDownUsersBtn",
        dataTableIds: "rfid-users-dataTable",
        edit: "rfid-users-editBtn",
        delete: "rfid-users-deleteBtn",
        resetPassword: "rfid-users-resetPasswordBtn",
        deactivate: "rfid-users-deactivateBtn",
        activate: "rfid-users-activateBtn",
      };
    case "userGroups":
      return {
        searchForm: "rfid-userGroups-userGroupsSearchFiled",
        addBtn: "rfid-userGroups-addUserGroupsBtn",
        dropDown: "rfid-userGroups-dropDownUserGroupsBtn",
        dropDownEdit: "rfid-userGroups-dropDownUserGroupsBtn",
        dropDownDelete: "rfid-userGroups-dropDownUserGroupsDeleteBtn",
        dataTableIds: "rfid-userGroups-dataTable",
      };
    case "roles":
      return {
        addBtn: "rfid-roles-addRolesBtn",
        dropDown: "rfid-roles-dropDownRolesBtn",
        dropDownEdit: "rfid-roles-dropDownRolesBtn",
        dropDownDelete: "rfid-roles-dropDownRolesDeleteBtn",
        dataTableIds: "rfid-roles-dataTable",
        view: "rfid-roles-dropDownRolesViewBtn",
      };
    case "contacts":
      return {
        searchForm: "rfid-contacts-ContactsSearchFiled",
        addBtn: "rfid-contacts-addContactsBtn",
        dropDown: "rfid-contacts-dropDownContactsBtn",
        dropDownEdit: "rfid-contacts-dropDownContactsBtn",
        dropDownDelete: "rfid-contacts-dropDownContactsDeleteBtn",
        view: "rfid-contacts-dropDownContactsViewBtn",
        dataTableIds: "rfid-contacts-dataTable",
      };
    case "auditLog":
      return {
        exportBtn: "rfid-auditLog-exportAuditLogBtn",
        dataTableIds: "rfid-auditLog-dataTable",
        calendar: "rfid-auditLog-calendarInput",
        refresh: "rfid-auditLog-refreshBtn",
        selectCategory: "rfid-auditLog-categoryBtn",
        optionSelectAll: "rfid-auditLog-All",
        optionSelectAdd: "rfid-auditLog-Add",
        optionSelectEdit: "rfid-auditLog-Edit",
        optionSelectDelete: "rfid-auditLog-Delete",
        optionSelectLogin: "rfid-auditLog-Login",
        optionSelectReport: "rfid-auditLog-Report",
        selectUser: "rfid-auditLog-selectUser",
        optionAll: "rfid-auditLog-optionAll",
        optionUser: "rfid-auditLog-optionUser",
      };
    case "add-assetGroup":
      return {
        name: "rfid-addAssetGroup-nameForm",
        select: "rfid-addAssetGroup-selectForm",
        option: "rfid-addAssetGroup-option",
        newDepartment: "rfid-addAssetGroup-addBtn",
        save: "rfid-addAssetGroup-saveBtn",
        cancel: "rfid-addAssetGroup-cancelBtn",
      };
    case "add-asset":
      return {
        assetCode: "rfid-addAsset-assetCodeForm",
        assetExternal: "rfid-addAsset-assetExternalForm",
        name: "rfid-addAsset-nameForm",
        assetGroup: "rfid-addAsset-selectForm",
        assetLocationForm: "rfid-asset-assetLocaitonForm",
        assetLocationOptions: "rfid-asset-assetLocationOption",
        assetLocationBtn: "rfid-asset-createAssetLocationBtn",
        customFieldForm: "rfid-addAsset-customFieldForm",
        customFieldOptions: "rfid-asset-customFieldOptions",
        customFieldBtn: "rfid-asset-customFieldBtn",
        option: "rfid-addAsset-optionBtn",
        create: "rfid-addAsset-createBtn",
        epcCodes: "rfid-addAsset-textArea",
        save: "rfid-addAsset-saveBtn",
        cancel: "rfid-addAsset-cancelBtn",
      };
    case "add-department":
      return {
        name: "rfid-addDepartment-nameForm",
        save: "rfid-addAsset-saveBtn",
        cancel: "rfid-addAsset-cancelBtn",
      };
    case "add-site":
      return {
        name: "rfid-addSite-nameForm",
        save: "rfid-addSite-saveBtn",
        cancel: "rfid-addSite-cancelBtn",
      };
    case "add-zone":
      return {
        name: "rfid-addSite-nameForm",
        site: "rfid-addSite-siteForm",
        create: "rfid-addSite-createBtn",
        option: "rfid-addSite-optionBtn",
        save: "rfid-addSite-saveBtn",
        cancel: "rfid-addSite-cancelBtn",
      };
    case "add-alertRule":
      return {
        name: "rifd-addAlertRule-nameForm",
        assetGroup: "rfid-addAlertRule-assetGroupFrom",
        option: "rfid-addAlertRule-optionBtn",
        asset: "rfid-addAlertRule-assetFrom",
        sites: "rfid-addAlertRule-siteFrom",
        zones: "rfid-addAlertRule-zoneFrom",
        relaySwitches: "rfid-addAlertRule-relaySwitchesFrom",
        contacts: "rfid-addAlertRule-contactsFrom",
        save: "rfid-addAlertRule-saveBtn",
        cancel: "rfid-addAlertRule-cancelBtn",
        previous: "rfid-addAlertRule-previusBtn",
        next: "rfid-addAlertRule-nextBtn",
      };
    case "add-user":
      return {
        name: "rifd-addUser-nameForm",
        surName: "rifd-addUser-SurnameForm",
        userName: "rifd-addUser-userNameForm",
        password: "rifd-addUser-passwordForm",
        confirmPassword: "rifd-addUser-confrimPasswordForm",
        userGroup: "rifd-addUser-selectForm",
        optionUserGroup: "rifd-addUser-optionBtn",
        department: "rifd-addUser-nameForm",
        optionDepartment: "rifd-addUser-selectForm",
        create: "rifd-addUser-createBtn",
        cancel: "rifd-addUser-cancelBtn",
        save: "rifd-addUser-saveBtn",
      };
    case "reset-password":
      return {
        userName: "rifd-resetPassword-userNameBtn",
        password: "rifd-resetPassword-passwordBtn",
        confirmPassword: "rifd-resetPassword-confirmPasswordBtn",
        cancel: "rifd-resetPassword-cancelBtn",
        save: "rifd-resetPassword-saveBtn",
      };
    case "add-userGroup":
      return {
        name: "rifd-addUserGroup-nameForm",
        role: "rifd-addUserGroup-roleForm",
        option: "rifd-addUserGroup-optionBtn",
        create: "rifd-addUserGroup-createBtn",
        cancel: "rifd-addUserGroup-cancelBtn",
        save: "rifd-addUserGroup-saveBtn",
        view: "rfid-addUserGroup-viewBtn",
      };
    case "add-role":
      return {
        name: "rfid-addRole-nameForm",
        displayName: "rfid-addRole-displayNameForm",
        description: "rfid-addRole-description",
        permissions: "rfid-addRole-permissions",
        cancel: "rfid-addRole-cancelBtn",
        save: "rfid-addRole-saveBtn",
      };
    case "update-assetGroup":
      return {
        name: "rfid-updateAssetGroup-nameForm",
        select: "rfid-updateAssetGroup-selectForm",
        option: "rfid-updateAssetGroup-option",
        newDepartment: "rfid-updateAssetGroup-addBtn",
        save: "rfid-updateAssetGroup-saveBtn",
        cancel: "rfid-updateAssetGroup-cancelBtn",
        edit: "rfid-updateAssetGroup-editBtn",
      };
    case "update-asset":
      return {
        assetCode: "rfid-updateAsset-assetCodeForm",
        assetExternal: "rfid-updateAsset-assetExternalForm",
        name: "rfid-updateAsset-nameForm",
        assetGroup: "rfid-updateAsset-selectForm",
        option: "rfid-updateAsset-optionBtn",
        create: "rfid-updateAsset-createBtn",
        epcCodes: "rfid-updateAsset-textArea",
        save: "rfid-updateAsset-saveBtn",
        cancel: "rfid-updateAsset-cancelBtn",
        edit: "rfid-updateAsset-editBtn",
      };
    case "update-department":
      return {
        name: "rfid-updateDepartment-nameForm",
        save: "rfid-updateDepartment-saveBtn",
        cancel: "rfid-updateDepartment-cancelBtn",
        edit: "rfid-updateDepartment-editBtn",
      };
    case "update-site":
      return {
        name: "rfid-updateSite-nameForm",
        save: "rfid-updateSite-saveBtn",
        cancel: "rfid-updateSite-cancelBtn",
        edit: "rfid-updateSite-editBtn",
      };
    case "update-zone":
      return {
        name: "rfid-updateZone-nameForm",
        save: "rfid-updateZone-saveBtn",
        cancel: "rfid-updateZone-cancelBtn",
      };
    case "update-alertRule":
      return {
        name: "rifd-updateAlertRule-nameForm",
        assetGroup: "rfid-updateAlertRule-assetGroupFrom",
        option: "rfid-updateAlertRule-optionBtn",
        asset: "rfid-updateAlertRule-assetFrom",
        sites: "rfid-updateAlertRule-siteFrom",
        zones: "rfid-updateAlertRule-zoneFrom",
        relaySwitches: "rfid-updateAlertRule-relaySwitchesFrom",
        contacts: "rfid-updateAlertRule-contactsFrom",
        cancel: "rfid-updateAlertRule-cancelBtn",
        save: "rfid-updateAlertRule-saveBtn",
        edit: "rfid-updateAlertRule-editBtn",
      };
    case "add-contact":
      return {
        name: "rfid-addContact-nameForm",
        surName: "rfid-addContact-surNameForm",
        mobile: "rfid-addContact-mobile",
        mobileNotifications: "rfid-addContact-mobileNotifications",
        email: "rfid-addContact-email",
        emailNotifications: "rfid-addContact-emailNotifications",
        cancel: "rfid-addContact-cancelBtn",
        save: "rfid-addContact-saveBtn",
      };
    case "update-department":
      return {
        name: "rfid-updateDepartment-nameForm",
        cancel: "rfid-updateDepartment-cancelBtn",
        save: "rfid-updateDepartment-saveBtn",
      };
    case "update-user":
      return {
        name: "rfid-updateUser-nameForm",
        surName: "rfid-updateUser-surNameForm",
        userName: "rfid-updateUser-userNameForm",
        userGroup: "rfid-updateUser-userGroupForm",
        department: "rfid-updateUser-departmentForm",
        create: "rfid-updateUser-createBtn",
        option: "rfid-updateUser-optionBtn",
        cancel: "rfid-updateUser--cancelBtn",
        save: "rfid-updateUser-saveBtn",
        edit: "rfid-updateUser-editBtn",
        view: "rfid-updateUser-viewBtn",
      };

    case "update-userGroup":
      return {
        name: "rfid-updateUserGroup-nameForm",
        role: "rifd-updateUserGroup-roleForm",
        option: "rifd-updateUserGroup-optionBtn",
        create: "rifd-updateUserGroup-createBtn",
        cancel: "rfid-updateUserGroup-cancelBtn",
        save: "rfid-updateUserGroup-saveBtn",
        edit: "rfid-updateUserGroup-editBtn",
        view: "rfid-updateUserGroup-viewBtn",
      };
    case "update-role":
      return {
        name: "rfid-updateRole-nameForm",
        displayName: "rfid-updateRole-displayNameForm",
        description: "rfid-updateRole-description",
        permissions: "rfid-updateRole-permissions",
        cancel: "rfid-updateRole-cancelBtn",
        save: "rfid-updateRole-saveBtn",
        edit: "rfid-updateRole-editBtn",
      };
    case "update-contact":
      return {
        name: "rfid-updateContact-nameForm",
        surName: "rfid-updateContact-surNameForm",
        mobile: "rfid-updateContact-mobile",
        mobileNotifications: "rfid-updateContact-mobileNotifications",
        email: "rfid-updateContact-email",
        emailNotifications: "rfid-updateContact-emailNotifications",
        cancel: "rfid-updateContact-cancelBtn",
        save: "rfid-updateContact-saveBtn",
        edit: "rfid-updateContact-editBtn",
      };
    case "customFields":
      return {
        dropDownEdit: "rfid-customFields-editCustomFieldsBtn",
        dropDownDelete: "rfid-customFields-deleteCustomFieldsBtn",
        addBtn: "rfid-addCustomField-addCustomFieldBtn",
        exportBtn: "rfid-addCustomField-exportCustomFieldBtn",
        view: "rfid-customFields-viewCustomFieldsBtn",
      };
    case "update-customField":
      return {
        cancel: "rfid-updateCustomField-cancelBtn",
        save: "rfid-updateCustomField-saveBtn",
        edit: "rfid-updateCustomField-editBtn",
      };
    case "add-customField":
      return {
        cancel: "rfid-addCustomField-cancelAddCustomFieldBtn",
        save: "rfid-addCustomField-saveAddCustomFieldBtn",
      };
    case "assetJourney":
      return {
        exportBtn: "rfid-assetJourney-exportAssetJourneyBtn",
        refresh: "rfid-assetJourney-refreshAssetJourneyBtn",
        selectAssetGroup: "rfid-assetJourney-selectAssetGroup",
        selectAsset: "rfid-assetJourney-selectAsset",
        calendar: "rfid-assetJourney-calendarInput",
      };
    case "mobileAssetMovement":
      return {
        exportBtn: "rfid-mobileAssetMovement-exportMobileAssetMovementBtn",
        calendar: "rfid-mobileAssetMovement-calendarInput",
        selectAssetGroup: "rfid-mobileAssetMovement-selectAsset",
        selectAsset: "rfid-mobileAssetMovement-selectAsset",
        refresh: "rfid-mobileAssetMovement-refreshMobileAssetMovementBtn",
      };
    case "vehicleMovement":
      return {
        exportBtn: "rfid-vehicleMovement-exportVehicleMovementBtn",
        calendar: "rfid-vehicleMovement-calendarInput",
        refresh: "rfid-vehicleMovement-refreshVehicleMovementBtn",
        selectVehicle: "rfid-vehicleMovement-selectVehicle",
      };
    case "update-userGroup":
      return {
        name: "rfid-updateUserGroup-nameForm",
        role: "rifd-updateUserGroup-roleForm",
        option: "rifd-updateUserGroup-optionBtn",
        create: "rifd-updateUserGroup-createBtn",
        cancel: "rfid-updateUserGroup-cancelBtn",
        save: "rfid-updateUserGroup-saveBtn",
      };
    case "update-role":
      return {
        name: "rfid-updateRole-nameForm",
        displayName: "rfid-updateRole-displayNameForm",
        description: "rfid-updateRole-description",
        permissions: "rfid-updateRole-permissions",
        cancel: "rfid-updateRole-cancelBtn",
        save: "rfid-updateRole-saveBtn",
      };
    case "update-contact":
      return {
        name: "rfid-updateContact-nameForm",
        surName: "rfid-updateContact-surNameForm",
        mobile: "rfid-updateContact-mobile",
        mobileNotifications: "rfid-updateContact-mobileNotifications",
        email: "rfid-updateContact-email",
        emailNotifications: "rfid-updateContact-emailNotifications",
        cancel: "rfid-updateContact-cancelBtn",
        save: "rfid-updateContact-saveBtn",
      };
    case "mobileReaders":
      return {
        exportBtn: "rfid-mobileReaders-exportMobileReadersBtn",
        searchForm: "rfid-mobileReaders-MobileReadersForm",
      };
    case "customFields":
      return {
        dropDownEdit: "rfid-customFields-editCustomFieldsBtn",
        addBtn: "rfid-addCustomField-addCustomFieldBtn",
        exportBtn: "rfid-addCustomField-exportCustomFieldBtn",
      };
    case "add-customField":
      return {
        cancel: "rfid-addCustomField-cancelAddCustomFieldBtn",
        save: "rfid-addCustomField-saveAddCustomFieldBtn",
      };
    case "assetJourney":
      return {
        exportBtn: "rfid-assetJourney-exportAssetJourneyBtn",
        refresh: "rfid-assetJourney-refreshAssetJourneyBtn",
        selectAssetGroup: "rfid-assetJourney-selectAssetGroup",
        selectAsset: "rfid-assetJourney-selectAsset",
        calendar: "rfid-assetJourney-calendarInput",
      };
    case "mobileAssetMovement":
      return {
        exportBtn: "rfid-mobileAssetMovement-exportMobileAssetMovementBtn",
        calendar: "rfid-mobileAssetMovement-calendarInput",
        selectAssetGroup: "rfid-mobileAssetMovement-selectAsset",
        selectAsset: "rfid-mobileAssetMovement-selectAsset",
        refresh: "rfid-mobileAssetMovement-refreshMobileAssetMovementBtn",
      };
    case "vehicleMovement":
      return {
        exportBtn: "rfid-vehicleMovement-exportVehicleMovementBtn",
        calendar: "rfid-vehicleMovement-calendarInput",
        refresh: "rfid-vehicleMovement-refreshVehicleMovementBtn",
        selectVehicle: "rfid-vehicleMovement-selectVehicle",
      };
    case "profile":
      return {
        cancel: "rfid-profile-cancelBtn",
        save: "rfid-profile.saveBtn",
        name: "rfid-profile-nameForm",
        surname: "rfid-profile-surnameForm",
        userNamePersonalDetails: "rfid-profile-userNameFormPersonalDetails",
        userNamePSW: "rfid-profile-userNameFormPSW",
        userGroup: "rfid-profile-userGroupForm",
        department: "rfid-profile-departmentForm",
        optionDepartment: "rfid-profile-option",
        create: "rfid-profile-createBtn",
        next: "rfid-profile-nextBtn",
        previous: "rfid-profile-previousBtn",
        password: "rfid-profile-passwordForm",
        confirmPassword: "rfid-profile-confirmPasswordForm",
        personalDetails: "rfid-profile-personalDetails",
        resetPsw: "rfid-profile-resetPsw",
      };
    case "assetAudit-journey":
      return {
        exportBtn: "rfid-assetAuditJourney-exportBtn",
        searchForm: "rfid-assetAuditJourney-searchForm",
        refresh: "rfid-assetAuditJourney-refreshBtn",
      };
    case "assetLocation":
      return {
        searchForm: "rfid-assetLocation-SearchField",
        addBtn: "rfid-assetLocation-addBtn",
      };
    case "add-assetLocation":
      return {
        name: "rfid-addAssetLocation-nameForm",
        location: "rfid-addAssetLocation-locationForm",
        save: "rfid-addAssetLocation-saveBtn",
        cancel: "rfid-addAssetLocation-cancelBtn",
        edit: "rfid-addAssetLocation-editBtn",
        option: "rfid-addAssetLocation-optionBtn",
        newLocation: "rfid-addAssetLocation-newLocationBtn",
      };
    case "update-assetLocation":
      return {
        name: "rfid-updateAssetLocation-nameForm",
        location: "rfid-updateAssetLocation-locationForm",
        save: "rfid-updateAssetLocation-saveBtn",
        cancel: "rfid-updateAssetLocation-cancelBtn",
        edit: "rfid-updateAssetLocation-editBtn",
        option: "rfid-updateAssetLocation-optionBtn",
        newLocation: "rfid-updateAssetLocation-newLocationBtn",
      };
    case "killed-assets":
      return {
        exportBtn: "rfid-killedAssets-exportBtn",
        calendar: "rfid-killedAssets-calendar",
        refresh: "rfid-killedAssets-refreshBtn",
      };
    case "sms-consumption":
      return {
        exportBtn: "rfid-smsConsumption-exportBtn",
        calendar: "rfid-smsConsumption-calendar",
        refresh: "rfid-smsConsumption-refreshBtn",
      };
    case "user-settings":
      return {
        cancel: "rfid-userSettings-cancelBtn",
        save: "rfid-userSettings-saveBtn",
        logoImg: "rfid-userSettings-logoImg",
      };
    case "floor-plan":
      return {
        exportBtn: "rfid-floorPlan-exportBtn",
        refresh: "rfid-floorPlan-refreshBtn",
      };
    case "view-floor-plan":
      return {
        cancel: "rfid-floorPlan-cancelBtn",
      };
  }
};
