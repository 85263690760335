import * as type from "../types";
import {
  getContactsService,
  saveContactService,
  deleteContactService,
} from "../../network/service/ContactService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchContacts = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.CONTACTS_FETCH_REQUEST,
      });
      const response = await dispatch(getContactsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.CONTACTS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.CONTACTS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.CONTACTS_FETCH_FAILURE,
      });
    }
  };
};

export const saveContact = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.CONTACT_SAVE_REQUEST,
      });
      const response = await dispatch(saveContactService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.CONTACT_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.CONTACT_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.CONTACT_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteContact = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.CONTACT_DELETE_REQUEST,
      });
      const response = await dispatch(deleteContactService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.CONTACT_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.CONTACT_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.CONTACT_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
