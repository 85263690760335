import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";

import { saveSite } from "../../../api/redux/action/SiteActions";
import useNotification from "../../../components/common/ResultNotifier";
import AddSiteForm from "./AddSiteForm";
import { useHistory } from "react-router-dom";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";
import { hasPermission } from "../../layout/Constants";
import AddFooterSection from "../../../components/common/AddFooterSection";
import { validateInput } from "../../../components/RequiredFieldRule";

const AddSite = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [prompt, setDirty, setPristine] = UnsavedChanges();

  let history = useHistory();

  const { error, requestStatus, user, language, englishLang, selectedTenant } =
    useSelector((store) => ({
      error: store.siteReducer.error,
      requestStatus: store.siteReducer.requestStatus,
      user: store.authReducer.user,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
      selectedTenant: store.authReducer.selectedTenant,
    }));
  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;
  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      form.setFieldsValue({
        siteId: props.location.state.selectedRow.siteId,
        name: props.location.state.selectedRow.name,
        location: props.location.state.selectedRow.location,
      });
    }
  }, []);

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      return history.push("/sites");
    }
  }, [requestStatus]);

  const handleSave = (values) => {
    const formData = {
      name: validateInput(form.getFieldValue().name),
      location: form.getFieldValue().location,
    };
    setPristine();
    dispatch(saveSite(formData, form.getFieldValue().siteId));
  };

  const handleCancel = () => {
    history.push("/sites");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_SITES");
  }

  const footer = (
    <AddFooterSection
      language={language}
      viewOption={props.location.state}
      handleCancel={handleCancel}
      form={form}
      updatePath={"/update-site"}
      handleEditingPermission={handleEditingPermission}
    />
  );

  return (
    <Card
      title={language?.SITE?.value ?? englishLang?.SITE?.value}
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddSiteForm
            handleSave={handleSave}
            form={form}
            footer={footer}
            setDirty={setDirty}
            readOnly={props.location.state}
            language={language}
          />
        </Col>
      </Row>
      <ResultNotifier />
      {prompt}
    </Card>
  );
};

export default AddSite;
