import { request } from "../axios/request";

export const getMobileReadersService = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/readers?setup=mobile", getState(), {
        method: "GET",
        params,
      })
    );
  };
};

export const getAllMobileReadersService = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/readers?setup=mobile", getState(), {
        method: "GET",
        params,
      })
    );
  };
};

export const saveMobileReaderService = (data, id = 0) => {
  if (id > 0) {
    return async (dispatch, getState) => {
      return dispatch(
        request("/readers/" + id + "?setup=mobile", getState(), {
          method: "PUT",
          data,
        })
      );
    };
  }
  return async (dispatch, getState) => {
    return dispatch(
      request("/readers?setup=mobile", getState(), {
        method: "POST",
        data,
      })
    );
  };
};

export const deleteMobileReaderService = (id = 0) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/readers/" + id + "?setup=mobile", getState(), {
        method: "DELETE",
      })
    );
  };
};
