import * as type from "../types";
import {
  getUsersService,
  saveUserService,
  deleteUserService,
  resetPasswordService,
  activateService,
  getPreferredColumnsService,
  savePreferredColumnsService,
  savePreferredLogoService,
  getPreferredLogoService,
  deletePreferredLogoService,
  changePasswordService,
} from "../../network/service/UserService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";
import axios from "axios";

export const fetchUsers = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.USERS_FETCH_REQUEST,
      });
      const response = await dispatch(getUsersService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.USERS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.USERS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.USERS_FETCH_FAILURE,
      });
    }
  };
};

export const saveUser = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.USER_SAVE_REQUEST,
      });
      const response = await dispatch(saveUserService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.USER_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.USER_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.USER_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteUser = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.USER_DELETE_REQUEST,
      });
      const response = await dispatch(deleteUserService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.USER_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.USER_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.USER_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const resetPassword = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.RESET_PASSWORD_REQUEST,
      });
      const response = await dispatch(resetPasswordService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.RESET_PASSWORD_SUCCESS,
        });
      } else {
        dispatch({
          type: type.RESET_PASSWORD_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.RESET_PASSWORD_FAILURE,
        payload: ex,
      });
    }
  };
};

export const changePassword = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.CHANGE_PASSWORD_REQUEST,
      });
      const response = await dispatch(changePasswordService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.CHANGE_PASSWORD_SUCCESS,
        });
      } else {
        dispatch({
          type: type.CHANGE_PASSWORD_FAILURE,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.CHANGE_PASSWORD_FAILURE,
        payload: ex,
      });
    }
  };
};

export const activateUser = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.USER_ACTIVATE_REQUEST,
      });
      const response = await dispatch(activateService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.USER_ACTIVATE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.USER_ACTIVATE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.USER_ACTIVATE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const fetchPreferredColumns = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.PREFERRED_COLUMNS_HANDHELD_FETCH_REQUEST,
      });
      const response = await dispatch(getPreferredColumnsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.PREFERRED_COLUMNS_HANDHELD_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.PREFERRED_COLUMNS_HANDHELD_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.PREFERRED_COLUMNS_HANDHELD_FETCH_FAILURE,
      });
    }
  };
};
export const savedPreferredLogo = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.USER_LOGO_REQUEST,
      });
      const response = await dispatch(savePreferredLogoService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.USER_LOGO_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: type.USER_LOGO_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.USER_LOGO_FAILURE,
        payload: ex,
      });
    }
  };
};

export const savePreferredColumns = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.PREFERRED_COLUMNS_HANDHELD_SAVE_REQUEST,
      });
      const response = await dispatch(savePreferredColumnsService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.PREFERRED_COLUMNS_HANDHELD_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.PREFERRED_COLUMNS_HANDHELD_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.PREFERRED_COLUMNS_HANDHELD_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const getPreferredLogo = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.GET_USER_LOGO_REQUEST,
      });
      const response = await dispatch(getPreferredLogoService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.GET_USER_LOGO_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.GET_USER_LOGO_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatch({
        type: type.GET_USER_LOGO_FAILURE,
      });
    }
  };
};

export const getLoginPreferredLogo = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.GET_USER_LOGO_LOGIN_REQUEST,
      });
      const response = await dispatch(getPreferredLogoService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.GET_USER_LOGO_LOGIN_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.GET_USER_LOGO_LOGIN_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatch({
        type: type.GET_USER_LOGO_LOGIN_FAILURE,
      });
    }
  };
};

export const deletePreferredLogo = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.DELETE_USER_LOGO_REQUEST,
      });
      const response = await dispatch(deletePreferredLogoService());
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.DELETE_USER_LOGO_SUCCESS,
        });
      } else {
        dispatch({
          type: type.DELETE_USER_LOGO_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.DELETE_USER_LOGO_FAILURE,
        payload: ex,
      });
    }
  };
};

export const removeLogoFromReducer = () => {
  return async (dispatch) => {
    dispatch({
      type: type.DELETE_USER_LOGO_SUCCESS,
    });
  };
};

export const validateToken = (params = null) => {
  return async (dispatch) => {
    dispatch({
      type: type.TOKEN_VALID_REQUEST,
    });

    const config = {
      method: "GET",
      url: "/api/web/users/validate-password-token",
      withCredentials: true,
      params,
    };
    return axios(config)
      .then((response) => {
        dispatch({
          type: type.TOKEN_VALID_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: type.TOKEN_VALID_FAILURE,
          payload: error.response.data.message,
        });
      });
  };
};
