import * as type from "../types";
import {
  deleteRoleService,
  getRolesService,
  saveRoleService,
} from "../../network/service/RoleService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchRoles = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ROLES_FETCH_REQUEST,
      });
      const response = await dispatch(getRolesService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ROLES_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ROLES_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ROLES_FETCH_FAILURE,
      });
    }
  };
};

export const saveRole = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ROLE_SAVE_REQUEST,
      });
      const response = await dispatch(saveRoleService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ROLE_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.ROLE_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ROLE_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteRole = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ROLE_DELETE_REQUEST,
      });
      const response = await dispatch(deleteRoleService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ROLE_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.ROLE_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ROLE_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
