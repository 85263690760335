import * as type from "../types";
import { RequestState } from "./RequestState";
const INITIAL_STATE = {
  floorPlans: [],
  data: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.GET_FLOOR_PLAN_REQUEST:
      return {
        ...state,
        floorPlans: [],
        error: {},
        loading: true,
      };
    case type.GET_FLOOR_PLAN_SUCCESS:
      return {
        ...state,
        floorPlans: action.payload,
        error: {},
        loading: false,
      };
    case type.GET_FLOOR_PLAN_FAILURE:
      return {
        ...state,
        floorPlans: [],
        error: action.payload,
        loading: false,
      };
    case type.FETCH_SINGLE_ANTENNA_REQUEST:
      return {
        ...state,
        error: {},
        data: {},
        loading: true,
      };
    case type.FETCH_SINGLE_ANTENNA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: {},
        loading: false,
      };
    case type.FETCH_SINGLE_ANTENNA_FAILURE:
      return {
        ...state,
        data: {},
        error: action.payload,
        loading: false,
      };
    case type.FETCH_SINGLE_FIXEDREADERS_REQUEST:
      return {
        ...state,
        error: {},
        data: {},
        loading: true,
      };
    case type.FETCH_SINGLE_FIXEDREADERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case type.FETCH_SINGLE_FIXEDREADERS_FAILURE:
      return {
        ...state,
        data: {},
        error: action.payload,
        loading: false,
      };
    case type.FETCH_IPRELAYCARD_REQUEST:
      return {
        ...state,
        error: {},
        data: {},
        loading: true,
      };
    case type.FETCH_IPRELAYCARD_SUCCESS:
      return {
        ...state,
        error: {},
        data: action.payload,
        loading: false,
      };
    case type.FETCH_IPRELAYCARD_FAILURE:
      return {
        ...state,
        data: {},
        error: action.payload,
        loading: false,
      };
    case type.FETCH_SINGLE_GATEWAY_REQUEST:
      return {
        ...state,
        error: {},
        data: {},
        loading: true,
      };
    case type.FETCH_SINGLE_GATEWAY_SUCCESS:
      return {
        ...state,
        error: {},
        data: action.payload,
        loading: false,
      };
    case type.FETCH_SINGLE_GATEWAY_FAILURE:
      return {
        ...state,
        data: {},
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default reducer;
