import * as type from "../types";
import {
  getAssetGroupsService,
  saveAssetGroupService,
  deleteAssetGroupService,
  getAllAssetGroupsService,
  getAssetGroupWithCustomFields,
} from "../../network/service/AssetGroupService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchAssetGroups = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ASSETGROUPS_FETCH_REQUEST,
      });
      const response = await dispatch(getAssetGroupsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ASSETGROUPS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ASSETGROUPS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ASSETGROUPS_FETCH_FAILURE,
      });
    }
  };
};

export const fetchAssetGroupWithCustomFields = (id = 0) => {
  return async (dispatch) => {
    try {
      if (id > 0) {
        dispatch({
          type: type.ASSETGROUPS_WITH_FIELDS_FETCH_REQUEST,
        });
        const response = await dispatch(getAssetGroupWithCustomFields(id));

        if (response.status === ResponseStatus.OK) {
          dispatch({
            type: type.ASSETGROUPS_WITH_FIELDS_FETCH_SUCCESS,
            payload: response.data,
          });
        } else {
          dispatch({
            type: type.ASSETGROUPS_WITH_FIELDS_FETCH_FAILURE,
            payload: response.data,
          });
        }
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ASSETGROUPS_WITH_FIELDS_FETCH_FAILURE,
      });
    }
  };
};

export const fetchAllAssetGroups = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_ASSETGROUPS_FETCH_REQUEST,
      });
      const response = await dispatch(getAllAssetGroupsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_ASSETGROUPS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_ASSETGROUPS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_ASSETGROUPS_FETCH_FAILURE,
      });
    }
  };
};

export const saveAssetGroup = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ASSETGROUP_SAVE_REQUEST,
      });
      const response = await dispatch(saveAssetGroupService(data, id));

      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ASSETGROUP_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.ASSETGROUP_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ASSETGROUP_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteAssetGroup = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ASSETGROUP_DELETE_REQUEST,
      });
      const response = await dispatch(deleteAssetGroupService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ASSETGROUP_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.ASSETGROUP_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ASSETGROUP_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
