import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Card, Tooltip, Skeleton, Space, Slider, Button, Result } from "antd";
import { InfoCircleOutlined, FileTextOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Map from "./Map";
import { ASSET_LAST_SEEN_AGE } from "../../../../api/graphQL/queries";
import { Empty } from "antd";

const LastSeenSince = (props) => {
  const history = useHistory();
  const [sliderStart, setSliderStart] = useState(0);
  const [sliderEnd, setSliderEnd] = useState(40);
  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));
  const { loading, error, data, stopPolling, startPolling } = useQuery(
    ASSET_LAST_SEEN_AGE,
    {
      variables: {
        // ...(selectedAssetGroup) && {variables:{assetGroupIds:[selectedAssetGroup]}} ,
        minAgeInDays: sliderStart,
        maxAgeInDays: sliderEnd,
      },
      pollInterval: 60000,
      fetchPolicy: "network-only",
    }
  );

  // useEffect(() => {
  // 	startPolling(60000);

  // 	return () => {
  // 	  stopPolling();
  // 	};
  //   }, [sliderStart,sliderEnd,stopPolling,startPolling])

  if (loading)
    return (
      <div style={{ background: "#fff", padding: "20px" }}>
        <Skeleton active />
      </div>
    );
  // if (error) return `Error!`;
  if (error) {
    // window.location.reload();
    return (
      <Result
        status="warning"
        title={
          language?.ERROR_FETCHING_DATA?.value ??
          englishLang?.ERROR_FETCHING_DATA?.value
        }
      />
    );
  }

  const marks = {
    0: "",
    100: "",
    200: "",
    300: "",
    400: "",
  };

  function formatter(value) {
    if (value == 0) {
      return "Today";
    } else if (value == 1) {
      return "Yesterday";
    } else {
      return `${value} days`;
    }
  }

  const handleSliderChange = (event) => {
    const start = event[0];
    const end = event[1];

    setSliderStart(start);
    setSliderEnd(end);
  };

  const tooltip = (
    <Tooltip
      title={
        <>
          <p>
            {props.language?.MAP_DIS_DETECTED_AND_COUNT_OCCUR_HEATMAP?.value ??
              englishLang?.MAP_DIS_DETECTED_AND_COUNT_OCCUR_HEATMAP?.value}
          </p>
        </>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );

  const setDateToString = (date) => {
    return (
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
    );
  };

  const calculateDate = (dateOffset) => {
    let date = new Date(
      new Date().getTime() - dateOffset * 24 * 60 * 60 * 1000
    );
    return date;
  };

  const handleButtonClick = () => {
    var start = setDateToString(calculateDate(sliderEnd));
    var end = setDateToString(calculateDate(sliderStart));
    history.push("/lastDetected?start=" + start + "&end=" + end);
  };

  const button = (
    <>
      <Tooltip
        title={
          props.language?.GO_TO_LAST_DETECTED_REPORT?.value ??
          englishLang?.GO_TO_LAST_DETECTED_REPORT?.value
        }
      >
        <Button
          id="rfid-dashboard-currentAssetDetection-assetsBtn"
          type="circle"
          size="small"
          icon={<FileTextOutlined />}
          onClick={handleButtonClick}
        />
      </Tooltip>
    </>
  );

  // check if we got data from api response
  function CheckForData() {
    if (data && data.assetLastDetectedAge.length > 0) {
      return <Map data={data} />;
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Card
      // bordered
      title={
        props.language?.LAST_DETECTED_SINCE?.value ??
        englishLang?.LAST_DETECTED_SINCE?.value
      }
      extra={
        <Space>
          <Slider
            style={{ width: "300px" }}
            id="rfid-dashboard-currentAssetDetection-assetsSlider"
            range
            marks={marks}
            min={0}
            max={400}
            defaultValue={[sliderStart, sliderEnd]}
            formatter={formatter}
            onAfterChange={handleSliderChange}
          />
          <br />
          <br />
          {button}
          {tooltip}
        </Space>
      }
    >
      {CheckForData()}
    </Card>
  );
};

export default LastSeenSince;
