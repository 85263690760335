import { Result } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const NotAuthorized = () => {
  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));
  return (
    <Result
      status="403"
      title="403"
      subTitle={
        language?.NO_PERMISSION?.value ?? englishLang?.NO_PERMISSION?.value
      }
      // extra={<Button type="primary">Back Home</Button>}
    />
  );
};

export default NotAuthorized;
