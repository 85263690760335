import * as type from "../types";
import { RequestState } from "./RequestState";

const INITIAL_STATE = {
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  savedAssetSuccess: false,
  savedAsset: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.POST_UPLOAD_ASSETS_REQUEST:
      return {
        ...state,
        error: {},
        requestStatus: RequestState.PENDING,
        loading: true,
        savedAssetSuccess: false,
        savedAsset: {},
      };
    case type.POST_UPLOAD_ASSETS_SUCCESS:
      return {
        ...state,
        error: {},
        requestStatus: RequestState.SUCCESS,
        loading: false,
        savedAssetSuccess: true,
        savedAsset: action.payload,
      };
    case type.POST_UPLOAD_ASSETS_FAILURE:
      return {
        ...state,
        error: action.payload,
        requestStatus: RequestState.FAILURE,
        loading: false,
        savedAssetSuccess: false,
        savedAsset: {},
      };
    case type.RESET_UPLOAD_STORE:
      return {
        ...state,
        error: {},
        requestStatus: RequestState.EMPTY,
        loading: false,
        savedAssetSuccess: false,
        savedAsset: {},
      };
    default:
      return state;
  }
};

export default reducer;
