import ImgCrop from "antd-img-crop";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  savedPreferredLogo,
  getPreferredLogo,
  deletePreferredLogo,
} from "../../../api/redux/action/UserActions";
import useNotification from "../../../components/common/ResultNotifier";

import {
  Card,
  Col,
  Row,
  Upload,
  Divider,
  Space,
  Button,
  Modal,
  Spin,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "./style.css";

import { ElementIDS } from "../../../components/common/ElementsIDs";

const UserSettings = () => {
  const [isButtonLoading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageThumbUrl, setImageThumbUrl] = useState("");
  const [imageName, setImageName] = useState("");

  let history = useHistory();
  const dispatch = useDispatch();

  const { error, requestStatus, savedLogoSuccess, language, englishLang } =
    useSelector((store) => ({
      error: store.userReducer.error,
      requestStatus: store.userReducer.requestStatus,
      savedLogoSuccess: store.userReducer.savedLogoSuccess,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
    }));
  let langLogoSuccess =
    language?.LOGO_UPLOADED_SUCC?.value ??
    englishLang?.LOGO_UPLOADED_SUCC?.value;
  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langLogoSuccess
  );

  const onChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
    }
    if (info.file.status === "done") {
      setLoading(false);
      setImageName(info.file.name);
    }
  };

  const onUpload = ({ file, onSuccess }) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(file);
    reader.onload = (...args) => {
      const fileContents = reader.result;
      setImageThumbUrl(fileContents);
      onSuccess("done", file);
    };
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSave = async () => {
    let customLogo = {
      name: imageName,
      image: imageThumbUrl,
    };
    await dispatch(savedPreferredLogo(customLogo));
    dispatch(getPreferredLogo());

    setImageThumbUrl("");
  };

  const handleResetLogo = () => {
    dispatch(deletePreferredLogo());
    setImageThumbUrl("");
  };

  const footer = (
    <>
      <Divider />

      <Row justify="center">
        <Space>
          <Button
            id={ElementIDS().cancel}
            onClick={handleCancel}
            type="dashed"
            htmlType="button"
          >
            {language?.CANCEL?.value ?? englishLang?.CANCEL?.value}
          </Button>
          <Button id={ElementIDS().save} onClick={handleSave} type="primary">
            {language?.SAVE?.value ?? englishLang?.SAVE?.value}
          </Button>
        </Space>
      </Row>
    </>
  );

  const uploadButton = (
    <div>
      {isButtonLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        {language?.UPLOAD?.value ?? englishLang?.UPLOAD?.value}
      </div>
    </div>
  );

  return (
    <>
      <Card
        title={
          language?.USER_SETTINGS?.value ?? englishLang?.USER_SETTINGS?.value
        }
        loading={isLoading}
      >
        <Row justify="center">
          <Col
            className="user_settings"
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={16}
          >
            <h2 className="user_settings_header">
              {language?.UPLOAD_YOUR_LOGO?.value ??
                englishLang?.UPLOAD_YOUR_LOGO?.value}
            </h2>
            <ImgCrop>
              <Upload
                customRequest={onUpload}
                name="photos"
                id={ElementIDS().logoImg}
                className="image_container"
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                showUploadList={false}
                onChange={onChange}
                onPreview={onPreview}
                maxCount={1}
              >
                {imageThumbUrl ? (
                  <img
                    src={imageThumbUrl}
                    alt={language?.AVATAR?.value ?? englishLang?.AVATAR?.value}
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
            <Button onClick={handleResetLogo}>
              {language?.USE_DEFAULT_LOGO?.value ??
                englishLang?.USE_DEFAULT_LOGO?.value}
            </Button>
          </Col>
        </Row>
        <ResultNotifier />
        {footer}
        <Modal
          centered
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={null}
          width={700}
        >
          <img
            alt={
              language?.CUSTOM_LOGO?.value ?? englishLang?.CUSTOM_LOGO?.value
            }
            style={{
              width: "100%",
            }}
          />
        </Modal>
      </Card>
    </>
  );
};

export default UserSettings;
