import * as type from "../types";
import {
  getAntennasService,
  saveAntennaService,
  deleteAntennaService,
  getAllAntennasService,
  getSingleAntennasService,
} from "../../network/service/AntennaService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchAntennas = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ANTENNAS_FETCH_REQUEST,
      });
      const response = await dispatch(getAntennasService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ANTENNAS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ANTENNAS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ANTENNAS_FETCH_FAILURE,
      });
    }
  };
};

export const fetchSingleAntenna = (id = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.FETCH_SINGLE_ANTENNA_REQUEST,
      });
      const response = await dispatch(getSingleAntennasService(id));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.FETCH_SINGLE_ANTENNA_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.FETCH_SINGLE_ANTENNA_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.FETCH_SINGLE_ANTENNA_FAILURE,
        payload: ex,
      });
    }
  };
};

export const fetchAllAntennas = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_ANTENNAS_FETCH_REQUEST,
      });
      const response = await dispatch(getAllAntennasService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_ANTENNAS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_ANTENNAS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_ANTENNAS_FETCH_FAILURE,
      });
    }
  };
};

export const saveAntenna = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ANTENNA_SAVE_REQUEST,
      });
      const response = await dispatch(saveAntennaService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ANTENNA_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.ANTENNA_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ANTENNA_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteAntenna = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ANTENNA_DELETE_REQUEST,
      });
      const response = await dispatch(deleteAntennaService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ANTENNA_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.ANTENNA_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ANTENNA_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
