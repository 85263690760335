import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { notification, Form, Input, Button, Alert } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { forgotPassword } from "../../api/redux/action/AuthActions";
import { fetchTranslations } from "../../api/redux/action/LangActions";

import "./Style.css";
import rfidLogo from "../../assets/images/rfid_logo_lhtBlue.png";

import { getVersionNumber } from "../layout/Constants";
import "antd/dist/antd.min.css";
import { getLoginPreferredLogo } from "../../api/redux/action/UserActions";

const ForgotPassword = (props) => {
  const [defaultLogo, setDefaultLogo] = useState();

  const history = useHistory();
  const dispatch = useDispatch();

  const { loading, error, preferredLogo, requestStatus } = useSelector(
    (store) => ({
      loading: store.authReducer.loading,
      error: store.authReducer.error,
      preferredLogo: store.userReducer.preferredLogo,
      requestStatus: store.authReducer.requestStatus,
    })
  );

  useEffect(() => {
    if (preferredLogo !== undefined) {
      if (preferredLogo !== undefined) {
        setDefaultLogo(preferredLogo.logoUrl);
      } else {
        setDefaultLogo(rfidLogo);
      }
    }
  }, [preferredLogo]);

  useEffect(() => {
    dispatch(getLoginPreferredLogo());
  }, []);

  useEffect(() => {
    dispatch(fetchTranslations());
  }, []);

  useEffect(() => {
    if (error) {
      notification.open({
        type: "error",
        message: "Error!",
        description: error,
        duration: 10,
      });
    }
  }, [error]);

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      notification.open({
        type: "success",
        message: "Success!",
        description:
          "An email has been sent to the supplied email address.  Follow the instruction in the email to reset your password",
        duration: 10,
      });
    }
  }, [requestStatus]);

  const onFinish = (values) => {
    dispatch(forgotPassword(values));
  };

  return (
    <div className="login">
      <img
        className="rfid_logo"
        src="/Images/rfid_logo_lhtBlue.png"
        alt="RFID Logo"
      />
      <img
        className="rfid_logo_mobile"
        src="/Images/rfid_logo_white.png"
        alt="RFID Logo White"
      />
      <div className="left_side_screen">
        <img
          className="regular_image"
          src="/Images/left-graphic.png"
          alt="RFID Image"
        />
      </div>
      <div className="right_side_screen">
        <div className="login_content">
          <h1>Forgot Password</h1>
          <h3>
            To reset your password, enter the username you use to sign in to
            RFID application
          </h3>
          <Form
            name="normal_login"
            layout="horizontal"
            initialValues={{
              "remember-me": true,
            }}
            className="login_form"
            onFinish={onFinish}
          >
            <Form.Item className="username_form_container" name="username">
              <Input
                className="login_input"
                prefix={<UserOutlined style={{ fontSize: 13 }} />}
                placeholder="Username"
              />
            </Form.Item>

            <div className="login_button_container">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="forgot-form-button"
                  block
                  loading={loading}
                >
                  Send reset link
                </Button>
              </Form.Item>
            </div>
          </Form>
          <div>
            <div className="handsOn_Logo_bottom">
              <a
                href="https://www.handsonsystems.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./Images/handson-logo.png" alt="Handson" />
              </a>
            </div>
            <div className="copyright">
              <p className="copyright_description">
                © {new Date().getFullYear()} All rights reserved. RFID is a
                registered trademark of HandsOn Systems Ltd.
              </p>
              <strong>V {getVersionNumber()}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
