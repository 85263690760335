import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Divider,
  Space,
  Tooltip,
  Row,
  DatePicker,
  Select,
  Button,
} from "antd";
import { RedoOutlined } from "@ant-design/icons";
import moment from "moment-timezone";

import useDataTable from "../../../components/common/DataTable";
import {
  fetchReport,
  fetchAllReport,
  resetReport,
} from "../../../api/redux/action/ReportActions";
import useNotification from "../../../components/common/ResultNotifier";
import { fetchAssets } from "../../../api/redux/action/AssetActions";
import { fetchAssetGroups } from "../../../api/redux/action/AssetGroupActions";
import Header from "../../../components/common/ReportHeader";
import { toTimestamp } from "../../layout/Constants";
import { ElementIDS } from "../../../components/common/ElementsIDs";

const dateFormat = "DD-MM-YYYY";
const { Option } = Select;

const { RangePicker } = DatePicker;

function FixedAssetMovement() {
  const today = new Date();
  const todayStr =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  const dispatch = useDispatch();

  const [exportAllSuccess, setExportAllSuccess] = useState(false);
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [flattenedData, setFlattenedData] = useState([]);

  const [options, setOptions] = useState([]);
  const [assetOptions, setAssetOptions] = React.useState([]);
  const [refresh, setRefresh] = useState(false);
  const [sortedAssetGroups, setSortedAssetGroups] = useState([]);

  const [filter, setFilter] = useState({
    fromDate: todayStr,
    toDate: todayStr,
    assetId: null,
    setup: "fixed",
  });

  const {
    user,
    data,
    allData,
    error,
    requestStatus,
    assetGroups,
    assets,
    loading,
    exportAllLoading,
    userTimeZone,
    language,
    englishLang,
  } = useSelector((store) => ({
    user: store.authReducer.user,
    data: store.reportReducer.data,
    allData: store.reportReducer.allData,
    error: store.reportReducer.error,
    requestStatus: store.reportReducer.requestStatus,
    assetGroups: store.assetGroupReducer.assetGroups,
    assets: store.assetReducer.assets,
    loading: store.reportReducer.loading,
    exportAllLoading: store.reportReducer.exportAllLoading,
    userTimeZone: store.authReducer.userTimeZone,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const columns = [
    {
      title: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      dataIndex: ["asset", "code"],
    },

    {
      title: language?.ASSET_NAME?.value ?? englishLang?.ASSET_NAME?.value,
      dataIndex: ["asset", "name"],
    },
    {
      title: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      dataIndex: ["zone", "name"],
    },
    {
      title: language?.SITE?.value ?? englishLang?.SITE?.value,
      dataIndex: "site",
    },
    {
      title:
        language?.FIRST_DETECTED?.value ?? englishLang?.FIRST_DETECTED?.value,
      dataIndex: ["firstSeen"],
      render: (firstSeen) => (
        <span>
          {firstSeen
            ? moment.tz(firstSeen, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
    },
    {
      title:
        language?.LAST_DETECTED?.value ?? englishLang?.LAST_DETECTED?.value,
      dataIndex: ["lastSeen"],
      render: (lastSeen) => (
        <span>
          {lastSeen
            ? moment
                .tz(lastSeen, userTimeZone, false)
                .format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
    },
  ];

  useEffect(() => {
    dispatch(resetReport());
    dispatch(fetchAssetGroups());
    dispatch(fetchAssets());
  }, []);

  useEffect(() => {
    if (assetGroups && assetGroups.content) {
      let sorted = assetGroups.content.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedAssetGroups(sorted);
    }
  }, [assetGroups]);

  useEffect(() => {
    if (assets.content) {
      const cats = assets.content.reduce(
        (catsSoFar, { assetGroup, assetId, code, name }) => {
          if (!catsSoFar[assetGroup.assetGroupId])
            catsSoFar[assetGroup.assetGroupId] = [];
          catsSoFar[assetGroup.assetGroupId].push({
            name: name,
            code: code,
            assetId: assetId,
          });
          return catsSoFar;
        },
        {}
      );

      setOptions(cats);
    }
  }, [assets.content]);

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    dataSource: data,
    updateEntityPath: "",
    filter,
    loading: loading,
  });

  useEffect(() => {
    dispatch(
      fetchReport({
        page: searchFormHelperHandler(),
        size: pageSize,
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
        // assetId: (filter.assetId? filter.assetId: undefined),
        assetIds: filter.assetId ? filter.assetId.join(",") : undefined,
        setup: "fixed",
        endpoint: "asset-movement",
      })
    );
  }, [currentPage, filter, isLoading, refresh, pageSize]);

  const handleExportAllButtonClick = () => {
    setExportAllSuccess(false);
    dispatch(
      fetchAllReport({
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
        // assetId: (filter.assetId? filter.assetId: undefined),
        assetIds: filter.assetId ? filter.assetId.join(",") : undefined,
        setup: "fixed",
        endpoint: "asset-movement",
      })
    ).then(() => {
      setExportAllSuccess(true);
    });
  };

  const handleDateRangeChange = (date, dateString) => {
    setFilter({
      ...filter,
      fromDate: dateString[0],
      toDate: dateString[1],
    });
  };
  const handleAssetChange = (value) => {
    setFilter({
      ...filter,
      assetId: value,
    });
  };

  const handleAssetGroupChange = (value) => {
    let sorted = [];
    if (options[value]) {
      sorted = options[value].sort((a, b) =>
        a.code.localeCompare(b.code, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
    }
    setAssetOptions(sorted);
  };

  const handleRefreshClick = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      let flatData = Object.values(data.content);

      flatData.map((item) => {
        item.firstSeenFormatted = item.firstSeen
          ? moment
              .tz(item.firstSeen, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
        item.lastSeenFormatted = item.lastSeen
          ? moment.tz(item.lastSeen, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedData(Object.values(flatData));
    }
  }, [data]);

  useEffect(() => {
    if (allData && Object.keys(allData).length !== 0) {
      let flatData = Object.values(allData.content);

      flatData.map((item) => {
        item.firstSeenFormatted = item.firstSeen
          ? moment
              .tz(item.firstSeen, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
        item.lastSeenFormatted = item.lastSeen
          ? moment.tz(item.lastSeen, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedAllData(Object.values(flatData));
    }
  }, [allData]);

  const headers = [
    {
      label: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      key: "asset.code",
    },
    {
      label: language?.ASSET_NAME?.value ?? englishLang?.ASSET_NAME?.value,
      key: "asset.name",
    },
    {
      label: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      key: "zone.name",
    },
    { label: language?.SITE?.value ?? englishLang?.SITE?.value, key: "site" },
    {
      label:
        language?.FIRST_DETECTED?.value ?? englishLang?.FIRST_DETECTED?.value,
      key: "firstSeenFormatted",
    },
    {
      label:
        language?.LAST_DETECTED?.value ?? englishLang?.LAST_DETECTED?.value,
      key: "lastSeenFormatted",
    },
  ];

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          hasSelected={hasSelected}
          exportTableTitle={
            language?.FIXEDASSETMOVEMENT?.value ??
            englishLang?.FIXEDASSETMOVEMENT?.value
          }
          addNewText=""
          title={
            language?.ASSET_MOVEMENT?.value ??
            englishLang?.ASSET_MOVEMENT?.value
          }
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportAllSuccess={exportAllSuccess}
          exportAllButtonClick={handleExportAllButtonClick}
        />
        <Row>
          <Col>
            <Space>
              <Tooltip
                title={
                  language?.SELECT_DATE_RANGE?.value ??
                  englishLang?.SELECT_DATE_RANGE?.value
                }
              >
                <RangePicker
                  defaultValue={[
                    moment(todayStr, dateFormat),
                    moment(todayStr, dateFormat),
                  ]}
                  format={dateFormat}
                  onChange={handleDateRangeChange}
                  id={ElementIDS().calendar}
                />
              </Tooltip>
              <Tooltip
                title={
                  language?.SELECT_AN_ASSET_GRUOP?.value ??
                  englishLang?.SELECT_AN_ASSET_GRUOP?.value
                }
              >
                <Select
                  id={ElementIDS().selectAssetGroup}
                  //   defaultValue="All"
                  style={{ width: 300 }}
                  placeholder={
                    language?.SELECT_AN_ASSET_GRUOP?.value ??
                    englishLang?.SELECT_AN_ASSET_GRUOP?.value
                  }
                  optionFilterProp="children"
                  onChange={handleAssetGroupChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {sortedAssetGroups
                    ? sortedAssetGroups.map((item) => (
                        <Option
                          id={ElementIDS().optionAssetGroup}
                          key={item.assetGroupId}
                          value={item.assetGroupId}
                        >
                          {item.name}
                        </Option>
                      ))
                    : undefined}
                </Select>
              </Tooltip>

              <Tooltip
                title={
                  language?.SELECT_AN_ASSET?.value ??
                  englishLang?.SELECT_AN_ASSET?.value
                }
              >
                <Select
                  id={ElementIDS().selectAsset}
                  //   defaultValue="All"
                  mode="multiple"
                  maxTagCount="responsive"
                  as
                  const
                  style={{ width: 300 }}
                  placeholder={
                    language?.SELECT_AN_ASSET?.value ??
                    englishLang?.SELECT_AN_ASSET?.value
                  }
                  optionFilterProp="children"
                  onChange={handleAssetChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {assetOptions
                    ? assetOptions.map((item) => (
                        <Option
                          id={ElementIDS().optionAsset}
                          key={item.assetId}
                          value={item.assetId}
                        >
                          {item.code}
                        </Option>
                      ))
                    : undefined}
                </Select>
              </Tooltip>
              <Button
                id={ElementIDS().refresh}
                type="primary"
                onClick={() => handleRefreshClick()}
              >
                {" "}
                <RedoOutlined />
                {language?.REFRESH?.value ?? englishLang?.REFRESH?.value}
              </Button>
            </Space>
          </Col>
        </Row>
        <Divider />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default FixedAssetMovement;
