import * as type from "../types";
import {
  getAlertRulesService,
  saveAlertRuleService,
  deleteAlertRuleService,
  enableService,
} from "../../network/service/AlertRuleService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchAlertRules = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALERTRULES_FETCH_REQUEST,
      });
      const response = await dispatch(getAlertRulesService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALERTRULES_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALERTRULES_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALERTRULES_FETCH_FAILURE,
      });
    }
  };
};

export const saveAlertRule = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALERTRULE_SAVE_REQUEST,
      });
      const response = await dispatch(saveAlertRuleService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ALERTRULE_SAVE_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALERTRULE_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALERTRULE_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteAlertRule = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALERTRULE_DELETE_REQUEST,
      });
      const response = await dispatch(deleteAlertRuleService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ALERTRULE_DELETE_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALERTRULE_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALERTRULE_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const enableAlertRule = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALERTRULE_ENABLE_REQUEST,
      });
      const response = await dispatch(enableService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ALERTRULE_ENABLE_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALERTRULE_ENABLE_FAILURE,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALERTRULE_ENABLE_FAILURE,
        payload: ex,
      });
    }
  };
};
