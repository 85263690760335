import * as axios from "axios";

const host = window.location.host;

function getRootUrl(reduxState) {
  let rootURL = host;
  if (reduxState !== null) {
    rootURL = "/api/web";
  }
  return rootURL;
}

const request = (url, reduxState, options) => {
  return async () => {
    let accessToken = localStorage.getItem("accToken");
    let refreshToken = localStorage.getItem("refreshToken");
    let source = axios.CancelToken.source();
    let config = {
      url: getRootUrl(reduxState) + url,
      ...options,
      withCredentials: true,
      headers: {
        cancelToken: source.token,
        Authorization: accessToken,
        "X-Tenant-ID": reduxState.authReducer.selectedTenant.domain,

        Accept: "application/vnd.handson.rfid.v5+json",
        "Content-Type": options?.params?.content
          ? options.params.content
          : "application/json",
      },
    };
    const requestInterceptor = (config) => {
      if (accessToken) {
        config.headers.Authorization = localStorage.getItem("accToken");
      }
      return config;
    };
    axios.interceptors.request.use(requestInterceptor);

    let response;

    try {
      response = await axios(config);
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("Request canceled:", err.message);
        return;
      }
      // Checking if response status is 401
      if (err.response && err.response.status === 401) {
        try {
          const refreshConfig = {
            url: "/api/token/refresh",
            method: "POST",
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              "X-Tenant-ID": reduxState.authReducer.selectedTenant.domain,
              Authorization: refreshToken,
            },
          };

          const refreshResponse = await axios(refreshConfig);

          let newAccToken = refreshResponse.headers.authorization;

          localStorage.setItem(
            "accToken",
            refreshResponse.headers.authorization
          );
          accessToken = newAccToken;

          localStorage.setItem(
            "refreshToken",
            refreshResponse.headers["x-auth-refresh-token"]
          );
          config.headers.Authorization = accessToken;

          response = await axios(config);
        } catch (refreshErr) {
          console.log("Error refreshing token:", refreshErr.message);
          localStorage.clear();

          location.reload();

          return;
        }
      } else {
        console.log("Error:", err.response);
        return err.response;
      }
    }
    return response;
  };
};

export { request };
