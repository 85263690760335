import * as type from "../types";
import {
  getDepartmentsService,
  saveDepartmentService,
  deleteDepartmentService,
  getAllDepartmentsService,
} from "../../network/service/DepartmentService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchDepartments = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.DEPARTMENTS_FETCH_REQUEST,
      });
      const response = await dispatch(getDepartmentsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.DEPARTMENTS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.DEPARTMENTS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.DEPARTMENTS_FETCH_FAILURE,
      });
    }
  };
};

export const fetchAllDepartments = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_DEPARTMENTS_FETCH_REQUEST,
      });
      const response = await dispatch(getAllDepartmentsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_DEPARTMENTS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_DEPARTMENTS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_DEPARTMENTS_FETCH_FAILURE,
      });
    }
  };
};

export const saveDepartment = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.DEPARTMENT_SAVE_REQUEST,
      });
      const response = await dispatch(saveDepartmentService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.DEPARTMENT_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.DEPARTMENT_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.DEPARTMENT_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteDepartment = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.DEPARTMENT_DELETE_REQUEST,
      });
      const response = await dispatch(deleteDepartmentService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.DEPARTMENT_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.DEPARTMENT_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.DEPARTMENT_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
