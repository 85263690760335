import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  UserOutlined,
  SettingOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Select, Affix } from "antd";

import { logoutJWT } from "../../api/redux/action/AuthActions";
import {
  languageSaveChanger,
  languageChanger,
} from "../../api/redux/action/LangActions";
import { getUsernameAvatar } from "../../components/common/UserAvatar";
import { removeLogoFromReducer } from "../../api/redux/action/UserActions";
import fleetLogo from "../../assets/images/fleet_logo.png";
import { getFleetActiveSession } from "../../api/redux/action/AuthActions";
import { getVersionNumber } from "../layout/Constants";
import { hasFleetSubscription } from "../layout/Constants";
import { hasPermission } from "../layout/Constants";

const { Header } = Layout;
const { SubMenu } = Menu;

const LayoutBanner = ({ collapsed, handleOnCollapse, user, language }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [buttonClick, setButtonClick] = useState(false);
  const { Option } = Select;

  const { fleetSession, languages, englishLang, selectedTenant, tenants } =
    useSelector((store) => ({
      fleetSession: store.authReducer.fleetSession,
      languages: store.langReducer.languages,
      englishLang: store.langReducer.englishLang,
      selectedTenant: store.authReducer.selectedTenant,
      tenants: store.authReducer.tenants,
    }));

  const getCollapseIcon = () => {
    if (collapsed) {
      return (
        <MenuUnfoldOutlined onClick={handleOnCollapse} className="trigger" />
      );
    }
    return <MenuFoldOutlined onClick={handleOnCollapse} className="trigger" />;
  };

  const handleFleetRedirection = () => {
    setButtonClick(true);
    //Make call to get active session
    dispatch(getFleetActiveSession());
  };

  useEffect(() => {
    const redirectUrl = "https://fleet.handsonsystems.com/";
    if (fleetSession && buttonClick) {
      document.cookie = "SessionID=" + fleetSession + ";";

      //redirect user to Fleet
      window.open(redirectUrl, "_blank");
    }
    return () => {
      setButtonClick(false);
    };
  }, [fleetSession, dispatch, buttonClick === true]);

  const handleLanguageChange = (value) => {
    const languageSelector = {
      language: value,
    };
    dispatch(languageSaveChanger(languageSelector, selectedTenant.userId));

    dispatch(languageChanger(languages, value));
  };

  const removeUserFromLocalStorage = (name) => {
    localStorage.removeItem(name);
  };

  const handleLogout = () => {
    dispatch(logoutJWT());
    // removeUserFromLocalStorage('user');
    // history.push('/login')
  };

  const changeTenant = () => {
    localStorage.removeItem("selectedTenant");
    dispatch(removeLogoFromReducer());
    history.push("/");
    location.reload();
  };

  const handleSettingMenuClick = (action) => {
    if (action.key === "logout") {
      handleLogout();
    }
    if (action.key === "changeTenant") {
      changeTenant();
    }
  };

  const version = (
    <span className="version_style">
      {language?.VERSION?.value ?? englishLang?.VERSION?.value}{" "}
      {getVersionNumber()}
    </span>
  );

  const getFleetSubsciption = () => {
    if (hasFleetSubscription(user)) {
      return (
        <Menu.Item
          key="fleet"
          onClick={() => {
            handleFleetRedirection();
          }}
        >
          <span id="rfid-navbar-fleetBtn">
            <img className="fleet-logo" src={fleetLogo} alt="Fleet Logo" />
            {language?.FLEET?.value ?? englishLang?.FLEET?.value}
          </span>
        </Menu.Item>
      );
    }
    return "";
  };

  const handleSaveLogoBtnClick = () => {
    history.push("/user-settings");
  };

  const handleSaveLogoBtn = hasPermission(selectedTenant, "CHANGE_LOGO")
    ? [
        {
          id: "rfid-navbar-userSettingsBtn",
          onClick: handleSaveLogoBtnClick,
          key: "userSettingsBtn",
          settingOutlined: <SettingOutlined style={{ paddingRight: "5px" }} />,
          lang: language?.SETTINGS?.value ?? englishLang?.SETTINGS?.value,
        },
      ].map((item) => (
        <Menu.Item id={item.id} onClick={item.onClick} key={item.key}>
          <span>
            {item.settingOutlined} {item.lang}
          </span>
        </Menu.Item>
      ))
    : null;

  return (
    <Affix>
      <Header className="header">
        <div id="rfid-navbar-sideMenuBtn">
          {window.innerWidth > 992 && getCollapseIcon()}
        </div>

        {version}

        <Select
          defaultValue={selectedTenant?.language ?? "translation_en"}
          style={{
            width: 150,
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onChange={handleLanguageChange}
        >
          <Option value="translation_en">English</Option>
          <Option value="translation_it">Italian</Option>
          <Option value="translation_rs">Serbian</Option>
          <Option value="translation_fr">French</Option>
        </Select>

        <Menu
          onClick={handleSettingMenuClick}
          mode="horizontal"
          className="menu"
        >
          <SubMenu
            key={user.name}
            title={getUsernameAvatar(user.name, user.surname)}
          >
            {/* <Menu.Divider /> */}
            {getFleetSubsciption()}

            {hasFleetSubscription(user) ? <Menu.Divider /> : ""}
            <Menu.Item
              key="history"
              id="rfid-navbar-myAccountBtn"
              onClick={() => {
                history.push("/profile");
              }}
            >
              <span>
                <UserOutlined style={{ marginRight: "10px" }} />
                {language?.MY_PROFILE?.value ?? englishLang?.MY_PROFILE?.value}
              </span>
            </Menu.Item>
            <Menu.Item
              id="rfid-navbar-userSettingsBtn"
              onClick={() => {
                history.push("/user-settings");
              }}
            >
              <span>
                <SettingOutlined style={{ marginRight: "10px" }} />
                {language?.SETTINGS?.value ?? englishLang?.SETTINGS?.value}
              </span>
            </Menu.Item>
            {tenants !== null && tenants?.length > 1 && (
              <Menu.Item key="changeTenant" id="rfid-navbar-changeTenantBtn">
                <span>
                  <SelectOutlined style={{ marginRight: "10px" }} />
                  {language?.CHANGE_TENANT?.value ??
                    englishLang?.CHANGE_TENANT?.value}
                </span>
              </Menu.Item>
            )}
            <Menu.Item key="logout" id="rfid-navbar-logOutBtn">
              <span>
                <LogoutOutlined style={{ marginRight: "10px" }} />
                {language?.LOGOUT?.value ?? englishLang?.LOGOUT?.value}
              </span>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
    </Affix>
  );
};

export default LayoutBanner;
