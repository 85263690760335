import * as type from '../types';
import { RequestState } from './RequestState';

const INITIAL_STATE = {
  gateways: {},
  allGateways:{},
  gateway: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  exportAllLoading:false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.GATEWAYS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        gateways: {},
        loading: true
      };
    case type.GATEWAYS_FETCH_SUCCESS:
      return {
        ...state,
        gateways: action.payload,
        loading: false
      };
    case type.GATEWAYS_FETCH_FAILURE:
      return {
        ...state,
        gateways: {},
        loading: false
      };
      case type.ALL_GATEWAYS_FETCH_REQUEST:
        return {
          ...state,
          error: {},
          allGateways: {},
          exportAllLoading: true
        };
      case type.ALL_GATEWAYS_FETCH_SUCCESS:
        return {
          ...state,
          allGateways: action.payload,
          exportAllLoading: false
        };
      case type.ALL_GATEWAYS_FETCH_FAILURE:
        return {
          ...state,
          allGateways: {},
          exportAllLoading: false
        };
      case type.GATEWAY_SAVE_REQUEST:
        return {
          ...state,
          error: {},
          gateway: {},
          requestStatus: RequestState.PENDING,
        };
    case type.GATEWAY_SAVE_SUCCESS:
      return {
        ...state,
        gateway: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.GATEWAY_SAVE_FAILURE:
      return {
        ...state,
        gateway: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
      case type.GATEWAY_DELETE_REQUEST:
        return {
          ...state,
          requestStatus: RequestState.PENDING,
        };
        case type.GATEWAY_DELETE_SUCCESS:
          return {
            ...state,
            gateway: action.payload,
            error: {},
            requestStatus: RequestState.SUCCESS,
          };
        case type.GATEWAY_DELETE_FAILURE:
          return {
            ...state,
            gateway: {},
            error: action.payload,
            requestStatus: RequestState.FAILURE,
          };
          case type.CLEAN_REQUEST_STATUS:
            return {
              ...state,
              requestStatus: RequestState.EMPTY
            };
    default:
      return state;
  }
};


export default reducer;