import { ResponseStatus } from "../../network/axios/ResponseStatus";
import * as type from "../types";
import { getTotalsService } from "../../network/service/DashboardService";
import dispatchStateIfUnauthorized from "./util";

export const fetchTotals = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.TOTALS_FETCH_REQUEST,
      });
      const response = await dispatch(getTotalsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.TOTALS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.TOTALS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.TOTALS_FETCH_FAILURE,
        payload: ex,
      });
    }
  };
};
