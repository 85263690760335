import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  Select,
  Tooltip,
  Row,
  Col,
  Button,
  Modal,
  Spin,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "../../layout/Style.css";
import { getModalSizeByWindowSize } from "../../layout/Constants";
import AddIpRelayCardForm from "../IpRelayCards/AddIpRelayCardForm";
import {
  fetchIpRelayCards,
  saveIpRelayCard,
} from "../../../api/redux/action/IpRelayCardActions";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import useNotification from "../../../components/common/ResultNotifier";
const { Option } = Select;

const AddAssetForm = (props) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState({ relayCard: false });
  const [relayCardForm] = Form.useForm();
  const [newRelayCardData, setNewRelayCardData] = useState(undefined);
  const [modalSize, setModalSize] = useState(getModalSizeByWindowSize());
  const [sortedIpRelayCards, setSortedIpRelayCards] = useState([]);

  const { ipRelayCards, error, requestStatus, language, englishLang } =
    useSelector((store) => ({
      ipRelayCards: store.ipRelayCardReducer.ipRelayCards,
      error: store.assetReducer.error,
      requestStatus: store.assetReducer.requestStatus,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
    }));
  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;
  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );

  useEffect(() => {
    dispatch(fetchIpRelayCards());
  }, [newRelayCardData]);

  useEffect(() => {
    if (
      ipRelayCards &&
      ipRelayCards.content &&
      newRelayCardData &&
      newRelayCardData.gateway
    ) {
      ipRelayCards.content.map((item) => {
        if (item.gatewayId == newRelayCardData.gateway) {
          props.form.setFieldsValue({
            ...(newRelayCardData && {
              ipRelayCard: item.ipRelayCardId,
            }),
          });
        }
      });
    }
  }, [ipRelayCards, newRelayCardData, isLoading]);

  useEffect(() => {
    if (ipRelayCards && ipRelayCards.content) {
      let sorted = ipRelayCards.content.sort((a, b) =>
        a.gateway.name.localeCompare(b.gateway.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      setSortedIpRelayCards(sorted);
    }
  }, [ipRelayCards]);

  const handleAddNewIpRelayCard = () => {
    showModal(true);
  };

  const handleIpRelayCardFormSave = (values) => {
    const formData = {
      gatewayId: relayCardForm.getFieldValue().gateway,
      ipAddress: relayCardForm.getFieldValue().ipAddress,
      port: relayCardForm.getFieldValue().portNumber,
    };
    if (Object.keys(formData).length > 0) {
      dispatch(saveIpRelayCard(formData))
        .then(() => {
          setNewRelayCardData(values);

          formData = {
            gatewayId: relayCardForm.resetFields().gateway,
            ipAddress: relayCardForm.resetFields().ipAddress,
            port: relayCardForm.resetFields().portNumber,
          };
        })
        .catch((err) => console.log(err));
    }
    setVisible(false);
  };

  const showModal = (relayCard) => {
    setVisible({ relayCard: relayCard });
  };

  const handleModalOk = (e) => {
    relayCardForm.submit();
  };

  const handleModalCancel = (e) => {
    setVisible(false);
  };

  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="relaySwitch-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        label={props.language?.NAME?.value ?? englishLang?.NAME?.value}
        name="name"
        rules={requiredFieldRule}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          id={ElementIDS().name}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <Form.Item
        label={
          props.language?.SWITCH_NUMBER?.value ??
          englishLang?.SWITCH_NUMBER?.value
        }
        name="switchNumber"
        rules={requiredFieldRule}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          id={ElementIDS().switchNumber}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <Form.Item
        label={
          props.language?.IP_RELAY_CARD?.value ??
          englishLang?.IP_RELAY_CARD?.value
        }
        required
      >
        <Row gutter={3}>
          <Col flex={9} span={22}>
            <Form.Item name="ipRelayCard" rules={requiredFieldRule}>
              <Select
                onChange={(e) => {
                  props.setDirty();
                }}
                id={ElementIDS().ipRelayCard}
                allowClear
                style={{ width: "100%" }}
                placeholder={
                  props.language?.SELECT_IP_RELAY_CARD?.value ??
                  englishLang?.SELECT_IP_RELAY_CARD?.value
                }
                showSearch
                filterOption={(input, option) =>
                  option.children[2]
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              >
                {sortedIpRelayCards
                  ? sortedIpRelayCards.map((item) => (
                      <Option
                        id={ElementIDS().ipRelayCardOption}
                        key={item.ipRelayCardId}
                        value={item.ipRelayCardId}
                      >
                        <strong>{item.ipAddress}</strong>{" "}
                        {item.gateway.description}
                      </Option>
                    ))
                  : undefined}
              </Select>
            </Form.Item>
          </Col>
          <Col flex={1} span={2}>
            <Tooltip
              title={
                props.language?.CREATE_IP_RELAY_CARD?.value ??
                englishLang?.CREATE_IP_RELAY_CARD?.value
              }
            >
              <Button
                id={ElementIDS().ipRelayCardCreate}
                icon={<PlusOutlined />}
                ghost
                type="primary"
                onClick={handleAddNewIpRelayCard}
                style={{ width: "100%" }}
                disabled={
                  props.readOnly && props.readOnly.viewOption
                    ? "disabled"
                    : null
                }
              />
            </Tooltip>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        label={
          props.language?.PUSLE_IN_MILLISECONDS?.value ??
          englishLang?.PUSLE_IN_MILLISECONDS?.value
        }
        name="pulseMs"
        rules={requiredFieldRule}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          id={ElementIDS().pulseInMilliseconds}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>

      <Modal
        title={
          props.language?.ADD_IP_RELAY_CARD?.value ??
          englishLang?.ADD_IP_RELAY_CARD?.value
        }
        visible={visible.relayCard}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={modalSize}
      >
        <Row justify="center">
          <Col span={20}>
            <AddIpRelayCardForm
              handleSave={handleIpRelayCardFormSave}
              form={relayCardForm}
              language={language}
              setDirty={props.setDirty}
            />
          </Col>
        </Row>
      </Modal>
      <ResultNotifier />
      {props.footer}
    </Form>
  );
};

export default AddAssetForm;
