import React, { useState, useRef } from "react";
import { Stage, Layer } from "react-konva";

import { Col, Row } from "antd";

import FloorPlanImage from "../../../../components/common/FloorPlanImage";
import FloorPlanComponents from "../../../../components/common/FloorPlanComponents";
import useKanvasLogic from "../../../../components/CustomHooks/KanvasLogic";
import ComponentModal from "./ComponentModal";

const LastDetectedFloorPlan = ({
  floorPlan,
  setSelectedComponent,
  detectedBySingleZone,
  detectedByAllZones,
  userTimeZone,
  loading,
}) => {
  const [selectedImageComponent, selectImageComponent] = useState({});
  const [isComponentModalOpen, setComponentModalOpen] = useState(false);

  const [stageSpec, setStageSpec] = useState({
    scale: 1,
    x: 0,
    y: 0,
  });
  const stageRef = useRef();

  const { handleZoomInOut, checkDeselectComponent, unSelectComponent } =
    useKanvasLogic({
      setStageSpec,
      selectImageComponent,
    });

  //Present the modal to the User when Icon is droped
  const showViewComponent = () => {
    // if (detectedBySingleZone && detectedBySingleZone.length > 0) {
    setComponentModalOpen(true);
    // } else {
    //   setComponentModalOpen(false);
    // }
  };

  const stageStyle = {
    border: "1px solid lightgrey",
    borderRadius: "4px",
    width: "100%",
    height: "100%",
    overflow: "auto",
  };

  const importImageHandler = () => {
    const imageUrl =
      floorPlan?.urlFloorPlanImage || "./FloorPlanComponents/BlankImage.jpg";
    return `${imageUrl}`;
  };

  return (
    <>
      <Row justify="center">
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={stageStyle}>
            <Stage
              ref={stageRef}
              draggable="true"
              scaleX={stageSpec.scale}
              scaleY={stageSpec.scale}
              x={stageSpec.x}
              y={stageSpec.y}
              width={window.innerWidth * 0.8}
              height={window.innerHeight * 0.68}
              onWheel={handleZoomInOut}
              onMouseDown={checkDeselectComponent}
            >
              <Layer>
                <FloorPlanImage
                  id="Floor_Plan_Image"
                  src={importImageHandler()}
                  checkDeselectComponent={checkDeselectComponent}
                  width={1500}
                  height={610}
                  space="fit"
                />
                {floorPlan?.components?.map((component) => {
                  if (component.type === "Antenna") {
                    return (
                      <FloorPlanComponents
                        assetCount={detectedByAllZones}
                        stageScale={stageSpec.scale}
                        key={component.id}
                        stageRef={stageRef}
                        component={component}
                        isSelected={component === selectedImageComponent}
                        report={true}
                        unSelectShape={unSelectComponent}
                        onSelect={() => {
                          if (component.type === "Antenna") {
                            showViewComponent();
                            selectImageComponent(component);
                            setSelectedComponent(parseInt(component.zoneId));
                          }
                        }}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </Layer>
            </Stage>
          </div>
        </Col>
      </Row>
      <ComponentModal
        loading={loading}
        setComponentModalOpen={setComponentModalOpen}
        isComponentModalOpen={isComponentModalOpen}
        detectedBySingleZone={detectedBySingleZone}
        userTimeZone={userTimeZone}
        selectedImageComponent={selectedImageComponent}
      />
    </>
  );
};

export default LastDetectedFloorPlan;
