import { request } from "../axios/request";

export const getAssetsService = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/assets", getState(), {
        method: "GET",
        params,
      })
    );
  };
};

export const getAllAssetsService = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/assets", getState(), {
        method: "GET",
        params,
      })
    );
  };
};

export const getAllWithKilledAssets = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/assets/all", getState(), {
        method: "GET",
        params,
      })
    );
  };
};

export const saveAssetService = (data, id = 0) => {
  if (id > 0) {
    return async (dispatch, getState) => {
      return dispatch(
        request("/assets/" + id, getState(), {
          method: "PUT",
          data,
        })
      );
    };
  }
  return async (dispatch, getState) => {
    return dispatch(
      request("/assets", getState(), {
        method: "POST",
        data,
      })
    );
  };
};

export const bulkUploadAssets = (data = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/assets/upload", getState(), {
        method: "POST",
        data,
      })
    );
  };
};

export const deleteAssetService = (id = 0) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/assets/" + id, getState(), {
        method: "DELETE",
      })
    );
  };
};

export const killAssetService = (data, id = 0) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/assets/" + id, getState(), {
        method: "PATCH",
        data,
      })
    );
  };
};
