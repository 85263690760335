import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Divider, Space, Tooltip, Row, DatePicker, Button } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import moment from "moment";

import useDataTable from "../../../components/common/DataTable";
import {
  fetchReport,
  fetchAllReport,
  resetReport,
} from "../../../api/redux/action/ReportActions";
import useNotification from "../../../components/common/ResultNotifier";
import Header from "../../../components/common/ReportHeader";
import { toTimestamp } from "../../layout/Constants";

const dateFormat = "DD-MM-YYYY";

const { RangePicker } = DatePicker;

function UndetectedAssets() {
  const today = new Date();
  const todayStr =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  const dispatch = useDispatch();

  const [exportAllSuccess, setExportAllSuccess] = useState(false);
  const [filter, setFilter] = useState({
    fromDate: todayStr,
    toDate: todayStr,
  });
  const [refresh, setRefresh] = useState(false);

  const {
    data,
    allData,
    error,
    requestStatus,
    loading,
    exportAllLoading,
    language,
    englishLang,
  } = useSelector((store) => ({
    data: store.reportReducer.data,
    allData: store.reportReducer.allData,
    error: store.reportReducer.error,
    requestStatus: store.reportReducer.requestStatus,
    loading: store.reportReducer.loading,
    exportAllLoading: store.reportReducer.exportAllLoading,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const columns = [
    {
      title: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      dataIndex: "code",
    },
    {
      title: language?.ASSET_NAME?.value ?? englishLang?.ASSET_NAME?.value,
      dataIndex: "name",
    },
    {
      title: language?.ASSET_GROUP?.value ?? englishLang?.ASSET_GROUP?.value,
      dataIndex: ["assetGroup", "name"],
    },
    // {
    //     title: 'Last Detected Position',
    //     dataIndex: [],
    //     render: assetCodes => (
    //         <span>
    //           link to Last Detected report
    //         </span>
    //       )
    //   },
  ];

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const { DataTable, hasSelected, currentPage, pageSize } = useDataTable({
    columns,
    dataSource: data,
    updateEntityPath: "",
    filter,
    loading: loading,
  });

  useEffect(() => {
    dispatch(resetReport());
  }, []);

  useEffect(() => {
    dispatch(
      fetchReport({
        page: currentPage,
        size: pageSize,
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
        // search: filter,
        endpoint: "undetected-assets",
      })
    );
  }, [currentPage, filter, isLoading, refresh, pageSize]);

  const handleExportAllButtonClick = () => {
    setExportAllSuccess(false);
    dispatch(
      fetchAllReport({
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
        // search: filter,
        endpoint: "undetected-assets",
      })
    ).then(() => {
      setExportAllSuccess(true);
    });
  };

  const handleDateRangeChange = (date, dateString) => {
    setFilter({
      ...filter,
      fromDate: dateString[0],
      toDate: dateString[1],
    });
  };

  const handleRefreshClick = () => {
    setRefresh(!refresh);
  };

  let flattenedData = [];

  if (Object.keys(data).length !== 0) {
    flattenedData = Object.values(data.content);
  }

  let flattenedAllData = [];

  if (Object.keys(allData).length !== 0) {
    flattenedAllData = Object.values(allData.content);
  }

  const headers = [
    {
      label: language?.ASSET_CODE?.value ?? englishLang?.ASSET_CODE?.value,
      key: "code",
    },
    {
      label: language?.ASSET_NAME?.value ?? englishLang?.ASSET_NAME?.value,
      key: "name",
    },
    {
      label: language?.ASSET_GROUP?.value ?? englishLang?.ASSET_GROUP?.value,
      key: "assetGroup.name",
    },
  ];

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          hasSelected={hasSelected}
          exportTableTitle={
            language?.UNDETECTEDASSETS?.value ??
            englishLang?.UNDETECTEDASSETS?.value
          }
          addNewText=""
          title={
            language?.UNDETECTED_ASSETS?.value ??
            englishLang?.UNDETECTED_ASSETS?.value
          }
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportAllSuccess={exportAllSuccess}
          exportAllButtonClick={handleExportAllButtonClick}
        />
        <Row>
          <Col>
            <Space>
              <Tooltip>
                <RangePicker
                  defaultValue={[
                    moment(todayStr, dateFormat),
                    moment(todayStr, dateFormat),
                  ]}
                  format={dateFormat}
                  onChange={handleDateRangeChange}
                />
              </Tooltip>
              <Button type="primary" onClick={() => handleRefreshClick()}>
                {" "}
                <RedoOutlined />
                {language?.REFRESH?.value ?? englishLang?.REFRESH?.value}
              </Button>
            </Space>
          </Col>
        </Row>
        <Divider />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default UndetectedAssets;
