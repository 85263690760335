import * as type from "../types";
import {
  getAssetsService,
  saveAssetService,
  deleteAssetService,
  getAllAssetsService,
  killAssetService,
  getAllWithKilledAssets,
} from "../../network/service/AssetService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchAssets = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ASSETS_FETCH_REQUEST,
      });
      const response = await dispatch(getAssetsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ASSETS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ASSETS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ASSETS_FETCH_FAILURE,
      });
    }
  };
};

export const fetchAllAssets = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_ASSETS_FETCH_REQUEST,
      });
      const response = await dispatch(getAllAssetsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_ASSETS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_ASSETS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_ASSETS_FETCH_FAILURE,
      });
    }
  };
};

export const saveAsset = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ASSET_SAVE_REQUEST,
      });
      const response = await dispatch(saveAssetService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ASSET_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.ASSET_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ASSET_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteAsset = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ASSET_DELETE_REQUEST,
      });
      const response = await dispatch(deleteAssetService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ASSET_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.ASSET_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ASSET_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const killAsset = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ASSET_KILL_REQUEST,
      });
      const response = await dispatch(killAssetService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.ASSET_KILL_SUCCESS,
        });
      } else {
        dispatch({
          type: type.ASSET_KILL_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ASSET_KILL_FAILURE,
        payload: ex,
      });
    }
  };
};

export const fetchAllWithKilledAssets = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_WITH_KILLED_ASSETS_REQUEST,
      });
      const response = await dispatch(getAllWithKilledAssets(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_WITH_KILLED_ASSETS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_WITH_KILLED_ASSETS_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_WITH_KILLED_ASSETS_FAILURE,
      });
    }
  };
};
