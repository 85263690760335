import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "antd";
import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchAlertRules,
  deleteAlertRule,
} from "../../../api/redux/action/AlertRuleActions";
import useNotification from "../../../components/common/ResultNotifier";
import { hasPermission } from "../../layout/Constants";
import { useHistory } from "react-router-dom";
import { activeColors } from "../../layout/Constants";
import AlertRuleActionMenu from "./ActionMenu";

function AlertRules() {
  const dispatch = useDispatch();

  const [dataTable, setDataTable] = useState();

  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  let history = useHistory();

  function getColor(active) {
    return activeColors[active].color;
  }

  function getDisplayName(active) {
    return activeColors[active].displayName;
  }

  const [searchText, setSearchText] = useState();

  const {
    alertRules,
    error,
    requestStatus,
    loading,
    user,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    alertRules: store.alertRuleReducer.alertRules,
    error: store.alertRuleReducer.error,
    requestStatus: store.alertRuleReducer.requestStatus,
    loading: store.alertRuleReducer.loading,
    user: store.authReducer.user,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const columns = [
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
      render: (name) => (
        <a
          onClick={() => {
            const viewOption = "disabled";
            history.push({
              pathname: "/update-alertRule",
              state: { selectedRow, viewOption },
            });
          }}
        >
          {name}
        </a>
      ),
    },
    {
      title: language?.STATUS?.value ?? englishLang?.STATUS?.value,
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (active, alertRuleId) => (
        <Tag color={getColor(alertRuleId.active)} key={active}>
          {getDisplayName(alertRuleId.active)}
        </Tag>
      ),
    },
  ];

  const [allowPermission, setAllowPermission] = useState({
    edit: hasPermission(selectedTenant, "UPDATE_ALERT_RULES"),
    delete: hasPermission(selectedTenant, "DELETE_ALERT_RULES"),
    activate: hasPermission(selectedTenant, "ACTIVATE_USERS"),
    deactivate: hasPermission(selectedTenant, "DEACTIVATE_USERS"),
    view: hasPermission(selectedTenant, "READ_ALERT_RULES"),
  });

  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    language?.ALERT_RULE_NOTIFICATION_SUCCESS?.value ??
      englishLang?.ALERT_RULE_NOTIFICATION_SUCCESS?.value
  );

  const handleSingleDelete = (selected) => {
    dispatch(deleteAlertRule(selected.alertRuleId));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    selectedRow,
    sortField,
    sortOrder,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "alertRule",
    dataSource: alertRules,
    updateEntityPath: "update-alertRule",
    customActionView:
      allowPermission.edit ||
      allowPermission.delete ||
      allowPermission.activate ||
      allowPermission.deactivate ||
      allowPermission.view
        ? AlertRuleActionMenu
        : "",
    searchText,
    loading: loading,
    handleSingleDelete,
    allowPermission,
    setSortedInfo,
    title: language?.ALERT_RULES?.value ?? englishLang?.ALERT_RULES?.value,
  });

  useEffect(() => {
    dispatch(
      fetchAlertRules({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo, pageSize]);

  useEffect(() => {
    setDataTable(DataTable);
  }, [loading, selectedRow, language]);

  let flattenedData = [];

  if (Object.keys(alertRules).length !== 0) {
    flattenedData = Object.values(alertRules.content);

    flattenedData.forEach((alertRules) => {
      let assetGroups1 = "";
      if (alertRules.assetGroups) {
        for (var x = 0; x < alertRules.assetGroups.length; x++) {
          assetGroups1 += x > 0 ? "," : "";
          assetGroups1 += alertRules.assetGroups[x].name;
        }
      }
      alertRules.assetGroups1 = assetGroups1;

      let assets1 = "";
      if (alertRules.assets) {
        for (var x = 0; x < alertRules.assets.length; x++) {
          assets1 += x > 0 ? "," : "";
          assets1 += alertRules.assets[x].name;
        }
      }
      alertRules.assets1 = assets1;

      let sites1 = "";
      if (alertRules.sites) {
        for (var x = 0; x < alertRules.sites.length; x++) {
          sites1 += x > 0 ? "," : "";
          sites1 += alertRules.sites[x].name;
        }
      }
      alertRules.sites1 = sites1;

      let zones1 = "";
      if (alertRules.zones) {
        for (var x = 0; x < alertRules.zones.length; x++) {
          zones1 += x > 0 ? "," : "";
          zones1 += alertRules.zones[x].name;
        }
      }
      alertRules.zones1 = zones1;

      let relaySwitches1 = "";
      if (alertRules.alertActions.relaySwitches) {
        for (var x = 0; x < alertRules.alertActions.relaySwitches.length; x++) {
          relaySwitches1 += x > 0 ? "," : "";
          relaySwitches1 += alertRules.alertActions.relaySwitches[x].name;
        }
      }
      alertRules.relaySwitches1 = relaySwitches1;

      let contacts1 = "";
      if (alertRules.alertActions.contacts) {
        for (var x = 0; x < alertRules.alertActions.contacts.length; x++) {
          contacts1 += x > 0 ? "," : "";
          contacts1 += alertRules.alertActions.contacts[x].name;
        }
      }
      alertRules.contacts1 = contacts1;

      alertRules.detectedInOtherZonesOrSites1 =
        alertRules.detectedInOtherZonesOrSites
          ? language?.DETECTED?.value ?? englishLang?.DETECTED?.value
          : language?.UNDETECTED?.value ?? englishLang?.UNDETECTED?.value;
    });
  }

  const headers = [
    { label: language?.NAME?.value ?? englishLang?.NAME?.value, key: "name" },
    {
      label: language?.ASSET_GROUPS?.value ?? englishLang?.ASSET_GROUPS?.value,
      key: "assetGroup1",
    },
    {
      label: language?.ASSETS?.value ?? englishLang?.ASSETS?.value,
      key: "asset1",
    },
    {
      label: language?.UN_DETECTED?.value ?? englishLang?.UN_DETECTED?.value,
      key: "detectedInOtherZonesOrSites1",
    },
    {
      label: language?.SITES?.value ?? englishLang?.SITES?.value,
      key: "sites1",
    },
    {
      label: language?.ZONES?.value ?? englishLang?.ZONES?.value,
      key: "zones1",
    },
    {
      label:
        language?.RELAY_SWITCHES?.value ?? englishLang?.RELAY_SWITCHES?.value,
      key: "relaySwitches1",
    },
    {
      label: language?.CONTACTS?.value ?? englishLang?.CONTACTS?.value,
      key: "contacts1",
    },
  ];

  const addNewPath = hasPermission(selectedTenant, "CREATE_ALERT_RULES")
    ? "add-alertRule"
    : "";

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath={addNewPath}
          hasSelected={hasSelected}
          exportTableTitle={
            language?.ALERTRULES?.value ?? englishLang?.ALERTRULES?.value
          }
          addNewText={
            language?.ADD_ALERT_RULE?.value ??
            englishLang?.ADD_ALERT_RULE?.value
          }
          title={
            language?.ALERT_RULES?.value ?? englishLang?.ALERT_RULES?.value
          }
          csvData={flattenedData}
          csvHeaders={headers}
          handleSearch={setSearchText}
          tooltipText={
            language?.TOOLTIP_SEARCH_NAME?.value ??
            englishLang?.TOOLTIP_SEARCH_NAME?.value
          }
        />
        {dataTable}
        <ResultNotifier />
      </div>
    </>
  );
}

export default AlertRules;
