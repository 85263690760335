import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, Menu, Modal } from "antd";
import {
  DeleteOutlined,
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  AimOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { ElementIDS } from "./ElementsIDs";
function useActionMenu({
  selectedRow,
  updateEntityPath,
  handleSingleDelete,
  handleKillAsset,
  allowPermission,
  title,
}) {
  const history = useHistory();
  const { confirm } = Modal;

  const { language, englishLang } = useSelector((store) => ({
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  const handleMenuClick = (action) => {
    const updatePath = "/" + updateEntityPath;
    if (action.key === "edit") {
      history.push({
        pathname: updatePath,
        state: { selectedRow },
      });
    }
    if (action.key === "view") {
      const viewOption = "disabled";

      history.push({
        pathname: updatePath,
        state: { selectedRow, viewOption },
      });
    }
  };

  function deleteNameHandler() {
    if (history.location.pathname === "/antennas") {
      return selectedRow.antennaNumber;
    } else if (history.location.pathname === "/ipRelayCards") {
      return selectedRow.gateway.name;
    } else {
      return selectedRow.name;
    }
  }

  function deleteTitleLastLetterHandler() {
    if (history.location.pathname === "/relaySwitches") {
      return title.slice(0, -2);
    } else {
      return title.slice(0, -1);
    }
  }

  function convertStringToCamelCase(title) {
    return title.replace(
      /(?:^\w|[A-Z]|\b\w|\s+)/g,
      function (camelCaseMatch, i) {
        if (+camelCaseMatch === 0) return "";
        return i === 0
          ? camelCaseMatch.toLowerCase()
          : camelCaseMatch.toUpperCase();
      }
    );
  }

  const showKillAssetConfirm = () => {
    confirm({
      title: `${
        language?.KILL?.value ?? englishLang?.KILL?.value
      } ${deleteTitleLastLetterHandler(title)}`,
      icon: <QuestionCircleOutlined />,
      onOk: () => {
        handleKillAsset(selectedRow);
      },
      okText: language?.KILL_ASSET?.value ?? englishLang?.KILL_ASSET?.value,
      okButtonProps: {
        id: `rfid-${convertStringToCamelCase(title)}-killModal-killBtn`,
      },
      okType: "danger",
      cancelText: language?.CANCEL?.value ?? englishLang?.CANCEL?.value,
      cancelButtonProps: {
        id: `rfid-${convertStringToCamelCase(title)}-killModal-cancelBtn`,
      },
      content:
        language?.SURE_TO_KILL_THIS_ENTRY?.value ??
        englishLang?.SURE_TO_KILL_THIS_ENTRY?.value +
          " " +
          deleteNameHandler() +
          "?",
    });
  };

  const showDeleteConfirm = () => {
    confirm({
      title:
        language?.DELETE?.value ??
        englishLang?.DELETE?.value + " " + deleteTitleLastLetterHandler(title),
      icon: <QuestionCircleOutlined />,
      onOk: () => {
        handleSingleDelete(selectedRow);
      },
      okText: language?.DELETE?.value ?? englishLang?.DELETE?.value,
      okButtonProps: {
        id:
          "rfid-" + convertStringToCamelCase(title) + "-deleteModal-deleteBtn",
      },
      okType: "danger",
      cancelText: language?.CANCEL?.value ?? englishLang?.CANCEL?.value,
      cancelButtonProps: {
        id:
          "rfid-" + convertStringToCamelCase(title) + "-deleteModal-cancelBtn",
      },
      content:
        language?.SURE_TO_DELETE_THIS_ENTRY?.value ??
        englishLang?.SURE_TO_DELETE_THIS_ENTRY?.value +
          " " +
          deleteNameHandler() +
          "?",
    });
  };

  const killMenuItem = allowPermission.kill
    ? [
        {
          id: ElementIDS().killBtn,
          key: "kill",
          onClick: showKillAssetConfirm,
          icon: <AimOutlined style={{ marginRight: "5px" }} />,
          lang: language?.KILL_ASSET?.value ?? englishLang?.KILL_ASSET?.value,
        },
      ].map((item) => (
        <Menu.Item key={item.key} id={item.id} onClick={item.onClick}>
          {item.icon}
          {item.lang}
        </Menu.Item>
      ))
    : null;

  const viewMenuItem = [
    {
      key: "view",
      id: ElementIDS().view,
      icon: <EyeOutlined style={{ marginRight: "5px" }} />,
      lang: language?.VIEW?.value ?? englishLang?.VIEW?.value,
    },
  ].map((item) => (
    <Menu.Item key={item.key} id={item.id}>
      {item.icon}
      {item.lang}
    </Menu.Item>
  ));

  const editMenuItem = allowPermission.edit
    ? [
        {
          id: ElementIDS().dropDownEdit,
          key: "edit",
          icon: <EditOutlined style={{ marginRight: "5px" }} />,
          lang: language?.EDIT?.value ?? englishLang?.EDIT?.value,
        },
      ].map((item) => (
        <Menu.Item id={item.id} key={item.key}>
          {item.icon} {item.lang}
        </Menu.Item>
      ))
    : null;

  const deleteMenuItem = allowPermission.delete ? (
    <>
      {[
        {
          id: ElementIDS().dropDownDelete,
          className: "action__menu__deleteBtn",
          placement: "left",
          key: "delete",
          icon: <DeleteOutlined type="delete" style={{ color: "red" }} />,
          onClick: showDeleteConfirm,
          lang: language?.DELETE?.value ?? englishLang?.DELETE?.value,
        },
      ].map((item) => (
        <Menu.Item
          id={item.id}
          className={item.className}
          placement={item.placement}
          key={item.key}
          icon={item.icon}
          onClick={item.onClick}
        >
          {item.lang}
        </Menu.Item>
      ))}
    </>
  ) : null;

  const withSeperatorSecond = allowPermission.separetor
    ? [{ className: "seperator" }].map((item) => (
        <Menu.Item
          key={"withSeperatorSecond"}
          className={item.className}
        ></Menu.Item>
      ))
    : null;

  const withSeperatorOne = [{ className: "seperator" }].map((item) => (
    <Menu.Item key={"withSeperatorOne"} className={item.className}></Menu.Item>
  ));

  const actionMenu = (
    <Menu onClick={handleMenuClick}>
      {viewMenuItem}
      {editMenuItem}
      {withSeperatorSecond}
      {killMenuItem}
      {withSeperatorOne}
      {deleteMenuItem}
    </Menu>
  );

  const actionColumnView = (
    <Dropdown overlay={actionMenu} trigger={["click"]}>
      <a id={ElementIDS().dropDown} className="ant-dropdown-link" href="/">
        <MoreOutlined style={{ fontSize: "22px" }} />
      </a>
    </Dropdown>
  );

  return actionColumnView;
}

export default useActionMenu;
