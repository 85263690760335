import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "antd";
import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import { fetchUsers, deleteUser } from "../../../api/redux/action/UserActions";
import UserActionView from "./ActionMenu";
import useNotification from "../../../components/common/ResultNotifier";
import { hasPermission, activeColors } from "../../layout/Constants";
import { useHistory } from "react-router-dom";
function getColor(active) {
  return activeColors[active].color;
}

function getDisplayName(active) {
  return activeColors[active].displayName;
}

function Users() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState();

  let history = useHistory();

  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  const {
    users,
    error,
    requestStatus,
    loading,
    user,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    users: store.userReducer.users,
    error: store.userReducer.error,
    requestStatus: store.userReducer.requestStatus,
    loading: store.userReducer.loading,
    user: store.authReducer.user,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const columns = [
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
      sorter: true,
      sortField: "name",
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      render: (name) => (
        <a
          onClick={() => {
            const viewOption = "disabled";

            history.push({
              pathname: "/update-user",
              state: { selectedRow, viewOption },
            });
          }}
        >
          {name}
        </a>
      ),
    },
    {
      title: language?.SURNAME?.value ?? englishLang?.SURNAME?.value,
      dataIndex: "surname",
      sorter: true,
      sortField: "surname",
      sortOrder: sortedInfo.columnKey === "surname" && sortedInfo.order,
    },
    {
      title: language?.USERNAME?.value ?? englishLang?.USERNAME?.value,
      dataIndex: "username",
      sorter: true,
      sortField: "username",
      sortOrder: sortedInfo.columnKey === "username" && sortedInfo.order,
    },
    {
      title: language?.STATUS?.value ?? englishLang?.STATUS?.value,
      dataIndex: "active",
      width: "10%",
      render: (active) => (
        <Tag color={getColor(active)} key={active}>
          {getDisplayName(active)}
        </Tag>
      ),
      sorter: true,
      sortField: "active",
      sortOrder: sortedInfo.columnKey === "active" && sortedInfo.order,
    },
    {
      title: language?.USER_GROUP?.value ?? englishLang?.USER_GROUP?.value,
      dataIndex: ["userGroup", "name"],
      sorter: true,
      sortField: "userGroupEntity.name",
      sortOrder:
        sortedInfo.columnKey === "userGroupEntity.name" && sortedInfo.order,
    },

    {
      title: language?.DEPARTMENT?.value ?? englishLang?.DEPARTMENT?.value,
      dataIndex: "department",
      render: (department) => (
        <span>
          {department
            ? department.map((d) => <li key={d.name}>{d.name}</li>)
            : ""}
        </span>
      ),
      // sorter: true,
      // sortField: 'departmentEntity.name',
      // sortOrder: sortedInfo.columnKey === 'departmentEntity.name' && sortedInfo.order,
    },
  ];

  const [allowPermission, setAllowPermission] = useState({
    edit: hasPermission(selectedTenant, "UPDATE_USERS"),
    delete: hasPermission(selectedTenant, "DELETE_USERS"),
    activate: hasPermission(selectedTenant, "ACTIVATE_USERS"),
    deactivate: hasPermission(selectedTenant, "DEACTIVATE_USERS"),
    view: hasPermission(selectedTenant, "READ_USERS"),
  });

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const handleSingleDelete = (selected) => {
    dispatch(deleteUser(selected.userAccountId));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    sortField,
    sortOrder,
    selectedRow,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    source: "user",
    dataSource: users,
    updateEntityPath: "update-user",
    customActionView:
      allowPermission.edit ||
      allowPermission.delete ||
      allowPermission.activate ||
      allowPermission.deactivate ||
      allowPermission.view
        ? UserActionView
        : "",
    searchText,
    loading: loading,
    handleSingleDelete,
    allowPermission,
    setSortedInfo,
    title: language?.USERS?.value ?? englishLang?.USERS?.value,
  });

  useEffect(() => {
    dispatch(
      fetchUsers({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo]);

  let flattenedData = [];

  if (Object.keys(users).length !== 0) {
    flattenedData = Object.values(users.content);
  }

  const headers = [
    { label: language?.NAME?.value ?? englishLang?.NAME?.value, key: "name" },
    {
      label: language?.SURNAME?.value ?? englishLang?.SURNAME?.value,
      key: "surname",
    },
    {
      label: language?.USERNAME?.value ?? englishLang?.USERNAME?.value,
      key: "username",
    },
    {
      label: language?.STATUS?.value ?? englishLang?.STATUS?.value,
      key: "active",
    },
    {
      label: language?.USER_GROUP?.value ?? englishLang?.USER_GROUP?.value,
      key: "userGroup.name",
    },
    {
      label: language?.DEPARTMENT?.value ?? englishLang?.DEPARTMENT?.value,
      key: "department.name",
    },
  ];

  const addNewPath = hasPermission(selectedTenant, "CREATE_USERS")
    ? "add-user"
    : "";

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath={addNewPath}
          hasSelected={hasSelected}
          exportTableTitle={language?.USERS?.value ?? englishLang?.USERS?.value}
          addNewText={language?.ADD_USER?.value ?? englishLang?.ADD_USER?.value}
          title={language?.USERS?.value ?? englishLang?.USERS?.value}
          csvData={flattenedData}
          csvHeaders={headers}
          loading={loading}
          handleSearch={setSearchText}
          tooltipText={
            language?.TOOLTIP_SEARCH_USER?.value ??
            englishLang?.TOOLTIP_SEARCH_USER?.value
          }
        />
        <DataTable />
        <ResultNotifier />
      </div>
    </>
  );
}

export default Users;
