import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";

import { saveCustomField } from "../../../api/redux/action/CustomFieldActions";
import useNotification from "../../../components/common/ResultNotifier";
import AddCustomFieldForm from "./AddCustomFieldForm";
import { useHistory } from "react-router-dom";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";
import { hasPermission } from "../../layout/Constants";
import AddFooterSection from "../../../components/common/AddFooterSection";
import { validateInput } from "../../../components/RequiredFieldRule";
import { fetchAssetGroups } from "../../../api/redux/action/AssetGroupActions";
import moment from "moment";

const AddCustomField = (props) => {
  const [fieldType, setFieldType] = useState(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [prompt, setDirty, setPristine] = UnsavedChanges();

  const dateFormat = "DD-MM-YYYY";
  const today = moment().format(dateFormat);

  let history = useHistory();

  const {
    error,
    requestStatus,
    language,
    user,
    assetGroups,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    error: store.customFieldReducer.error,
    requestStatus: store.customFieldReducer.requestStatus,
    language: store.langReducer.language,
    assetGroups: store.assetGroupReducer.assetGroups,
    user: store.authReducer.user,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));
  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;
  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      let options = "";

      form.setFieldsValue({
        name: props.location.state.selectedRow.name,
        customFieldId: props.location.state.selectedRow.customFieldId,
        type: props.location.state.selectedRow.type,
        host: props.location.state.selectedRow.host,
        assetGroup: props.location.state.selectedRow.assetGroupIds,
        // defaultValue: props.location.state.selectedRow.defaultValue,
        options: props.location.state.selectedRow.options,
      });
    }
  }, [props.location.state]);

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      return history.push("/customFields");
    }
  }, [requestStatus]);

  useEffect(() => {
    dispatch(fetchAssetGroups());
  }, []);

  const handleSave = (values) => {
    const formData = {
      name: form.getFieldValue().name,
      type: form.getFieldValue().type,
      required: form.getFieldValue().required,
      host: form.getFieldValue().host,
      assetGroupIds: form.getFieldValue().assetGroup,
      // defaultValue: form.getFieldValue().defaultValue,
      options: form.getFieldValue().options,
    };

    setPristine();

    dispatch(saveCustomField(formData, form.getFieldValue().customFieldId));
  };

  const handleCancel = () => {
    history.push("/customFields");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_CUSTOM_FIELDS");
  }

  const footer = (
    <AddFooterSection
      language={language}
      viewOption={props.location.state}
      handleCancel={handleCancel}
      form={form}
      updatePath={"/update-customField"}
      handleEditingPermission={handleEditingPermission}
    />
  );

  return (
    <Card
      title={
        language?.CUSTOM_FILEDS?.value ?? englishLang?.CUSTOM_FILEDS?.value
      }
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddCustomFieldForm
            handleSave={handleSave}
            form={form}
            footer={footer}
            setDirty={setDirty}
            readOnly={props.location.state}
            language={language}
            assetGroups={assetGroups}
            setFieldType={setFieldType}
            // defaultValue={moment(today, dateFormat)}
          />
        </Col>
      </Row>
      <ResultNotifier />
      {prompt}
    </Card>
  );
};

export default AddCustomField;
