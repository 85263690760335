import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Divider, Tooltip, Row, DatePicker, Space, Button } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import moment from "moment-timezone";

import useDataTable from "../../../components/common/DataTable";
import {
  fetchReport,
  markAsRead,
  resetReport,
} from "../../../api/redux/action/ReportActions";
import useNotification from "../../../components/common/ResultNotifier";
import Header from "../../../components/common/ReportHeader";

import { hasPermission, toTimestamp } from "../../layout/Constants";

import { ElementIDS } from "../../../components/common/ElementsIDs";
import { exportRequest } from "../../../api/network/axios/exportRequest";

const dateFormat = "DD-MM-YYYY";

const { RangePicker } = DatePicker;

const topColResponsiveProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

function AlertLog() {
  const today = new Date();
  const todayStr =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  const dispatch = useDispatch();

  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [updatedTable, setUpdatedTable] = useState(false);
  const [missingFieldsText, setMissingFieldsText] = useState();
  const [dateValue, setDateValue] = useState([]);
  const [dataTable, setDataTable] = useState();
  const [filter, setFilter] = useState({
    fromDate: todayStr,
    toDate: todayStr,
    includeRead: false,
  });
  const [refresh, setRefresh] = useState(false);

  const {
    data,
    allData,
    error,
    requestStatus,
    loading,
    user,
    userTimeZone,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    data: store.reportReducer.data,
    allData: store.reportReducer.allData,
    error: store.reportReducer.error,
    requestStatus: store.reportReducer.requestStatus,
    loading: store.reportReducer.loading,
    user: store.authReducer.user,
    userTimeZone: store.authReducer.userTimeZone,
    exportData: store.reportReducer.exportData,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const columns = [
    {
      title:
        language?.DATE_AND_TIME?.value ?? englishLang?.DATE_AND_TIME?.value,
      dataIndex: "timestamp",
      render: (timestamp) => (
        <span>
          {timestamp
            ? moment.tz(timestamp, userTimeZone).format("DD-MM-YYYY HH:mm:ss")
            : ""}
        </span>
      ),
    },
    {
      title: language?.ASSET?.value ?? englishLang?.ASSET?.value,
      dataIndex: ["asset"],
      render: (a) => (
        <>
          {" "}
          <strong> {a && a.code ? a.code : " "}</strong>{" "}
          {a && a.name ? a.name : " "}
        </>
      ),
    },
    {
      title: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      dataIndex: ["zone", "name"],
    },
    {
      title: language?.ALERT_RULE?.value ?? englishLang?.ALERT_RULE?.value,
      dataIndex: ["alertRule", "name"],
    },
  ];

  const [ResultNotifier, isLoading] = useNotification(error, requestStatus);

  const {
    DataTable,
    currentPage,
    pageSize,
    selected,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    dataSource: data,
    updateEntityPath: "",
    filter,
    loading: loading,
    rowSelectors: true,
    missingFieldsText: missingFieldsText,
  });

  const checkNoMissingParams = () => {
    if (!(filter.fromDate && filter.toDate)) {
      setMissingFieldsText(
        language?.SELECT_DATE_RANGE?.value ??
          englishLang?.SELECT_DATE_RANGE?.value
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    // dispatch(resetReport());
    if (checkNoMissingParams()) {
      setMissingFieldsText();
      dispatch(
        fetchReport({
          page: searchFormHelperHandler(),
          size: pageSize,
          fromDate: toTimestamp(filter.fromDate, "start"),
          toDate: toTimestamp(filter.toDate, "end"),
          includeRead: filter.includeRead,
          endpoint: "alert-log",
        })
      );
    }
  }, [currentPage, filter, isLoading, updatedTable, refresh, pageSize]);

  useEffect(() => {
    setDataTable(DataTable);
  }, [loading, language]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-alert-log";
    let options = {
      url: "/api/web/alert-logs/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        fromDate: toTimestamp(filter.fromDate, "start"),
        toDate: toTimestamp(filter.toDate, "end"),
        includeRead: filter.includeRead,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  const handleDateRangeChange = (date, dateString) => {
    setDateValue(date);
    setFilter({
      ...filter,
      fromDate: dateString[0],
      toDate: dateString[1],
    });
  };

  const handleReadChange = (e) => {
    setFilter({
      ...filter,
      includeRead: e.target.checked,
    });
  };

  let flattenedData = [];

  if (Object.keys(data).length !== 0) {
    let flattenedData;
    function flatDataHandler() {
      if (data.content === undefined) {
        return (flattenedData = Object.values(data));
      } else {
        return (flattenedData = Object.values(data.content));
      }
    }
    flatDataHandler();

    flattenedData.forEach((record) => {
      // record.asset= {
      //     codeName :'('  + record.asset?.code + ') ' + record.asset?.name
      // };
      if (record.asset == undefined) {
        record.asset = {};
      }
      record.asset && record.asset.codeName
        ? (record.asset.codeName =
            "(" + record.asset?.code + ") " + record.asset?.name)
        : " ";
      record.readString = record.read ? "READ" : "NOT READ";
      record.timestampFormatted = record.timestamp
        ? moment
            .tz(record.timestamp, userTimeZone)
            .format("DD-MM-YYYY HH:mm:ss")
        : "";
    });
  }

  useEffect(() => {
    if (allData && Object.keys(allData).length !== 0) {
      var flatAllData = Object.values(allData.content);
      flatAllData.forEach((record) => {
        // record.asset= {
        //     codeName :'('  + record.asset?.code + ') ' + record.asset?.name
        // };
        if (record.asset == "undefined") {
          record.asset = {};
        }
        record.asset.codeName =
          "(" + record.asset?.code + ") " + record.asset?.name;
        record.readString = record.read ? "READ" : "NOT READ";
        record.timestampFormatted = record.timestamp
          ? moment
              .tz(record.timestamp, userTimeZone)
              .format("DD-MM-YYYY HH:mm:ss")
          : "";
      });

      setFlattenedAllData(flatAllData);
    }
  }, [allData]);

  const headers = [
    {
      label:
        language?.DATE_AND_TIME?.value ?? englishLang?.DATE_AND_TIME?.value,
      key: "timestampFormatted",
    },
    {
      label: language?.ASSET?.value ?? englishLang?.ASSET?.value,
      key: "asset.codeName",
    },
    {
      label: language?.ZONE?.value ?? englishLang?.ZONE?.value,
      key: "zone.name",
    },
    {
      label: language?.RULE?.value ?? englishLang?.RULE?.value,
      key: "alertRule.name",
    },
    {
      label: language?.READ?.value ?? englishLang?.READ?.value,
      key: "readString",
    },
  ];

  const handleMarkAsRead = () => {
    if (selected.length > 0) {
      dispatch(markAsRead(selected)).then(() => {
        setUpdatedTable(!updatedTable);
      });
    }
  };

  //  const handleMarkAllAsRead=()=>{
  //     const allUnread = (allData.content).filter(function(item) {
  //         return item.read === false;
  //     });

  //     if (allUnread.length > 0) {
  //         var allUnreadIds = [];
  //         allUnread.map((a)=>{
  //             allUnreadIds.push(a.alertLogId);
  //         })

  //         dispatch(
  //             markAsRead(allUnreadIds),
  //         ) .then(()=>{
  //             setUpdatedTable(!updatedTable)
  //           })

  //     }

  //  }

  const handleRefreshClick = () => {
    setRefresh(!refresh);
  };

  const markAsReadButton = hasPermission(selectedTenant, "MARK_AS_READ") ? (
    <Button
      type="primary"
      style={{ float: "right" }}
      onClick={handleMarkAsRead}
    >
      Mark as Read
    </Button>
  ) : null;

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath=""
          //   hasSelected={hasSelected}
          exportTableTitle={
            language?.ALERTLOG?.value ?? englishLang?.ALERTLOG?.value
          }
          addNewText=""
          title={language?.ALERT_LOG?.value ?? englishLang?.ALERT_LOG?.value}
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportAllButtonClick={handleExportAllButtonClick}
        />

        <Row>
          <Col {...topColResponsiveProps}>
            <Space>
              <Tooltip
                title={
                  language?.SELECT_DATE_RANGE?.value ??
                  englishLang?.SELECT_DATE_RANGE?.value
                }
              >
                <RangePicker
                  id={ElementIDS().calendar}
                  defaultValue={[
                    moment(todayStr, dateFormat),
                    moment(todayStr, dateFormat),
                  ]}
                  format={dateFormat}
                  onChange={handleDateRangeChange}
                />
              </Tooltip>
              <Button
                id={ElementIDS().refresh}
                type="primary"
                onClick={() => handleRefreshClick()}
              >
                {" "}
                <RedoOutlined />
                {language?.REFRESH?.value ?? englishLang?.REFRESH?.value}
              </Button>
            </Space>
          </Col>
          <Col {...topColResponsiveProps}>
            <Space style={{ float: "right" }}>
              {markAsReadButton}

              {/* <Button
                                // type="primary"
                                style={{ float: 'right' }}
                                onClick={handleMarkAllAsRead}
                            >
                                Mark All as Read
                            </Button>      */}
            </Space>
          </Col>
        </Row>
        <Divider />
        {dataTable}
        <ResultNotifier />
      </div>
    </>
  );
}

export default AlertLog;
