import * as type from "../types";
import {
  getIpRelayCardsService,
  saveIpRelayCardService,
  deleteIpRelayCardService,
  getAllIpRelayCardsService,
  getSingleIpRelayCardsService,
} from "../../network/service/IpRelayCardService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchIpRelayCards = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.IPRELAYCARDS_FETCH_REQUEST,
      });
      const response = await dispatch(getIpRelayCardsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.IPRELAYCARDS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.IPRELAYCARDS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.IPRELAYCARDS_FETCH_FAILURE,
      });
    }
  };
};

export const fetchSingleIpRelayCards = (id = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.FETCH_IPRELAYCARD_REQUEST,
      });
      const response = await dispatch(getSingleIpRelayCardsService(id));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.FETCH_IPRELAYCARD_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.FETCH_IPRELAYCARD_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.FETCH_IPRELAYCARD_FAILURE,
      });
    }
  };
};

export const fetchAllIpRelayCards = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ALL_IPRELAYCARDS_FETCH_REQUEST,
      });
      const response = await dispatch(getAllIpRelayCardsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ALL_IPRELAYCARDS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ALL_IPRELAYCARDS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ALL_IPRELAYCARDS_FETCH_FAILURE,
      });
    }
  };
};

export const saveIpRelayCard = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.IPRELAYCARD_SAVE_REQUEST,
      });
      const response = await dispatch(saveIpRelayCardService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.IPRELAYCARD_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.IPRELAYCARD_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.IPRELAYCARD_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteIpRelayCard = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.IPRELAYCARD_DELETE_REQUEST,
      });
      const response = await dispatch(deleteIpRelayCardService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.IPRELAYCARD_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.IPRELAYCARD_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.IPRELAYCARD_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
