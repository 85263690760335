import { request } from "../axios/request";

export const getPermissionsService = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/permissions", getState(), {
        method: "GET",
        params,
      })
    );
  };
};

// export const savePermissionService = (data, id = 0) => {
// 	if (id > 0) {
// 		return async (dispatch, getState) => {
// 			return dispatch(
// 				request('/permissions/' + id, getState(), {
// 					method: 'PUT',
// 					data,
// 				}),
// 			);
// 		};
// 	}
// 	return async (dispatch, getState) => {
// 		return dispatch(
// 			request('/permissions', getState(), {
// 				method: 'POST',
// 				data,
// 			}),
// 		);
// 	};
// };

// export const deletePermissionService = (id = 0) => {
// 	return async (dispatch, getState) => {
// 		return dispatch(
// 			request('/permissions/' + id, getState(), {
// 				method: 'DELETE'
// 			}),
// 		);
// 	};
// };
