import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";

import { saveAntenna } from "../../../api/redux/action/AntennaActions";
import useNotification from "../../../components/common/ResultNotifier";
import AddAntennaForm from "./AddAntennaForm";
import { useHistory } from "react-router-dom";
import UnsavedChanges from "../../../components/CustomHooks/UnsavedChanges";

import { ElementIDS } from "../../../components/common/ElementsIDs";
import { hasPermission } from "../../layout/Constants";
import AddFooterSection from "../../../components/common/AddFooterSection";
import { validateInput } from "../../../components/RequiredFieldRule";

const AddAntenna = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [prompt, setDirty, setPristine] = UnsavedChanges();

  let history = useHistory();

  const { error, requestStatus, user, language, englishLang, selectedTenant } =
    useSelector((store) => ({
      error: store.antennaReducer.error,
      requestStatus: store.antennaReducer.requestStatus,
      user: store.authReducer.user,
      language: store.langReducer.language,
      englishLang: store.langReducer.englishLang,
      selectedTenant: store.authReducer.selectedTenant,
    }));

  let langSavedItem =
    language?.SAVED_ITEM?.value ?? englishLang?.SAVED_ITEM?.value;
  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    langSavedItem
  );

  useEffect(() => {
    if (props.location.state && props.location.state.selectedRow) {
      form.setFieldsValue({
        antennaId: props.location.state.selectedRow.antennaId,
        antennaNumber: props.location.state.selectedRow.antennaNumber,
        reader: props.location.state.selectedRow.reader.readerId,
        zone: props.location.state.selectedRow.zone.zoneId,
      });
    }
  }, []);

  useEffect(() => {
    if (requestStatus === "SUCCESS") {
      return history.push("/antennas");
    }
  }, [requestStatus]);

  const handleSave = (values) => {
    const formData = {
      antennaNumber: form.getFieldValue().antennaNumber,
      readerId: form.getFieldValue().reader,
      zoneId: form.getFieldValue().zone,
    };
    setPristine();
    dispatch(saveAntenna(formData, form.getFieldValue().antennaId));
  };

  const handleCancel = () => {
    history.push("/antennas");
  };

  function handleEditingPermission() {
    return hasPermission(selectedTenant, "UPDATE_ANTENNAS");
  }

  const footer = (
    <AddFooterSection
      language={language}
      viewOption={props.location.state}
      handleCancel={handleCancel}
      form={form}
      updatePath={"/update-antenna"}
      handleEditingPermission={handleEditingPermission}
    />
  );

  return (
    <Card
      title={language?.ANTENNA?.value ?? englishLang?.ANTENNA?.value}
      loading={isLoading}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <AddAntennaForm
            readOnly={props.location.state}
            handleSave={handleSave}
            form={form}
            footer={footer}
            setDirty={setDirty}
          />
        </Col>
      </Row>
      {prompt}
      <ResultNotifier />
    </Card>
  );
};

export default AddAntenna;
