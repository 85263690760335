import { request } from "../axios/request";

export const getTotalsService = (params = null) => {
  return async (dispatch, getState) => {
    return dispatch(
      request("/reports/summary", getState(), {
        method: "GET",
        params,
      })
    );
  };
};
