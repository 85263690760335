import * as type from "../types";
import {
  deleteUserGroupService,
  getUserGroupsService,
  saveUserGroupService,
} from "../../network/service/UserGroupService";
import { ResponseStatus } from "../../network/axios/ResponseStatus";
import dispatchStateIfUnauthorized from "./util";

export const fetchUserGroups = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.USERGROUPS_FETCH_REQUEST,
      });
      const response = await dispatch(getUserGroupsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.USERGROUPS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.USERGROUPS_FETCH_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.USERGROUPS_FETCH_FAILURE,
      });
    }
  };
};

export const saveUserGroup = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.USERGROUP_SAVE_REQUEST,
      });
      const response = await dispatch(saveUserGroupService(data, id));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.USERGROUP_SAVE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.USERGROUP_SAVE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.USERGROUP_SAVE_FAILURE,
        payload: ex,
      });
    }
  };
};

export const deleteUserGroup = (data, id = 0) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.USERGROUP_DELETE_REQUEST,
      });
      const response = await dispatch(deleteUserGroupService(data));
      if (
        response.status === ResponseStatus.OK ||
        response.status === ResponseStatus.CREATED
      ) {
        dispatch({
          type: type.USERGROUP_DELETE_SUCCESS,
        });
      } else {
        dispatch({
          type: type.USERGROUP_DELETE_FAILURE,
          payload: response.data,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.USERGROUP_DELETE_FAILURE,
        payload: ex,
      });
    }
  };
};
