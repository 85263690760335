import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../../components/common/Header";
import useDataTable from "../../../components/common/DataTable";
import {
  fetchAssetGroups,
  deleteAssetGroup,
  fetchAllAssetGroups,
} from "../../../api/redux/action/AssetGroupActions";
import { exportRequest } from "../../../api/network/axios/exportRequest";
import AssetGroupActionView from "../../../components/common/ActionMenu";
import useNotification from "../../../components/common/ResultNotifier";
import { hasPermission } from "../../layout/Constants";
import { useHistory } from "react-router-dom";
function AssetGroups() {
  const dispatch = useDispatch();
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [searchText, setSearchText] = useState();
  const [dataTable, setDataTable] = useState();
  const [flattenedAllData, setFlattenedAllData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: null,
    order: null,
  });

  let history = useHistory();

  const {
    assetGroups,
    allAssetGroups,
    error,
    requestStatus,
    loading,
    user,
    language,
    englishLang,
    selectedTenant,
  } = useSelector((store) => ({
    assetGroups: store.assetGroupReducer.assetGroups,
    allAssetGroups: store.assetGroupReducer.allAssetGroups,
    error: store.assetGroupReducer.error,
    requestStatus: store.assetGroupReducer.requestStatus,
    loading: store.assetGroupReducer.loading,
    language: store.langReducer.language,
    user: store.authReducer.user,
    englishLang: store.langReducer.englishLang,
    selectedTenant: store.authReducer.selectedTenant,
  }));

  const columns = [
    {
      title: language?.NAME?.value ?? englishLang?.NAME?.value,
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortField: "name",
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      render: (name) => (
        <a
          onClick={() => {
            const viewOption = "disabled";

            history.push({
              pathname: "/update-assetGroup",
              state: { selectedRow, viewOption },
            });
          }}
        >
          {name}
        </a>
      ),
    },
    {
      title: language?.DEPARTMENT?.value ?? englishLang?.DEPARTMENT?.value,
      dataIndex: ["department", "name"],
      key: "department",
      sorter: true,
      sortField: "departmentEntity.name",
      sortOrder:
        sortedInfo.columnKey === "departmentEntity.name" && sortedInfo.order,
    },
  ];

  const [allowPermission, setAllowPermission] = useState({
    edit: hasPermission(selectedTenant, "UPDATE_ASSET_GROUPS"),
    delete: hasPermission(selectedTenant, "DELETE_ASSET_GROUPS"),
    view: hasPermission(selectedTenant, "READ_ASSET_GROUPS"),
  });

  const [ResultNotifier, isLoading] = useNotification(
    error,
    requestStatus,
    null,
    "ASSETS_NOT_DELETED"
  );

  const handleSingleDelete = (selected) => {
    dispatch(deleteAssetGroup(selected.assetGroupId));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  };

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    selectedRow,
    sortOrder,
    sortField,
    searchFormHelperHandler,
  } = useDataTable({
    columns,
    dataSource: assetGroups,
    updateEntityPath: "update-assetGroup",
    customActionView:
      allowPermission.edit || allowPermission.delete || allowPermission.view
        ? AssetGroupActionView
        : "",
    searchText,
    loading: loading,
    handleSingleDelete,
    allowPermission,
    setSortedInfo,
    title: language?.ASSET_GROUPS?.value ?? englishLang?.ASSET_GROUPS?.value,
  });

  useEffect(() => {
    dispatch(
      fetchAssetGroups({
        page: searchFormHelperHandler(),
        size: pageSize,
        searchText: searchText,
        sort: sortedInfo.columnKey
          ? sortedInfo.columnKey +
            "," +
            (sortedInfo.order == "ascend" ? "ASC" : "DESC")
          : null,
      })
    );
  }, [currentPage, isLoading, searchText, sortedInfo]);

  useEffect(() => {
    setDataTable(DataTable);
  }, [loading, selectedRow, language]);

  const handleExportAllButtonClick = () => {
    setExportAllLoading(true);
    const exportName = "export-asset-group";
    let options = {
      url: "/api/web/asset-groups/export",
      method: "GET",
      responseType: "blob", // important\
      withCredentials: true,
      headers: {
        Accept: "application/vnd.handson.rfid.v5+json",
      },
    };

    options = {
      ...options,
      params: {
        searchText: searchText,
      },
    };
    exportRequest(options, setExportAllLoading, exportName);
  };

  let flattenedData = [];

  if (Object.keys(assetGroups).length !== 0) {
    flattenedData = Object.values(assetGroups.content);
  }

  useEffect(() => {
    if (allAssetGroups && Object.keys(allAssetGroups).length !== 0) {
      setFlattenedAllData(Object.values(allAssetGroups.content));
    }
  }, [allAssetGroups]);

  const headers = [
    { label: language?.NAME?.value ?? englishLang?.NAME?.value, key: "name" },
    {
      label: language?.DEPARTMENT?.value ?? englishLang?.DEPARTMENT?.value,
      key: "department.name",
    },
  ];

  const addNewPath = hasPermission(selectedTenant, "CREATE_ASSET_GROUPS")
    ? "add-assetGroup"
    : "";

  const tooltipCheck = hasPermission(selectedTenant, "READ_DEPARTMENTS")
    ? language?.TOOLTIP_SEARCH_ASSET_GROUP?.value ??
      englishLang?.TOOLTIP_SEARCH_ASSET_GROUP?.value
    : language?.TOOLTIP_SEARCH_NAME?.value ??
      englishLang?.TOOLTIP_SEARCH_NAME?.value;

  return (
    <>
      <div style={{ padding: 24, background: "#fff", minHeight: 20 }}>
        <Header
          addNewPath={addNewPath}
          hasSelected={hasSelected}
          exportTableTitle={
            language?.ASSETGROUPS?.value ?? englishLang?.ASSETGROUPS?.value
          }
          addNewText={
            language?.ADD_ASSET_GROUP?.value ??
            englishLang?.ADD_ASSET_GROUP?.value
          }
          title={
            language?.ASSET_GROUPS?.value ?? englishLang?.ASSET_GROUPS?.value
          }
          csvData={flattenedData}
          csvAllData={flattenedAllData}
          csvHeaders={headers}
          exportAllButtonClick={handleExportAllButtonClick}
          exportAllLoading={exportAllLoading}
          loading={loading}
          exportButton={true}
          exportAllButton={true}
          handleSearch={setSearchText}
          tooltipText={tooltipCheck}
        />
        {dataTable}
        <ResultNotifier />
      </div>
    </>
  );
}

export default AssetGroups;
