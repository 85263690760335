import * as type from "../types";
import { RequestState } from "./RequestState";
import rfidLogo from "../../../assets/images/rfid_logo_lhtBlue.png";

const INITIAL_STATE = {
  users: {},
  user: {},
  preferredLogo: {},
  preferredColumns: {},
  error: {},
  requestStatus: RequestState.EMPTY,
  loading: false,
  savedLogoSuccess: false,
  tokenValid: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.USERS_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        users: {},
        loading: true,
      };
    case type.USERS_FETCH_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case type.USERS_FETCH_FAILURE:
      return {
        ...state,
        users: {},
        loading: false,
      };
    case type.USER_SAVE_REQUEST:
      return {
        ...state,
        error: {},
        user: {},
        requestStatus: RequestState.PENDING,
      };
    case type.USER_SAVE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.USER_SAVE_FAILURE:
      return {
        ...state,
        user: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
    case type.USER_DELETE_REQUEST:
      return {
        ...state,
        requestStatus: RequestState.PENDING,
      };
    case type.USER_DELETE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.USER_DELETE_FAILURE:
      return {
        ...state,
        user: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
    case type.CLEAN_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: RequestState.EMPTY,
      };

    case type.USER_ACTIVATE_REQUEST:
      return {
        ...state,
        requestStatus: RequestState.PENDING,
      };
    case type.USER_ACTIVATE_SUCCESS:
      return {
        ...state,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.USER_ACTIVATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
    case type.PREFERRED_COLUMNS_HANDHELD_FETCH_REQUEST:
      return {
        ...state,
        error: {},
        preferredColumns: {},
        loading: true,
      };
    case type.PREFERRED_COLUMNS_HANDHELD_FETCH_SUCCESS:
      return {
        ...state,
        preferredColumns: action.payload,
        loading: false,
      };
    case type.PREFERRED_COLUMNS_HANDHELD_FETCH_FAILURE:
      return {
        ...state,
        preferredColumns: {},
        loading: false,
      };
    case type.PREFERRED_COLUMNS_HANDHELD_SAVE_REQUEST:
      return {
        ...state,
        error: {},
        requestStatus: RequestState.PENDING,
      };
    case type.PREFERRED_COLUMNS_HANDHELD_SAVE_SUCCESS:
      return {
        ...state,
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.PREFERRED_COLUMNS_HANDHELD_SAVE_FAILURE:
      return {
        ...state,
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };
    case type.USER_LOGO_REQUEST:
      return {
        ...state,
        savedLogoSuccess: false,
        error: {},
        loading: true,
        requestStatus: RequestState.PENDING,
      };
    case type.USER_LOGO_SUCCESS:
      return {
        ...state,
        error: {},
        savedLogoSuccess: true,
        loading: false,
        requestStatus: RequestState.SUCCESS,
      };
    case type.USER_LOGO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        savedLogoSuccess: false,
        requestStatus: RequestState.FAILURE,
      };
    case type.GET_USER_LOGO_REQUEST:
      return {
        ...state,
        error: {},
        preferredLogo: {},
        loading: true,
      };
    case type.GET_USER_LOGO_SUCCESS:
      localStorage.setItem("preferredLogo", JSON.stringify(action.payload));
      return {
        ...state,
        preferredLogo: action.payload,
        loading: false,
      };
    case type.GET_USER_LOGO_FAILURE:
      return {
        ...state,
        preferredLogo: {},
        loading: false,
      };
    case type.GET_USER_LOGO_LOGIN_REQUEST:
      return {
        ...state,
        error: {},
        preferredLogo: {},
        loading: true,
      };
    case type.GET_USER_LOGO_LOGIN_SUCCESS:
      return {
        ...state,
        preferredLogo: action.payload,
        loading: false,
      };
    case type.GET_USER_LOGO_LOGIN_FAILURE:
      return {
        ...state,
        preferredLogo: {},
        loading: false,
      };
    case type.DELETE_USER_LOGO_REQUEST:
      return {
        ...state,
        requestStatus: RequestState.PENDING,
      };
    case type.DELETE_USER_LOGO_SUCCESS:
      localStorage.removeItem("preferredLogo");
      return {
        ...state,
        preferredLogo: {},
        error: {},
        requestStatus: RequestState.SUCCESS,
      };
    case type.DELETE_USER_LOGO_FAILURE:
      return {
        ...state,
        preferredLogo: {},
        error: action.payload,
        requestStatus: RequestState.FAILURE,
      };

    case type.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        requestStatus: RequestState.PENDING,
      };
    case type.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        requestStatus: RequestState.SUCCESS,
      };
    case type.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        requestStatus: RequestState.FAILURE,
      };

    case type.TOKEN_VALID_REQUEST:
      return {
        ...state,
        error: {},
        tokenValid: null,
        loading: true,
        requestStatus: RequestState.PENDING,
      };
    case type.TOKEN_VALID_SUCCESS:
      return {
        ...state,
        tokenValid: true,
        loading: false,
        requestStatus: RequestState.SUCCESS,
      };
    case type.TOKEN_VALID_FAILURE:
      return {
        ...state,
        tokenValid: false,
        loading: false,
        requestStatus: RequestState.FAILURE,
      };

    default:
      return state;
  }
};

export default reducer;
