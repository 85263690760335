export const getAlertRuleDescription = (form, language, englishLang) => {
  let name = form.getFieldValue().name ? (
    <p>
      {" "}
      {language?.ALERT_RULE?.value ?? englishLang?.ALERT_RULE?.value}{" "}
      <strong> {form.getFieldValue().name} </strong>{" "}
      {language?.IS_TRIGGERD_WHEN?.value ??
        englishLang?.IS_TRIGGERD_WHEN?.value}{" "}
    </p>
  ) : null;

  let assetGroupsAndAssets = null;
  if (
    form.getFieldValue().assetGroupObject &&
    form.getFieldValue().assetGroupObject.length > 0 &&
    form.getFieldValue().assetObject &&
    form.getFieldValue().assetObject.length > 0
  ) {
    //assetGroups & assets
    assetGroupsAndAssets = (
      <>
        <p> {language?.ASSETS_S?.value ?? englishLang?.ASSETS_S?.value} </p>
        <ul>
          {form.getFieldValue().assetObject.map((item) => (
            <li key={item.id} value={item.id}>
              {item.name}
            </li>
          ))}
        </ul>
        <p>
          {" "}
          {language?.ANY_ASSET_IN_ASSET_GROUP_S?.value ??
            englishLang?.ANY_ASSET_IN_ASSET_GROUP_S?.value}{" "}
        </p>
        <ul>
          {form.getFieldValue().assetGroupObject.map((item) => (
            <li key={item.id} value={item.id}>
              {item.name}
            </li>
          ))}
        </ul>
      </>
    );
  } else if (
    form.getFieldValue().assetGroupObject &&
    form.getFieldValue().assetGroupObject.length > 0
  ) {
    //assetGroups
    assetGroupsAndAssets = (
      <>
        <p>
          {" "}
          {language?.ANY_ASSET_IN_ASSET_GROUP_S?.value ??
            englishLang?.ANY_ASSET_IN_ASSET_GROUP_S?.value}{" "}
        </p>
        <ul>
          {form.getFieldValue().assetGroupObject &&
            form.getFieldValue().assetGroupObject.map((item) => (
              <li key={item.id} value={item.id}>
                {item.name}
              </li>
            ))}
        </ul>
      </>
    );
  } else if (
    form.getFieldValue().assetObject ||
    form.getFieldValue().assetGroupObject
  ) {
    //assets
    assetGroupsAndAssets = (
      <>
        <p> {language?.ASSETS_S?.value ?? englishLang?.ASSETS_S?.value} </p>
        <ul>
          {form.getFieldValue().assetObject &&
            form.getFieldValue().assetObject.map((item) => (
              <li key={item.id} value={item.id}>
                {item.name}
              </li>
            ))}
        </ul>
      </>
    );
  }

  let detectedOrUndetected =
    form.getFieldValue().assetObject ||
    form.getFieldValue().assetGroupObject ? (
      <p>
        {" "}
        {language?.ARE?.value ?? englishLang?.ARE?.value}{" "}
        <strong>
          {" "}
          {language?.DETECTED?.value ?? englishLang?.DETECTED?.value}
        </strong>{" "}
        {language?.IN?.value ?? englishLang?.IN?.value}{" "}
      </p>
    ) : (
      ""
    );

  let sitesAndZones = null;
  if (
    form.getFieldValue().siteObject &&
    form.getFieldValue().siteObject.length > 0 &&
    form.getFieldValue().zoneObject &&
    form.getFieldValue().zoneObject.length > 0
  ) {
    //sites & zones
    sitesAndZones = (
      <>
        <p> {language?.ZONE_S?.value ?? englishLang?.ZONE_S?.value} </p>
        <ul>
          {form.getFieldValue().zoneObject.map((item) => (
            <li key={item.id} value={item.id}>
              {item.name}
            </li>
          ))}
        </ul>
        <p>
          {" "}
          {language?.ANY_ZONE_IN_SITE_S?.value ??
            englishLang?.ANY_ZONE_IN_SITE_S?.value}{" "}
        </p>
        <ul>
          {form.getFieldValue().siteObject.map((item) => (
            <li key={item.id} value={item.id}>
              {item.name}
            </li>
          ))}
        </ul>
      </>
    );
  } else if (
    form.getFieldValue().siteObject &&
    form.getFieldValue().siteObject.length > 0
  ) {
    //sites
    sitesAndZones = (
      <>
        <p>
          {" "}
          {language?.ANY_ZONE_IN_SITE_S?.value ??
            englishLang?.ANY_ZONE_IN_SITE_S?.value}{" "}
        </p>
        <ul>
          {form.getFieldValue().siteObject.map((item) => (
            <li key={item.id} value={item.id}>
              {item.name}
            </li>
          ))}
        </ul>
      </>
    );
  } else if (
    form.getFieldValue().zoneObject &&
    form.getFieldValue().zoneObject.length > 0
  ) {
    //zones
    sitesAndZones = (
      <>
        <p> {language?.ZONE_S?.value ?? englishLang?.ZONE_S?.value} </p>
        <ul>
          {form.getFieldValue().zoneObject.map((item) => (
            <li key={item.id} value={item.id}>
              {item.name}
            </li>
          ))}
        </ul>
      </>
    );
  }

  let relaySwitchesAndContacts = null;
  if (
    form.getFieldValue().relaySwitchObject &&
    form.getFieldValue().relaySwitchObject.length > 0 &&
    form.getFieldValue().contactObject &&
    form.getFieldValue().contactObject.length > 0
  ) {
    //relay Switches and contacts
    relaySwitchesAndContacts = (
      <>
        <p>
          {language?.RELAY_SWITCHES_LIST?.value ??
            englishLang?.RELAY_SWITCHES_LIST?.value}{" "}
        </p>
        <ul>
          {form.getFieldValue().relaySwitchObject.map((item) => (
            <li key={item.id} value={item.id}>
              {item.name}
            </li>
          ))}
        </ul>
        <p>
          {" "}
          {language?.WILL_BE_OFF_AND_CONTACTS?.value ??
            englishLang?.WILL_BE_OFF_AND_CONTACTS?.value}{" "}
        </p>
        <ul>
          {form.getFieldValue().contactObject.map((item) => (
            <li key={item.id} value={item.id}>
              {item.name}
            </li>
          ))}
        </ul>
        <p>
          {language?.WILL_BE_NOTIFIED?.value ??
            englishLang?.WILL_BE_NOTIFIED?.value}{" "}
        </p>
      </>
    );
  } else if (
    form.getFieldValue().relaySwitchObject &&
    form.getFieldValue().relaySwitchObject.length > 0
  ) {
    //relay switches
    relaySwitchesAndContacts = (
      <>
        <p>
          {language?.RELAY_SWITCHES_LIST?.value ??
            englishLang?.RELAY_SWITCHES_LIST?.value}
        </p>
        <ul>
          {form.getFieldValue().relaySwitchObject.map((item) => (
            <li key={item.id} value={item.id}>
              {item.name}
            </li>
          ))}
        </ul>
        <p>
          {" "}
          {language?.WILL_BE_OFF?.value ?? englishLang?.WILL_BE_OFF?.value}
        </p>
      </>
    );
  } else if (
    form.getFieldValue().contactObject &&
    form.getFieldValue().contactObject.length > 0
  ) {
    //contacts
    relaySwitchesAndContacts = (
      <>
        <p>
          {" "}
          {language?.CONTACTS_LIST?.value ??
            englishLang?.CONTACTS_LIST?.value}{" "}
        </p>
        <ul>
          {form.getFieldValue().contactObject.map((item) => (
            <li key={item.id} value={item.id}>
              {item.name}
            </li>
          ))}
        </ul>
        <p>
          {language?.WILL_BE_NOTIFIED?.value ??
            englishLang?.WILL_BE_NOTIFIED?.value}
        </p>
      </>
    );
  }

  const descriptionText =
    name ||
    assetGroupsAndAssets ||
    detectedOrUndetected ||
    sitesAndZones ||
    relaySwitchesAndContacts ? (
      <>
        {name}
        {assetGroupsAndAssets}
        {detectedOrUndetected}
        {sitesAndZones}
        {relaySwitchesAndContacts}
      </>
    ) : null;

  return descriptionText;
};
