import React, { useEffect, useState } from "react";
import { Form, Input, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useSelector } from "react-redux";
import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "../../layout/Style.css";
import { defaultIcon } from "../../layout/Constants";
import { ElementIDS } from "../../../components/common/ElementsIDs";

const currentTimestamp = Date.now();

const AddSiteForm = (props) => {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const { englishLang } = useSelector((store) => ({
    englishLang: store.langReducer.englishLang,
  }));
  useEffect(() => {
    let mapInstance = L.map("mapSite" + currentTimestamp, {
      center: [35.9375, 14.3754],
      zoom: 10,
    });

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      mapInstance
    );

    setMap(mapInstance);

    return () => {
      if (mapInstance) {
        mapInstance.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (map && props.form.getFieldsValue().location) {
      const { latitude, longitude } = props.form.getFieldsValue().location;
      const markerLatLng = new L.LatLng(latitude, longitude);
      const latLng = {
        latlng: markerLatLng,
      };
      addMarker(latLng);
      map.panTo(markerLatLng);
    }
  }, [map]);

  useEffect(() => {
    if (map && (!props.readOnly || props.readOnly.viewOption !== "disabled")) {
      const clickHandler = (e) => {
        addMarker(e);
        props.setDirty();
      };

      map.on("click", clickHandler);

      return () => {
        map.off("click", clickHandler);
      };
    }
  }, [marker, map, props.form, props.readOnly]);

  const addMarker = (e) => {
    const { lat, lng } = e.latlng;
    const coordinates = {
      latitude: lat,
      longitude: lng,
    };

    if (marker) {
      map.removeLayer(marker);
    }
    const newMarker = L.marker(e.latlng, {
      icon: defaultIcon,
      draggable: true,
    }).addTo(map);

    newMarker.on("dragend", (event) => {
      const { lat, lng } = event.target.getLatLng();
      const updatedCoordinates = {
        latitude: lat,
        longitude: lng,
      };
      props.form.setFieldsValue({ location: updatedCoordinates });
    });

    setMarker(newMarker);

    props.form.setFieldsValue({ location: coordinates });
  };

  function infoLocation() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "5rem",
        }}
      >
        <p
          style={{
            textalign: "center",
            margin: 0,
          }}
        >
          {props.language?.LOCATION?.value ?? englishLang?.LOCATION?.value}
        </p>
        <InfoCircleOutlined
          style={{
            zIndex: 1,
            fontSize: "14px",
            cursor: "pointer",
            color: "#585858",
          }}
          type="message"
        />
      </div>
    );
  }

  const handleToolTipVisible = () => {
    if (props.readOnly && props.readOnly.viewOption) {
      return false;
    }
  };
  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="site-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        id={ElementIDS().name}
        label={props.language?.NAME?.value ?? englishLang?.NAME?.value}
        name="name"
        rules={requiredFieldRule}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <Tooltip
        title={
          props.language?.CLICK_ON_THE_MAP_TO_SET_LOCATION?.value ??
          englishLang?.CLICK_ON_THE_MAP_TO_SET_LOCATION?.value
        }
        overlayClassName={
          props.readOnly && props.readOnly.viewOption
            ? "toolTip_hidden"
            : "toolTip_Visible"
        }
      >
        <Form.Item
          label={infoLocation()}
          name="location"
          rules={requiredFieldRule}
        >
          <div
            id={"mapSite" + currentTimestamp}
            style={{ height: "40vh", zIndex: 0 }}
          />
        </Form.Item>
      </Tooltip>
      {props.footer}
    </Form>
  );
};

export default AddSiteForm;
