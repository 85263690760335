import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Stage, Layer } from "react-konva";

import { ElementIDS } from "../../../components/common/ElementsIDs";
import { Card, Col, Row, Button } from "antd";

import { fetchSingleAntenna } from "../../../api/redux/action/AntennaActions";
import { fetchSingleFixedReaders } from "../../../api/redux/action/FixedReaderActions";
import { fetchSingleGateway } from "../../../api/redux/action/GatewayActions";
import { fetchSingleIpRelayCards } from "../../../api/redux/action/IpRelayCardActions";

import FloorPlanImage from "../../../components/common/FloorPlanImage";
import FloorPlanComponents from "../../../components/common/FloorPlanComponents";
import FloorPlanComponentModal from "./FloorPlanComponentModal";
import useKanvasLogic from "../../../components/CustomHooks/KanvasLogic";

const ViewFloorPlan = (props) => {
  const [selectedImageComponent, selectImageComponent] = useState({});
  const [isComponentModalOpen, setComponentModalOpen] = useState(false);
  const [dataComponent, setDataComponent] = useState({
    data: null,
    type: null,
  });
  const [stageSpec, setStageSpec] = useState({
    scale: 1,
    x: 0,
    y: 0,
  });
  const stageRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  const { handleZoomInOut, checkDeselectComponent, unSelectComponent } =
    useKanvasLogic({
      setStageSpec,
      selectImageComponent,
    });

  const {
    requestStatus,
    error,
    data,
    loading,
    userTimeZone,
    language,
    englishLang,
  } = useSelector((store) => ({
    requestStatus: store.floorPlanReducer.requestStatus,
    userTimeZone: store.authReducer.userTimeZone,
    loading: store.floorPlanReducer.loading,
    error: store.floorPlanReducer.error,
    data: store.floorPlanReducer.data,
    language: store.langReducer.language,
    englishLang: store.langReducer.englishLang,
  }));

  useEffect(() => {
    setDataComponent({ data: data, type: selectedImageComponent.type });
  }, [selectedImageComponent, data]);

  useEffect(() => {
    switch (selectedImageComponent?.type) {
      case "Reader":
        dispatch(
          fetchSingleFixedReaders(parseInt(selectedImageComponent.componentId))
        );
        break;
      case "Antenna":
        dispatch(
          fetchSingleAntenna(parseInt(selectedImageComponent.componentId))
        );
        break;
      case "Denkovi":
        dispatch(
          fetchSingleIpRelayCards(parseInt(selectedImageComponent.componentId))
        );
        break;
      case "Mini PC":
        dispatch(
          fetchSingleGateway(parseInt(selectedImageComponent.componentId))
        );
        break;
      default:
        null;
      // case "Reader AIO":
      //   dispatch();
      //   break;
    }
  }, [selectedImageComponent]);

  const stageStyle = {
    border: "1px solid lightgrey",
    borderRadius: "4px",
    width: "100%",
    height: "100%",
    overflow: "auto",
  };

  return (
    <>
      <Card title={props.location.state?.selectedRow.title}>
        <Row justify="center">
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={stageStyle}>
              <Stage
                ref={stageRef}
                draggable="true"
                scaleX={stageSpec.scale}
                scaleY={stageSpec.scale}
                x={stageSpec.x}
                y={stageSpec.y}
                width={window.innerWidth * 0.8}
                height={window.innerHeight * 0.68}
                onWheel={handleZoomInOut}
                onMouseDown={checkDeselectComponent}
              >
                <Layer>
                  <FloorPlanImage
                    id="Floor_Plan_Image"
                    src={props.location.state?.selectedRow.urlFloorPlanImage}
                    checkDeselectComponent={checkDeselectComponent}
                    width={1500}
                    height={610}
                    space="fit"
                  />
                  {props.location.state?.selectedRow.components?.map(
                    (component) => (
                      <FloorPlanComponents
                        assetCount={null}
                        isSelected={component === selectedImageComponent}
                        unSelectShape={unSelectComponent}
                        stageScale={stageSpec.scale}
                        key={component.id}
                        stageRef={stageRef}
                        component={component}
                        onSelect={() => {
                          selectImageComponent(component);
                          setComponentModalOpen(true);
                          if (
                            component.type === "Siren" ||
                            component.type === "Barrier"
                          ) {
                            setComponentModalOpen(false);
                          }
                        }}
                      />
                    )
                  )}
                </Layer>
              </Stage>
            </div>

            <Button
              style={{ marginTop: "10px" }}
              id={ElementIDS().cancel}
              type="dashed"
              htmlType="button"
              onClick={() => {
                history.goBack();
              }}
            >
              {language?.CANCEL?.value ?? englishLang?.CANCEL?.value}
            </Button>
          </Col>
        </Row>
        <FloorPlanComponentModal
          setComponentModalOpen={setComponentModalOpen}
          isComponentModalOpen={isComponentModalOpen}
          dataComponent={dataComponent}
          loading={loading}
          userTimeZone={userTimeZone}
        />
      </Card>
    </>
  );
};

export default ViewFloorPlan;
