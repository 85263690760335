import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Checkbox, Switch, message } from "antd";
import CountryPhoneCode from "antd-country-phone-input";

import { requiredFieldRule } from "../../../components/RequiredFieldRule";
import "../../layout/Style.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import parseMax from "libphonenumber-js/max";
import { ElementIDS } from "../../../components/common/ElementsIDs";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
const AddContactForm = (props) => {
  const previousCountRef = useRef();
  const { englishLang } = useSelector((store) => ({
    englishLang: store.langReducer.englishLang,
  }));
  useEffect(() => {
    previousCountRef.current = props.editMobileNumber?.replace(/[\(\)]/g, "");
  }, []);

  return (
    <Form
      labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 6 }}
      wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
      form={props.form}
      name="contact-form"
      onFinish={props.handleSave}
    >
      <Form.Item
        id={ElementIDS().name}
        label={props.language?.NAME?.value ?? englishLang?.NAME?.value}
        name="name"
        rules={requiredFieldRule}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <Form.Item
        id={ElementIDS().surName}
        label={props.language?.SURNAME?.value ?? englishLang?.SURNAME?.value}
        name="surname"
        rules={requiredFieldRule}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>

      <Form.Item
        label={props.language?.MOBILE?.value ?? englishLang?.MOBILE?.value}
        id={ElementIDS().mobile}
      >
        <PhoneInput
          name="mobile"
          isValid={(value, country) => {
            if (value.match(/12345/)) {
              return "Invalid value: " + value + ", " + country.name;
            } else if (value.match(/1234/)) {
              return false;
            } else {
              return true;
            }
          }}
          placeholder={
            props.language?.MOBILE_NUMBER?.value ??
            englishLang?.MOBILE_NUMBER?.value
          }
          value={previousCountRef.current}
          onChange={(value, country) => {
            props.setMobileNumber({
              countryCode: country.dialCode,
              phoneNum: value,
            });
          }}
          country={"mt"}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
          inputClass={
            props.readOnly && props.readOnly.viewOption
              ? "contact_disabled"
              : "form-control"
          }
        />
      </Form.Item>

      <Form.Item
        id={ElementIDS().mobileNotifications}
        label={
          props.language?.MOBILE_NOTIFICATIONS?.value ??
          englishLang?.MOBILE_NOTIFICATIONS?.value
        }
        name="receiveSmsNotifications"
      >
        <Switch
          onChange={props.smsNotificationsHandler}
          checked={props.isSmsNotification}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        ></Switch>
      </Form.Item>
      <Form.Item
        id={ElementIDS().email}
        label={props.language?.EMAIL?.value ?? englishLang?.EMAIL?.value}
        name="email"
        rules={[{ type: "email" }]}
      >
        <Input
          onChange={(e) => {
            props.setDirty();
          }}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        />
      </Form.Item>
      <Form.Item
        id={ElementIDS().emailNotifications}
        label={
          props.language?.EMAIL_NOTIFICATIONS?.value ??
          englishLang?.EMAIL_NOTIFICATIONS?.value
        }
        name="receiveEmailNotifications"
      >
        <Switch
          onChange={props.emailNotificationsHandler}
          checked={props.isEmailNotification}
          disabled={
            props.readOnly && props.readOnly.viewOption ? "disabled" : null
          }
        ></Switch>
      </Form.Item>
      {props.footer}
    </Form>
  );
};

export default AddContactForm;
